export interface IScroll {
  x: number
  y: number
}

export const getScroll = (): IScroll => {
  let x: number
  let y: number
  let docEl: HTMLElement

  if (typeof window.pageYOffset === 'number') {
    x = window.pageXOffset
    y = window.pageYOffset
  } else {
    docEl = document.compatMode && document.compatMode === 'CSS1Compat' ? document.documentElement : document.body
    x = docEl.scrollLeft
    y = docEl.scrollTop
  }
  return { x, y }
}
