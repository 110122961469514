import { IAddressResponse } from '@omnicar/sam-types'
import { countryValueDefaults, countryModel } from './country'
import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredPrimaryKey, requiredString } from './validations'

// Default Values
export const addressValueDefaults: IAddressResponse = {
  addressId: -1,
  address1: '',
  address2: '',
  city: '',
  country: countryValueDefaults,
  postalCode: '',
}
// API Model
const model: IApiModel = {
  name: 'addressModel',
  fields: [
    {
      name: 'addressId',
      uiType: 'string',
      title: 'Address ID',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'address1',
      uiType: 'string',
      title: 'Address 1',
      validation: [requiredString],
    },
    {
      name: 'address2',
      uiType: 'string',
      title: 'Address 2',
    },
    {
      name: 'city',
      uiType: 'string',
      title: 'City',
      validation: [requiredString],
    },
    {
      name: 'postalCode',
      uiType: 'string',
      title: 'Postal Code',
      validation: [requiredString],
    },
    {
      name: 'country',
      uiType: 'string',
      title: 'Country',
      relation: 'Country',
    },
  ],
  view: {},
}

const countryRelation = countryModel()

export const addressModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IAddressResponse>(view, viewContext, model, addressValueDefaults, {
    Country: { name: 'Country', model: countryRelation },
  })
