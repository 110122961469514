import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredNumber, requiredBoolean } from './validations'
import { IAccessKey, AccessKeyPermission } from '@omnicar/sam-types'
import { AccessKeyService } from '@omnicar/sam-types'

export const model: IApiModel = {
  name: 'accessKey',
  fields: [
    {
      name: 'accessKeyId',
      title: 'ID',
      uiType: 'number',
    },
    {
      name: 'apiKey',
      title: 'Api key',
      uiType: 'string',
    },
    {
      name: 'keyPermission',
      title: 'Permission',
      uiType: 'number',
      validation: [requiredNumber],
    },
    {
      name: 'service',
      title: 'Service',
      uiType: 'number',
      validation: [requiredNumber],
    },
    {
      name: 'contractProviderIds',
      title: 'ContractProviderIds',
      uiType: 'string',
    },
    {
      name: 'allProvidersAllowed',
      uiType: 'boolean',
      title: 'All providers allowed',
      validation: [requiredBoolean],
    },
    {
      name: 'createdByUserEmail',
      title: 'Created/Updated by',
      uiType: 'string',
    },
    {
      name: 'representativeUserEmail',
      title: 'Representative User',
      uiType: 'string',
    },
  ],
  view: {},
}

export const accessKeyDefaults: IAccessKey = {
  apiKey: '',
  keyPermission: AccessKeyPermission.Read,
  service: AccessKeyService.Report,
  contractProviderIds: [],
  createdByUserEmail: '',
  representativeUserEmail: '',
  accessKeyId: -1,
  allProvidersAllowed: false,
}

export const accessKeyModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IAccessKey>(view, viewContext, model, accessKeyDefaults, {})
