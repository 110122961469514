import React, { ChangeEvent } from 'react'
import NumberField from '../../Field/NumberField/NumberField'
import { IApiErrors } from '../../../apiModels/apiModel'
import { TPrecision } from '../../../samTypes'

interface IProps {
  precision?: TPrecision
  edit?: boolean
  errors?: IApiErrors
  name: string
  title?: boolean | string
  onBlur?: (e: any) => void
  onChange: (e: ChangeEvent<any>) => void
  valueInCentsOrTenthOfCents: number | null // Value in in cents or tenthOfCents.
}

const DecimalField: React.SFC<IProps> = ({
  precision,
  edit,
  name,
  title,
  onBlur,
  onChange,
  valueInCentsOrTenthOfCents,
}) => {
  const decimals: number = precision && precision === '3Decimals' ? 3 : 2 // Number of decimal places.
  const step: string = precision && precision === '3Decimals' ? '0.001' : '0.01'
  const factor: number = precision && precision === '3Decimals' ? 1000 : 100
  const extraTitle: string = `(Of total price · 1 means the price will be left unchanged) · 1,1 means 10% increased total price, while 1,125 means 12,5% increased total price, etc...`

  let value: number = !valueInCentsOrTenthOfCents ? 0 : valueInCentsOrTenthOfCents
  value = value / factor

  return (
    <NumberField
      className="PriceSpecification__decimal"
      disabled={!edit}
      locked={!edit}
      max={99999}
      min={0}
      name={name}
      onBlur={onBlur}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = '' + Number.parseFloat(e.target.value) * factor
        onChange && onChange(e)
      }}
      step={parseFloat(step)}
      subtitle={`The value is in ${decimals} decimals`}
      title={title + ` ${extraTitle}`}
      value={value}
    />
  )
}

export default DecimalField
