import React, { ChangeEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import { formatDateFieldValue } from 'utils/formatDate'
import './DateField.css'

interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  name: string
  value: Date
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  size?: number
}

const DateField: React.SFC<IProps> = ({ className, disabled, hidden, name, onBlur, onChange, size, value }) => (
  <input
    type="date"
    className={className}
    disabled={disabled}
    hidden={hidden}
    id={name}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    size={size}
    value={formatDateFieldValue(value)}
  />
)

DateField.defaultProps = {
  className: '',
  disabled: false,
  hidden: false,
  value: new Date(),
}

export default withStyledField(DateField, 'DateField')
