import React, { ReactNode } from 'react'
import './FormSection.css'

interface IProps {
  classNamePrefix?: string
  name: string
  title?: boolean | string
  children?: ReactNode
}

const FormSection: React.SFC<IProps> = ({ classNamePrefix, name, title, children }) => (
  <section className={`FormSection ${classNamePrefix}__form-section-${name}`}>
    {title && (
      <h2 className={`FormSection__header ${classNamePrefix}__form-section-${name}-header`}>
        {title !== true ? title : 'Section'}
      </h2>
    )}
    <main className={`FormSection__content ${classNamePrefix}__form-section-${name}-content`}>{children}</main>
  </section>
)

export default FormSection
