import React, { ReactNode } from 'react'
import './AppContent.css'

export interface IProps {
  children: ReactNode
}

const AppContent: React.SFC<IProps> = ({ children }) => <div className="AppContent">{children && children}</div>

export default AppContent
