const prefixKey = 'sdlkWEWrvvsd2342ds#klæxYd8Mbndh'
const tokenRefreshStartedKey = 'refreshStarted'

export const getLocalStoreItem = (key: string) => {
  return localStorage.getItem(`${prefixKey}${key}`)
}

export const setLocalStoreItem = (key: string, value: string) => {
  localStorage.setItem(`${prefixKey}${key}`, value)
}

export const setTokenRefreshStarted = (time: number) => {
  localStorage.setItem(tokenRefreshStartedKey, time.toString())
  return time
}

export const getTokenRefreshStarted = () => {
  const time = Number(localStorage.getItem(tokenRefreshStartedKey))
  return isNaN(time) ? 0 : time
}
