import moment from 'moment'

export enum DateFormat {
  DEFAULT = 'DD.MM.YYYY',
}

/**
 * Return formatted date
 * @param {Date | string} date
 * @param {string} format
 * @returns {string}
 */
export function formatDate(date: Date | string, format: string = DateFormat.DEFAULT): string {
  return moment(date)
    .utc()
    .format(format)
}

/**
 * Return formatted date
 * @param {Date | string} date
 * @returns {string}
 */
export function formatDateFieldValue(date: Date): string {
  return moment(date).format('YYYY-MM-DD')
}
