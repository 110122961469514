import React from 'react'
import { releaseSystemOptions, releaseVersionModel } from 'apiModels/releaseVersion'
import FormSection from 'components/FormSection/FormSection'
import StringField from 'components/Field/StringField/StringField'
import SelectField from 'components/Field/SelectField/SelectField'
import DateField from 'components/Field/DateField/DateField'
import { IReleaseVersionResponse } from '@omnicar/sam-types'
import { Form, Formik } from 'formik'

const model = releaseVersionModel()

interface IProps {
  version: IReleaseVersionResponse
  onSubmit: (releaseVersion: IReleaseVersionResponse) => void
  onReset: () => void
}

const ReleaseVersion: React.SFC<IProps> = ({ version, onSubmit, onReset }) => (
  <Formik onSubmit={onSubmit} initialValues={version} enableReinitialize validate={(values) => model.getErrors(values)}>
    {({ dirty, isValid, handleBlur, handleChange, handleSubmit, values }) => {
      const disableSubmit = !(isValid && dirty)

      return (
        <Form className="ReleaseNotes" onSubmit={handleSubmit} onReset={onReset}>
          <button className="ReleaseNotes__resetbutton" type="reset">
            Cancel/Back
          </button>
          <button className="ReleaseNotes__submitbutton" disabled={disableSubmit} type="submit">
            Save Version
          </button>
          <div className="ReleaseVersion">
            <FormSection name="general" classNamePrefix="ReleaseVersion">
              <span title="Full version name">
                <StringField
                  className="ReleaseVersion__version"
                  name="version"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size={24}
                  title="Version name"
                  value={values.version}
                />
              </span>
              <span title="Release System">
                <SelectField
                  defaultValue={releaseSystemOptions[0].value}
                  className="ReleaseVersion__release-system"
                  name="releaseSystem"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  title="Release System"
                  placeholder="Select a system"
                  options={releaseSystemOptions}
                  value={values.releaseSystem}
                />
              </span>
              <span title="Release Date">
                <DateField
                  className="ReleaseVersion__release-date"
                  name="releaseDate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  title="Release Date"
                  value={new Date(values.releaseDate)}
                />
              </span>
            </FormSection>
          </div>
        </Form>
      )
    }}
  </Formik>
)

export default ReleaseVersion
