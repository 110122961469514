import { applyMiddleware, compose, createStore } from 'redux'
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunkMiddleware from 'redux-thunk'
import { persistReducer } from 'redux-persist'
import persistConfig from './storePersistConfig'
import rootReducer from '../reducers/rootReducer'
import { initialState, IRootState } from '../reducers/initialState'
import { routerMiddleware } from 'connected-react-router'

// @TODO: Again - do not allow any!!
const configureStore = (history: any) => {
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside
    // a dispatch or between dispatches.
    // @NOTE: Disabled as we do not want error outputs in PROD
    // reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunkMiddleware,

    // Connected React Router middleware
    routerMiddleware(history),
  ]

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  return createStore<IRootState>(persistedReducer, initialState, compose(applyMiddleware(...middlewares)))
}

export default configureStore
