import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

export interface ProviderFilter {
  title: string
  colorClass?: string
  activeColorClass?: string
  key: string
}

interface IProps {
  filters: ProviderFilter[]
  onChange: (activeFilter: ProviderFilter | undefined) => void
  activeFilter: ProviderFilter | undefined
}

type TProps = IProps & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    filterContainer: {
      marginLeft: '15px',
      display: 'flex',
      flexDirection: 'row',
    },
    filterButton: {
      margin: '2px',
      border: '1px solid',
      borderRadius: '5%',
      padding: '5px',
      cursor: 'pointer',
    },
  })

interface IState {}

const stateDefault: IState = {}

class PPProviderFilters extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    this.state = { ...stateDefault }
  }

  render() {
    const { handleFilterToggle } = this
    const { filters, classes } = this.props

    return (
      <div className={classes.filterContainer}>
        {filters.map((filter, index) => {
          const active = !!this.props.activeFilter && this.props.activeFilter.key === filter.key
          return (
            <div
              className={classNames(classes.filterButton, active && filter.activeColorClass, filter.colorClass)}
              key={`Provider-${index}`}
              onClick={() => handleFilterToggle(filter)}
              data-id={index}
            >
              {filter.title}
            </div>
          )
        })}
      </div>
    )
  }

  private handleFilterToggle = (filter: ProviderFilter) => {
    const { onChange } = this.props
    let newFilter: ProviderFilter | undefined = filter
    // Untoggle already active filter
    if (this.props.activeFilter && this.props.activeFilter.key === filter.key) {
      newFilter = undefined
    }
    onChange(newFilter)
  }
}

export default withStyles(styles)(PPProviderFilters)
