import ActionTypes from '../../actions/ActionTypes'
import ActionTypeKeys from '../../actions/ActionTypeKeys'
import { providerPageInitialState } from './providerPageInitialState'

export const providerPageReducer = (state = providerPageInitialState, action: ActionTypes) => {
  switch (action.type) {
    /***********************************************
     * Reducers for providerPage
     *
     **********************************************/
    case ActionTypeKeys.PROVIDERPAGE_UPDATE:
      return {
        ...state,
        ...action.props,
      }

    case ActionTypeKeys.PROVIDERPAGE_RESET:
      return {
        ...providerPageInitialState,
      }

    case ActionTypeKeys.PROVIDERPAGE_ROUTE_UPDATE:
      let newState = {
        ...state,
      }

      if (state.route !== action.route) {
        newState = {
          ...state,
          optionDetails: null,
          optionList: null,
          route: action.route,
          stripeDetails: null,
          templateDetails: null,
          templateList: null,
        }
      }

      return newState

    /***********************************************
     * Reducers for providerPage.provider
     *
     **********************************************/

    case ActionTypeKeys.CONTRACT_PROVIDER_DETAILS_RESET:
      return {
        ...state,
        providerDetails: providerPageInitialState.providerDetails,
      }

    case ActionTypeKeys.CONTRACT_PROVIDER_DETAILS_UPDATE:
      return {
        ...state,
        providerDetails: { ...action.providerDetails },
      }

    case ActionTypeKeys.CONTRACT_PROVIDER_ID_UPDATE:
      return {
        ...state,
        providerId: action.providerId,
      }

    case ActionTypeKeys.CONTRACT_PROVIDER_LIST_RESET:
      return {
        ...state,
        providerList: providerPageInitialState.providerList,
      }

    case ActionTypeKeys.CONTRACT_PROVIDER_LIST_UPDATE:
      return {
        ...state,
        providerList: [...action.providerList],
      }

    /***********************************************
     * Reducers for providerPage.country
     *
     **********************************************/
    case ActionTypeKeys.COUNTRY_LIST_UPDATE:
      return {
        ...state,
        countryList: [...action.countryList],
      }

    /***********************************************
     * Reducers for providerPage.template
     *
     **********************************************/

    case ActionTypeKeys.CONTRACT_TEMPLATE_DETAILS_RESET:
      return {
        ...state,
        templateDetails: providerPageInitialState.templateDetails,
      }

    case ActionTypeKeys.CONTRACT_TEMPLATE_DETAILS_UPDATE:
      return {
        ...state,
        templateDetails: { ...action.templateDetails },
      }

    case ActionTypeKeys.CONTRACT_TEMPLATE_ID_UPDATE:
      return {
        ...state,
        templateId: action.templateId,
      }

    case ActionTypeKeys.CONTRACT_TEMPLATE_LIST_RESET:
      return {
        ...state,
        templateList: providerPageInitialState.templateList,
      }

    case ActionTypeKeys.CONTRACT_TEMPLATE_LIST_UPDATE:
      return {
        ...state,
        templateList: [...action.templateList],
      }

    /***********************************************
     * Reducers for providerPage.option
     *
     **********************************************/

    case ActionTypeKeys.CONTRACT_OPTION_DETAILS_RESET:
      return {
        ...state,
        optionDetails: providerPageInitialState.optionDetails,
      }

    case ActionTypeKeys.CONTRACT_OPTION_DETAILS_UPDATE:
      return {
        ...state,
        optionDetails: { ...action.optionDetails },
      }

    case ActionTypeKeys.CONTRACT_OPTION_ID_UPDATE:
      return {
        ...state,
        optionId: action.optionId,
      }

    case ActionTypeKeys.CONTRACT_OPTION_LIST_RESET:
      return {
        ...state,
        optionList: providerPageInitialState.optionList,
      }

    case ActionTypeKeys.CONTRACT_OPTION_LIST_UPDATE:
      return {
        ...state,
        optionList: [...action.optionList],
      }

    /***********************************************
     * Reducers for providerPage.stripe
     *
     **********************************************/
    case ActionTypeKeys.STRIPE_DETAILS_UPDATE:
      return {
        ...state,
        stripeDetails: action.stripeDetails,
      }

    case ActionTypeKeys.STRIPE_DETAILS_RESET:
      return {
        ...state,
        stripeDetails: providerPageInitialState.stripeDetails,
      }

    /***********************************************
     * Reducers for providerPage.user
     *
     **********************************************/
    case ActionTypeKeys.USER_LIST_UPDATE:
      return {
        ...state,
        userList: [...action.userList],
      }

    default:
      return state
  }
}
