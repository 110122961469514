import React, { ReactNode } from 'react'
import { IApiFieldRendererValue } from './apiModel'

interface IUiType {
  renderer(cbArg: IApiFieldRendererValue): ReactNode
}

interface IUiContext {
  [key: string]: IUiType
}

interface IUiTypes {
  [key: string]: IUiContext
}

const uiType: IUiTypes = {}

uiType.avatar = {
  grid: {
    renderer: ({ value, key }): ReactNode => {
      return React.createElement('img', { src: value, className: 'uiType-grid uiType-grid--avatar', key }, null)
    },
  },
}

uiType.boolean = {
  grid: {
    renderer: ({ value, key }) => {
      const outputValue = value ? 'true' : 'false'

      return React.createElement('div', { className: 'uiType-grid uiType-grid--boolean', key }, outputValue)
    },
  },
}

uiType.booleanToReadable = {
  grid: {
    renderer: ({ value, key }) => {
      const outputValue = value ? 'Yes' : 'No'

      return React.createElement('div', { className: 'uiType-grid uiType-grid--booleanToReadable', key }, outputValue)
    },
  },
}

uiType.image = {
  grid: {
    renderer: ({ value, key }) => {
      return React.createElement('img', { src: value, className: 'uiType-grid uiType-grid--image', key }, null)
    },
  },
}

uiType.number = {
  grid: {
    renderer: ({ value, key }) => {
      const valueType = typeof value
      let outputValue

      switch (valueType) {
        case 'number':
          outputValue = value
          break
        case 'string':
          outputValue = value
          break
        default:
          outputValue = 'NaN'
          break
      }

      return React.createElement('div', { className: 'uiType-grid uiType-grid--number', key }, outputValue)
    },
  },
}

uiType.string = {
  grid: {
    renderer: ({ value, key }) => {
      const valueType = typeof value
      let outputValue

      switch (valueType) {
        case 'object':
          outputValue = JSON.stringify(value)
          break
        default:
          outputValue = value
          break
      }

      return React.createElement('div', { className: 'uiType-grid uiType-grid--string', key }, outputValue)
    },
  },
}

uiType.csv = {
  grid: {
    renderer: ({ value, key }) => {
      return React.createElement(
        'ul',
        { key },
        value &&
          value
            .toString()
            .split(',')
            .map((v) => React.createElement('li', { key: v }, v.trim())),
      )
    },
  },
}

export default uiType
