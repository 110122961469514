import { Form } from 'formik'
import React from 'react'
import { Dispatch, connect } from 'react-redux'
import { IStripeExternalAccount } from '@omnicar/sam-types'
import ActionTypes from '../../../actions/ActionTypes'
import { IProviderPageRouteUpdate, providerPageRouteUpdate } from '../../../actions/providerPageActions'
import { postStripeExternalAccount } from '../../../api/api'
import { IApiErrors } from '../../../apiModels/apiModel'
import {
  stripeExternalAccountCreateDefaults,
  stripeExternalAccountCreateModel,
} from '../../../apiModels/stripeExternalAccountCreate'
import ExternalAccount from '../../../components/Form/Stripe/ExternalAccount/ExternalAccount'
import Omnik from '../../../components/Omnik/Omnik'
import { IRootState } from '../../../reducers/initialState'
import './PPExternalAccountCreate.css'
import { toCountryOptions } from '../../../apiModels/stripe'
import { StripeCountryType, ICountryResponse } from '@omnicar/sam-types'
import { getAllCountries } from '.../../api/api'
import { BaseOption as Option } from 'types'

interface IReducerProps {
  providerId: number
}

interface IActionProps {
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
}

interface IState {
  countryOptions: null | Option<StripeCountryType>[]
  externalAccountDetails: IStripeExternalAccount
}

const model = stripeExternalAccountCreateModel()

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPExternalAccountCreate extends React.Component<IReducerProps & IActionProps, IState> {
  constructor(props: IReducerProps & IActionProps) {
    super(props)

    this.state = {
      countryOptions: null,
      externalAccountDetails: { ...stripeExternalAccountCreateDefaults, ...{ providerId: props.providerId } },
    }
  }

  async componentDidMount() {
    const response = await getAllCountries()

    if (!response || !response.data) {
      console.error('Fetching countries failed')
    } else {
      const countries: ICountryResponse[] = []

      response.data.forEach((item: ICountryResponse) => {
        countries.push(item)
      })

      this.setState({ countryOptions: toCountryOptions(countries) })
    }
  }

  // tslint:disable jsx-no-lambda
  public render() {
    const { handleFormSubmit, handleFormReset } = this
    const { externalAccountDetails } = this.state

    return (
      <Omnik
        model={model}
        initialValues={externalAccountDetails}
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
        validate={(values) => {
          let errors: IApiErrors | {} = {}

          if (model) {
            errors = model.getErrors(values)
          }

          return errors
        }}
      >
        {({ dirty, errors, handleBlur, handleSubmit, handleReset, handleChange, isSubmitting, values, isValid }) => {
          return (
            <Form
              className={`PPExternalAccountCreate ${isSubmitting ? 'PPExternalAccountCreate--saving' : ''}`}
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              <header className="PPExternalAccountCreate__header">
                <div className="PPExternalAccountCreate__info">
                  <h1 className="PPExternalAccountCreate__title">Create new Stripe External Account</h1>
                </div>
                <div className="PPExternalAccountCreate__actions">
                  <button className="PPExternalAccountCreate__resetbutton" type="reset">
                    Reset
                  </button>
                  <button className="PPExternalAccountCreate__submitbutton" disabled={!dirty || !isValid} type="submit">
                    Save
                  </button>
                </div>
              </header>
              <main className="PPExternalAccountCreate__content">
                <ExternalAccount
                  edit={true}
                  errors={errors}
                  countryOptions={this.state.countryOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values}
                />
              </main>
            </Form>
          )
        }}
      </Omnik>
    )
  }
  // tslint:enable jsx-no-lambda

  private handleFormSubmit = async (values: IStripeExternalAccount) => {
    const post = await postStripeExternalAccount(values)

    // @TODO Route to error display if error
    if (!post.errorData) {
      this.props.providerPageRouteUpdate('stripe')
    }
  }

  private handleFormReset = () => {
    const { externalAccountDetails } = this.state

    this.setState({ externalAccountDetails })
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerId: state.providerPage.providerId,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPExternalAccountCreate)
