import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredPrimaryKey, requiredString, requiredNumber } from './validations'
import { IReleaseNoteResponse } from '@omnicar/sam-types'
import { BaseOption } from 'types'
import { ReleaseTag } from '@omnicar/sam-types'

export const model: IApiModel = {
  name: 'releaseNote',
  fields: [
    {
      name: 'releaseNoteId',
      title: 'Note ID',
      uiType: 'number',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'releaseTag',
      title: 'Tag',
      uiType: 'number',
      validation: [requiredNumber],
    },
    {
      name: 'descriptions',
      title: 'Description',
      uiType: 'string',
      validation: [requiredString],
    },
  ],
  view: {},
}

export const releaseTagOptions: BaseOption<number>[] = [
  { value: 1, label: 'ADDED' },
  { value: 2, label: 'FIXED' },
  { value: 3, label: 'NEW' },
  { value: 4, label: 'REMOVED' },
  { value: 5, label: 'UPDATED' },
  { value: 6, label: 'IMPROVED' },
]

export const releaseNoteValueDefaults = (releaseVersionId: number): IReleaseNoteResponse => ({
  releaseVersionId,
  descriptions: [],
  releaseNoteId: -1,
  releaseTag: ReleaseTag.ADDED,
})

export const releaseNoteModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IReleaseNoteResponse>(view, viewContext, model, releaseNoteValueDefaults(-1), {})
