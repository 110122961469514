import React from 'react'

// tslint:disable-next-line interface-name
class Error404Page extends React.Component<{}, {}> {
  public render() {
    return (
      <section className="Error404Page">
        <h1>Four Oh Four!</h1>
      </section>
    )
  }
}

export default Error404Page
