import React, { ReactElement } from 'react'

export interface IWithStyledFieldProps {
  className?: string
  locked?: boolean
  name: string
  subtitle?: string
  title?: string
  errors?: string[]
  hidden?: boolean
}

const createClassNames = (props: IWithStyledFieldProps & any, fieldClassName: string, name: string = '') => {
  const { className, locked, errors } = props
  const prefixBase = name === '' ? 'Field' : `Field__${name}`
  const prefixName = name === '' ? fieldClassName : `${fieldClassName}__${name}`
  const prefixedClassName = name === '' ? className : `${className}-${name}`

  return `${prefixBase} ${prefixName} ${prefixedClassName} ${
    errors ? `${prefixBase}--error ${prefixName}--error ${prefixedClassName}--error` : ''
  } ${locked ? `${prefixBase}--locked ${prefixName}--locked ${prefixedClassName}--locked` : ''}`
}

const createErrors = (errors: string[], fieldClassName: string) => {
  const errorElements: Array<ReactElement<HTMLDivElement>> = []

  if (errors && errors.length) {
    errors.forEach((error: string) => {
      errorElements.push(<div key={`${fieldClassName}-${error.replace(/\W/g, '')}`}>{error}</div>)
    })
  }
  return errorElements
}

export default function withStyledField<T extends IWithStyledFieldProps = IWithStyledFieldProps>(
  FieldComponent: React.StatelessComponent<T> | React.ComponentClass<T>,
  fieldClassName: string,
  displayName?: string,
) {
  const ComponentWithStyledField = (props: T) => {
    const { name, subtitle, title, errors } = props

    return (
      <div hidden={props.hidden} className={createClassNames(props, fieldClassName)}>
        {title && (
          <label className={createClassNames(props, fieldClassName, 'title')} htmlFor={name}>
            {title}
          </label>
        )}
        <div className={createClassNames(props, fieldClassName, 'wrapper')}>
          {subtitle && <div className={createClassNames(props, fieldClassName, 'subtitle')}>{subtitle}</div>}
          <FieldComponent {...props} className={createClassNames(props, fieldClassName, 'element')} />
          {errors && (
            <div className={createClassNames(props, fieldClassName, 'error')}>
              {createErrors(errors, fieldClassName)}
            </div>
          )}
        </div>
      </div>
    )
  }
  ComponentWithStyledField.displayName = `WithStyledField(${displayName || fieldClassName})`

  return ComponentWithStyledField
}
