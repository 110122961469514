import React, { ChangeEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './EmailField.css'

interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  hidden?: boolean
  maxLength?: number
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  size?: number
  value?: string
}

const EmailField: React.SFC<IProps> = ({
  className,
  disabled,
  hidden,
  maxLength,
  name,
  onBlur,
  onChange,
  size,
  value,
}) => (
  <input
    type="email"
    className={className}
    disabled={disabled}
    hidden={hidden}
    id={name}
    maxLength={maxLength}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    size={size}
    value={value}
  />
)

EmailField.defaultProps = {
  className: '',
  disabled: false,
  hidden: false,
  value: '',
}

export default withStyledField(EmailField, 'EmailField')
