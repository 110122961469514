import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredString } from './validations'
import { formatDateFieldValue } from 'utils/formatDate'
import { ITranslationResponse } from '@omnicar/sam-types'

export const model: IApiModel = {
  name: 'translation',
  fields: [
    {
      name: 'id',
      title: 'ID',
      uiType: 'number',
    },
    {
      name: 'key',
      title: 'Key',
      uiType: 'string',
      validation: [requiredString],
    },
    {
      name: 'applications',
      title: 'Systems',
      uiType: 'string',
    },
    {
      name: 'userEmail',
      title: 'Updated by',
      uiType: 'string',
    },
    {
      name: 'entityUpdated',
      title: 'Updated at',
      uiType: 'date',
    },
    {
      name: 'missedValues',
      title: 'Has missed values',
      uiType: 'boolean',
    },
  ],
  view: {},
}

export const translationDefaults: ITranslationResponse = {
  key: '',
  applications: [],
  id: -1,
  userEmail: '',
  entityUpdated: formatDateFieldValue(new Date()),
  missedValues: true,
}

export const translationModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<ITranslationResponse>(view, viewContext, model, translationDefaults, {})
