import React, { ChangeEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './NumberField.css'

/**
 * @note: This component may not be sutable for decimal numbers, use the component
 * DecimalField instead.
 */

interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  maxLength?: number
  max?: number
  min?: number
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  size?: number
  step?: number
  value?: number
}

const NumberField: React.SFC<IProps> = ({
  className,
  disabled,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  size,
  step,
  value,
}) => (
  <input
    className={className}
    disabled={disabled}
    id={name}
    max={max}
    maxLength={maxLength}
    min={min}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    size={size}
    step={step}
    type="number"
    value={value}
  />
)

export default withStyledField(NumberField, 'NumberField')
