import React, { ChangeEvent } from 'react'
import { Field, FieldProps } from 'formik'
import { IApiErrors } from '../../../apiModels/apiModel'
import { PriceSpecification as IPriceSpecification } from '@omnicar/sam-types'
import FormSection from '../../FormSection/FormSection'
import NumberField from '../../Field/NumberField/NumberField'
import SelectField from '../../Field/SelectField/SelectField'
import { precisionRound, toIncludingVAT, toExcludingVAT } from 'utils/mathAndPrices'
import { TPrecision } from '../../../samTypes'

interface IProps {
  precision?: TPrecision
  edit?: boolean
  errors?: IApiErrors
  errorKey?: keyof IPriceSpecification & string
  name?: string
  title?: boolean | string
  value: IPriceSpecification
  vatPct?: number
  countryName?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
}

interface IVATLabel {
  vatPct?: number
  countryName?: string
}

const VATLabel = (props: IVATLabel) => {
  if (props.vatPct && props.countryName) {
    return (
      <div>
        VAT rate in {props.countryName} is {100 * props.vatPct}%
      </div>
    )
  } else {
    return <div />
  }
}

// tslint:disable jsx-no-lambda
const PriceSpecification: React.SFC<IProps> = ({
  precision,
  edit,
  errors = {},
  name,
  title,
  value,
  vatPct,
  countryName,
  onBlur,
  onChange,
}) => {
  const decimals: number = precision && precision === '3Decimals' ? 3 : 2 // Number of decimalPlaces FOR the main price (price without VAT).
  const step: string = precision && precision === '3Decimals' ? '0.001' : '0.01'

  const titleNumberField1 = `Price without VAT (${decimals} decimals) - NOTE: This price (without VAT) is the principal price, that is saved in the Database.`
  const titleNumberField2 =
    'Price incl. VAT (2 decimals) - NOTE: This price (including VAT) is calculated on the fly (based on the principal price).'

  const fieldNameWithoutVAT = precision !== '3Decimals' ? 'price' : 'priceWith3Decimals'
  const fieldNameInclVAT = 'priceInclVat'

  return (
    <div className="PriceSpecification">
      <FormSection
        name="general"
        classNamePrefix="PriceSpecification"
        title={title ? (title === true ? 'Price Specification' : title) : ''}
      >
        {vatPct && countryName && <VATLabel vatPct={vatPct} countryName={countryName} />}
        <Field name={name ? `${name}.${fieldNameWithoutVAT}` : fieldNameWithoutVAT}>
          {({ form }: FieldProps) => {
            return (
              <NumberField
                className="PriceSpecification__price"
                disabled={!edit}
                errors={errors[fieldNameWithoutVAT] || errors.price}
                locked={!edit}
                max={99999}
                min={0}
                name={name ? `${name}.${fieldNameWithoutVAT}` : fieldNameWithoutVAT}
                onBlur={onBlur}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target
                  const numberValue = +value
                  const priceIncludingVAT = precisionRound(toIncludingVAT(numberValue, vatPct || 0.25), 2) // NOTE: Remember priceIncludingVAT is not saved in DB only "presented" as a "preview" for the user - So we show max 2 decimals, whole cents/ören.

                  onChange(e)

                  // Set the other field price INCLUDING VAT.
                  form.setFieldValue(name ? `${name}.${fieldNameInclVAT}` : fieldNameInclVAT, priceIncludingVAT)
                }}
                step={parseFloat(step)}
                subtitle={`The price is in whole ${value.currency}`}
                title={titleNumberField1}
                value={precision !== '3Decimals' ? value.price : value.priceWith3Decimals}
              />
            )
          }}
        </Field>
        <Field name={name ? `${name}.priceInclVat` : 'priceInclVat'}>
          {({ form }: FieldProps) => {
            return (
              <NumberField
                className="PriceSpecification__priceInclVat"
                disabled={!edit}
                errors={errors.priceInclVat}
                locked={!edit}
                max={99999}
                min={0}
                name={name ? `${name}.${fieldNameInclVAT}` : fieldNameInclVAT}
                onBlur={onBlur}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target
                  const numberValue = +value
                  const priceWithoutVAT = precisionRound(toExcludingVAT(numberValue, vatPct || 0.25), decimals)

                  onChange(e)

                  // Set the other field price WITHOUT VAT.
                  form.setFieldValue(name ? `${name}.${fieldNameWithoutVAT}` : fieldNameWithoutVAT, priceWithoutVAT)
                }}
                step={parseFloat('0.01')}
                subtitle={`The price is in whole ${value.currency}`}
                title={titleNumberField2}
                value={parseFloat(precisionRound(value.priceInclVat, 2).toString())}
              />
            )
          }}
        </Field>
        <SelectField
          className="PriceSpecification__currency"
          hidden={true}
          disabled={!edit}
          defaultValue="dkk"
          errors={errors.currency}
          locked={!edit}
          name={name ? `${name}.currency` : 'currency'}
          onBlur={onBlur}
          onChange={onChange}
          title="Currency"
          value={value.currency}
          options={[
            { label: 'Danish Kroner', value: 'dkk' },
            { label: 'Swedish Kroner', value: 'sek' },
            { label: 'Euro', value: 'eur' },
          ]}
        />
      </FormSection>
    </div>
  )
}
export default PriceSpecification
