/**
 * Rounds the given amount value to "precision" number of decimals.
 *
 * @example priceInclVAT(1.554, 2) will return 1.55
 *          priceInclVAT(1.555, 2) will return 1.56
 *
 * @param value A decimal number.
 * @param decimals Number of decimal placements.
 */
export const precisionRound = (value: number, decimals: number): number => {
  const factor = Math.pow(10, decimals)

  return Math.round(value * factor) / factor
}

/**
 * Converts a price to a price including VAT.
 *
 * @param priceWithoutVAT The price without VAT.
 * @param VAT A number, for example 0.25 for 25% VAT.
 * @returns The price including VAT.
 */
export const toIncludingVAT = (priceWithoutVAT: number, VAT: number): number => {
  return priceWithoutVAT * (1 + VAT)
}

/**
 * Converts a price to a price without VAT.
 *
 * @param priceInclVAT The price including VAT.
 * @param VAT A number, for example 0.25 for 25% VAT.
 * @returns The price without VAT.
 */
export const toExcludingVAT = (priceInclVAT: number, VAT: number): number => {
  return priceInclVAT / (1 + VAT)
}
