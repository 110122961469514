import React from 'react'
import SortableTable, { createButton } from 'components/SortableTable/SortableTable'
import { accessKeyModel } from 'apiModels/accessKey'
import { IAccessKey } from '@omnicar/sam-types'
import IApiModel, { TApiRecord, IApiField } from 'apiModels/apiModel'
import { AccessKeyPermission } from '@omnicar/sam-types'
import { AccessKeyService } from '@omnicar/sam-types'
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons'
import { trimString } from 'utils/formatString'

interface IProps {
  accessKeys: IAccessKey[]
  onEdit: (record: IAccessKey) => void
  onDelete: (record: IAccessKey) => void
}

const model = accessKeyModel() as IApiModel
// remove contractProviderIds and allProvidersAllowed fields from model for table
const modelWithFilteredFields = {
  ...model,
  fields: model.fields.filter(
    (field: IApiField) => !['contractProviderIds', 'allProvidersAllowed'].includes(field.name),
  ),
}
const tableFieldsMapper = (record: TApiRecord, fieldName: string): string | undefined => {
  switch (fieldName) {
    case 'contractProviderIds':
      return record[fieldName].join(', ')
    case 'service':
      return AccessKeyService[record[fieldName]]
    case 'keyPermission':
      return AccessKeyPermission[record[fieldName]]
    case 'representativeUserEmail':
      return record[fieldName] ? trimString(record[fieldName], 20) : 'N/A'
    case 'createdByUserEmail':
      return trimString(record[fieldName] || '', 20)
    case 'apiKey':
      return trimString(record[fieldName] || '', 14)

    default:
      return undefined
  }
}

const AccessKeysList: React.SFC<IProps> = ({ accessKeys, onDelete, onEdit }) => (
  <div className="AccessKeys__list">
    {!!accessKeys.length && (
      <SortableTable
        primaryKey={'id'}
        data={accessKeys}
        model={modelWithFilteredFields}
        defaultSortColumn={'accessKeyId'}
        defaultSortingOrder={'desc'}
        customMapper={tableFieldsMapper}
        additionalColumns={[
          {
            title: 'All providers allowed',
            processRecord: (accessKey: IAccessKey) =>
              accessKey.allProvidersAllowed ? <CheckBox /> : <CheckBoxOutlineBlank />,
          },
          { title: '', processRecord: createButton(onEdit, 'AccessKeys__editbutton', true) },
          { title: '', processRecord: createButton(onDelete, 'AccessKeys__deletebutton') },
        ]}
      />
    )}
  </div>
)

export default AccessKeysList
