import * as React from 'react'
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@material-ui/core'
import IApiModel, { TApiRecord, IApiField } from '../../apiModels/apiModel'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
// import { CircularProgress, Dialog, DialogContent } from '@material-ui/core'

export interface IAdditionalColumn {
  title: string
  processRecord: (record: TApiRecord) => JSX.Element[] | JSX.Element
}

export const createButton: <T>(
  handleClick: (record: T) => void,
  className: string,
  isEditButton?: boolean,
) => (record: T) => JSX.Element = <T extends {}>(
  handleClick: (record: T) => void,
  className: string,
  isEditButton?: boolean,
) => (record: T) => {
  const title = isEditButton ? 'Edit' : 'Delete'

  return (
    <button className={className} onClick={() => handleClick(record)}>
      {title}
    </button>
  )
}

/**
 * Creates a function for button creation based on table records
 * T is a type of table records
 * @param text button text (string or function based on record)
 * @param handleClick button onClick handler
 * @param className (string or function based on record)
 * @returns function that creates a JSX.Element
 */
export function createStandardTableButton<T extends TApiRecord>(
  text: string | ((record: T) => string),
  handleClick: (record: T) => void,
  className: string | ((record: T) => string),
): (record: TApiRecord) => JSX.Element {
  return (record: TApiRecord) => (
    <button
      className={typeof className === 'function' ? className(record as T) : className}
      onClick={() => handleClick(record as T)}
    >
      {typeof text === 'function' ? text(record as T) : text}
    </button>
  )
}

interface IProps {
  model: IApiModel
  data: TApiRecord[]
  primaryKey: string
  additionalColumns?: IAdditionalColumn[]
  onRowClick?: (record: TApiRecord) => void
  customMapper?: (record: TApiRecord, fieldName: string) => string | undefined
  defaultSortColumn?: string // model field name
  defaultSortingOrder?: SortingOrder
  customStyling?: [{ key: string; value: string; class: string }]
}

interface IState {
  data: TApiRecord[]
  sortingParameter: string
  sortingOrder: SortingOrder
}

type SortingOrder = 'asc' | 'desc'

const styles = () =>
  createStyles({
    tableRow: {
      fontSize: '13px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#7cddb1',
      },
    },
    tableHeader: {
      fontSize: '13px',
    },
    columnHeader_Name: {
      minWidth: '24vw',
    },
    columnHeader_ContactPerson: {
      minWidth: '16vw',
    },
    cell: {
      fontSize: '13px',
    },
    paper: {
      display: 'block',
      paddingBottom: '1.0em',
      boxShadow: 'none',
      maxWidth: 'inherit',
      overflowX: 'auto',
      marginTop: '12px',
      marginBottom: '12px',
    },
    thinRow: {
      paddingRight: '5px',
    },
  })

type TProps = IProps & WithStyles<typeof styles>

const descendingComparator = (a: any, b: any, sortingParameter: any) => {
  if (b[sortingParameter] < a[sortingParameter]) {
    return -1
  }
  if (b[sortingParameter] > a[sortingParameter]) {
    return 1
  }
  return 0
}

const getComparator = (sortingOrder: any, sortingParameter: any) => {
  return sortingOrder === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, sortingParameter)
    : (a: any, b: any) => -descendingComparator(a, b, sortingParameter)
}

const stableSort = (array: any, comparator: any) => {
  const stabilizedThis: any[][] = array.map((el: any, index: any) => [el, index])
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

class SortableTable extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      data: props.data || [],
      sortingParameter: props.defaultSortColumn || props.model.fields[0].name,
      sortingOrder: props.defaultSortingOrder || 'asc',
    }
  }

  componentDidUpdate(prevProps: TProps) {
    const { data } = this.props

    if (data !== prevProps.data) {
      this.setState({
        data,
      })
    }
  }

  render() {
    const { classes, primaryKey, model, additionalColumns, customStyling } = this.props
    const { sortingOrder, sortingParameter, data } = this.state

    return (
      <Paper className={classNames(classes.paper)}>
        <Table>
          <TableHead>
            <TableRow>
              {model.fields.map((modelField: IApiField, index: number) => (
                <TableCell
                  key={index}
                  className={classNames(
                    classes.tableHeader,
                    classes.thinRow,
                    'Name' === modelField.title ? classes.columnHeader_Name : '',
                    'Fragus Contact person name' === modelField.title ? classes.columnHeader_ContactPerson : '',
                  )}
                >
                  <TableSortLabel
                    onClick={() => this.sortTable(modelField.name)}
                    active={sortingParameter === modelField.name}
                    direction={sortingParameter === modelField.name ? sortingOrder : 'asc'}
                  >
                    {modelField.title}
                  </TableSortLabel>
                </TableCell>
              ))}
              {additionalColumns &&
                additionalColumns.map((c, i) => (
                  <TableCell key={`additional_columns_${i}`} className={classNames(classes.tableHeader)}>
                    {c.title}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(data, getComparator(sortingOrder, sortingParameter)).map((data: TApiRecord, index: number) => (
              <TableRow
                key={index}
                onClick={() => this.handleRowClick(data[primaryKey])}
                className={classNames(classes.tableRow, customStyling && this.mapStyling(data))}
              >
                {model.fields.map((modelField: IApiField, index: number) => (
                  <TableCell
                    key={index}
                    className={classNames(classes.cell, modelField.smaller && classes.thinRow)}
                    title={this.tableCellTooltip(data, model, index)}
                  >
                    {this.mapRelations(data, modelField)}
                  </TableCell>
                ))}
                {additionalColumns &&
                  additionalColumns.map((c, i) => (
                    <TableCell key={`additional_columns_${index}_${i}`} className={classes.cell}>
                      {c.processRecord(data)}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }

  private tableCellTooltip = (rowData: TApiRecord, columnModel: IApiModel, columnIndex: number): string => {
    const columnFields = columnModel.fields[columnIndex]
    return `${columnFields.title}: "${rowData[columnFields.name]}"`
  }

  private sortTable = (newSortingParameter: string): void => {
    const { sortingOrder, sortingParameter } = this.state

    const isAsc = sortingParameter === newSortingParameter && sortingOrder === 'asc'
    this.setState({
      sortingParameter: newSortingParameter,
      sortingOrder: isAsc ? 'desc' : 'asc',
    })
  }

  private handleRowClick = (id: number): void => {
    const { data } = this.state
    const { primaryKey } = this.props

    const recordArray: TApiRecord[] = data.filter((record: TApiRecord) => record[primaryKey] === id)
    if (recordArray.length !== 0) {
      const record: TApiRecord = recordArray[0]
      this.props.onRowClick && this.props.onRowClick(record)
    }
  }

  private mapStyling(rowdata: TApiRecord): string {
    const { customStyling } = this.props

    let classes = ''
    if (customStyling) {
      for (let s of customStyling) {
        if (rowdata[s.key] && rowdata[s.key] === s.value) {
          classes = classNames(classes, s.class)
        }
      }
    }

    return classes
  }

  private mapRelations = (record: TApiRecord, field: IApiField): React.ReactNode => {
    const { customMapper } = this.props
    const customValue = customMapper && customMapper(record, field.name)
    let value: string = 'N/A'

    if (customValue) {
      return customValue
    }

    if (field?.isShowCheckboxInProviderList) {
      const value = !record[field?.name] ? null : field.checkboxCaptionShort || 'X'
      return <span title={field?.title || ''}>{value || '-'}</span>
    }

    if (field?.name === 'contractProviderId') {
      try {
        return '#' + ('' + record[field.name]).padStart(3, '0')
      } catch (err) {
        // Nop.
      }
    }

    if (field?.name === 'parentProviderId') {
      if (record[field.name] !== null) {
        try {
          return '#' + ('' + record[field.name]).padStart(3, '0')
        } catch (err) {
          // Nop.
        }
      }
    }

    if (field.relation && record[field.relation]) {
      try {
        return record[field.relation][field.name]
      } catch (err) {
        return value
      }
    } else {
      return record[field.name] || '-'
    }
  }
}

export default withStyles(styles)(SortableTable)
