import React, { ChangeEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './BooleanField.css'

interface IProps extends IWithStyledFieldProps {
  disabled?: boolean
  hidden?: boolean
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: boolean
}

const BooleanField: React.SFC<IProps> = ({ className, disabled, hidden, name, value, onChange, onBlur }) => (
  <input
    checked={value}
    className={className}
    disabled={disabled}
    hidden={hidden}
    id={name}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    type="checkbox"
  />
)

BooleanField.defaultProps = {
  className: '',
  disabled: false,
  hidden: false,
  value: false,
}

export default withStyledField(BooleanField, 'BooleanField')
