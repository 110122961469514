import { PaymentGateway, TIsoCountry } from '@omnicar/sam-types'
import React from 'react'
import ActionButton from '../ActionButton/ActionButton'
import './index.css'
import PaymentGatewaysDialog from './PaymentGatewaysDialog'

interface IProps {
  className?: string
  label?: string
  edit: boolean
  paymentGateways: PaymentGateway[]
  isoCountry: TIsoCountry
  providerId: number
  providerName: string
  callbackSetFieldValue: any
}

interface IState {
  isDialogOpen: boolean
}

const initialState: IState = {
  isDialogOpen: false,
}

class ProviderPaymentGatewaysField extends React.Component<IProps, IState> {
  state = initialState

  render() {
    const { label, edit, paymentGateways, isoCountry, providerId, providerName } = this.props
    const { isDialogOpen } = this.state

    const isEditing = edit

    return (
      <>
        <label className={'Field__title SelectField__title'}>{label || 'Payment Gateways'}</label>
        <>{this.renderPaymentGateways(paymentGateways)}</>
        {isEditing && (
          <ActionButton
            className="ConnectProvider__edit_button"
            onClick={this.openDialogEditor}
            text="Edit Payment Gateways ..."
          />
        )}
        {
          <PaymentGatewaysDialog
            isOpen={isDialogOpen}
            paymentGateways={paymentGateways}
            isoCountry={isoCountry}
            providerId={providerId}
            providerName={providerName || ''}
            callbackSetFieldValue={this.props.callbackSetFieldValue}
            onClose={() => {
              this.closeDialogEditor()
            }}
          />
        }
      </>
    )
  }

  renderPaymentGateways = (paymentGateways: PaymentGateway[]): JSX.Element => {
    if (!paymentGateways) {
      return <i>-- Loading... --</i>
    }

    let gatewaysString: string = ''
    if (paymentGateways.length > 0) {
      gatewaysString = '' + paymentGateways
      // eslint-disable-next-line
      gatewaysString = gatewaysString.replace(/["\[\]]/g, '') // Strip the following chars: " [ ]
      gatewaysString = gatewaysString.replace(/,/g, ', ')
    }

    return (
      <>
        {gatewaysString ? (
          <span className={'ConnectProvider__gateways-string'}> {gatewaysString}</span>
        ) : (
          <span className={'ConnectProvider__no_gateways_string'}>-- No Payment Gateways --</span>
        )}
      </>
    )
  }

  /**
   * Open Dialog-"ConnectProviderAddToV4".
   */
  openDialogEditor = () => {
    this.setState({ isDialogOpen: true })
  }

  /**
   * Open Dialog-"ConnectProviderAddToV4".
   */
  closeDialogEditor = async () => {
    this.setState({ isDialogOpen: false })
  }
}

export default ProviderPaymentGatewaysField
