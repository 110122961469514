import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { IRootState } from 'reducers/initialState'
import ActionButton from '../ActionButton/ActionButton'
import history from '../../utils/history'
import ActionTypes from '../../actions/ActionTypes'
import { applicationNetworkErrorReset, IApplicationNetworkErrorReset } from '../../actions/applicationActions'
import './NetworkErrorOverlay.css'

interface IReducerProps {
  networkError: boolean
}

interface IActionProps {
  applicationNetworkErrorReset: () => IApplicationNetworkErrorReset
}

// tslint:disable jsx-no-lambda
const NetworkErrorOverlay: React.SFC<IReducerProps & IActionProps> = ({
  networkError,
  applicationNetworkErrorReset,
}) => (
  <div className={`NetworkErrorOverlay ${networkError ? 'NetworkErrorOverlay--show' : ''}`}>
    <div className="NetworkErrorOverlay__background" />
    <div className="NetworkErrorOverlay__panel">
      <div className="NetworkErrorOverlay__text">There is a problem connecting to the API server</div>
      <ActionButton
        className="NetworkErrorOverlay__button"
        text="Click to Retry"
        onClick={() => {
          applicationNetworkErrorReset()
          history.push(history.location.pathname)
        }}
      />
    </div>
  </div>
)
// tslint:enable jsx-no-lambda

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  applicationNetworkErrorReset: () => dispatch(applicationNetworkErrorReset()),
})

const mapStateToProps = (state: IRootState) => ({
  networkError: state.application.networkError,
})

export default connect(mapStateToProps, mapDispatchToProps)(NetworkErrorOverlay)
