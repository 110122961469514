import React, { ChangeEvent, KeyboardEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './StringField.css'

/**
 * @param placeholder Specifies a short hint that describes the expected value of an <input> element
 */
interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  maxLength?: number
  name: string
  placeholder?: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  size?: number
  value?: string
}

const StringField: React.SFC<IProps> = ({
  className,
  disabled,
  hidden,
  maxLength,
  name,
  placeholder,
  onBlur,
  onChange,
  onKeyPress,
  size,
  value,
}) => (
  <input
    type="text"
    className={className}
    disabled={disabled}
    hidden={hidden}
    id={name}
    maxLength={maxLength}
    name={name}
    placeholder={placeholder}
    onBlur={onBlur}
    onChange={onChange}
    onKeyPress={onKeyPress}
    size={size}
    value={value}
  />
)

StringField.defaultProps = {
  className: '',
  disabled: false,
  hidden: false,
  value: '',
}

export default withStyledField(StringField, 'StringField')
