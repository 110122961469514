import React, { ReactNode } from 'react'
import NetworkErrorOverlay from '../../components/NetworkErrorOverlay/NetworkErrorOverlay'
import TransactionOverlay from '../../components/TransactionOverlay/TransactionOverlay'
import EnvironmentOverlay from '../../components/EnvironmentOverlay/EnvironmentOverlay'
import { getLocalStoreItem, setLocalStoreItem } from '../../utils/localStorage'
import AppNavigation from '../AppNavigation/AppNavigation'
import AppContent from '../AppContent/AppContent'
import './App.css'
import LogIn from '../../components/LogIn/LogIn'
import { Route, Redirect, Switch, withRouter, RouteComponentProps } from 'react-router-dom'
import withTracker from '../../hoc/withTracker/WithTracker'
import { loginPagePath } from '../../routes/paths'
import { connect } from 'react-redux'
import { selectIsUserLoggedIn } from '../../selectors/user'
import { IRootState } from 'reducers/initialState'
import { disableBrowserCheck, supportedBrowsers } from 'utils/config'
import { detect } from 'detect-browser'
import { showWarning } from 'utils/toastify'
import Outage from '../Outage/Outage'
import { setRefreshTokenInterval } from 'utils/auth'

interface IProps extends RouteComponentProps<any> {
  children?: ReactNode
  isUserLoggedIn: boolean
}

interface IState {
  collapsed: boolean
  isActiveOutage: boolean
}

const EnhancedLogIn = withTracker(LogIn)

// tslint:disable-next-line interface-name
class App extends React.Component<IProps, IState> {
  public state: IState = {
    collapsed: false,
    isActiveOutage: false,
  }

  componentDidMount() {
    // Check if browser is supported
    const wasRedirect = getLocalStoreItem('wasRedirect')
    // Don't show the error message if this was just a redirect to the main page
    !wasRedirect && !disableBrowserCheck && this.checkIfBrowserIsSupported()
    setLocalStoreItem('wasRedirect', '')

    const localStorageCollapsed = getLocalStoreItem('navigationCollapsed')
    let collapsed = !localStorageCollapsed ? false : localStorageCollapsed === 'true' ? true : false

    // If there is no localStorageCollapsed - then we initialize and set it to false
    if (!localStorageCollapsed) {
      setLocalStoreItem('navigationCollapsed', 'false')
    }

    this.handleCollapse(collapsed)
    this.props.isUserLoggedIn && setRefreshTokenInterval()
  }

  public render() {
    const { handleCollapseClick } = this
    const { collapsed, isActiveOutage } = this.state
    const { children, isUserLoggedIn } = this.props

    return (
      <div className="App">
        {<Outage onOutageChange={this.handleOutageChange} />}
        {isUserLoggedIn ? (
          <>
            <nav
              className={`App__navigation ${collapsed ? 'App__navigation--collapsed' : ''} ${
                isActiveOutage ? 'App__padding-top' : ''
              }`}
            >
              <div className="App__navigation-container">
                <AppNavigation collapsed={collapsed} />
              </div>
              <div className="App__navigation-collapse" onClick={handleCollapseClick} />
            </nav>
            <main
              className={`App__content ${collapsed ? 'App__content--collapsed' : ''} ${
                isActiveOutage ? 'App__padding-top' : ''
              }`}
            >
              <AppContent>{children && children}</AppContent>
            </main>
          </>
        ) : (
          <Switch>
            <Route path={loginPagePath} component={EnhancedLogIn} />
            <Redirect to={loginPagePath} />
          </Switch>
        )}
        <EnvironmentOverlay />
        <NetworkErrorOverlay />
        <TransactionOverlay />
        <div id="ModalPortal" />
      </div>
    )
  }

  private handleCollapse = (collapsed: boolean) => {
    this.setState({ collapsed })
    setLocalStoreItem('navigationCollapsed', collapsed ? 'true' : 'false')
  }

  private handleCollapseClick = () => {
    this.handleCollapse(!this.state.collapsed)
  }

  private checkIfBrowserIsSupported() {
    const browser = detect()
    if (browser && !this.browserIsSupported(browser.name.toLocaleLowerCase())) {
      showWarning(
        `This browser is not supported by JustGO, some features might not function properly. We strongly recommend using one of 
          the following browsers on a personal computer for the best experience: %browserList: ${this.getItemsAsCommaSeperatedText(
            supportedBrowsers,
          )}`,
      )
    }
  }

  private browserIsSupported(browser: string) {
    return !!supportedBrowsers.filter((sb) => browser.startsWith(sb)).length
  }

  private getItemsAsCommaSeperatedText(array: string[]) {
    return array.reduce(
      (text, item, i) =>
        (text += `${item}${array.length > 1 && i === array.length - 2 ? ' or ' : i !== array.length - 1 ? ', ' : ''}`),
      '',
    )
  }

  private handleOutageChange = (isActiveOutage: boolean) => {
    if (isActiveOutage !== this.state.isActiveOutage) this.setState({ isActiveOutage })
  }
}

const mapStateToProps = (state: IRootState) => ({
  isUserLoggedIn: selectIsUserLoggedIn(state),
})

export default withRouter(connect(mapStateToProps)(App))
