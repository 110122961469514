import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core'
import {
  IContractOptionResponse,
  IContractTemplateResponse,
  IGenericContractTemplateResponse,
  PriceSource,
  TCurrency,
} from '@omnicar/sam-types'
import { autoexpertenBaseValues } from 'apiModels/autoexpertenContractTemplate'
import { contractTemplateBaseValues, ITemplateConfig, templateConfigs } from 'apiModels/contractTemplate'
import BooleanField from 'components/Field/BooleanField/BooleanField'
import { Field } from 'formik'
import { isEqual } from 'lodash'
import React, { ChangeEvent } from 'react'
import { getHideWebcalcOptionsWarning, setHideWebcalcOptionsWarning } from 'utils/cookie'
import { IApiErrors } from '../../../apiModels/apiModel'
import FileUploadField, { IOnDidUploadEvent } from '../../Field/FileUploadField/FileUploadField'
import DecimalField from '../../Field/NumberField/DecimalField'
import NumberField from '../../Field/NumberField/NumberField'
import SelectField, { TSelecetValue } from '../../Field/SelectField/SelectField'
import StringField from '../../Field/StringField/StringField'
import FormSection from '../../FormSection/FormSection'
import ContractTemplateOptions from '../ContractTemplateOptions/ContractTemplateOptions'
import PriceSpecification from '../PriceSpecification/PriceSpecification'
import TermsOfService from '../TermsOfService/TermsOfService'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  onChangeTemplateType: (newValues: IGenericContractTemplateResponse) => void
  options: IContractOptionResponse[]
  title?: boolean | string
  value: IGenericContractTemplateResponse
  templateDetails?: IGenericContractTemplateResponse
  currency?: TCurrency
  vatProcentage?: number | undefined
}

interface IState {
  previewUrlForImage: string | null
  templateConfig: { lockFields: { maxAge: boolean } }
  hasOptionsNotAvailableOnWebcalc: boolean
  openDialog: boolean
  hideWarning: boolean
}

/* tslint:disable jsx-no-lambda */
class ContractTemplate extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    if (props.templateDetails) {
      this.state = {
        previewUrlForImage: !props.templateDetails.imageUrl ? null : props.templateDetails.imageUrl,
        templateConfig: { lockFields: { maxAge: false } },
        hasOptionsNotAvailableOnWebcalc: false,
        openDialog: false,
        hideWarning: getHideWebcalcOptionsWarning(),
      }
    }
  }

  private onDidUpload = (event: IOnDidUploadEvent): void => {
    if (event && event.targetID) {
      switch (event.targetID) {
        case 'image':
          this.setState({ previewUrlForImage: event.response.url })
          break
      }
    }
  }

  public componentDidMount() {
    this.checkOptionsForWebcalc()
  }

  public componentDidUpdate(prevProps: IProps) {
    // Attention: There is a hack to make sure that the template type (and options type) is set to the correct value
    let { showOnWebcalc: prevShowOnWebcalc, options: prevOptions } = prevProps.value as IContractTemplateResponse
    let { showOnWebcalc, options } = this.props.value as IContractTemplateResponse

    if (showOnWebcalc !== prevShowOnWebcalc || !isEqual(prevOptions, options)) {
      this.checkOptionsForWebcalc()
    }
  }

  public render() {
    const { edit, errors = {}, name, onBlur, onChange, options, title, value } = this.props
    const { previewUrlForImage, openDialog, hideWarning } = this.state

    const { globalBucket } = value.termsOfService
    const templateConfig = this.getTemplateConfig(value.priceSource)

    // Decides if standard template fields should be hidden or
    // not, these has not been given it's own display type
    // as some might not be happy about these fields moving
    const isAutoexpertenTemplate = value.priceSource === 'Autoexperten'
    const isEditDisabledInternalName: boolean = !edit

    return (
      <div className="ContractTemplate">
        <FormSection
          name="general"
          classNamePrefix="ContractTemplate"
          title={title ? (title === true ? 'Contract Template' : title) : ''}
        >
          <SelectField
            defaultValue="Pricelist"
            className="ContractTemplate__priceSource"
            disabled={!edit}
            errors={errors.priceSource}
            locked={!edit}
            name={name ? `${name}.priceSource` : 'priceSource'}
            onBlur={onBlur}
            onChange={onChange}
            onFieldChange={this.handleTemplateTypeChange}
            title="Price Source"
            value={value.priceSource}
            options={[
              { label: 'Price List', value: 'Pricelist' },
              { label: 'Autoexperten', value: 'Autoexperten' },
            ]}
          />
          <StringField
            className="ContractTemplate__name"
            disabled={isEditDisabledInternalName}
            errors={errors.name}
            locked={isEditDisabledInternalName}
            name={name ? `${name}.name` : 'name'}
            onBlur={onBlur}
            onChange={onChange}
            size={40}
            title="Internal Name (used in pricefile + etc, contact a dev if you REALLY want to update this)"
            value={value.name}
          />
          <StringField
            className="ContractTemplate__description"
            disabled={!edit}
            errors={errors.description}
            locked={!edit}
            name={name ? `${name}.description` : 'description'}
            onBlur={onBlur}
            onChange={onChange}
            size={80}
            title="External Name (shown on website)"
            value={value.description}
          />
          <StringField
            className="ContractTemplate__formattedDescription"
            disabled={!edit}
            hidden={true}
            errors={errors.formattedDescription}
            locked={!edit}
            name={name ? `${name}.formattedDescription` : 'formattedDescription'}
            onBlur={onBlur}
            onChange={onChange}
            size={80}
            title="Formatted Description"
            value={value.formattedDescription || undefined}
          />
          <Field name={name ? `${name}.image` : 'image'} value={value.image}>
            {({ field, form }: any) => (
              <FileUploadField
                accept=".png, .jpg, .jpeg"
                className="ContractProvider__image"
                contextParent={form}
                disabled={!edit}
                errors={errors.image}
                locked={!edit}
                name={field.name}
                onBlur={onBlur}
                onChange={onChange}
                title="Image"
                serverURL={`contractproviders/${value.providerId}/files`}
                uploadStorage={'General-Storage'}
                onDidUpload={this.onDidUpload}
                previewURL={previewUrlForImage || undefined}
                value={field.value}
              />
            )}
          </Field>
          {/* In standard template */}
          <BooleanField
            className="ContractTemplate__showOnWebcalc"
            errors={errors.showOnWebcalc}
            disabled={!edit}
            locked={!edit}
            hidden={['Pricelist', 'Autoexperten'].includes(value.priceSource) ? false : true}
            name={name ? `${name}.showOnWebcalc` : 'showOnWebcalc'}
            value={
              ['Pricelist', 'Autoexperten'].includes(value.priceSource)
                ? (value as IContractTemplateResponse).showOnWebcalc
                : false
            }
            onBlur={onBlur}
            onChange={onChange}
            title="Available on Webcalc"
          />
          {/* In ax template */}
          <BooleanField
            className="ContractTemplate__disableServiceInfoFile"
            errors={errors.disableServiceInfoFile}
            disabled={!edit}
            locked={!edit}
            hidden={value.priceSource === 'Autoexperten' ? false : true}
            name={name ? `${name}.disableServiceInfoFile` : 'disableServiceInfoFile'}
            value={value.priceSource === 'Autoexperten' ? value.disableServiceInfoFile || false : false}
            onBlur={onBlur}
            onChange={onChange}
            title="Disable default autoexperten service info file"
          />
          <SelectField
            defaultValue={null}
            className="ContractTemplate__calculationMethod"
            disabled={!edit}
            errors={errors.calculationMethod}
            locked={!edit}
            name={name ? `${name}.calculationMethod` : 'calculationMethod'}
            onBlur={onBlur}
            onChange={onChange}
            title="Calculation Method"
            value={value.calculationMethod}
            options={[
              { label: 'First Registration Date', value: 100 },
              { label: 'Contract Creation Date', value: 200 },
            ]}
          />
          <NumberField
            className="ContractTemplate__minimumPaymentsCount"
            disabled={globalBucket || !edit}
            errors={errors.minimumPaymentsCount}
            locked={globalBucket || !edit}
            min={0}
            name={name ? `${name}.minimumPaymentsCount` : 'minimumPaymentsCount'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Minimum months before cancellation"
            title="Minimum payments count"
            value={value.minimumPaymentsCount === null ? undefined : value.minimumPaymentsCount}
          />
          <NumberField
            className="ContractTemplate__minAge"
            disabled={!edit}
            errors={errors.minAge}
            locked={!edit}
            max={99999}
            min={0}
            name={name ? `${name}.minAge` : 'minAge'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Minimum age of the car (at contract creation) [months]"
            title="Min. Age"
            value={value.minAge}
          />
          <NumberField
            className="ContractTemplate__maxAge"
            disabled={!edit}
            errors={errors.maxAge}
            locked={(templateConfig && this.shouldBeLocked(templateConfig, 'maxAge')) || !edit}
            max={99999}
            min={0}
            name={name ? `${name}.maxAge` : 'maxAge'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Maximum age of the car (at contract creation) [months]"
            title="Max. Age"
            value={value.maxAge}
          />
          <NumberField
            className="ContractTemplate__defaultDuration"
            disabled={!edit}
            errors={errors.defaultDuration}
            locked={(templateConfig && this.shouldBeLocked(templateConfig, 'defaultDuration')) || !edit}
            min={0}
            name={name ? `${name}.defaultDuration` : 'defaultDuration'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Initial duration [months] to set by default when selecting the template"
            title="Default Duration"
            value={value.defaultDuration || undefined}
          />
          <NumberField
            className="ContractTemplate__defaultMileage"
            disabled={!edit}
            errors={errors.defaultMileage}
            locked={(templateConfig && this.shouldBeLocked(templateConfig, 'defaultMileage')) || !edit}
            min={0}
            name={name ? `${name}.defaultMileage` : 'defaultMileage'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Initial mileage [km] to set by default when selecting the template"
            title="Default Mileage"
            value={value.defaultMileage || undefined}
          />
          <NumberField
            className="ContractTemplate__weight"
            disabled={!edit}
            errors={errors.weight}
            locked={!edit}
            max={99999}
            min={0}
            name={name ? `${name}.weight` : 'weight'}
            onBlur={onBlur}
            onChange={onChange}
            title="Weight"
            value={value.weight}
          />
          {!!isAutoexpertenTemplate && (
            <DecimalField
              precision={'3Decimals'}
              edit={edit}
              errors={errors.totalPriceFactorInTenthOfCents}
              name={name ? `${name}.totalPriceFactorInTenthOfCents` : 'totalPriceFactorInTenthOfCents'}
              onBlur={onBlur}
              onChange={onChange}
              title="Total Price Factor"
              valueInCentsOrTenthOfCents={value.totalPriceFactorInTenthOfCents}
            />
          )}
          <TermsOfService
            uploadUrl={`contractproviders/${value.providerId}/files`}
            edit={edit && !globalBucket}
            errors={errors.termsOfService}
            name={name ? `${name}.termsOfService` : 'termsOfService'}
            onBlur={onBlur}
            onChange={onChange}
            title={true}
            value={value.termsOfService}
          />
          {'underdrivenCharge' in value && this.renderUnderAndOverDrivenCharge(value as IContractTemplateResponse)}
        </FormSection>
        <ContractTemplateOptions
          edit={edit}
          title="Template Properties"
          name={name ? `${name}.properties` : 'properties'}
          type="properties"
          value={value.properties}
          opposingValue={value.options}
          options={options}
        />
        <ContractTemplateOptions
          edit={edit}
          title="Template Options"
          name={name ? `${name}.options` : 'options'}
          type="options"
          value={value.options}
          opposingValue={value.properties}
          options={options}
        />
        <Dialog open={openDialog} onClose={this.closeDialog}>
          <DialogTitle>There are template options not available on Webcalc</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={<Checkbox checked={hideWarning} onChange={this.handleDontShowAgain} color="primary" />}
              label="Don't show this message again"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  private handleDontShowAgain = () => {
    const { hideWarning } = this.state
    setHideWebcalcOptionsWarning(!hideWarning)
    this.setState({ hideWarning: !hideWarning })
  }

  private closeDialog = () => this.setState({ openDialog: false })

  private checkOptionsForWebcalc = () => {
    // if user prefers to hide the warning, we don't need to check the options
    if (getHideWebcalcOptionsWarning()) {
      return
    }
    // Attention: There is a hack to make sure that the template type (and options type) is set to the correct value
    // Note: options are stringified array, so we need to parse them
    const { showOnWebcalc, options } = this.props.value as IContractTemplateResponse
    const hasOptionsNotAvailableOnWebcalc = showOnWebcalc && options.some((option) => !option.showOnWebcalc)
    this.setState({ hasOptionsNotAvailableOnWebcalc, openDialog: hasOptionsNotAvailableOnWebcalc })
  }

  private handleTemplateTypeChange = (newValue: TSelecetValue) => {
    if (newValue === this.props.value.priceSource) return

    let newContractTemplate: any = contractTemplateBaseValues
    let name = this.props.value.name
    let description = this.props.value.description

    if (newValue === 'Pricelist') {
      name = ''
      description = ''
    } else if (newValue === 'Autoexperten') {
      newContractTemplate = { ...newContractTemplate, ...autoexpertenBaseValues }
      newContractTemplate.showOnWebcalc = false
      name = ''
      description = ''
    }

    const baseContractTemplateValues = this.mapToContractTemplateBaseType(this.props.value)
    baseContractTemplateValues.priceSource = newValue as PriceSource

    const templateDetails = {
      ...baseContractTemplateValues,
      ...newContractTemplate,
      name,
      description,
      disableServiceInfoFile: false,
    } as IGenericContractTemplateResponse

    this.props.onChangeTemplateType(templateDetails)
  }

  private getTemplateConfig = (priceSource: PriceSource) => {
    return templateConfigs[priceSource] || undefined
  }

  private shouldBeLocked = (config: ITemplateConfig, fieldName: string) => {
    return config.lockedFields.includes(fieldName)
  }

  private mapToContractTemplateBaseType = (template: IGenericContractTemplateResponse) => {
    return {
      id: template.id,
      name: template.name,
      image: template.image,
      imageUrl: template.imageUrl,
      description: template.description,
      properties: template.properties,
      options: template.options,
      calculationMethod: template.calculationMethod,
      priceSource: template.priceSource,
      minAge: template.minAge,
      maxAge: template.maxAge,
      defaultDuration: template.defaultDuration,
      defaultMileage: template.defaultMileage,
      minimumPaymentsCount: template.minimumPaymentsCount,
      termsOfService: template.termsOfService,
      providerId: template.providerId,
      formattedDescription: template.formattedDescription,
      weight: template.weight,
      maxEndAge: template.maxEndAge,
      serviceVariantName: template.serviceVariantName,
      serviceVariantId: template.serviceVariantId,
      isProductTemplate: template.isProductTemplate,
    }
  }

  private renderUnderAndOverDrivenCharge = (value: IContractTemplateResponse) => {
    const { errors = {}, edit, name, onBlur, onChange, vatProcentage } = this.props
    return (
      <>
        <PriceSpecification
          edit={edit}
          errors={errors.underdrivenCharge}
          name={name ? `${name}.underdrivenCharge` : 'underdrivenCharge'}
          onBlur={onBlur}
          onChange={onChange}
          title="Underdriven Charge"
          value={value.underdrivenCharge}
          vatPct={vatProcentage}
        />
        <PriceSpecification
          edit={edit}
          errors={errors.overdrivenCharge}
          name={name ? `${name}.overdrivenCharge` : 'overdrivenCharge'}
          onBlur={onBlur}
          onChange={onChange}
          title="Overdriven Charge"
          value={value.overdrivenCharge}
          vatPct={vatProcentage}
        />
      </>
    )
  }
}

export default ContractTemplate
