import React, { ChangeEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './PasswordField.css'

interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  name: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
}

const PasswordField: React.SFC<IProps> = ({ className, disabled, name, onChange, value }) => (
  <input
    type="password"
    className={className}
    disabled={disabled}
    id={name}
    name={name}
    onChange={onChange}
    value={value}
  />
)

PasswordField.defaultProps = {
  className: '',
  disabled: false,
  value: '',
}

export default withStyledField(PasswordField, 'PasswordField')
