import { ISAMSuperAdminConfig } from './configTypes'

export const Config: ISAMSuperAdminConfig = {
  supportedCountries: ['DK', 'SE', 'FI', 'NO'],
  appName: 'SAM-SuperAdmin',
  dryRun: process.env.DRYRUN === '1',

  env: process.env.NODE_ENV || 'local',

  gCloud: {
    bucket: process.env.GCLOUD_BUCKET || '',
    bucketURL: process.env.GCLOUD_BUCKET_URL || '',
    translationBucket: process.env.GCLOUD_TRANSLATION_BUCKET || '',
    projectId: process.env.PROJECT_ID || '',
    keyFileName: process.env.KEY_FILE_NAME || '',
  },
}
