import { combineReducers } from 'redux'
import { initialState, IRootState } from './initialState'
import { connectRouter } from 'connected-react-router'
import { applicationReducer } from './application/applicationReducer'
import { providerPageReducer } from './providerPage/providerPageReducer'
import { outageReducer } from './outageReducer'
import userReducer from './user'
import browserHistory from 'utils/history'

import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'

// @TODO: Use "as any" before, which is wrong - will "{}" work properly?
const appReducer = combineReducers<IRootState>({
  application: applicationReducer,
  providerPage: providerPageReducer,
  router: connectRouter(browserHistory),
  user: userReducer,
  outage: outageReducer,
} as {})

const rootReducer = (state: IRootState | undefined = initialState, action: ActionTypes): IRootState => {
  if (action.type === ActionTypeKeys.STORE_RESET) {
    state = { ...initialState }
  }

  return appReducer(state, action)
}

export default rootReducer
