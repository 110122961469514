import React from 'react'
import { Tab, Tabs } from '@material-ui/core'
import './TabPanel.css'

interface IProps {
  tabIndex: number
  onChange: (e: React.ChangeEvent<{}>, tabIndex: number) => void
  tabTitles: string[]
  tabComponents: React.ReactNode[]
}

interface ITabContentProps {
  children?: React.ReactNode
  index: number
  value: number
  className: string
}

const TabContent = ({ children, value, index, ...other }: ITabContentProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && children}
  </div>
)

const getTabProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

const TabPanel: React.SFC<IProps> = ({ tabIndex, onChange, tabTitles, tabComponents }) => {
  if (!tabTitles.length || !tabComponents.length || tabTitles.length !== tabComponents.length) {
    return <></>
  }
  return (
    <>
      <Tabs value={tabIndex} onChange={onChange} className="TabPanel__tabs">
        {tabTitles.map((title, index) => (
          <Tab label={title} key={index} {...getTabProps(index)} className="TabPanel__tab" />
        ))}
      </Tabs>
      {tabComponents.map((component, index) => (
        <TabContent value={tabIndex} key={index} index={index} className="TabPanel__content">
          {component}
        </TabContent>
      ))}
    </>
  )
}

export default TabPanel
