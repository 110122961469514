export interface IUserAuthData {
  token: string
  isDev?: boolean
}

export interface IUserState {
  authData: IUserAuthData
}

export default {
  authData: { token: '' },
} as IUserState
