import React from 'react'
import TextareaField from 'components/Field/TextareaField/TextareaField'
import FormSection from 'components/FormSection/FormSection'
import { IsoLocale, ITranslationValueResponse } from '@omnicar/sam-types'
import { isEqual } from 'lodash'

interface IState {
  currentValue: string | undefined
}

interface IProps {
  value: ITranslationValueResponse
  locale: IsoLocale
  onChange: (value: ITranslationValueResponse) => void
  disabled: boolean
}

class TranslationValue extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = { currentValue: undefined }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { value } = this.props
    if (!isEqual(prevProps.value, value)) {
      this.setState({ currentValue: value.value })
    }
  }

  render() {
    const { locale, disabled } = this.props
    const { currentValue } = this.state

    return (
      <div className="TranslationValue_inner">
        <FormSection name="general" classNamePrefix="TranslationValue">
          <span title="Translation value">
            <TextareaField
              className="Translation__value"
              name="value"
              onChange={this.handleChange}
              cols={73}
              title={locale}
              value={currentValue}
              disabled={disabled}
            />
          </span>
        </FormSection>
      </div>
    )
  }

  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: currentValue } = e.target
    const { onChange, value } = this.props

    this.setState({ currentValue })
    onChange({ ...value, value: currentValue })
  }
}

export default TranslationValue
