import { ITermsOfServiceResponse } from '@omnicar/sam-types'
import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredPrimaryKey, requiredString } from './validations'

// API Interfaces -> Just direct exports to keep import proxied here
// as we also have default values, filters and models
export interface ITermsOfServicePatch extends ITermsOfServiceResponse {}
export interface ITermsOfServicePost extends ITermsOfServiceResponse {}

// Default Values
export const termsOfServiceValueDefaults: ITermsOfServiceResponse = {
  id: -1,
  name: '',
  ref: '',
  globalBucket: false,
}

// API Model
const model: IApiModel = {
  name: 'termsOfService',
  fields: [
    {
      name: 'id',
      uiType: 'string',
      title: 'Terms of Service ID',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'name',
      uiType: 'string',
      title: 'Name',
      validation: [requiredString],
    },
    {
      name: 'ref',
      uiType: 'string',
      title: 'Reference',
      validation: [requiredString],
    },
  ],
  view: {
    providerPage: [
      {
        name: 'id',
      },
      {
        name: 'name',
      },
      {
        name: 'description',
      },
    ],
  },
}

export const termsOfServiceModel = (view?: string | IApiField[], context?: string) =>
  createModel<ITermsOfServiceResponse>(view, context, model, termsOfServiceValueDefaults)
