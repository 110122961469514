import React from 'react'
import './EnvironmentOverlay.css'

const getEnvironmentText = () => {
  let text = ''

  if (process.env.REACT_APP_BRANCH) {
    text = process.env.REACT_APP_BRANCH
  } else {
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case 'development':
        text = 'development'
        break
      case 'development_local':
        text = 'local'
        break
      case 'demo':
        text = 'demo'
        break
      case 'staging':
        text = 'staging'
        break
      default:
        break
    }
  }
  return text
}

const EnvironmentOverlay: React.SFC<{}> = () => {
  const environment = getEnvironmentText()

  return (
    <div className={`EnvironmentOverlay ${environment ? `EnvironmentOverlay--${environment}` : ''}`}>
      {environment && <div className="EnvironmentOverlay__text">{environment}</div>}
    </div>
  )
}

export default EnvironmentOverlay
