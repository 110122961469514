import React, { ChangeEvent } from 'react'
import { StripeCountryType, IStripeExternalAccount } from '@omnicar/sam-types'
import { IApiErrors } from '../../../../apiModels/apiModel'
import { currencyOptions, entityOptions } from '../../../../apiModels/stripe'
import BooleanField from '../../../Field/BooleanField/BooleanField'
import SelectField from '../../../Field/SelectField/SelectField'
import StringField from '../../../Field/StringField/StringField'
import FormSection from '../../../FormSection/FormSection'
import { BaseOption as Option } from 'types'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  key?: string
  name?: string
  countryOptions: null | Option<StripeCountryType>[]
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean
  value: IStripeExternalAccount
}

const ExternalAccount: React.SFC<IProps> = ({
  edit,
  errors = {},
  name,
  countryOptions,
  onBlur,
  onChange,
  title,
  value,
}) => (
  <div className="ExternalAccount">
    <FormSection
      name="general"
      classNamePrefix="ExternalAccount"
      title={title ? (title === true ? 'External Account ' : title) : ''}
    >
      <input type="hidden" value={value.providerId} />

      <StringField
        className="ExternalAccount__accountNumber"
        disabled={!edit}
        errors={errors.accountNumber}
        locked={!edit}
        name={name ? `${name}.accountNumber` : 'accountNumber'}
        onBlur={onBlur}
        onChange={onChange}
        size={50}
        title="IBAN No."
        value={value.accountNumber}
      />
      <StringField
        className="ExternalAccount__accountHolderName"
        disabled={!edit}
        errors={errors.accountHolderName}
        locked={!edit}
        name={name ? `${name}.accountHolderName` : 'accountHolderName'}
        onBlur={onBlur}
        onChange={onChange}
        size={50}
        title="Account Holder Name"
        value={value.accountHolderName}
      />
      <SelectField
        className="ExternalAccount__accountHolderType"
        defaultValue="company"
        disabled={!edit}
        errors={errors.accountHolderType}
        locked={!edit}
        name={name ? `${name}.accountHolderType` : 'accountHolderType'}
        onBlur={onBlur}
        onChange={onChange}
        options={entityOptions}
        selectProps={{ clearable: false }}
        title="Account Holder Type"
        value={value.accountHolderType}
      />
      {countryOptions ? (
        <SelectField
          className="ExternalAccount__country"
          defaultValue="DK"
          disabled={!edit}
          errors={errors.country}
          locked={!edit}
          name={name ? `${name}.country` : 'country'}
          onBlur={onBlur}
          onChange={onChange}
          options={countryOptions || [{ label: '-- Countries not initialized --', value: '' }]}
          title="Country"
          value={value.country}
        />
      ) : (
        <div>Fetching countries...</div>
      )}
      <SelectField
        className="ExternalAccount__currency"
        defaultValue="dkk"
        disabled={!edit}
        errors={errors.currency}
        locked={!edit}
        name={name ? `${name}.currency` : 'currency'}
        onBlur={onBlur}
        onChange={onChange}
        options={currencyOptions}
        title="Currency"
        value={value.currency}
      />
      <BooleanField
        className="Stripe__defaultForCurrency"
        disabled={true}
        errors={errors.defaultForCurrency}
        hidden={true}
        locked={true}
        name={name ? `${name}.defaultForCurrency` : 'defaultForCurrency'}
        onBlur={onBlur}
        onChange={onChange}
        title="Default For Currency"
        value={value.defaultForCurrency}
      />
    </FormSection>
  </div>
)

export default ExternalAccount
