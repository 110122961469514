import { ICountryResponse } from '@omnicar/sam-types'
import IApiModel, { createModel, IApiField } from './apiModel'
import { requiredPrimaryKey, requiredString } from './validations'

export interface ICountryPatch extends ICountryResponse {}

export interface ICountryPost extends ICountryResponse {}

export interface ICountryGet {
  countryId?: number
  isoName?: number
}

// Default Values
export const countryValueDefaults: ICountryResponse = {
  countryId: -1,
  isoName: 'DK',
  countryName: 'Denmark',
  languageName: 'Danish',
  currency: 'DKK',
  vatPct: 0.25,
}

// API Model
const model: IApiModel = {
  name: 'countryModel',
  fields: [
    {
      name: 'countryId',
      uiType: 'string',
      title: 'Country ID',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'isoName',
      uiType: 'string',
      title: 'ISO Name',
      validation: [requiredString],
    },
  ],
  view: {
    providerPage: [
      {
        name: 'isoName',
      },
    ],
  },
}

export const countryModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<ICountryResponse>(view, viewContext, model, countryValueDefaults)
