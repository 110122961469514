import { IContractOptionResponse } from '@omnicar/sam-types'
import React, { ChangeEvent } from 'react'
import { BaseOption as Option } from 'types'
import { IApiErrors } from '../../../apiModels/apiModel'
import BooleanField from '../../Field/BooleanField/BooleanField'
// import { PriceSpecification, TPrecision } from '../PriceSpecification/PriceSpecification'
import NumberField from '../../Field/NumberField/NumberField'
import SelectField from '../../Field/SelectField/SelectField'
import StringField from '../../Field/StringField/StringField'
import FormSection from '../../FormSection/FormSection'
import PriceSpecification from '../PriceSpecification/PriceSpecification'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean | string
  value: IContractOptionResponse
  warrantyOptions: Option<number | null>[]
  vatPct?: number
  countryName?: string
  currency?: string
}

const ContractOption: React.SFC<IProps> = ({
  edit,
  errors = {},
  name,
  title,
  value,
  onBlur,
  onChange,
  warrantyOptions,
  vatPct,
  countryName,
  currency,
}) => {
  const isEditDisabledInternalName: boolean = !edit

  return (
    <div className="ContractOption">
      <FormSection
        classNamePrefix="ContractOption"
        name="general"
        title={title ? (title === true ? 'Contract Option' : title) : ''}
      >
        <StringField
          className="ContractOption__internalName"
          disabled={isEditDisabledInternalName}
          errors={errors.internalName}
          locked={isEditDisabledInternalName}
          name={name ? `${name}.internalName` : 'internalName'}
          onBlur={onBlur}
          onChange={onChange}
          size={90}
          title="Internal Name (used in pricefile + etc, contact a dev if you REALLY want to update this)"
          value={value.internalName}
        />
        <StringField
          className="ContractOption__description"
          disabled={!edit}
          errors={errors.description}
          locked={!edit}
          name={name ? `${name}.description` : 'description'}
          onBlur={onBlur}
          onChange={onChange}
          size={90}
          title="External Name (shown on website)"
          value={value.description}
        />
        <StringField
          className="ContractOption__conditionText"
          disabled={!edit}
          errors={errors.conditionText}
          locked={!edit}
          name={name ? `${name}.conditionText` : 'conditionText'}
          onBlur={onBlur}
          onChange={onChange}
          size={120}
          title="Conditions"
          value={value.conditionText}
        />
        <StringField
          className="ContractOption__abbreviation"
          disabled={!edit}
          errors={errors.abbreviation}
          locked={!edit}
          name={name ? `${name}.abbreviation` : 'abbreviation'}
          onBlur={onBlur}
          onChange={onChange}
          size={3}
          title="Abbreviation"
          value={value.abbreviation}
        />
        <NumberField
          className="ContractOption__weight"
          disabled={!edit}
          errors={errors.weight}
          locked={!edit}
          max={99999}
          min={0}
          name={name ? `${name}.weight` : 'weight'}
          onBlur={onBlur}
          onChange={onChange}
          title="Weight"
          value={value.weight}
        />
        <PriceSpecification
          precision={currency === 'EUR' ? '3Decimals' : '2Decimals'}
          edit={edit}
          errors={errors.price}
          name={name ? `${name}.price` : 'price'}
          onBlur={onBlur}
          onChange={onChange}
          value={value.price}
          title="Price"
          vatPct={vatPct}
          countryName={countryName}
        />
        <NumberField
          className="ContractOption__optionGroup"
          disabled={!edit}
          errors={errors.weight}
          locked={!edit}
          max={99999}
          min={0}
          name={name ? `${name}.optionGroup` : 'optionGroup'}
          onBlur={onBlur}
          onChange={onChange}
          title="Option Group (unless empty, only one option from each group can be chosen to a contract)"
          value={value.optionGroup === null ? undefined : value.optionGroup}
        />
        <BooleanField
          className="ContractOption__showOnWebcalc"
          errors={errors.showOnWebcalc}
          disabled={!edit}
          locked={!edit}
          hidden={false}
          name={name ? `${name}.showOnWebcalc` : 'showOnWebcalc'}
          value={value.showOnWebcalc}
          onBlur={onBlur}
          onChange={onChange}
          title="Available on Webcalc"
        />
        <SelectField
          defaultValue={null}
          className="ContractOption__calculationMethod"
          disabled={!edit}
          errors={errors.calculationMethod}
          locked={!edit}
          name={name ? `${name}.calculationMethod` : 'calculationMethod'}
          onBlur={onBlur}
          onChange={onChange}
          title="Calculation Method"
          value={value.calculationMethod}
          options={[
            { label: '==== Same as Template ====', value: null },
            { label: 'First Registration Date', value: 100 },
            { label: 'Contract Creation Date', value: 200 },
          ]}
        />
        <SelectField
          defaultValue={null}
          className="ContractOption__warranty"
          disabled={!edit}
          errors={errors.warranty}
          locked={!edit}
          name={name ? `${name}.warranty` : 'warranty'}
          onBlur={onBlur}
          onChange={onChange}
          title="Warranty"
          value={value.warranty}
          options={warrantyOptions}
        />
      </FormSection>
    </div>
  )
}

export default ContractOption
