import React, { MouseEvent, KeyboardEvent, ReactElement, ReactNode } from 'react'
import IApiModel, { IApiField, TApiRecord, IApiFieldRendererValue } from '../../apiModels/apiModel'
import getUniqueId from '../../utils/uniqueId'
import './DataList.css'

export interface IDataListActionColumn {
  name: string
  renderer(cbArg: IApiFieldRendererValue): ReactNode
}

interface IProps {
  actionColumns?: IDataListActionColumn[]
  model: IApiModel
  primaryKey: string
  activeId?: number
  data: TApiRecord[]
  onRowClick?: (record: TApiRecord) => void
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class DataList extends React.Component<IProps, {}> {
  private uniqueId: string = getUniqueId('DataList')

  private headerCells: any[] = []
  private bodyRows: any[] = []

  public render() {
    this.updateHtmlElements()

    const { headerCells, bodyRows } = this

    return (
      <table className="DataList">
        <thead className="DataList__header">
          <tr className="DataList__header-row">{headerCells}</tr>
        </thead>
        <tbody className="DataList__body">{bodyRows}</tbody>
        <tfoot className="DataList__footer">
          <tr className="DataList__footer-row">
            <td className="DataList__footer-row-cell" colSpan={100} />
          </tr>
        </tfoot>
      </table>
    )
  }

  private handleRowClick = (id: string) => {
    const recordId = +id
    const recordArr = this.getRecordById(recordId)

    if (recordArr && recordArr.length) {
      const record = recordArr[0]

      if (record) {
        const { onRowClick } = this.props
        onRowClick && onRowClick(record)
      }
    }
  }

  private handleRowClickMouse = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    const { id } = event.currentTarget.dataset

    if (id) {
      this.handleRowClick(id)
    }
  }

  private handleRowClickKeyboard = (event: KeyboardEvent<HTMLElement>) => {
    event.stopPropagation()

    // keyCode 13 is the enter key
    if (event.keyCode === 13) {
      const { id } = event.currentTarget.dataset

      if (id) {
        this.handleRowClick(id)
      }
    }
  }

  private getRecordById = (id: number) => {
    const { primaryKey, data } = this.props
    return data.filter((record: TApiRecord) => (record[primaryKey] === id ? record : false))
  }

  private updateHtmlElements = () => {
    const headerCells = this.renderHeaderCells()
    this.headerCells = headerCells || []

    const bodyRows = this.renderBodyRows()
    this.bodyRows = bodyRows || []
  }

  private renderHeaderCells = () => {
    const { renderHeaderRecordCell, renderHeaderActionCell } = this
    const { model, actionColumns = [] } = this.props

    // Add record cells
    const recordCells = model.fields.map((field: IApiField) => renderHeaderRecordCell(field))

    // Add action cells
    const actionCells = actionColumns.map((column: IDataListActionColumn) => renderHeaderActionCell(column))

    return [...recordCells, ...actionCells]
  }

  private renderHeaderRecordCell = (field: IApiField) => {
    const { uniqueId } = this

    return (
      <th
        className={`DataList__header-row-cell DataList__header-row-cell--${field.uiType}`}
        key={`head-cell-${field.name}-${uniqueId}`}
      >
        {field.title && field.title}
      </th>
    )
  }

  private renderHeaderActionCell = (column: IDataListActionColumn) => {
    const { uniqueId } = this

    return (
      <th
        className="DataList__header-row-cell DataList__header-row-cell--action"
        key={`head-cell-action-${column.name}-${uniqueId}`}
      />
    )
  }

  private renderBodyRows = () => {
    const { renderBodyRow } = this
    const { data } = this.props
    const bodyRows: Array<ReactElement<HTMLTableRowElement>> = []

    if (data && data.length) {
      data.forEach((record: TApiRecord, rowIndex: number) => {
        const row = renderBodyRow(record, rowIndex)
        bodyRows.push(row)
      })
    }

    return bodyRows
  }

  private renderBodyRow = (record: TApiRecord, rowIndex: number) => {
    const { uniqueId } = this
    const { actionColumns = [], activeId, model, primaryKey } = this.props
    const { handleRowClickMouse, handleRowClickKeyboard, renderBodyRowRecordCell, renderBodyRowActionCell } = this

    const bodyRowCells: Array<ReactElement<HTMLTableDataCellElement>> = []
    const primaryValue = record[primaryKey]

    model.fields.forEach((field: IApiField) => {
      const cell = renderBodyRowRecordCell(record, field, rowIndex)

      if (cell) {
        bodyRowCells.push(cell)
      }
    })

    actionColumns.forEach((column: IDataListActionColumn) => {
      const cell = renderBodyRowActionCell(column, record, rowIndex)

      if (cell) {
        bodyRowCells.push(cell)
      }
    })

    const selectedClass =
      activeId != null && primaryValue != null ? (activeId === primaryValue ? 'DataList__body-row--selected' : '') : ''

    return (
      <tr
        className={`DataList__body-row ${selectedClass}`}
        key={`body-row-${primaryValue}-${uniqueId}`}
        data-id={primaryValue}
        onClick={handleRowClickMouse}
        onKeyPress={handleRowClickKeyboard}
      >
        {bodyRowCells}
      </tr>
    )
  }

  private renderBodyRowRecordCell = (record: TApiRecord, field: IApiField, rowIndex: number) => {
    const { uniqueId } = this
    const innerHtml: ReactNode[] = []

    const key = `body-row-cell-${field.name}-${uniqueId}`
    const innerKey = `${key}-inner`

    // Only proceed if there is an actual value involved that is not undefined
    // @NOTE: We allow null as a value - so only check on not undefined
    const isRecordCell = field.relation
      ? field.name && record[field.relation] !== undefined
      : field.name && record[field.name] !== undefined

    if (isRecordCell) {
      let value = record[field.name] || '-'

      if (field.relation && record[field.relation]) {
        try {
          value = record[field.relation][field.name]
        } catch (err) {
          return value
        }
      }

      // Deal with possible provided renderer from model to render inside <td />
      if (field.renderer) {
        const cbArg = {
          value,
          record,
          key: `${key}-renderer`,
          recordIndex: rowIndex,
        }
        const renderedHtml = field.renderer(cbArg)

        innerHtml.push(renderedHtml)
      } else {
        innerHtml.push(value)
      }
    }

    return (
      <td className={`DataList__body-row-cell DataList__body-row-cell--${field.uiType}`} key={key}>
        <div className="DataList__body-row-cell-inner" key={innerKey}>
          {innerHtml[0]}
        </div>
      </td>
    )
  }

  private renderBodyRowActionCell = (column: IDataListActionColumn, record: TApiRecord, rowIndex: number) => {
    const { uniqueId } = this
    const innerHtml: ReactNode[] = []

    const key = `body-row-cell-${column.name}-${uniqueId}-action`
    const innerKey = `${key}-inner`

    const renderedHtml = column.renderer({
      value: null,
      record,
      key: `${key}-renderer`,
      recordIndex: rowIndex,
    })

    innerHtml.push(renderedHtml)

    return (
      <td className="DataList__body-row-cell DataList__body-row-cell--action" key={key}>
        <div className="DataList__body-row-cell-inner" key={innerKey}>
          {innerHtml[0]}
        </div>
      </td>
    )
  }
}

export default DataList
