import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { trackPageView } from './analytics'

const withTracker = (WrappedComponent: React.StatelessComponent<any> | React.ComponentClass<any>) => {
  const HOC = class extends Component<RouteComponentProps<any>, {}> {
    public componentDidMount() {
      const page = this.props.location.pathname
      trackPageView(page)
    }

    public render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}

export default withTracker
