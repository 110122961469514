import React from 'react'
import './ReleaseNotes.css'
import SortableTable, { IAdditionalColumn, createStandardTableButton } from 'components/SortableTable/SortableTable'
import { IReleaseVersionResponse, ReleaseSystem } from '@omnicar/sam-types'
import { model } from 'apiModels/releaseVersion'

interface IProps {
  itemsList: IReleaseVersionResponse[]
  onPublish: (releaseVersion: IReleaseVersionResponse) => Promise<void>
  onEdit: (releaseVersion: IReleaseVersionResponse) => void
  onCreate: () => void
}

const tableFieldsMapper = (record: IReleaseVersionResponse, fieldName: string): string | undefined =>
  ['releaseSystem'].includes(fieldName) ? ReleaseSystem[record[fieldName]] : undefined

const ReleaseVersions: React.SFC<IProps> = ({ itemsList, onEdit, onPublish, onCreate }) => {
  const tableButtons: IAdditionalColumn[] = [
    {
      title: '',
      processRecord: createStandardTableButton('Edit', onEdit, 'ReleaseNotes__editbutton'),
    },
    {
      title: '',
      processRecord: createStandardTableButton(
        (record: IReleaseVersionResponse) => (record.published ? 'Unpublish' : 'Publish'),
        onPublish,
        (record: IReleaseVersionResponse) => `ReleaseNotes__${record.published ? 'deletebutton' : 'publishbutton'}`,
      ),
    },
  ]

  return (
    <>
      <button className="ReleaseNotes__createbutton" onClick={onCreate}>
        Create
      </button>
      <SortableTable
        primaryKey="releaseVersionId"
        data={itemsList}
        model={model}
        customMapper={tableFieldsMapper}
        additionalColumns={tableButtons}
        defaultSortColumn={'version'}
        defaultSortingOrder={'desc'}
      />
    </>
  )
}

export default ReleaseVersions
