export interface IZipCodes {
  [key: string]: string
}

export interface IZipCodeMap {
  dk: IZipCodes
  se: IZipCodes
}

export const zipCodeMap: IZipCodeMap = {
  dk: {
    '1000': 'København K',
    '1001': 'København K',
    '1002': 'København K',
    '1003': 'København K',
    '1004': 'København K',
    '1005': 'København K',
    '1006': 'København K',
    '1007': 'København K',
    '1008': 'København K',
    '1009': 'København K',
    '1010': 'København K',
    '1011': 'København K',
    '1012': 'København K',
    '1013': 'København K',
    '1014': 'København K',
    '1015': 'København K',
    '1016': 'København K',
    '1017': 'København K',
    '1018': 'København K',
    '1019': 'København K',
    '1020': 'København K',
    '1021': 'København K',
    '1022': 'København K',
    '1023': 'København K',
    '1024': 'København K',
    '1025': 'København K',
    '1026': 'København K',
    '1045': 'København K',
    '1050': 'København K',
    '1051': 'København K',
    '1052': 'København K',
    '1053': 'København K',
    '1054': 'København K',
    '1055': 'København K',
    '1056': 'København K',
    '1057': 'København K',
    '1058': 'København K',
    '1059': 'København K',
    '1060': 'København K',
    '1061': 'København K',
    '1062': 'København K',
    '1063': 'København K',
    '1064': 'København K',
    '1065': 'København K',
    '1066': 'København K',
    '1067': 'København K',
    '1068': 'København K',
    '1069': 'København K',
    '1070': 'København K',
    '1071': 'København K',
    '1072': 'København K',
    '1073': 'København K',
    '1074': 'København K',
    '1092': 'København K',
    '1093': 'København K',
    '1095': 'København K',
    '1098': 'København K',
    '1100': 'København K',
    '1101': 'København K',
    '1102': 'København K',
    '1103': 'København K',
    '1104': 'København K',
    '1105': 'København K',
    '1106': 'København K',
    '1107': 'København K',
    '1110': 'København K',
    '1111': 'København K',
    '1112': 'København K',
    '1113': 'København K',
    '1114': 'København K',
    '1115': 'København K',
    '1116': 'København K',
    '1117': 'København K',
    '1118': 'København K',
    '1119': 'København K',
    '1120': 'København K',
    '1121': 'København K',
    '1122': 'København K',
    '1123': 'København K',
    '1124': 'København K',
    '1125': 'København K',
    '1126': 'København K',
    '1127': 'København K',
    '1128': 'København K',
    '1129': 'København K',
    '1130': 'København K',
    '1131': 'København K',
    '1140': 'København K',
    '1147': 'København K',
    '1148': 'København K',
    '1150': 'København K',
    '1151': 'København K',
    '1152': 'København K',
    '1153': 'København K',
    '1154': 'København K',
    '1155': 'København K',
    '1156': 'København K',
    '1157': 'København K',
    '1158': 'København K',
    '1159': 'København K',
    '1160': 'København K',
    '1161': 'København K',
    '1162': 'København K',
    '1163': 'København K',
    '1164': 'København K',
    '1165': 'København K',
    '1166': 'København K',
    '1167': 'København K',
    '1168': 'København K',
    '1169': 'København K',
    '1170': 'København K',
    '1171': 'København K',
    '1172': 'København K',
    '1173': 'København K',
    '1174': 'København K',
    '1175': 'København K',
    '1200': 'København K',
    '1201': 'København K',
    '1202': 'København K',
    '1203': 'København K',
    '1204': 'København K',
    '1205': 'København K',
    '1206': 'København K',
    '1207': 'København K',
    '1208': 'København K',
    '1209': 'København K',
    '1210': 'København K',
    '1211': 'København K',
    '1212': 'København K',
    '1213': 'København K',
    '1214': 'København K',
    '1215': 'København K',
    '1216': 'København K',
    '1217': 'København K',
    '1218': 'København K',
    '1219': 'København K',
    '1220': 'København K',
    '1221': 'København K',
    '1240': 'København K',
    '1250': 'København K',
    '1251': 'København K',
    '1253': 'København K',
    '1254': 'København K',
    '1255': 'København K',
    '1256': 'København K',
    '1257': 'København K',
    '1259': 'København K',
    '1260': 'København K',
    '1261': 'København K',
    '1263': 'København K',
    '1264': 'København K',
    '1265': 'København K',
    '1266': 'København K',
    '1267': 'København K',
    '1268': 'København K',
    '1270': 'København K',
    '1271': 'København K',
    '1300': 'København K',
    '1301': 'København K',
    '1302': 'København K',
    '1303': 'København K',
    '1304': 'København K',
    '1306': 'København K',
    '1307': 'København K',
    '1308': 'København K',
    '1309': 'København K',
    '1310': 'København K',
    '1311': 'København K',
    '1312': 'København K',
    '1313': 'København K',
    '1314': 'København K',
    '1315': 'København K',
    '1316': 'København K',
    '1317': 'København K',
    '1318': 'København K',
    '1319': 'København K',
    '1320': 'København K',
    '1321': 'København K',
    '1322': 'København K',
    '1323': 'København K',
    '1324': 'København K',
    '1325': 'København K',
    '1326': 'København K',
    '1327': 'København K',
    '1328': 'København K',
    '1329': 'København K',
    '1350': 'København K',
    '1352': 'København K',
    '1353': 'København K',
    '1354': 'København K',
    '1355': 'København K',
    '1356': 'København K',
    '1357': 'København K',
    '1358': 'København K',
    '1359': 'København K',
    '1360': 'København K',
    '1361': 'København K',
    '1362': 'København K',
    '1363': 'København K',
    '1364': 'København K',
    '1365': 'København K',
    '1366': 'København K',
    '1367': 'København K',
    '1368': 'København K',
    '1369': 'København K',
    '1370': 'København K',
    '1371': 'København K',
    '1400': 'København K',
    '1401': 'København K',
    '1402': 'København K',
    '1403': 'København K',
    '1404': 'København K',
    '1406': 'København K',
    '1407': 'København K',
    '1408': 'København K',
    '1409': 'København K',
    '1410': 'København K',
    '1411': 'København K',
    '1412': 'København K',
    '1413': 'København K',
    '1414': 'København K',
    '1415': 'København K',
    '1416': 'København K',
    '1417': 'København K',
    '1418': 'København K',
    '1419': 'København K',
    '1420': 'København K',
    '1421': 'København K',
    '1422': 'København K',
    '1423': 'København K',
    '1424': 'København K',
    '1425': 'København K',
    '1426': 'København K',
    '1427': 'København K',
    '1428': 'København K',
    '1429': 'København K',
    '1430': 'København K',
    '1431': 'København K',
    '1432': 'København K',
    '1433': 'København K',
    '1434': 'København K',
    '1435': 'København K',
    '1436': 'København K',
    '1437': 'København K',
    '1438': 'København K',
    '1439': 'København K',
    '1440': 'København K',
    '1441': 'København K',
    '1448': 'København K',
    '1450': 'København K',
    '1451': 'København K',
    '1452': 'København K',
    '1453': 'København K',
    '1454': 'København K',
    '1455': 'København K',
    '1456': 'København K',
    '1457': 'København K',
    '1458': 'København K',
    '1459': 'København K',
    '1460': 'København K',
    '1461': 'København K',
    '1462': 'København K',
    '1463': 'København K',
    '1464': 'København K',
    '1465': 'København K',
    '1466': 'København K',
    '1467': 'København K',
    '1468': 'København K',
    '1470': 'København K',
    '1471': 'København K',
    '1472': 'København K',
    '1473': 'København K',
    '1500': 'København V',
    '1501': 'København V',
    '1502': 'København V',
    '1503': 'København V',
    '1504': 'København V',
    '1505': 'København V',
    '1506': 'København V',
    '1507': 'København V',
    '1508': 'København V',
    '1509': 'København V',
    '1510': 'København V',
    '1512': 'København V',
    '1513': 'København V',
    '1532': 'København V',
    '1533': 'København V',
    '1550': 'København V',
    '1551': 'København V',
    '1552': 'København V',
    '1553': 'København V',
    '1554': 'København V',
    '1555': 'København V',
    '1556': 'København V',
    '1557': 'København V',
    '1558': 'København V',
    '1559': 'København V',
    '1560': 'København V',
    '1561': 'København V',
    '1562': 'København V',
    '1563': 'København V',
    '1564': 'København V',
    '1566': 'København V',
    '1567': 'København V',
    '1568': 'København V',
    '1569': 'København V',
    '1570': 'København V',
    '1571': 'København V',
    '1572': 'København V',
    '1573': 'København V',
    '1574': 'København V',
    '1575': 'København V',
    '1576': 'København V',
    '1577': 'København V',
    '1592': 'København V',
    '1599': 'København V',
    '1600': 'København V',
    '1601': 'København V',
    '1602': 'København V',
    '1603': 'København V',
    '1604': 'København V',
    '1605': 'København V',
    '1606': 'København V',
    '1607': 'København V',
    '1608': 'København V',
    '1609': 'København V',
    '1610': 'København V',
    '1611': 'København V',
    '1612': 'København V',
    '1613': 'København V',
    '1614': 'København V',
    '1615': 'København V',
    '1616': 'København V',
    '1617': 'København V',
    '1618': 'København V',
    '1619': 'København V',
    '1620': 'København V',
    '1621': 'København V',
    '1622': 'København V',
    '1623': 'København V',
    '1624': 'København V',
    '1630': 'København V',
    '1631': 'København V',
    '1632': 'København V',
    '1633': 'København V',
    '1634': 'København V',
    '1635': 'København V',
    '1650': 'København V',
    '1651': 'København V',
    '1652': 'København V',
    '1653': 'København V',
    '1654': 'København V',
    '1655': 'København V',
    '1656': 'København V',
    '1657': 'København V',
    '1658': 'København V',
    '1659': 'København V',
    '1660': 'København V',
    '1661': 'København V',
    '1662': 'København V',
    '1663': 'København V',
    '1664': 'København V',
    '1665': 'København V',
    '1666': 'København V',
    '1667': 'København V',
    '1668': 'København V',
    '1669': 'København V',
    '1670': 'København V',
    '1671': 'København V',
    '1672': 'København V',
    '1673': 'København V',
    '1674': 'København V',
    '1675': 'København V',
    '1676': 'København V',
    '1677': 'København V',
    '1699': 'København V',
    '1700': 'København V',
    '1701': 'København V',
    '1702': 'København V',
    '1703': 'København V',
    '1704': 'København V',
    '1705': 'København V',
    '1706': 'København V',
    '1707': 'København V',
    '1708': 'København V',
    '1709': 'København V',
    '1710': 'København V',
    '1711': 'København V',
    '1712': 'København V',
    '1713': 'København V',
    '1714': 'København V',
    '1715': 'København V',
    '1716': 'København V',
    '1717': 'København V',
    '1718': 'København V',
    '1719': 'København V',
    '1720': 'København V',
    '1721': 'København V',
    '1722': 'København V',
    '1723': 'København V',
    '1724': 'København V',
    '1725': 'København V',
    '1726': 'København V',
    '1727': 'København V',
    '1728': 'København V',
    '1729': 'København V',
    '1730': 'København V',
    '1731': 'København V',
    '1732': 'København V',
    '1733': 'København V',
    '1734': 'København V',
    '1735': 'København V',
    '1736': 'København V',
    '1737': 'København V',
    '1738': 'København V',
    '1739': 'København V',
    '1749': 'København V',
    '1750': 'København V',
    '1751': 'København V',
    '1752': 'København V',
    '1753': 'København V',
    '1754': 'København V',
    '1755': 'København V',
    '1756': 'København V',
    '1757': 'København V',
    '1758': 'København V',
    '1759': 'København V',
    '1760': 'København V',
    '1761': 'København V',
    '1762': 'København V',
    '1763': 'København V',
    '1764': 'København V',
    '1765': 'København V',
    '1766': 'København V',
    '1770': 'København V',
    '1771': 'København V',
    '1772': 'København V',
    '1773': 'København V',
    '1774': 'København V',
    '1775': 'København V',
    '1777': 'København V',
    '1780': 'København V',
    '1782': 'København V',
    '1785': 'København V',
    '1786': 'København V',
    '1787': 'København V',
    '1790': 'København V',
    '1799': 'København V',
    '1800': 'Frederiksberg C',
    '1801': 'Frederiksberg C',
    '1802': 'Frederiksberg C',
    '1803': 'Frederiksberg C',
    '1804': 'Frederiksberg C',
    '1805': 'Frederiksberg C',
    '1806': 'Frederiksberg C',
    '1807': 'Frederiksberg C',
    '1808': 'Frederiksberg C',
    '1809': 'Frederiksberg C',
    '1810': 'Frederiksberg C',
    '1811': 'Frederiksberg C',
    '1812': 'Frederiksberg C',
    '1813': 'Frederiksberg C',
    '1814': 'Frederiksberg C',
    '1815': 'Frederiksberg C',
    '1816': 'Frederiksberg C',
    '1817': 'Frederiksberg C',
    '1818': 'Frederiksberg C',
    '1819': 'Frederiksberg C',
    '1820': 'Frederiksberg C',
    '1822': 'Frederiksberg C',
    '1823': 'Frederiksberg C',
    '1824': 'Frederiksberg C',
    '1825': 'Frederiksberg C',
    '1826': 'Frederiksberg C',
    '1827': 'Frederiksberg C',
    '1828': 'Frederiksberg C',
    '1829': 'Frederiksberg C',
    '1835': 'Frederiksberg C',
    '1850': 'Frederiksberg C',
    '1851': 'Frederiksberg C',
    '1852': 'Frederiksberg C',
    '1853': 'Frederiksberg C',
    '1854': 'Frederiksberg C',
    '1855': 'Frederiksberg C',
    '1856': 'Frederiksberg C',
    '1857': 'Frederiksberg C',
    '1860': 'Frederiksberg C',
    '1861': 'Frederiksberg C',
    '1862': 'Frederiksberg C',
    '1863': 'Frederiksberg C',
    '1864': 'Frederiksberg C',
    '1865': 'Frederiksberg C',
    '1866': 'Frederiksberg C',
    '1867': 'Frederiksberg C',
    '1868': 'Frederiksberg C',
    '1870': 'Frederiksberg C',
    '1871': 'Frederiksberg C',
    '1872': 'Frederiksberg C',
    '1873': 'Frederiksberg C',
    '1874': 'Frederiksberg C',
    '1875': 'Frederiksberg C',
    '1876': 'Frederiksberg C',
    '1877': 'Frederiksberg C',
    '1878': 'Frederiksberg C',
    '1879': 'Frederiksberg C',
    '1900': 'Frederiksberg C',
    '1901': 'Frederiksberg C',
    '1902': 'Frederiksberg C',
    '1903': 'Frederiksberg C',
    '1904': 'Frederiksberg C',
    '1905': 'Frederiksberg C',
    '1906': 'Frederiksberg C',
    '1908': 'Frederiksberg C',
    '1909': 'Frederiksberg C',
    '1910': 'Frederiksberg C',
    '1911': 'Frederiksberg C',
    '1912': 'Frederiksberg C',
    '1913': 'Frederiksberg C',
    '1914': 'Frederiksberg C',
    '1915': 'Frederiksberg C',
    '1916': 'Frederiksberg C',
    '1917': 'Frederiksberg C',
    '1920': 'Frederiksberg C',
    '1921': 'Frederiksberg C',
    '1922': 'Frederiksberg C',
    '1923': 'Frederiksberg C',
    '1924': 'Frederiksberg C',
    '1925': 'Frederiksberg C',
    '1926': 'Frederiksberg C',
    '1927': 'Frederiksberg C',
    '1928': 'Frederiksberg C',
    '1931': 'Frederiksberg C',
    '1950': 'Frederiksberg C',
    '1951': 'Frederiksberg C',
    '1952': 'Frederiksberg C',
    '1953': 'Frederiksberg C',
    '1954': 'Frederiksberg C',
    '1955': 'Frederiksberg C',
    '1956': 'Frederiksberg C',
    '1957': 'Frederiksberg C',
    '1958': 'Frederiksberg C',
    '1959': 'Frederiksberg C',
    '1960': 'Frederiksberg C',
    '1961': 'Frederiksberg C',
    '1962': 'Frederiksberg C',
    '1963': 'Frederiksberg C',
    '1964': 'Frederiksberg C',
    '1965': 'Frederiksberg C',
    '1966': 'Frederiksberg C',
    '1967': 'Frederiksberg C',
    '1970': 'Frederiksberg C',
    '1971': 'Frederiksberg C',
    '1972': 'Frederiksberg C',
    '1973': 'Frederiksberg C',
    '1974': 'Frederiksberg C',
    '2000': 'Frederiksberg',
    '2100': 'København Ø',
    '2150': 'Nordhavn',
    '2200': 'København N',
    '2300': 'København S',
    '2400': 'København NV',
    '2450': 'København SV',
    '2500': 'Valby',
    '2600': 'Glostrup',
    '2605': 'Brøndby',
    '2610': 'Rødovre',
    '2620': 'Albertslund',
    '2625': 'Vallensbæk',
    '2630': 'Taastrup',
    '2635': 'Ishøj',
    '2640': 'Hedehusene',
    '2650': 'Hvidovre',
    '2660': 'Brøndby Strand',
    '2665': 'Vallensbæk Strand',
    '2670': 'Greve',
    '2680': 'Solrød Strand',
    '2690': 'Karlslunde',
    '2700': 'Brønshøj',
    '2720': 'Vanløse',
    '2730': 'Herlev',
    '2740': 'Skovlunde',
    '2750': 'Ballerup',
    '2760': 'Måløv',
    '2765': 'Smørum',
    '2770': 'Kastrup',
    '2791': 'Dragør',
    '2800': 'Kongens Lyngby',
    '2820': 'Gentofte',
    '2830': 'Virum',
    '2840': 'Holte',
    '2850': 'Nærum',
    '2860': 'Søborg',
    '2870': 'Dyssegård',
    '2880': 'Bagsværd',
    '2900': 'Hellerup',
    '2920': 'Charlottenlund',
    '2930': 'Klampenborg',
    '2942': 'Skodsborg',
    '2950': 'Vedbæk',
    '2960': 'Rungsted Kyst',
    '2970': 'Hørsholm',
    '2980': 'Kokkedal',
    '2990': 'Nivå',
    '3000': 'Helsingør',
    '3050': 'Humlebæk',
    '3060': 'Espergærde',
    '3070': 'Snekkersten',
    '3080': 'Tikøb',
    '3100': 'Hornbæk',
    '3120': 'Dronningmølle',
    '3140': 'Ålsgårde',
    '3150': 'Hellebæk',
    '3200': 'Helsinge',
    '3210': 'Vejby',
    '3220': 'Tisvildeleje',
    '3230': 'Græsted',
    '3250': 'Gilleleje',
    '3300': 'Frederiksværk',
    '3310': 'Ølsted',
    '3320': 'Skævinge',
    '3330': 'Gørløse',
    '3360': 'Liseleje',
    '3370': 'Melby',
    '3390': 'Hundested',
    '3400': 'Hillerød',
    '3450': 'Allerød',
    '3460': 'Birkerød',
    '3480': 'Fredensborg',
    '3490': 'Kvistgård',
    '3500': 'Værløse',
    '3520': 'Farum',
    '3540': 'Lynge',
    '3550': 'Slangerup',
    '3600': 'Frederikssund',
    '3630': 'Jægerspris',
    '3650': 'Ølstykke',
    '3660': 'Stenløse',
    '3670': 'Veksø Sjælland',
    '3700': 'Rønne',
    '3720': 'Aakirkeby',
    '3730': 'Nexø',
    '3740': 'Svaneke',
    '3751': 'Østermarie',
    '3760': 'Gudhjem',
    '3770': 'Allinge',
    '3782': 'Klemensker',
    '3790': 'Hasle',
    '4000': 'Roskilde',
    '4030': 'Tune',
    '4040': 'Jyllinge',
    '4050': 'Skibby',
    '4060': 'Kirke Såby',
    '4070': 'Kirke Hyllinge',
    '4100': 'Ringsted',
    '4129': 'Ringsted',
    '4130': 'Viby Sjælland',
    '4140': 'Borup',
    '4160': 'Herlufmagle',
    '4171': 'Glumsø',
    '4173': 'Fjenneslev',
    '4174': 'Jystrup Midtsj',
    '4180': 'Sorø',
    '4190': 'Munke Bjergby',
    '4200': 'Slagelse',
    '4220': 'Korsør',
    '4230': 'Skælskør',
    '4241': 'Vemmelev',
    '4242': 'Boeslunde',
    '4243': 'Rude',
    '4244': 'Agersø',
    '4245': 'Omø',
    '4250': 'Fuglebjerg',
    '4261': 'Dalmose',
    '4262': 'Sandved',
    '4270': 'Høng',
    '4281': 'Gørlev',
    '4291': 'Ruds Vedby',
    '4293': 'Dianalund',
    '4295': 'Stenlille',
    '4296': 'Nyrup',
    '4300': 'Holbæk',
    '4305': 'Orø',
    '4320': 'Lejre',
    '4330': 'Hvalsø',
    '4340': 'Tølløse',
    '4350': 'Ugerløse',
    '4360': 'Kirke Eskilstrup',
    '4370': 'Store Merløse',
    '4390': 'Vipperød',
    '4400': 'Kalundborg',
    '4420': 'Regstrup',
    '4440': 'Mørkøv',
    '4450': 'Jyderup',
    '4460': 'Snertinge',
    '4470': 'Svebølle',
    '4480': 'Store Fuglede',
    '4490': 'Jerslev Sjælland',
    '4500': 'Nykøbing S',
    '4520': 'Svinninge',
    '4532': 'Gislinge',
    '4534': 'Hørve',
    '4540': 'Fårevejle',
    '4550': 'Asnæs',
    '4560': 'Vig',
    '4571': 'Grevinge',
    '4572': 'Nørre Asmindrup',
    '4573': 'Højby',
    '4581': 'Rørvig',
    '4583': 'Sjællands Odde',
    '4591': 'Føllenslev',
    '4592': 'Sejerø',
    '4593': 'Eskebjerg',
    '4600': 'Køge',
    '4621': 'Gadstrup',
    '4622': 'Havdrup',
    '4623': 'Lille Skensved',
    '4632': 'Bjæverskov',
    '4640': 'Fakse',
    '4652': 'Hårlev',
    '4653': 'Karise',
    '4654': 'Fakse Ladeplads',
    '4660': 'Store Heddinge',
    '4671': 'Strøby',
    '4672': 'Klippinge',
    '4673': 'Rødvig Stevns',
    '4681': 'Herfølge',
    '4682': 'Tureby',
    '4683': 'Rønnede',
    '4684': 'Holmegaard',
    '4690': 'Haslev',
    '4700': 'Næstved',
    '4720': 'Præstø',
    '4733': 'Tappernøje',
    '4735': 'Mern',
    '4736': 'Karrebæksminde',
    '4750': 'Lundby',
    '4760': 'Vordingborg',
    '4771': 'Kalvehave',
    '4772': 'Langebæk',
    '4773': 'Stensved',
    '4780': 'Stege',
    '4791': 'Borre',
    '4792': 'Askeby',
    '4793': 'Bogø By',
    '4800': 'Nykøbing F',
    '4840': 'Nørre Alslev',
    '4850': 'Stubbekøbing',
    '4862': 'Guldborg',
    '4863': 'Eskilstrup',
    '4871': 'Horbelev',
    '4872': 'Idestrup',
    '4873': 'Væggerløse',
    '4874': 'Gedser',
    '4880': 'Nysted',
    '4891': 'Toreby L',
    '4892': 'Kettinge',
    '4894': 'Øster Ulslev',
    '4895': 'Errindlev',
    '4900': 'Nakskov',
    '4912': 'Harpelunde',
    '4913': 'Horslunde',
    '4920': 'Søllested',
    '4930': 'Maribo',
    '4941': 'Bandholm',
    '4942': 'Askø og Lilleø',
    '4943': 'Torrig L',
    '4944': 'Fejø',
    '4945': 'Femø',
    '4951': 'Nørreballe',
    '4952': 'Stokkemarke',
    '4953': 'Vesterborg',
    '4960': 'Holeby',
    '4970': 'Rødby',
    '4983': 'Dannemare',
    '4990': 'Sakskøbing',
    '4992': 'Midtsjælland USF P',
    '5000': 'Odense C',
    '5029': 'Odense C',
    '5100': 'Odense C',
    '5200': 'Odense V',
    '5210': 'Odense NV',
    '5220': 'Odense SØ',
    '5230': 'Odense M',
    '5240': 'Odense NØ',
    '5250': 'Odense SV',
    '5260': 'Odense S',
    '5270': 'Odense N',
    '5290': 'Marslev',
    '5300': 'Kerteminde',
    '5320': 'Agedrup',
    '5330': 'Munkebo',
    '5350': 'Rynkeby',
    '5370': 'Mesinge',
    '5380': 'Dalby',
    '5390': 'Martofte',
    '5400': 'Bogense',
    '5450': 'Otterup',
    '5462': 'Morud',
    '5463': 'Harndrup',
    '5464': 'Brenderup Fyn',
    '5466': 'Asperup',
    '5471': 'Søndersø',
    '5474': 'Veflinge',
    '5485': 'Skamby',
    '5491': 'Blommenslyst',
    '5492': 'Vissenbjerg',
    '5500': 'Middelfart',
    '5540': 'Ullerslev',
    '5550': 'Langeskov',
    '5560': 'Aarup',
    '5580': 'Nørre Aaby',
    '5591': 'Gelsted',
    '5592': 'Ejby',
    '5600': 'Faaborg',
    '5602': 'Avernakø',
    '5603': 'Bjørnø',
    '5610': 'Assens',
    '5620': 'Glamsbjerg',
    '5631': 'Ebberup',
    '5642': 'Millinge',
    '5672': 'Broby',
    '5683': 'Haarby',
    '5690': 'Tommerup',
    '5700': 'Svendborg',
    '5750': 'Ringe',
    '5762': 'Vester Skerninge',
    '5771': 'Stenstrup',
    '5772': 'Kværndrup',
    '5792': 'Årslev',
    '5800': 'Nyborg',
    '5853': 'Ørbæk',
    '5854': 'Gislev',
    '5856': 'Ryslinge',
    '5863': 'Ferritslev Fyn',
    '5871': 'Frørup',
    '5874': 'Hesselager',
    '5881': 'Skårup Fyn',
    '5882': 'Vejstrup',
    '5883': 'Oure',
    '5884': 'Gudme',
    '5892': 'Gudbjerg Sydfyn',
    '5900': 'Rudkøbing',
    '5932': 'Humble',
    '5935': 'Bagenkop',
    '5943': 'Strynø',
    '5953': 'Tranekær',
    '5960': 'Marstal',
    '5965': 'Birkholm',
    '5970': 'Ærøskøbing',
    '5985': 'Søby Ærø',
    '6000': 'Kolding',
    '6040': 'Egtved',
    '6051': 'Almind',
    '6052': 'Viuf',
    '6064': 'Jordrup',
    '6070': 'Christiansfeld',
    '6091': 'Bjert',
    '6092': 'Sønder Stenderup',
    '6093': 'Sjølund',
    '6094': 'Hejls',
    '6100': 'Haderslev',
    '6200': 'Aabenraa',
    '6210': 'Barsø',
    '6230': 'Rødekro',
    '6240': 'Løgumkloster',
    '6261': 'Bredebro',
    '6270': 'Tønder',
    '6280': 'Højer',
    '6300': 'Gråsten',
    '6310': 'Broager',
    '6320': 'Egernsund',
    '6330': 'Padborg',
    '6340': 'Kruså',
    '6360': 'Tinglev',
    '6372': 'Bylderup Bov',
    '6392': 'Bolderslev',
    '6400': 'Sønderborg',
    '6430': 'Nordborg',
    '6440': 'Augustenborg',
    '6470': 'Sydals',
    '6500': 'Vojens',
    '6510': 'Gram',
    '6520': 'Toftlund',
    '6534': 'Agerskov',
    '6535': 'Branderup J',
    '6541': 'Bevtoft',
    '6560': 'Sommersted',
    '6580': 'Vamdrup',
    '6600': 'Vejen',
    '6621': 'Gesten',
    '6622': 'Bække',
    '6623': 'Vorbasse',
    '6630': 'Rødding',
    '6640': 'Lunderskov',
    '6650': 'Brørup',
    '6660': 'Lintrup',
    '6670': 'Holsted',
    '6682': 'Hovborg',
    '6683': 'Føvling',
    '6690': 'Gørding',
    '6700': 'Esbjerg',
    '6701': 'Esbjerg',
    '6705': 'Esbjerg Ø',
    '6710': 'Esbjerg V',
    '6715': 'Esbjerg N',
    '6720': 'Fanø',
    '6731': 'Tjæreborg',
    '6740': 'Bramming',
    '6752': 'Glejbjerg',
    '6753': 'Agerbæk',
    '6760': 'Ribe',
    '6771': 'Gredstedbro',
    '6780': 'Skærbæk',
    '6792': 'Rømø',
    '6800': 'Varde',
    '6818': 'Årre',
    '6823': 'Ansager',
    '6830': 'Nørre Nebel',
    '6840': 'Oksbøl',
    '6851': 'Janderup Vestj',
    '6852': 'Billum',
    '6853': 'Vejers Strand',
    '6854': 'Henne',
    '6855': 'Outrup',
    '6857': 'Blåvand',
    '6862': 'Tistrup',
    '6870': 'Ølgod',
    '6880': 'Tarm',
    '6893': 'Hemmet',
    '6900': 'Skjern',
    '6920': 'Videbæk',
    '6933': 'Kibæk',
    '6940': 'Lem St',
    '6950': 'Ringkøbing',
    '6960': 'Hvide Sande',
    '6971': 'Spjald',
    '6973': 'Ørnhøj',
    '6980': 'Tim',
    '6990': 'Ulfborg',
    '7000': 'Fredericia',
    '7007': 'Fredericia',
    '7017': 'Taulov Pakkecenter',
    '7018': 'Pakker TLP',
    '7029': 'Fredericia',
    '7080': 'Børkop',
    '7100': 'Vejle',
    '7120': 'Vejle Øst',
    '7130': 'Juelsminde',
    '7140': 'Stouby',
    '7150': 'Barrit',
    '7160': 'Tørring',
    '7171': 'Uldum',
    '7173': 'Vonge',
    '7182': 'Bredsten',
    '7183': 'Randbøl',
    '7184': 'Vandel',
    '7190': 'Billund',
    '7200': 'Grindsted',
    '7250': 'Hejnsvig',
    '7260': 'Sønder Omme',
    '7270': 'Stakroge',
    '7280': 'Sønder Felding',
    '7300': 'Jelling',
    '7321': 'Gadbjerg',
    '7323': 'Give',
    '7330': 'Brande',
    '7361': 'Ejstrupholm',
    '7362': 'Hampen',
    '7400': 'Herning',
    '7429': 'Herning',
    '7430': 'Ikast',
    '7441': 'Bording',
    '7442': 'Engesvang',
    '7451': 'Sunds',
    '7470': 'Karup J',
    '7480': 'Vildbjerg',
    '7490': 'Aulum',
    '7500': 'Holstebro',
    '7540': 'Haderup',
    '7550': 'Sørvad',
    '7560': 'Hjerm',
    '7570': 'Vemb',
    '7600': 'Struer',
    '7620': 'Lemvig',
    '7650': 'Bøvlingbjerg',
    '7660': 'Bækmarksbro',
    '7673': 'Harboøre',
    '7680': 'Thyborøn',
    '7700': 'Thisted',
    '7730': 'Hanstholm',
    '7741': 'Frøstrup',
    '7742': 'Vesløs',
    '7752': 'Snedsted',
    '7755': 'Bedsted Thy',
    '7760': 'Hurup Thy',
    '7770': 'Vestervig',
    '7790': 'Thyholm',
    '7800': 'Skive',
    '7830': 'Vinderup',
    '7840': 'Højslev',
    '7850': 'Stoholm Jyll',
    '7860': 'Spøttrup',
    '7870': 'Roslev',
    '7884': 'Fur',
    '7900': 'Nykøbing M',
    '7950': 'Erslev',
    '7960': 'Karby',
    '7970': 'Redsted M',
    '7980': 'Vils',
    '7990': 'Øster Assels',
    '7992': 'Sydjylland/Fyn USF P',
    '7993': 'Sydjylland/Fyn USF B',
    '7996': 'Fakturaservice',
    '7997': 'Fakturascanning',
    '7998': 'Statsservice',
    '7999': 'Kommunepost',
    '8000': 'Aarhus C',
    '8100': 'Aarhus C',
    '8200': 'Aarhus N',
    '8210': 'Aarhus V',
    '8220': 'Brabrand',
    '8229': 'Risskov Ø',
    '8230': 'Åbyhøj',
    '8240': 'Risskov',
    '8245': 'Risskov Ø',
    '8250': 'Egå',
    '8260': 'Viby J',
    '8270': 'Højbjerg',
    '8300': 'Odder',
    '8305': 'Samsø',
    '8310': 'Tranbjerg J',
    '8320': 'Mårslet',
    '8330': 'Beder',
    '8340': 'Malling',
    '8350': 'Hundslund',
    '8355': 'Solbjerg',
    '8361': 'Hasselager',
    '8362': 'Hørning',
    '8370': 'Hadsten',
    '8380': 'Trige',
    '8381': 'Tilst',
    '8382': 'Hinnerup',
    '8400': 'Ebeltoft',
    '8410': 'Rønde',
    '8420': 'Knebel',
    '8444': 'Balle',
    '8450': 'Hammel',
    '8462': 'Harlev J',
    '8464': 'Galten',
    '8471': 'Sabro',
    '8472': 'Sporup',
    '8500': 'Grenaa',
    '8520': 'Lystrup',
    '8530': 'Hjortshøj',
    '8541': 'Skødstrup',
    '8543': 'Hornslet',
    '8544': 'Mørke',
    '8550': 'Ryomgård',
    '8560': 'Kolind',
    '8570': 'Trustrup',
    '8581': 'Nimtofte',
    '8585': 'Glesborg',
    '8586': 'Ørum Djurs',
    '8592': 'Anholt',
    '8600': 'Silkeborg',
    '8620': 'Kjellerup',
    '8632': 'Lemming',
    '8641': 'Sorring',
    '8643': 'Ans By',
    '8653': 'Them',
    '8654': 'Bryrup',
    '8660': 'Skanderborg',
    '8670': 'Låsby',
    '8680': 'Ry',
    '8700': 'Horsens',
    '8721': 'Daugård',
    '8722': 'Hedensted',
    '8723': 'Løsning',
    '8732': 'Hovedgård',
    '8740': 'Brædstrup',
    '8751': 'Gedved',
    '8752': 'Østbirk',
    '8762': 'Flemming',
    '8763': 'Rask Mølle',
    '8765': 'Klovborg',
    '8766': 'Nørre Snede',
    '8781': 'Stenderup',
    '8783': 'Hornsyld',
    '8789': 'Endelave',
    '8799': 'Tunø',
    '8800': 'Viborg',
    '8830': 'Tjele',
    '8831': 'Løgstrup',
    '8832': 'Skals',
    '8840': 'Rødkærsbro',
    '8850': 'Bjerringbro',
    '8860': 'Ulstrup',
    '8870': 'Langå',
    '8881': 'Thorsø',
    '8882': 'Fårvang',
    '8883': 'Gjern',
    '8900': 'Randers C',
    '8920': 'Randers NV',
    '8930': 'Randers NØ',
    '8940': 'Randers SV',
    '8950': 'Ørsted',
    '8960': 'Randers SØ',
    '8961': 'Allingåbro',
    '8963': 'Auning',
    '8970': 'Havndal',
    '8981': 'Spentrup',
    '8983': 'Gjerlev J',
    '8990': 'Fårup',
    '9000': 'Aalborg',
    '9029': 'Aalborg',
    '9100': 'Aalborg',
    '9200': 'Aalborg SV',
    '9210': 'Aalborg SØ',
    '9220': 'Aalborg Øst',
    '9230': 'Svenstrup J',
    '9240': 'Nibe',
    '9260': 'Gistrup',
    '9270': 'Klarup',
    '9280': 'Storvorde',
    '9293': 'Kongerslev',
    '9300': 'Sæby',
    '9310': 'Vodskov',
    '9320': 'Hjallerup',
    '9330': 'Dronninglund',
    '9340': 'Asaa',
    '9352': 'Dybvad',
    '9362': 'Gandrup',
    '9370': 'Hals',
    '9380': 'Vestbjerg',
    '9381': 'Sulsted',
    '9382': 'Tylstrup',
    '9400': 'Nørresundby',
    '9430': 'Vadum',
    '9440': 'Aabybro',
    '9460': 'Brovst',
    '9480': 'Løkken',
    '9490': 'Pandrup',
    '9492': 'Blokhus',
    '9493': 'Saltum',
    '9500': 'Hobro',
    '9510': 'Arden',
    '9520': 'Skørping',
    '9530': 'Støvring',
    '9541': 'Suldrup',
    '9550': 'Mariager',
    '9560': 'Hadsund',
    '9574': 'Bælum',
    '9575': 'Terndrup',
    '9600': 'Aars',
    '9610': 'Nørager',
    '9620': 'Aalestrup',
    '9631': 'Gedsted',
    '9632': 'Møldrup',
    '9640': 'Farsø',
    '9670': 'Løgstør',
    '9681': 'Ranum',
    '9690': 'Fjerritslev',
    '9700': 'Brønderslev',
    '9740': 'Jerslev J',
    '9750': 'Øster Vrå',
    '9760': 'Vrå',
    '9800': 'Hjørring',
    '9830': 'Tårs',
    '9850': 'Hirtshals',
    '9870': 'Sindal',
    '9881': 'Bindslev',
    '9900': 'Frederikshavn',
    '9940': 'Læsø',
    '9970': 'Strandby',
    '9981': 'Jerup',
    '9982': 'Ålbæk',
    '9990': 'Skagen',
    '9992': 'Jylland USF P',
    '9993': 'Jylland USF B',
    '9996': 'Fakturaservice',
    '9997': 'Fakturascanning',
    '9998': 'Borgerservice',
    '2412': 'Santa Claus/Julemanden',
    '3900': 'Nuuk',
    '3905': 'Nuussuaq',
    '3910': 'Kangerlussuaq',
    '3911': 'Sisimiut',
    '3912': 'Maniitsoq',
    '3913': 'Tasiilaq',
    '3915': 'Kulusuk',
    '3919': 'Alluitsup Paa',
    '3920': 'Qaqortoq',
    '3921': 'Narsaq',
    '3922': 'Nanortalik',
    '3923': 'Narsarsuaq',
    '3924': 'Ikerasassuaq',
    '3930': 'Kangilinnguit',
    '3932': 'Arsuk',
    '3940': 'Paamiut',
    '3950': 'Aasiaat',
    '3951': 'Qasigiannguit',
    '3952': 'Ilulissat',
    '3953': 'Qeqertarsuaq',
    '3955': 'Kangaatsiaq',
    '3961': 'Uummannaq',
    '3962': 'Upernavik',
    '3964': 'Qaarsut',
    '3970': 'Pituffik',
    '3971': 'Qaanaaq',
    '3972': 'Staon Nord',
    '3980': 'Ioqqortoormiit',
    '3982': 'Mestersvig',
    '3984': 'Danmarkshavn',
    '3985': 'Constable Pynt',
    '3992': 'Slædepatruljen Sirius',
    '100': 'Tórshavn',
    '110': 'Tórshavn',
    '160': 'Argir',
    '165': 'Argir',
    '175': 'Kirkjubøur',
    '176': 'Velbastadur',
    '177': 'Sydradalur',
    '178': 'Nordradalur',
    '180': 'Kaldbak',
    '185': 'Kaldbaksbotnur',
    '186': 'Sund',
    '187': 'Hvitanes',
    '188': 'Hoyvík',
    '210': 'Sandur',
    '215': 'Sandur',
    '220': 'Skálavík',
    '230': 'Húsavík',
    '235': 'Dalur',
    '236': 'Skarvanes',
    '240': 'Skopun',
    '260': 'Skúvoy',
    '270': 'Nólsoy',
    '280': 'Hestur',
    '285': 'Koltur',
    '286': 'Stóra Dimun',
    '330': 'Stykkid',
    '335': 'Leynar',
    '336': 'Skællingur',
    '340': 'Kvívík',
    '350': 'Vestmanna',
    '355': 'Vestmanna',
    '358': 'Válur',
    '360': 'Sandavágur',
    '370': 'Midvágur',
    '375': 'Midvágur',
    '380': 'Sørvágur',
    '385': 'Vatnsoyrar',
    '386': 'Bøur',
    '387': 'Gásadalur',
    '388': 'Mykines',
    '400': 'Oyrarbakki',
    '405': 'Oyrarbakki',
    '410': 'Kollafjørdur',
    '415': 'Oyrareingir',
    '416': 'Signabøur',
    '420': 'Hósvík',
    '430': 'Hvalvík',
    '435': 'Streymnes',
    '436': 'Saksun',
    '437': 'Nesvík',
    '438': 'Langasandur',
    '440': 'Haldarsvík',
    '445': 'Tjørnuvík',
    '450': 'Oyri',
    '460': 'Nordskáli',
    '465': 'Svináir',
    '466': 'Ljósá',
    '470': 'Eidi',
    '475': 'Funningur',
    '476': 'Gjógv',
    '477': 'Funningsfjørdur',
    '478': 'Elduvík',
    '480': 'Skáli',
    '485': 'Skálafjørdur',
    '490': 'Strendur',
    '494': 'Innan Glyvur',
    '495': 'Kolbanargjógv',
    '496': 'Morskranes',
    '497': 'Selatrad',
    '510': 'Gøta',
    '511': 'Gøtugjógv',
    '512': 'Nordragøta',
    '513': 'Sydrugøta',
    '515': 'Gøta',
    '520': 'Leirvík',
    '530': 'Fuglafjørdur',
    '535': 'Fuglafjørdur',
    '600': 'Saltangará',
    '610': 'Saltangará',
    '620': 'Runavík',
    '625': 'Glyvrar',
    '626': 'Lambareidi',
    '627': 'Lambi',
    '640': 'Rituvík',
    '645': 'Æduvík',
    '650': 'Toftir',
    '655': 'Nes',
    '656': 'Saltnes',
    '660': 'Søldarfjørdur',
    '665': 'Skipanes',
    '666': 'Gøtueidi',
    '690': 'Oyndarfjørdur',
    '695': 'Hellur',
    '700': 'Klaksvík',
    '710': 'Klaksvík',
    '725': 'Nordoyri',
    '726': 'Ánir',
    '727': 'Árnafjørdur',
    '730': 'Norddepil',
    '735': 'Depil',
    '736': 'Nordtoftir',
    '737': 'Múli',
    '740': 'Hvannasund',
    '750': 'Vidareidi',
    '765': 'Svinoy',
    '766': 'Kirkja',
    '767': 'Hattarvík',
    '780': 'Kunoy',
    '785': 'Haraldssund',
    '795': 'Sydradalur',
    '796': 'Húsar',
    '797': 'Mikladalur',
    '798': 'Trøllanes',
    '800': 'Tvøroyri',
    '810': 'Tvøroyri',
    '825': 'Frodba',
    '826': 'Trongisvágur',
    '827': 'Øravík',
    '850': 'Hvalba',
    '860': 'Sandvík',
    '870': 'Fámjin',
    '900': 'Vágur',
    '910': 'Vágur',
    '925': 'Nes',
    '926': 'Lopra',
    '927': 'Akrar',
    '928': 'Vikarbyrgi',
    '950': 'Porkeri',
    '960': 'Hov',
    '970': 'Sumba',
  },
  se: {
    '186 00': 'Vallentuna',
    '186 01': 'Vallentuna',
    '186 03': 'Brottby',
    '186 21': 'Vallentuna',
    '186 22': 'Vallentuna',
    '186 23': 'Vallentuna',
    '186 24': 'Vallentuna',
    '186 25': 'Vallentuna',
    '186 26': 'Vallentuna',
    '186 30': 'Vallentuna',
    '186 31': 'Vallentuna',
    '186 32': 'Vallentuna',
    '186 33': 'Vallentuna',
    '186 34': 'Vallentuna',
    '186 35': 'Vallentuna',
    '186 36': 'Vallentuna',
    '186 37': 'Vallentuna',
    '186 38': 'Vallentuna',
    '186 39': 'Vallentuna',
    '186 40': 'Vallentuna',
    '186 41': 'Vallentuna',
    '186 42': 'Vallentuna',
    '186 43': 'Vallentuna',
    '186 44': 'Vallentuna',
    '186 45': 'Vallentuna',
    '186 46': 'Vallentuna',
    '186 49': 'Vallentuna',
    '186 50': 'Vallentuna',
    '186 51': 'Vallentuna',
    '186 52': 'Vallentuna',
    '186 53': 'Vallentuna',
    '186 54': 'Vallentuna',
    '186 55': 'Vallentuna',
    '186 60': 'Vallentuna',
    '186 70': 'Brottby',
    '186 86': 'Vallentuna',
    '186 91': 'Vallentuna',
    '186 92': 'Vallentuna',
    '186 93': 'Vallentuna',
    '186 94': 'Vallentuna',
    '186 95': 'Vallentuna',
    '186 96': 'Vallentuna',
    '186 97': 'Brottby',
    '130 25': 'Ingmarsö',
    '184 00': 'Åkersberga',
    '184 03': 'Ljusterö',
    '184 10': 'Åkersberga',
    '184 21': 'Åkersberga',
    '184 22': 'Åkersberga',
    '184 23': 'Åkersberga',
    '184 24': 'Åkersberga',
    '184 25': 'Åkersberga',
    '184 26': 'Åkersberga',
    '184 27': 'Åkersberga',
    '184 28': 'Åkersberga',
    '184 30': 'Åkersberga',
    '184 31': 'Åkersberga',
    '184 32': 'Åkersberga',
    '184 33': 'Åkersberga',
    '184 34': 'Åkersberga',
    '184 35': 'Åkersberga',
    '184 36': 'Åkersberga',
    '184 37': 'Åkersberga',
    '184 38': 'Åkersberga',
    '184 40': 'Åkersberga',
    '184 41': 'Åkersberga',
    '184 42': 'Åkersberga',
    '184 43': 'Åkersberga',
    '184 44': 'Åkersberga',
    '184 50': 'Åkersberga',
    '184 51': 'Österskär',
    '184 52': 'Österskär',
    '184 60': 'Åkersberga',
    '184 61': 'Åkersberga',
    '184 62': 'Åkersberga',
    '184 63': 'Åkersberga',
    '184 70': 'Åkersberga',
    '184 80': 'Åkersberga',
    '184 85': 'Åkersberga',
    '184 86': 'Åkersberga',
    '184 91': 'Åkersberga',
    '184 92': 'Åkersberga',
    '184 94': 'Åkersberga',
    '184 95': 'Ljusterö',
    '184 97': 'Ljusterö',
    '130 33': 'Gällnöby',
    '130 34': 'Skälvik',
    '130 36': 'Nämdö',
    '130 37': 'Stavsnäs',
    '130 38': 'Runmarö',
    '130 39': 'Sandhamn',
    '130 40': 'Djurhamn',
    '130 42': 'Stavsudda',
    '130 43': 'Möja',
    '134 00': 'Gustavsberg',
    '134 01': 'Gustavsberg',
    '134 06': 'Ingarö',
    '134 07': 'Ingarö',
    '134 08': 'Ingarö',
    '134 21': 'Gustavsberg',
    '134 22': 'Gustavsberg',
    '134 23': 'Gustavsberg',
    '134 24': 'Gustavsberg',
    '134 25': 'Gustavsberg',
    '134 26': 'Gustavsberg',
    '134 27': 'Gustavsberg',
    '134 28': 'Gustavsberg',
    '134 29': 'Gustavsberg',
    '134 30': 'Gustavsberg',
    '134 31': 'Gustavsberg',
    '134 33': 'Gustavsberg',
    '134 34': 'Gustavsberg',
    '134 35': 'Gustavsberg',
    '134 36': 'Gustavsberg',
    '134 37': 'Gustavsberg',
    '134 38': 'Gustavsberg',
    '134 40': 'Gustavsberg',
    '134 41': 'Gustavsberg',
    '134 42': 'Gustavsberg',
    '134 43': 'Gustavsberg',
    '134 44': 'Gustavsberg',
    '134 60': 'Ingarö',
    '134 61': 'Ingarö',
    '134 62': 'Ingarö',
    '134 63': 'Ingarö',
    '134 64': 'Ingarö',
    '134 65': 'Ingarö',
    '134 66': 'Ingarö',
    '134 67': 'Ingarö',
    '134 69': 'Ingarö',
    '134 81': 'Gustavsberg',
    '134 82': 'Gustavsberg',
    '139 01': 'Värmdö',
    '139 21': 'Värmdö',
    '139 22': 'Värmdö',
    '139 23': 'Värmdö',
    '139 24': 'Värmdö',
    '139 30': 'Värmdö',
    '139 32': 'Värmdö',
    '139 33': 'Värmdö',
    '139 34': 'Värmdö',
    '139 35': 'Värmdö',
    '139 36': 'Värmdö',
    '139 40': 'Värmdö',
    '139 41': 'Värmdö',
    '139 50': 'Värmdö',
    '139 51': 'Värmdö',
    '139 52': 'Värmdö',
    '139 53': 'Värmdö',
    '139 54': 'Värmdö',
    '139 55': 'Värmdö',
    '139 56': 'Värmdö',
    '139 60': 'Värmdö',
    '139 90': 'Värmdö',
    '175 00': 'Järfälla',
    '175 04': 'Järfälla',
    '175 06': 'Järfälla',
    '175 08': 'Järfälla',
    '175 26': 'Järfälla',
    '175 27': 'Järfälla',
    '175 29': 'Järfälla',
    '175 41': 'Järfälla',
    '175 43': 'Järfälla',
    '175 44': 'Järfälla',
    '175 45': 'Järfälla',
    '175 46': 'Järfälla',
    '175 47': 'Järfälla',
    '175 48': 'Järfälla',
    '175 49': 'Järfälla',
    '175 50': 'Järfälla',
    '175 51': 'Järfälla',
    '175 52': 'Järfälla',
    '175 53': 'Järfälla',
    '175 54': 'Järfälla',
    '175 55': 'Järfälla',
    '175 56': 'Järfälla',
    '175 57': 'Järfälla',
    '175 60': 'Järfälla',
    '175 61': 'Järfälla',
    '175 62': 'Järfälla',
    '175 63': 'Järfälla',
    '175 64': 'Järfälla',
    '175 65': 'Järfälla',
    '175 66': 'Järfälla',
    '175 67': 'Järfälla',
    '175 68': 'Järfälla',
    '175 69': 'Järfälla',
    '175 79': 'Järfälla',
    '175 80': 'Järfälla',
    '175 84': 'Järfälla',
    '175 88': 'Järfälla',
    '175 89': 'Järfälla',
    '176 00': 'Järfälla',
    '176 02': 'Järfälla',
    '176 27': 'Järfälla',
    '176 68': 'Järfälla',
    '176 69': 'Järfälla',
    '176 70': 'Järfälla',
    '176 71': 'Järfälla',
    '176 72': 'Järfälla',
    '176 73': 'Järfälla',
    '176 74': 'Järfälla',
    '176 75': 'Järfälla',
    '176 76': 'Järfälla',
    '176 77': 'Järfälla',
    '176 80': 'Järfälla',
    '177 10': 'Järfälla',
    '177 21': 'Järfälla',
    '177 22': 'Järfälla',
    '177 23': 'Järfälla',
    '177 24': 'Järfälla',
    '177 25': 'Järfälla',
    '177 28': 'Järfälla',
    '177 30': 'Järfälla',
    '177 31': 'Järfälla',
    '177 32': 'Järfälla',
    '177 33': 'Järfälla',
    '177 34': 'Järfälla',
    '177 35': 'Järfälla',
    '177 36': 'Järfälla',
    '177 37': 'Järfälla',
    '177 38': 'Järfälla',
    '177 39': 'Järfälla',
    '177 40': 'Järfälla',
    '177 41': 'Järfälla',
    '177 42': 'Järfälla',
    '177 50': 'Järfälla',
    '177 51': 'Järfälla',
    '177 52': 'Järfälla',
    '177 53': 'Järfälla',
    '177 54': 'Järfälla',
    '177 55': 'Järfälla',
    '177 56': 'Järfälla',
    '177 57': 'Järfälla',
    '177 58': 'Järfälla',
    '177 59': 'Järfälla',
    '177 60': 'Järfälla',
    '177 61': 'Järfälla',
    '177 62': 'Järfälla',
    '177 63': 'Järfälla',
    '177 64': 'Järfälla',
    '177 70': 'Järfälla',
    '177 71': 'Järfälla',
    '177 80': 'Järfälla',
    '177 81': 'Järfälla',
    '178 00': 'Ekerö',
    '178 02': 'Drottningholm',
    '178 21': 'Ekerö',
    '178 22': 'Ekerö',
    '178 23': 'Ekerö',
    '178 24': 'Ekerö',
    '178 30': 'Ekerö',
    '178 31': 'Ekerö',
    '178 32': 'Ekerö',
    '178 33': 'Ekerö',
    '178 34': 'Ekerö',
    '178 35': 'Ekerö',
    '178 36': 'Ekerö',
    '178 37': 'Ekerö',
    '178 38': 'Ekerö',
    '178 39': 'Ekerö',
    '178 40': 'Ekerö',
    '178 51': 'Ekerö',
    '178 52': 'Ekerö',
    '178 53': 'Ekerö',
    '178 54': 'Ekerö',
    '178 90': 'Ekerö',
    '178 91': 'Munsö',
    '178 92': 'Adelsö',
    '178 93': 'Drottningholm',
    '179 03': 'Stenhamra',
    '179 04': 'Färentuna',
    '179 05': 'Svartsjö',
    '179 60': 'Stenhamra',
    '179 61': 'Stenhamra',
    '179 62': 'Stenhamra',
    '179 63': 'Stenhamra',
    '179 65': 'Stenhamra',
    '179 75': 'Skå',
    '179 95': 'Svartsjö',
    '179 96': 'Svartsjö',
    '179 97': 'Färentuna',
    '179 98': 'Färentuna',
    '127 55': 'Skärholmen',
    '127 84': 'Skärholmen',
    '141 00': 'Huddinge',
    '141 01': 'Huddinge',
    '141 02': 'Huddinge',
    '141 04': 'Huddinge',
    '141 05': 'Huddinge',
    '141 06': 'Kungens Kurva',
    '141 07': 'Huddinge',
    '141 09': 'Huddinge',
    '141 12': 'Huddinge',
    '141 18': 'Huddinge',
    '141 21': 'Huddinge',
    '141 22': 'Huddinge',
    '141 23': 'Huddinge',
    '141 24': 'Huddinge',
    '141 25': 'Huddinge',
    '141 26': 'Huddinge',
    '141 27': 'Huddinge',
    '141 28': 'Huddinge',
    '141 30': 'Huddinge',
    '141 31': 'Huddinge',
    '141 32': 'Huddinge',
    '141 33': 'Huddinge',
    '141 34': 'Huddinge',
    '141 35': 'Huddinge',
    '141 37': 'Huddinge',
    '141 38': 'Huddinge',
    '141 39': 'Huddinge',
    '141 40': 'Huddinge',
    '141 41': 'Huddinge',
    '141 42': 'Huddinge',
    '141 43': 'Huddinge',
    '141 44': 'Huddinge',
    '141 45': 'Huddinge',
    '141 46': 'Huddinge',
    '141 47': 'Huddinge',
    '141 48': 'Huddinge',
    '141 49': 'Huddinge',
    '141 50': 'Huddinge',
    '141 51': 'Huddinge',
    '141 52': 'Huddinge',
    '141 53': 'Huddinge',
    '141 54': 'Huddinge',
    '141 55': 'Huddinge',
    '141 56': 'Huddinge',
    '141 57': 'Huddinge',
    '141 59': 'Huddinge',
    '141 60': 'Huddinge',
    '141 61': 'Huddinge',
    '141 62': 'Huddinge',
    '141 70': 'Segeltorp',
    '141 71': 'Segeltorp',
    '141 72': 'Segeltorp',
    '141 73': 'Segeltorp',
    '141 75': 'Kungens Kurva',
    '141 81': 'Huddinge',
    '141 83': 'Huddinge',
    '141 84': 'Huddinge',
    '141 85': 'Huddinge',
    '141 87': 'Huddinge',
    '141 88': 'Huddinge',
    '141 89': 'Huddinge',
    '141 91': 'Huddinge',
    '142 00': 'Skogås',
    '142 02': 'Trångsund',
    '142 21': 'Skogås',
    '142 22': 'Skogås',
    '142 25': 'Skogås',
    '142 29': 'Trångsund',
    '142 30': 'Skogås',
    '142 31': 'Skogås',
    '142 32': 'Skogås',
    '142 33': 'Skogås',
    '142 34': 'Skogås',
    '142 35': 'Skogås',
    '142 40': 'Skogås',
    '142 41': 'Skogås',
    '142 42': 'Skogås',
    '142 43': 'Skogås',
    '142 50': 'Skogås',
    '142 51': 'Skogås',
    '142 52': 'Skogås',
    '142 53': 'Skogås',
    '142 60': 'Trångsund',
    '142 61': 'Trångsund',
    '142 62': 'Trångsund',
    '142 63': 'Trångsund',
    '142 64': 'Trångsund',
    '142 65': 'Trångsund',
    '142 66': 'Trångsund',
    '142 81': 'Skogås',
    '142 82': 'Trångsund',
    '142 91': 'Skogås',
    '143 00': 'Vårby',
    '143 01': 'Vårby',
    '143 03': 'Vårby',
    '143 30': 'Vårby',
    '143 31': 'Vårby',
    '143 32': 'Vårby',
    '143 35': 'Vårby',
    '143 40': 'Vårby',
    '143 41': 'Vårby',
    '143 42': 'Vårby',
    '143 43': 'Vårby',
    '143 44': 'Vårby',
    '143 45': 'Vårby',
    '143 47': 'Vårby',
    '145 00': 'Norsborg',
    '145 01': 'Norsborg',
    '145 02': 'Norsborg',
    '145 03': 'Norsborg',
    '145 04': 'Norsborg',
    '145 50': 'Norsborg',
    '145 51': 'Norsborg',
    '145 52': 'Norsborg',
    '145 53': 'Norsborg',
    '145 56': 'Norsborg',
    '145 57': 'Norsborg',
    '145 58': 'Norsborg',
    '145 59': 'Norsborg',
    '145 60': 'Norsborg',
    '145 63': 'Norsborg',
    '145 64': 'Norsborg',
    '145 65': 'Norsborg',
    '145 67': 'Norsborg',
    '145 68': 'Norsborg',
    '145 69': 'Norsborg',
    '145 70': 'Norsborg',
    '145 71': 'Norsborg',
    '145 72': 'Norsborg',
    '145 73': 'Norsborg',
    '145 74': 'Norsborg',
    '145 81': 'Norsborg',
    '145 83': 'Norsborg',
    '145 88': 'Norsborg',
    '145 90': 'Norsborg',
    '146 00': 'Tullinge',
    '146 21': 'Tullinge',
    '146 22': 'Tullinge',
    '146 30': 'Tullinge',
    '146 31': 'Tullinge',
    '146 32': 'Tullinge',
    '146 33': 'Tullinge',
    '146 36': 'Tullinge',
    '146 37': 'Tullinge',
    '146 38': 'Tullinge',
    '146 40': 'Tullinge',
    '146 41': 'Tullinge',
    '146 45': 'Tullinge',
    '146 46': 'Tullinge',
    '146 48': 'Tullinge',
    '146 49': 'Tullinge',
    '146 50': 'Tullinge',
    '146 52': 'Tullinge',
    '146 53': 'Tullinge',
    '146 54': 'Tullinge',
    '146 80': 'Tullinge',
    '147 00': 'Tumba',
    '147 01': 'Tumba',
    '147 07': 'Grödinge',
    '147 21': 'Tumba',
    '147 22': 'Tumba',
    '147 23': 'Tumba',
    '147 25': 'Tumba',
    '147 30': 'Tumba',
    '147 31': 'Tumba',
    '147 32': 'Tumba',
    '147 33': 'Tumba',
    '147 34': 'Tumba',
    '147 39': 'Tumba',
    '147 40': 'Tumba',
    '147 41': 'Tumba',
    '147 42': 'Tumba',
    '147 43': 'Tumba',
    '147 44': 'Tumba',
    '147 50': 'Tumba',
    '147 51': 'Tumba',
    '147 52': 'Tumba',
    '147 53': 'Tumba',
    '147 54': 'Tumba',
    '147 55': 'Tumba',
    '147 60': 'Uttran',
    '147 61': 'Uttran',
    '147 62': 'Uttran',
    '147 63': 'Uttran',
    '147 70': 'Grödinge',
    '147 71': 'Grödinge',
    '147 80': 'Tumba',
    '147 82': 'Tumba',
    '147 85': 'Tumba',
    '147 92': 'Grödinge',
    '144 00': 'Rönninge',
    '144 02': 'Rönninge',
    '144 21': 'Rönninge',
    '144 24': 'Rönninge',
    '144 30': 'Rönninge',
    '144 31': 'Rönninge',
    '144 32': 'Rönninge',
    '144 33': 'Rönninge',
    '144 37': 'Rönninge',
    '144 40': 'Rönninge',
    '144 42': 'Rönninge',
    '144 44': 'Rönninge',
    '144 51': 'Rönninge',
    '144 61': 'Rönninge',
    '144 62': 'Rönninge',
    '144 63': 'Rönninge',
    '144 64': 'Rönninge',
    '144 80': 'Rönninge',
    '130 54': 'Dalarö',
    '130 55': 'Ornö',
    '130 56': 'Utö',
    '130 61': 'Hårsfjärden',
    '136 00': 'Haninge',
    '136 02': 'Haninge',
    '136 03': 'Haninge',
    '136 11': 'Haninge',
    '136 18': 'Haninge',
    '136 21': 'Haninge',
    '136 22': 'Haninge',
    '136 23': 'Haninge',
    '136 25': 'Haninge',
    '136 26': 'Haninge',
    '136 27': 'Haninge',
    '136 39': 'Haninge',
    '136 40': 'Haninge',
    '136 41': 'Haninge',
    '136 42': 'Haninge',
    '136 43': 'Haninge',
    '136 44': 'Haninge',
    '136 45': 'Haninge',
    '136 46': 'Haninge',
    '136 47': 'Haninge',
    '136 48': 'Haninge',
    '136 49': 'Haninge',
    '136 50': 'Haninge',
    '136 51': 'Haninge',
    '136 52': 'Haninge',
    '136 53': 'Haninge',
    '136 54': 'Haninge',
    '136 59': 'Haninge',
    '136 60': 'Haninge',
    '136 61': 'Haninge',
    '136 62': 'Haninge',
    '136 63': 'Haninge',
    '136 64': 'Haninge',
    '136 65': 'Haninge',
    '136 66': 'Haninge',
    '136 67': 'Haninge',
    '136 68': 'Haninge',
    '136 69': 'Haninge',
    '136 70': 'Haninge',
    '136 71': 'Haninge',
    '136 72': 'Haninge',
    '136 73': 'Haninge',
    '136 75': 'Haninge',
    '136 80': 'Haninge',
    '136 81': 'Haninge',
    '136 82': 'Haninge',
    '136 83': 'Haninge',
    '136 87': 'Haninge',
    '136 89': 'Haninge',
    '136 91': 'Haninge',
    '137 00': 'Västerhaninge',
    '137 02': 'Tungelsta',
    '137 21': 'Västerhaninge',
    '137 22': 'Västerhaninge',
    '137 23': 'Västerhaninge',
    '137 24': 'Västerhaninge',
    '137 31': 'Västerhaninge',
    '137 32': 'Västerhaninge',
    '137 34': 'Västerhaninge',
    '137 36': 'Västerhaninge',
    '137 37': 'Västerhaninge',
    '137 38': 'Västerhaninge',
    '137 40': 'Västerhaninge',
    '137 41': 'Västerhaninge',
    '137 54': 'Tungelsta',
    '137 55': 'Tungelsta',
    '137 56': 'Tungelsta',
    '137 57': 'Tungelsta',
    '137 91': 'Västerhaninge',
    '137 92': 'Tungelsta',
    '137 93': 'Tungelsta',
    '148 25': 'Muskö',
    '148 80': 'Muskö',
    '148 95': 'Muskö',
    '135 00': 'Tyresö',
    '135 02': 'Tyresö',
    '135 17': 'Tyresö',
    '135 21': 'Tyresö',
    '135 22': 'Tyresö',
    '135 23': 'Tyresö',
    '135 24': 'Tyresö',
    '135 25': 'Tyresö',
    '135 26': 'Tyresö',
    '135 27': 'Tyresö',
    '135 28': 'Tyresö',
    '135 29': 'Tyresö',
    '135 30': 'Tyresö',
    '135 33': 'Tyresö',
    '135 34': 'Tyresö',
    '135 35': 'Tyresö',
    '135 36': 'Tyresö',
    '135 37': 'Tyresö',
    '135 39': 'Tyresö',
    '135 40': 'Tyresö',
    '135 41': 'Tyresö',
    '135 42': 'Tyresö',
    '135 43': 'Tyresö',
    '135 44': 'Tyresö',
    '135 45': 'Tyresö',
    '135 46': 'Tyresö',
    '135 47': 'Tyresö',
    '135 48': 'Tyresö',
    '135 49': 'Tyresö',
    '135 50': 'Tyresö',
    '135 51': 'Tyresö',
    '135 52': 'Tyresö',
    '135 53': 'Tyresö',
    '135 54': 'Tyresö',
    '135 55': 'Tyresö',
    '135 58': 'Tyresö',
    '135 59': 'Tyresö',
    '135 60': 'Tyresö',
    '135 61': 'Tyresö',
    '135 62': 'Tyresö',
    '135 63': 'Tyresö',
    '135 67': 'Tyresö',
    '135 68': 'Tyresö',
    '135 69': 'Tyresö',
    '135 81': 'Tyresö',
    '135 82': 'Tyresö',
    '155 00': 'Nykvarn',
    '155 21': 'Nykvarn',
    '155 22': 'Nykvarn',
    '155 30': 'Nykvarn',
    '155 31': 'Nykvarn',
    '155 32': 'Nykvarn',
    '155 33': 'Nykvarn',
    '155 34': 'Nykvarn',
    '155 80': 'Nykvarn',
    '155 91': 'Nykvarn',
    '155 92': 'Nykvarn',
    '155 93': 'Nykvarn',
    '155 94': 'Nykvarn',
    '183 00': 'Täby',
    '183 01': 'Täby',
    '183 02': 'Täby',
    '183 03': 'Täby',
    '183 04': 'Täby',
    '183 11': 'Täby',
    '183 12': 'Täby',
    '183 13': 'Täby',
    '183 14': 'Täby',
    '183 15': 'Täby',
    '183 16': 'Täby',
    '183 17': 'Täby',
    '183 18': 'Täby',
    '183 19': 'Täby',
    '183 21': 'Täby',
    '183 22': 'Täby',
    '183 23': 'Täby',
    '183 25': 'Täby',
    '183 26': 'Täby',
    '183 30': 'Täby',
    '183 31': 'Täby',
    '183 32': 'Täby',
    '183 33': 'Täby',
    '183 34': 'Täby',
    '183 35': 'Täby',
    '183 36': 'Täby',
    '183 37': 'Täby',
    '183 48': 'Täby',
    '183 49': 'Täby',
    '183 50': 'Täby',
    '183 51': 'Täby',
    '183 52': 'Täby',
    '183 53': 'Täby',
    '183 54': 'Täby',
    '183 55': 'Täby',
    '183 56': 'Täby',
    '183 57': 'Täby',
    '183 58': 'Täby',
    '183 59': 'Täby',
    '183 60': 'Täby',
    '183 61': 'Täby',
    '183 62': 'Täby',
    '183 63': 'Täby',
    '183 64': 'Täby',
    '183 65': 'Täby',
    '183 66': 'Täby',
    '183 67': 'Täby',
    '183 69': 'Täby',
    '183 70': 'Täby',
    '183 77': 'Täby',
    '183 78': 'Täby',
    '183 79': 'Täby',
    '183 80': 'Täby',
    '183 81': 'Täby',
    '183 83': 'Täby',
    '183 84': 'Täby',
    '183 85': 'Täby',
    '187 00': 'Täby',
    '187 05': 'Täby',
    '187 06': 'Täby',
    '187 10': 'Täby',
    '187 11': 'Täby',
    '187 12': 'Täby',
    '187 13': 'Täby',
    '187 14': 'Täby',
    '182 00': 'Danderyd',
    '182 01': 'Danderyd',
    '182 02': 'Danderyd',
    '182 03': 'Danderyd',
    '182 04': 'Enebyberg',
    '182 05': 'Djursholm',
    '182 06': 'Djursholm',
    '182 07': 'Stocksund',
    '182 10': 'Stocksund',
    '182 11': 'Danderyd',
    '182 12': 'Danderyd',
    '182 14': 'Danderyd',
    '182 15': 'Danderyd',
    '182 16': 'Danderyd',
    '182 17': 'Danderyd',
    '182 30': 'Danderyd',
    '182 31': 'Danderyd',
    '182 32': 'Danderyd',
    '182 33': 'Danderyd',
    '182 34': 'Danderyd',
    '182 35': 'Danderyd',
    '182 36': 'Danderyd',
    '182 37': 'Danderyd',
    '182 38': 'Danderyd',
    '182 39': 'Danderyd',
    '182 45': 'Enebyberg',
    '182 46': 'Enebyberg',
    '182 47': 'Enebyberg',
    '182 48': 'Enebyberg',
    '182 49': 'Enebyberg',
    '182 50': 'Danderyd',
    '182 52': 'Danderyd',
    '182 53': 'Danderyd',
    '182 54': 'Djursholm',
    '182 55': 'Djursholm',
    '182 56': 'Danderyd',
    '182 57': 'Danderyd',
    '182 60': 'Djursholm',
    '182 61': 'Djursholm',
    '182 62': 'Djursholm',
    '182 63': 'Djursholm',
    '182 64': 'Djursholm',
    '182 65': 'Djursholm',
    '182 66': 'Djursholm',
    '182 67': 'Djursholm',
    '182 68': 'Djursholm',
    '182 69': 'Djursholm',
    '182 70': 'Stocksund',
    '182 73': 'Stocksund',
    '182 74': 'Stocksund',
    '182 75': 'Stocksund',
    '182 76': 'Stocksund',
    '182 77': 'Stocksund',
    '182 78': 'Stocksund',
    '182 79': 'Stocksund',
    '182 85': 'Danderyd',
    '182 87': 'Danderyd',
    '182 91': 'Danderyd',
    '100 05': 'Stockholm',
    '100 12': 'Stockholm',
    '100 26': 'Stockholm',
    '100 28': 'Stockholm',
    '100 29': 'Stockholm',
    '100 31': 'Stockholm',
    '100 40': 'Stockholm',
    '100 41': 'Stockholm',
    '100 44': 'Stockholm',
    '100 52': 'Stockholm',
    '100 54': 'Stockholm',
    '100 55': 'Stockholm',
    '100 61': 'Stockholm',
    '100 64': 'Stockholm',
    '100 73': 'Stockholm',
    '100 74': 'Stockholm',
    '101 10': 'Stockholm',
    '101 20': 'Stockholm',
    '101 21': 'Stockholm',
    '101 22': 'Stockholm',
    '101 23': 'Stockholm',
    '101 24': 'Stockholm',
    '101 25': 'Stockholm',
    '101 26': 'Stockholm',
    '101 27': 'Stockholm',
    '101 28': 'Stockholm',
    '101 29': 'Stockholm',
    '101 30': 'Stockholm',
    '101 31': 'Stockholm',
    '101 32': 'Stockholm',
    '101 33': 'Stockholm',
    '101 34': 'Stockholm',
    '101 35': 'Stockholm',
    '101 36': 'Stockholm',
    '101 37': 'Stockholm',
    '101 38': 'Stockholm',
    '101 39': 'Stockholm',
    '101 52': 'Stockholm',
    '101 55': 'Stockholm',
    '101 56': 'Stockholm',
    '101 58': 'Stockholm',
    '102 04': 'Stockholm',
    '102 14': 'Stockholm',
    '102 21': 'Stockholm',
    '102 22': 'Stockholm',
    '102 23': 'Stockholm',
    '102 24': 'Stockholm',
    '102 25': 'Stockholm',
    '102 26': 'Stockholm',
    '102 27': 'Stockholm',
    '102 28': 'Stockholm',
    '102 29': 'Stockholm',
    '102 31': 'Stockholm',
    '102 32': 'Stockholm',
    '102 33': 'Stockholm',
    '102 34': 'Stockholm',
    '102 35': 'Stockholm',
    '102 39': 'Stockholm',
    '102 40': 'Stockholm',
    '102 41': 'Stockholm',
    '102 42': 'Stockholm',
    '102 43': 'Stockholm',
    '102 44': 'Stockholm',
    '102 45': 'Stockholm',
    '102 46': 'Stockholm',
    '102 47': 'Stockholm',
    '102 48': 'Stockholm',
    '102 49': 'Stockholm',
    '102 51': 'Stockholm',
    '102 52': 'Stockholm',
    '102 53': 'Stockholm',
    '102 54': 'Stockholm',
    '102 61': 'Stockholm',
    '102 62': 'Stockholm',
    '102 63': 'Stockholm',
    '102 64': 'Stockholm',
    '102 65': 'Stockholm',
    '102 66': 'Stockholm',
    '102 67': 'Stockholm',
    '102 68': 'Stockholm',
    '102 69': 'Stockholm',
    '102 70': 'Stockholm',
    '102 71': 'Stockholm',
    '102 72': 'Stockholm',
    '102 73': 'Stockholm',
    '102 74': 'Stockholm',
    '103 01': 'Stockholm',
    '103 02': 'Stockholm',
    '103 03': 'Stockholm',
    '103 04': 'Stockholm',
    '103 05': 'Stockholm',
    '103 06': 'Stockholm',
    '103 07': 'Stockholm',
    '103 10': 'Stockholm',
    '103 11': 'Stockholm',
    '103 12': 'Stockholm',
    '103 13': 'Stockholm',
    '103 14': 'Stockholm',
    '103 15': 'Stockholm',
    '103 16': 'Stockholm',
    '103 17': 'Stockholm',
    '103 18': 'Stockholm',
    '103 20': 'Stockholm',
    '103 21': 'Stockholm',
    '103 22': 'Stockholm',
    '103 23': 'Stockholm',
    '103 24': 'Stockholm',
    '103 25': 'Stockholm',
    '103 26': 'Stockholm',
    '103 27': 'Stockholm',
    '103 29': 'Stockholm',
    '103 32': 'Stockholm',
    '103 33': 'Stockholm',
    '103 34': 'Stockholm',
    '103 35': 'Stockholm',
    '103 36': 'Stockholm',
    '103 37': 'Stockholm',
    '103 38': 'Stockholm',
    '103 39': 'Stockholm',
    '103 50': 'Stockholm',
    '103 51': 'Stockholm',
    '103 52': 'Stockholm',
    '103 54': 'Stockholm',
    '103 55': 'Stockholm',
    '103 60': 'Stockholm',
    '103 61': 'Stockholm',
    '103 62': 'Stockholm',
    '103 63': 'Stockholm',
    '103 64': 'Stockholm',
    '103 65': 'Stockholm',
    '103 66': 'Stockholm',
    '103 67': 'Stockholm',
    '103 68': 'Stockholm',
    '103 69': 'Stockholm',
    '103 70': 'Stockholm',
    '103 71': 'Stockholm',
    '103 72': 'Stockholm',
    '103 73': 'Stockholm',
    '103 74': 'Stockholm',
    '103 75': 'Stockholm',
    '103 77': 'Stockholm',
    '103 78': 'Stockholm',
    '103 80': 'Stockholm',
    '103 82': 'Stockholm',
    '103 83': 'Stockholm',
    '103 84': 'Stockholm',
    '103 85': 'Stockholm',
    '103 86': 'Stockholm',
    '103 87': 'Stockholm',
    '103 88': 'Stockholm',
    '103 89': 'Stockholm',
    '103 90': 'Stockholm',
    '103 91': 'Stockholm',
    '103 92': 'Stockholm',
    '103 93': 'Stockholm',
    '103 94': 'Stockholm',
    '103 95': 'Stockholm',
    '103 96': 'Stockholm',
    '103 97': 'Stockholm',
    '103 98': 'Stockholm',
    '103 99': 'Stockholm',
    '104 05': 'Stockholm',
    '104 20': 'Stockholm',
    '104 22': 'Stockholm',
    '104 25': 'Stockholm',
    '104 30': 'Stockholm',
    '104 31': 'Stockholm',
    '104 32': 'Stockholm',
    '104 35': 'Stockholm',
    '104 40': 'Stockholm',
    '104 41': 'Stockholm',
    '104 50': 'Stockholm',
    '104 51': 'Stockholm',
    '104 60': 'Stockholm',
    '104 62': 'Stockholm',
    '104 65': 'Stockholm',
    '105 00': 'Stockholm',
    '105 06': 'Stockholm',
    '105 07': 'Stockholm',
    '105 10': 'Stockholm',
    '105 11': 'Stockholm',
    '105 12': 'Stockholm',
    '105 15': 'Stockholm',
    '105 16': 'Stockholm',
    '105 17': 'Stockholm',
    '105 18': 'Stockholm',
    '105 19': 'Stockholm',
    '105 20': 'Stockholm',
    '105 21': 'Stockholm',
    '105 22': 'Stockholm',
    '105 23': 'Stockholm',
    '105 24': 'Stockholm',
    '105 25': 'Stockholm',
    '105 31': 'Stockholm',
    '105 32': 'Stockholm',
    '105 33': 'Stockholm',
    '105 34': 'Stockholm',
    '105 35': 'Stockholm',
    '105 36': 'Stockholm',
    '105 39': 'Stockholm',
    '105 40': 'Stockholm',
    '105 44': 'Stockholm',
    '105 45': 'Stockholm',
    '105 46': 'Stockholm',
    '105 52': 'Stockholm',
    '105 53': 'Stockholm',
    '105 60': 'Stockholm',
    '105 61': 'Stockholm',
    '105 71': 'Stockholm',
    '105 78': 'Stockholm',
    '106 10': 'Stockholm',
    '106 11': 'Stockholm',
    '106 12': 'Stockholm',
    '106 14': 'Stockholm',
    '106 18': 'Stockholm',
    '106 20': 'Stockholm',
    '106 26': 'Stockholm',
    '106 27': 'Stockholm',
    '106 30': 'Stockholm',
    '106 32': 'Stockholm',
    '106 35': 'Stockholm',
    '106 36': 'Stockholm',
    '106 38': 'Stockholm',
    '106 40': 'Stockholm',
    '106 42': 'Stockholm',
    '106 47': 'Stockholm',
    '106 48': 'Stockholm',
    '106 50': 'Stockholm',
    '106 54': 'Stockholm',
    '106 55': 'Stockholm',
    '106 58': 'Stockholm',
    '106 60': 'Stockholm',
    '106 61': 'Stockholm',
    '106 64': 'Stockholm',
    '106 65': 'Stockholm',
    '106 67': 'Stockholm',
    '106 70': 'Stockholm',
    '106 75': 'Stockholm',
    '106 77': 'Stockholm',
    '106 78': 'Stockholm',
    '106 80': 'Stockholm',
    '106 82': 'Stockholm',
    '106 88': 'Stockholm',
    '106 91': 'Stockholm',
    '107 07': 'Stockholm',
    '107 21': 'Stockholm',
    '107 22': 'Stockholm',
    '107 23': 'Stockholm',
    '107 24': 'Stockholm',
    '107 25': 'Stockholm',
    '107 26': 'Stockholm',
    '107 80': 'Stockholm',
    '107 81': 'Stockholm',
    '107 85': 'Stockholm',
    '107 86': 'Stockholm',
    '107 88': 'Stockholm',
    '107 95': 'Stockholm',
    '107 96': 'Stockholm',
    '111 01': 'Stockholm',
    '111 20': 'Stockholm',
    '111 21': 'Stockholm',
    '111 22': 'Stockholm',
    '111 23': 'Stockholm',
    '111 24': 'Stockholm',
    '111 27': 'Stockholm',
    '111 28': 'Stockholm',
    '111 29': 'Stockholm',
    '111 30': 'Stockholm',
    '111 31': 'Stockholm',
    '111 34': 'Stockholm',
    '111 35': 'Stockholm',
    '111 36': 'Stockholm',
    '111 37': 'Stockholm',
    '111 38': 'Stockholm',
    '111 39': 'Stockholm',
    '111 40': 'Stockholm',
    '111 43': 'Stockholm',
    '111 44': 'Stockholm',
    '111 45': 'Stockholm',
    '111 46': 'Stockholm',
    '111 47': 'Stockholm',
    '111 48': 'Stockholm',
    '111 49': 'Stockholm',
    '111 51': 'Stockholm',
    '111 52': 'Stockholm',
    '111 53': 'Stockholm',
    '111 56': 'Stockholm',
    '111 57': 'Stockholm',
    '111 60': 'Stockholm',
    '111 61': 'Stockholm',
    '111 64': 'Stockholm',
    '111 71': 'Stockholm',
    '111 73': 'Stockholm',
    '111 74': 'Stockholm',
    '111 76': 'Stockholm',
    '111 77': 'Stockholm',
    '111 81': 'Stockholm',
    '111 82': 'Stockholm',
    '111 83': 'Stockholm',
    '111 84': 'Stockholm',
    '111 85': 'Stockholm',
    '111 86': 'Stockholm',
    '111 87': 'Stockholm',
    '111 88': 'Stockholm',
    '111 89': 'Stockholm',
    '111 91': 'Stockholm',
    '111 92': 'Stockholm',
    '111 93': 'Stockholm',
    '111 94': 'Stockholm',
    '111 95': 'Stockholm',
    '111 96': 'Stockholm',
    '111 97': 'Stockholm',
    '111 98': 'Stockholm',
    '111 99': 'Stockholm',
    '112 01': 'Stockholm',
    '112 02': 'Stockholm',
    '112 03': 'Stockholm',
    '112 04': 'Stockholm',
    '112 06': 'Stockholm',
    '112 20': 'Stockholm',
    '112 21': 'Stockholm',
    '112 22': 'Stockholm',
    '112 23': 'Stockholm',
    '112 24': 'Stockholm',
    '112 25': 'Stockholm',
    '112 26': 'Stockholm',
    '112 27': 'Stockholm',
    '112 28': 'Stockholm',
    '112 29': 'Stockholm',
    '112 30': 'Stockholm',
    '112 31': 'Stockholm',
    '112 32': 'Stockholm',
    '112 33': 'Stockholm',
    '112 34': 'Stockholm',
    '112 35': 'Stockholm',
    '112 36': 'Stockholm',
    '112 37': 'Stockholm',
    '112 38': 'Stockholm',
    '112 39': 'Stockholm',
    '112 40': 'Stockholm',
    '112 41': 'Stockholm',
    '112 42': 'Stockholm',
    '112 43': 'Stockholm',
    '112 44': 'Stockholm',
    '112 45': 'Stockholm',
    '112 46': 'Stockholm',
    '112 47': 'Stockholm',
    '112 48': 'Stockholm',
    '112 49': 'Stockholm',
    '112 50': 'Stockholm',
    '112 51': 'Stockholm',
    '112 52': 'Stockholm',
    '112 53': 'Stockholm',
    '112 54': 'Stockholm',
    '112 55': 'Stockholm',
    '112 56': 'Stockholm',
    '112 57': 'Stockholm',
    '112 58': 'Stockholm',
    '112 59': 'Stockholm',
    '112 60': 'Stockholm',
    '112 61': 'Stockholm',
    '112 62': 'Stockholm',
    '112 63': 'Stockholm',
    '112 64': 'Stockholm',
    '112 65': 'Stockholm',
    '112 66': 'Stockholm',
    '112 67': 'Stockholm',
    '112 69': 'Stockholm',
    '112 73': 'Stockholm',
    '112 75': 'Stockholm',
    '112 76': 'Stockholm',
    '112 77': 'Stockholm',
    '112 81': 'Stockholm',
    '112 82': 'Stockholm',
    '112 83': 'Stockholm',
    '112 84': 'Stockholm',
    '112 85': 'Stockholm',
    '112 86': 'Stockholm',
    '112 87': 'Stockholm',
    '112 88': 'Stockholm',
    '112 89': 'Stockholm',
    '112 92': 'Stockholm',
    '112 93': 'Stockholm',
    '112 94': 'Stockholm',
    '112 96': 'Stockholm',
    '112 97': 'Stockholm',
    '112 98': 'Stockholm',
    '113 01': 'Stockholm',
    '113 02': 'Stockholm',
    '113 03': 'Stockholm',
    '113 04': 'Stockholm',
    '113 20': 'Stockholm',
    '113 21': 'Stockholm',
    '113 22': 'Stockholm',
    '113 23': 'Stockholm',
    '113 24': 'Stockholm',
    '113 25': 'Stockholm',
    '113 26': 'Stockholm',
    '113 27': 'Stockholm',
    '113 28': 'Stockholm',
    '113 29': 'Stockholm',
    '113 30': 'Stockholm',
    '113 31': 'Stockholm',
    '113 32': 'Stockholm',
    '113 33': 'Stockholm',
    '113 35': 'Stockholm',
    '113 36': 'Stockholm',
    '113 37': 'Stockholm',
    '113 38': 'Stockholm',
    '113 39': 'Stockholm',
    '113 40': 'Stockholm',
    '113 41': 'Stockholm',
    '113 42': 'Stockholm',
    '113 43': 'Stockholm',
    '113 44': 'Stockholm',
    '113 45': 'Stockholm',
    '113 46': 'Stockholm',
    '113 47': 'Stockholm',
    '113 48': 'Stockholm',
    '113 49': 'Stockholm',
    '113 50': 'Stockholm',
    '113 51': 'Stockholm',
    '113 52': 'Stockholm',
    '113 53': 'Stockholm',
    '113 54': 'Stockholm',
    '113 55': 'Stockholm',
    '113 56': 'Stockholm',
    '113 57': 'Stockholm',
    '113 58': 'Stockholm',
    '113 59': 'Stockholm',
    '113 60': 'Stockholm',
    '113 62': 'Stockholm',
    '113 64': 'Stockholm',
    '113 76': 'Stockholm',
    '113 80': 'Stockholm',
    '113 81': 'Stockholm',
    '113 82': 'Stockholm',
    '113 83': 'Stockholm',
    '113 84': 'Stockholm',
    '113 85': 'Stockholm',
    '113 86': 'Stockholm',
    '113 87': 'Stockholm',
    '113 88': 'Stockholm',
    '113 89': 'Stockholm',
    '113 90': 'Stockholm',
    '113 91': 'Stockholm',
    '113 92': 'Stockholm',
    '113 93': 'Stockholm',
    '113 94': 'Stockholm',
    '113 95': 'Stockholm',
    '113 96': 'Stockholm',
    '113 97': 'Stockholm',
    '113 98': 'Stockholm',
    '113 99': 'Stockholm',
    '114 01': 'Stockholm',
    '114 20': 'Stockholm',
    '114 21': 'Stockholm',
    '114 22': 'Stockholm',
    '114 23': 'Stockholm',
    '114 24': 'Stockholm',
    '114 25': 'Stockholm',
    '114 26': 'Stockholm',
    '114 27': 'Stockholm',
    '114 28': 'Stockholm',
    '114 29': 'Stockholm',
    '114 30': 'Stockholm',
    '114 31': 'Stockholm',
    '114 32': 'Stockholm',
    '114 33': 'Stockholm',
    '114 34': 'Stockholm',
    '114 35': 'Stockholm',
    '114 36': 'Stockholm',
    '114 37': 'Stockholm',
    '114 38': 'Stockholm',
    '114 39': 'Stockholm',
    '114 40': 'Stockholm',
    '114 41': 'Stockholm',
    '114 42': 'Stockholm',
    '114 43': 'Stockholm',
    '114 44': 'Stockholm',
    '114 45': 'Stockholm',
    '114 46': 'Stockholm',
    '114 47': 'Stockholm',
    '114 48': 'Stockholm',
    '114 49': 'Stockholm',
    '114 50': 'Stockholm',
    '114 51': 'Stockholm',
    '114 52': 'Stockholm',
    '114 53': 'Stockholm',
    '114 54': 'Stockholm',
    '114 55': 'Stockholm',
    '114 56': 'Stockholm',
    '114 57': 'Stockholm',
    '114 58': 'Stockholm',
    '114 59': 'Stockholm',
    '114 60': 'Stockholm',
    '114 75': 'Stockholm',
    '114 76': 'Stockholm',
    '114 78': 'Stockholm',
    '114 79': 'Stockholm',
    '114 80': 'Stockholm',
    '114 81': 'Stockholm',
    '114 82': 'Stockholm',
    '114 83': 'Stockholm',
    '114 84': 'Stockholm',
    '114 85': 'Stockholm',
    '114 86': 'Stockholm',
    '114 87': 'Stockholm',
    '114 88': 'Stockholm',
    '114 89': 'Stockholm',
    '114 92': 'Stockholm',
    '114 94': 'Stockholm',
    '114 95': 'Stockholm',
    '114 97': 'Stockholm',
    '114 98': 'Stockholm',
    '114 99': 'Stockholm',
    '115 01': 'Stockholm',
    '115 20': 'Stockholm',
    '115 21': 'Stockholm',
    '115 22': 'Stockholm',
    '115 23': 'Stockholm',
    '115 24': 'Stockholm',
    '115 25': 'Stockholm',
    '115 26': 'Stockholm',
    '115 27': 'Stockholm',
    '115 28': 'Stockholm',
    '115 29': 'Stockholm',
    '115 30': 'Stockholm',
    '115 31': 'Stockholm',
    '115 32': 'Stockholm',
    '115 33': 'Stockholm',
    '115 34': 'Stockholm',
    '115 35': 'Stockholm',
    '115 36': 'Stockholm',
    '115 37': 'Stockholm',
    '115 38': 'Stockholm',
    '115 39': 'Stockholm',
    '115 40': 'Stockholm',
    '115 41': 'Stockholm',
    '115 42': 'Stockholm',
    '115 43': 'Stockholm',
    '115 50': 'Stockholm',
    '115 53': 'Stockholm',
    '115 56': 'Stockholm',
    '115 57': 'Stockholm',
    '115 58': 'Stockholm',
    '115 59': 'Stockholm',
    '115 73': 'Stockholm',
    '115 74': 'Stockholm',
    '115 77': 'Stockholm',
    '115 78': 'Stockholm',
    '115 79': 'Stockholm',
    '115 80': 'Stockholm',
    '115 82': 'Stockholm',
    '115 84': 'Stockholm',
    '115 87': 'Stockholm',
    '115 88': 'Stockholm',
    '115 89': 'Stockholm',
    '115 90': 'Stockholm',
    '115 91': 'Stockholm',
    '115 92': 'Stockholm',
    '115 93': 'Stockholm',
    '115 94': 'Stockholm',
    '115 95': 'Stockholm',
    '116 01': 'Stockholm',
    '116 18': 'Stockholm',
    '116 20': 'Stockholm',
    '116 21': 'Stockholm',
    '116 22': 'Stockholm',
    '116 23': 'Stockholm',
    '116 24': 'Stockholm',
    '116 25': 'Stockholm',
    '116 28': 'Stockholm',
    '116 29': 'Stockholm',
    '116 30': 'Stockholm',
    '116 31': 'Stockholm',
    '116 32': 'Stockholm',
    '116 33': 'Stockholm',
    '116 34': 'Stockholm',
    '116 35': 'Stockholm',
    '116 36': 'Stockholm',
    '116 37': 'Stockholm',
    '116 38': 'Stockholm',
    '116 39': 'Stockholm',
    '116 40': 'Stockholm',
    '116 41': 'Stockholm',
    '116 42': 'Stockholm',
    '116 43': 'Stockholm',
    '116 44': 'Stockholm',
    '116 45': 'Stockholm',
    '116 46': 'Stockholm',
    '116 47': 'Stockholm',
    '116 61': 'Stockholm',
    '116 62': 'Stockholm',
    '116 63': 'Stockholm',
    '116 64': 'Stockholm',
    '116 65': 'Stockholm',
    '116 66': 'Stockholm',
    '116 67': 'Stockholm',
    '116 68': 'Stockholm',
    '116 74': 'Stockholm',
    '116 85': 'Stockholm',
    '116 88': 'Stockholm',
    '116 91': 'Stockholm',
    '116 92': 'Stockholm',
    '116 93': 'Stockholm',
    '116 94': 'Stockholm',
    '117 18': 'Stockholm',
    '117 26': 'Stockholm',
    '117 27': 'Stockholm',
    '117 28': 'Stockholm',
    '117 29': 'Stockholm',
    '117 30': 'Stockholm',
    '117 31': 'Stockholm',
    '117 32': 'Stockholm',
    '117 33': 'Stockholm',
    '117 34': 'Stockholm',
    '117 35': 'Stockholm',
    '117 36': 'Stockholm',
    '117 37': 'Stockholm',
    '117 38': 'Stockholm',
    '117 39': 'Stockholm',
    '117 40': 'Stockholm',
    '117 41': 'Stockholm',
    '117 43': 'Stockholm',
    '117 50': 'Stockholm',
    '117 60': 'Stockholm',
    '117 61': 'Stockholm',
    '117 62': 'Stockholm',
    '117 63': 'Stockholm',
    '117 64': 'Stockholm',
    '117 65': 'Stockholm',
    '117 66': 'Stockholm',
    '117 67': 'Stockholm',
    '117 68': 'Stockholm',
    '117 69': 'Stockholm',
    '117 70': 'Stockholm',
    '117 71': 'Stockholm',
    '117 83': 'Stockholm',
    '117 84': 'Stockholm',
    '117 85': 'Stockholm',
    '117 86': 'Stockholm',
    '117 88': 'Stockholm',
    '117 90': 'Stockholm',
    '117 91': 'Stockholm',
    '117 92': 'Stockholm',
    '117 93': 'Stockholm',
    '117 94': 'Stockholm',
    '117 95': 'Stockholm',
    '117 97': 'Stockholm',
    '117 98': 'Stockholm',
    '117 99': 'Stockholm',
    '118 01': 'Stockholm',
    '118 18': 'Stockholm',
    '118 20': 'Stockholm',
    '118 21': 'Stockholm',
    '118 22': 'Stockholm',
    '118 23': 'Stockholm',
    '118 24': 'Stockholm',
    '118 25': 'Stockholm',
    '118 26': 'Stockholm',
    '118 27': 'Stockholm',
    '118 28': 'Stockholm',
    '118 29': 'Stockholm',
    '118 42': 'Stockholm',
    '118 46': 'Stockholm',
    '118 47': 'Stockholm',
    '118 48': 'Stockholm',
    '118 49': 'Stockholm',
    '118 50': 'Stockholm',
    '118 51': 'Stockholm',
    '118 52': 'Stockholm',
    '118 53': 'Stockholm',
    '118 54': 'Stockholm',
    '118 55': 'Stockholm',
    '118 56': 'Stockholm',
    '118 57': 'Stockholm',
    '118 58': 'Stockholm',
    '118 59': 'Stockholm',
    '118 60': 'Stockholm',
    '118 61': 'Stockholm',
    '118 62': 'Stockholm',
    '118 63': 'Stockholm',
    '118 64': 'Stockholm',
    '118 65': 'Stockholm',
    '118 66': 'Stockholm',
    '118 67': 'Stockholm',
    '118 69': 'Stockholm',
    '118 72': 'Stockholm',
    '118 80': 'Stockholm',
    '118 82': 'Stockholm',
    '118 83': 'Stockholm',
    '118 85': 'Stockholm',
    '118 86': 'Stockholm',
    '118 87': 'Stockholm',
    '118 88': 'Stockholm',
    '118 89': 'Stockholm',
    '118 90': 'Stockholm',
    '118 91': 'Stockholm',
    '118 92': 'Stockholm',
    '118 93': 'Stockholm',
    '118 94': 'Stockholm',
    '118 95': 'Stockholm',
    '120 00': 'Stockholm',
    '120 01': 'Stockholm',
    '120 02': 'Årsta',
    '120 04': 'Årsta',
    '120 05': 'Årsta',
    '120 06': 'Stockholm',
    '120 07': 'Stockholm',
    '120 08': 'Stockholm',
    '120 09': 'Stockholm',
    '120 10': 'Årsta',
    '120 11': 'Stockholm',
    '120 12': 'Stockholm',
    '120 13': 'Stockholm',
    '120 14': 'Stockholm',
    '120 15': 'Stockholm',
    '120 16': 'Stockholm',
    '120 17': 'Stockholm',
    '120 18': 'Stockholm',
    '120 19': 'Stockholm',
    '120 21': 'Stockholm',
    '120 22': 'Stockholm',
    '120 23': 'Stockholm',
    '120 24': 'Stockholm',
    '120 25': 'Stockholm',
    '120 26': 'Stockholm',
    '120 30': 'Stockholm',
    '120 31': 'Stockholm',
    '120 32': 'Stockholm',
    '120 33': 'Stockholm',
    '120 38': 'Årsta',
    '120 39': 'Årsta',
    '120 40': 'Årsta',
    '120 44': 'Årsta',
    '120 47': 'Enskede Gård',
    '120 48': 'Enskede Gård',
    '120 50': 'Årsta',
    '120 51': 'Årsta',
    '120 52': 'Årsta',
    '120 53': 'Årsta',
    '120 54': 'Årsta',
    '120 55': 'Årsta',
    '120 56': 'Årsta',
    '120 57': 'Årsta',
    '120 58': 'Årsta',
    '120 59': 'Årsta',
    '120 60': 'Årsta',
    '120 64': 'Stockholm',
    '120 65': 'Stockholm',
    '120 66': 'Stockholm',
    '120 67': 'Stockholm',
    '120 68': 'Stockholm',
    '120 79': 'Stockholm',
    '120 80': 'Stockholm',
    '120 82': 'Stockholm',
    '120 87': 'Stockholm',
    '120 88': 'Stockholm',
    '120 90': 'Stockholm',
    '121 00': 'Johanneshov',
    '121 05': 'Johanneshov',
    '121 06': 'Johanneshov',
    '121 11': 'Johanneshov',
    '121 12': 'Johanneshov',
    '121 13': 'Johanneshov',
    '121 14': 'Enskededalen',
    '121 15': 'Enskededalen',
    '121 16': 'Johanneshov',
    '121 17': 'Johanneshov',
    '121 18': 'Johanneshov',
    '121 21': 'Johanneshov',
    '121 22': 'Johanneshov',
    '121 23': 'Johanneshov',
    '121 24': 'Johanneshov',
    '121 26': 'Stockholm-Globen',
    '121 27': 'Stockholm-Globen',
    '121 28': 'Stockholm-Globen',
    '121 29': 'Stockholm',
    '121 30': 'Enskededalen',
    '121 31': 'Enskededalen',
    '121 32': 'Enskededalen',
    '121 33': 'Enskededalen',
    '121 34': 'Enskededalen',
    '121 35': 'Johanneshov',
    '121 36': 'Johanneshov',
    '121 37': 'Johanneshov',
    '121 38': 'Johanneshov',
    '121 39': 'Johanneshov',
    '121 40': 'Johanneshov',
    '121 43': 'Johanneshov',
    '121 44': 'Johanneshov',
    '121 45': 'Johanneshov',
    '121 46': 'Johanneshov',
    '121 47': 'Johanneshov',
    '121 48': 'Johanneshov',
    '121 49': 'Johanneshov',
    '121 50': 'Johanneshov',
    '121 51': 'Johanneshov',
    '121 52': 'Johanneshov',
    '121 53': 'Johanneshov',
    '121 54': 'Johanneshov',
    '121 55': 'Johanneshov',
    '121 56': 'Johanneshov',
    '121 62': 'Johanneshov',
    '121 63': 'Johanneshov',
    '121 77': 'Johanneshov',
    '121 86': 'Johanneshov',
    '121 87': 'Enskededalen',
    '121 88': 'Stockholm-Globen',
    '121 89': 'Johanneshov',
    '122 00': 'Enskede',
    '122 03': 'Enskede',
    '122 06': 'Enskede',
    '122 31': 'Enskede',
    '122 32': 'Enskede',
    '122 33': 'Enskede',
    '122 34': 'Enskede',
    '122 37': 'Enskede',
    '122 38': 'Enskede',
    '122 39': 'Enskede',
    '122 40': 'Enskede',
    '122 41': 'Enskede',
    '122 42': 'Enskede',
    '122 43': 'Enskede',
    '122 44': 'Enskede',
    '122 45': 'Enskede',
    '122 46': 'Enskede',
    '122 47': 'Enskede',
    '122 48': 'Enskede',
    '122 60': 'Enskede',
    '122 62': 'Enskede',
    '122 63': 'Enskede',
    '122 64': 'Enskede',
    '122 65': 'Enskede',
    '122 66': 'Enskede',
    '122 86': 'Enskede',
    '122 88': 'Enskede',
    '123 00': 'Farsta',
    '123 01': 'Farsta',
    '123 03': 'Farsta',
    '123 05': 'Farsta',
    '123 21': 'Farsta',
    '123 22': 'Farsta',
    '123 23': 'Farsta',
    '123 24': 'Farsta',
    '123 26': 'Farsta',
    '123 27': 'Farsta',
    '123 28': 'Farsta',
    '123 32': 'Farsta',
    '123 33': 'Farsta',
    '123 34': 'Farsta',
    '123 41': 'Farsta',
    '123 42': 'Farsta',
    '123 43': 'Farsta',
    '123 44': 'Farsta',
    '123 46': 'Farsta',
    '123 47': 'Farsta',
    '123 48': 'Farsta',
    '123 49': 'Farsta',
    '123 52': 'Farsta',
    '123 56': 'Farsta',
    '123 57': 'Farsta',
    '123 58': 'Farsta',
    '123 59': 'Farsta',
    '123 60': 'Farsta',
    '123 63': 'Farsta',
    '123 71': 'Farsta',
    '123 72': 'Farsta',
    '123 73': 'Farsta',
    '123 86': 'Farsta',
    '124 00': 'Bandhagen',
    '124 03': 'Bandhagen',
    '124 04': 'Bandhagen',
    '124 21': 'Bandhagen',
    '124 22': 'Bandhagen',
    '124 25': 'Bandhagen',
    '124 30': 'Bandhagen',
    '124 31': 'Bandhagen',
    '124 32': 'Bandhagen',
    '124 33': 'Bandhagen',
    '124 50': 'Bandhagen',
    '124 51': 'Bandhagen',
    '124 52': 'Bandhagen',
    '124 53': 'Bandhagen',
    '124 54': 'Bandhagen',
    '124 55': 'Bandhagen',
    '124 56': 'Bandhagen',
    '124 57': 'Bandhagen',
    '124 58': 'Bandhagen',
    '124 59': 'Bandhagen',
    '124 60': 'Bandhagen',
    '124 61': 'Bandhagen',
    '124 62': 'Bandhagen',
    '124 63': 'Bandhagen',
    '124 64': 'Bandhagen',
    '124 65': 'Bandhagen',
    '124 66': 'Bandhagen',
    '124 67': 'Bandhagen',
    '124 70': 'Bandhagen',
    '124 71': 'Bandhagen',
    '124 72': 'Bandhagen',
    '124 73': 'Bandhagen',
    '124 74': 'Bandhagen',
    '124 75': 'Bandhagen',
    '124 76': 'Bandhagen',
    '125 00': 'Älvsjö',
    '125 01': 'Älvsjö',
    '125 02': 'Älvsjö',
    '125 03': 'Älvsjö',
    '125 05': 'Älvsjö',
    '125 21': 'Älvsjö',
    '125 22': 'Älvsjö',
    '125 23': 'Älvsjö',
    '125 24': 'Älvsjö',
    '125 25': 'Älvsjö',
    '125 30': 'Älvsjö',
    '125 33': 'Älvsjö',
    '125 34': 'Älvsjö',
    '125 35': 'Älvsjö',
    '125 40': 'Älvsjö',
    '125 41': 'Älvsjö',
    '125 42': 'Älvsjö',
    '125 43': 'Älvsjö',
    '125 44': 'Älvsjö',
    '125 46': 'Älvsjö',
    '125 51': 'Älvsjö',
    '125 52': 'Älvsjö',
    '125 53': 'Älvsjö',
    '125 54': 'Älvsjö',
    '125 55': 'Älvsjö',
    '125 56': 'Älvsjö',
    '125 57': 'Älvsjö',
    '125 58': 'Älvsjö',
    '125 59': 'Älvsjö',
    '125 71': 'Älvsjö',
    '125 72': 'Älvsjö',
    '125 73': 'Älvsjö',
    '125 74': 'Älvsjö',
    '125 80': 'Stockholm',
    '125 81': 'Stockholm',
    '125 82': 'Stockholm',
    '125 86': 'Älvsjö',
    '126 00': 'Hägersten',
    '126 01': 'Hägersten',
    '126 09': 'Hägersten',
    '126 11': 'Stockholm',
    '126 12': 'Stockholm',
    '126 13': 'Stockholm',
    '126 14': 'Stockholm',
    '126 15': 'Stockholm',
    '126 16': 'Stockholm',
    '126 17': 'Stockholm',
    '126 18': 'Stockholm',
    '126 25': 'Stockholm',
    '126 27': 'Stockholm',
    '126 30': 'Hägersten',
    '126 31': 'Hägersten',
    '126 32': 'Hägersten',
    '126 33': 'Hägersten',
    '126 34': 'Hägersten',
    '126 35': 'Hägersten',
    '126 36': 'Hägersten',
    '126 37': 'Hägersten',
    '126 38': 'Hägersten',
    '126 39': 'Hägersten',
    '126 40': 'Hägersten',
    '126 41': 'Hägersten',
    '126 42': 'Hägersten',
    '126 47': 'Hägersten',
    '126 48': 'Hägersten',
    '126 49': 'Hägersten',
    '126 50': 'Hägersten',
    '126 51': 'Hägersten',
    '126 52': 'Hägersten',
    '126 53': 'Hägersten',
    '126 75': 'Stockholm',
    '126 78': 'Hägersten',
    '126 79': 'Hägersten',
    '126 86': 'Stockholm',
    '126 88': 'Stockholm',
    '126 89': 'Hägersten',
    '127 00': 'Skärholmen',
    '127 02': 'Skärholmen',
    '127 03': 'Skärholmen',
    '127 21': 'Skärholmen',
    '127 22': 'Skärholmen',
    '127 23': 'Skärholmen',
    '127 24': 'Skärholmen',
    '127 25': 'Skärholmen',
    '127 26': 'Skärholmen',
    '127 29': 'Stockholm',
    '127 30': 'Skärholmen',
    '127 31': 'Skärholmen',
    '127 32': 'Skärholmen',
    '127 33': 'Skärholmen',
    '127 34': 'Skärholmen',
    '127 35': 'Skärholmen',
    '127 36': 'Skärholmen',
    '127 37': 'Skärholmen',
    '127 38': 'Skärholmen',
    '127 39': 'Skärholmen',
    '127 40': 'Skärholmen',
    '127 41': 'Skärholmen',
    '127 42': 'Skärholmen',
    '127 43': 'Skärholmen',
    '127 44': 'Skärholmen',
    '127 45': 'Skärholmen',
    '127 46': 'Skärholmen',
    '127 47': 'Skärholmen',
    '127 48': 'Skärholmen',
    '127 49': 'Skärholmen',
    '127 61': 'Skärholmen',
    '127 81': 'Skärholmen',
    '127 85': 'Skärholmen',
    '127 86': 'Skärholmen',
    '127 87': 'Skärholmen',
    '128 00': 'Skarpnäck',
    '128 04': 'Bagarmossen',
    '128 05': 'Bagarmossen',
    '128 06': 'Sköndal',
    '128 07': 'Sköndal',
    '128 21': 'Skarpnäck',
    '128 22': 'Skarpnäck',
    '128 23': 'Skarpnäck',
    '128 24': 'Skarpnäck',
    '128 30': 'Skarpnäck',
    '128 31': 'Skarpnäck',
    '128 32': 'Skarpnäck',
    '128 33': 'Skarpnäck',
    '128 34': 'Skarpnäck',
    '128 35': 'Skarpnäck',
    '128 36': 'Skarpnäck',
    '128 38': 'Skarpnäck',
    '128 39': 'Skarpnäck',
    '128 41': 'Bagarmossen',
    '128 42': 'Bagarmossen',
    '128 43': 'Bagarmossen',
    '128 44': 'Bagarmossen',
    '128 45': 'Bagarmossen',
    '128 46': 'Bagarmossen',
    '128 47': 'Bagarmossen',
    '128 48': 'Bagarmossen',
    '128 62': 'Sköndal',
    '128 64': 'Sköndal',
    '128 65': 'Sköndal',
    '128 66': 'Sköndal',
    '128 67': 'Sköndal',
    '128 68': 'Sköndal',
    '128 69': 'Sköndal',
    '128 85': 'Sköndal',
    '129 00': 'Hägersten',
    '129 04': 'Hägersten',
    '129 06': 'Hägersten',
    '129 07': 'Hägersten',
    '129 18': 'Hägersten',
    '129 21': 'Hägersten',
    '129 22': 'Hägersten',
    '129 23': 'Hägersten',
    '129 30': 'Hägersten',
    '129 31': 'Hägersten',
    '129 32': 'Hägersten',
    '129 33': 'Hägersten',
    '129 34': 'Hägersten',
    '129 35': 'Hägersten',
    '129 36': 'Hägersten',
    '129 37': 'Hägersten',
    '129 38': 'Hägersten',
    '129 39': 'Hägersten',
    '129 40': 'Hägersten',
    '129 41': 'Hägersten',
    '129 42': 'Hägersten',
    '129 43': 'Hägersten',
    '129 44': 'Hägersten',
    '129 45': 'Hägersten',
    '129 46': 'Hägersten',
    '129 47': 'Hägersten',
    '129 48': 'Hägersten',
    '129 49': 'Hägersten',
    '129 50': 'Hägersten',
    '129 51': 'Hägersten',
    '129 52': 'Hägersten',
    '129 53': 'Hägersten',
    '129 54': 'Hägersten',
    '129 55': 'Hägersten',
    '131 29': 'Stockholm',
    '131 55': 'Stockholm',
    '131 88': 'Stockholm',
    '131 91': 'Stockholm',
    '135 70': 'Stockholm',
    '141 82': 'Stockholm',
    '141 86': 'Stockholm',
    '141 99': 'Stockholm',
    '143 46': 'Vårby',
    '145 84': 'Stockholm',
    '161 00': 'Bromma',
    '161 02': 'Bromma',
    '161 03': 'Bromma',
    '161 04': 'Bromma',
    '161 05': 'Bromma',
    '161 07': 'Bromma',
    '161 11': 'Bromma',
    '161 24': 'Bromma',
    '161 25': 'Bromma',
    '161 26': 'Bromma',
    '161 84': 'Bromma',
    '161 86': 'Stockholm',
    '161 89': 'Stockholm',
    '162 00': 'Vällingby',
    '162 06': 'Vällingby',
    '162 11': 'Vällingby',
    '162 12': 'Vällingby',
    '162 15': 'Vällingby',
    '162 16': 'Stockholm',
    '162 44': 'Vällingby',
    '162 45': 'Vällingby',
    '162 46': 'Vällingby',
    '162 47': 'Vällingby',
    '162 50': 'Vällingby',
    '162 51': 'Vällingby',
    '162 52': 'Vällingby',
    '162 53': 'Vällingby',
    '162 54': 'Vällingby',
    '162 55': 'Vällingby',
    '162 56': 'Vällingby',
    '162 57': 'Vällingby',
    '162 58': 'Vällingby',
    '162 60': 'Vällingby',
    '162 61': 'Vällingby',
    '162 62': 'Vällingby',
    '162 63': 'Vällingby',
    '162 64': 'Vällingby',
    '162 65': 'Vällingby',
    '162 66': 'Vällingby',
    '162 67': 'Vällingby',
    '162 68': 'Vällingby',
    '162 70': 'Vällingby',
    '162 71': 'Vällingby',
    '162 72': 'Vällingby',
    '162 83': 'Vällingby',
    '162 87': 'Stockholm',
    '162 88': 'Vällingby',
    '162 89': 'Stockholm',
    '163 00': 'Spånga',
    '163 03': 'Spånga',
    '163 04': 'Spånga',
    '163 05': 'Spånga',
    '163 08': 'Spånga',
    '163 10': 'Spånga',
    '163 21': 'Spånga',
    '163 29': 'Spånga',
    '163 40': 'Spånga',
    '163 41': 'Spånga',
    '163 42': 'Spånga',
    '163 43': 'Spånga',
    '163 44': 'Spånga',
    '163 45': 'Spånga',
    '163 46': 'Spånga',
    '163 47': 'Spånga',
    '163 50': 'Spånga',
    '163 51': 'Spånga',
    '163 52': 'Spånga',
    '163 53': 'Spånga',
    '163 54': 'Spånga',
    '163 55': 'Spånga',
    '163 56': 'Spånga',
    '163 61': 'Spånga',
    '163 62': 'Spånga',
    '163 63': 'Spånga',
    '163 64': 'Spånga',
    '163 65': 'Spånga',
    '163 66': 'Spånga',
    '163 67': 'Spånga',
    '163 68': 'Spånga',
    '163 70': 'Spånga',
    '163 71': 'Spånga',
    '163 72': 'Spånga',
    '163 73': 'Spånga',
    '163 74': 'Spånga',
    '163 75': 'Spånga',
    '163 80': 'Spånga',
    '163 85': 'Spånga',
    '163 89': 'Spånga',
    '163 91': 'Spånga',
    '163 94': 'Stockholm',
    '164 00': 'Kista',
    '164 06': 'Kista',
    '164 07': 'Kista',
    '164 19': 'Kista',
    '164 21': 'Kista',
    '164 22': 'Kista',
    '164 25': 'Kista',
    '164 26': 'Kista',
    '164 28': 'Kista',
    '164 30': 'Kista',
    '164 31': 'Kista',
    '164 32': 'Kista',
    '164 33': 'Kista',
    '164 34': 'Kista',
    '164 35': 'Kista',
    '164 36': 'Kista',
    '164 37': 'Kista',
    '164 38': 'Kista',
    '164 39': 'Kista',
    '164 40': 'Kista',
    '164 41': 'Kista',
    '164 42': 'Kista',
    '164 43': 'Kista',
    '164 44': 'Kista',
    '164 45': 'Kista',
    '164 46': 'Kista',
    '164 47': 'Kista',
    '164 48': 'Kista',
    '164 51': 'Kista',
    '164 52': 'Kista',
    '164 53': 'Kista',
    '164 55': 'Kista',
    '164 71': 'Kista',
    '164 72': 'Kista',
    '164 73': 'Kista',
    '164 74': 'Kista',
    '164 75': 'Kista',
    '164 76': 'Kista',
    '164 77': 'Kista',
    '164 78': 'Kista',
    '164 79': 'Kista',
    '164 80': 'Stockholm',
    '164 81': 'Kista',
    '164 82': 'Stockholm',
    '164 83': 'Stockholm',
    '164 84': 'Stockholm',
    '164 85': 'Stockholm',
    '164 86': 'Stockholm',
    '164 87': 'Kista',
    '164 88': 'Kista',
    '164 91': 'Kista',
    '164 92': 'Stockholm',
    '164 93': 'Kista',
    '164 94': 'Kista',
    '164 96': 'Kista',
    '164 97': 'Kista',
    '165 00': 'Hässelby',
    '165 10': 'Hässelby',
    '165 11': 'Hässelby',
    '165 12': 'Hässelby',
    '165 13': 'Hässelby',
    '165 15': 'Hässelby',
    '165 16': 'Hässelby',
    '165 21': 'Hässelby',
    '165 22': 'Hässelby',
    '165 23': 'Hässelby',
    '165 24': 'Hässelby',
    '165 52': 'Hässelby',
    '165 53': 'Hässelby',
    '165 54': 'Hässelby',
    '165 55': 'Hässelby',
    '165 56': 'Hässelby',
    '165 57': 'Hässelby',
    '165 58': 'Hässelby',
    '165 59': 'Hässelby',
    '165 60': 'Hässelby',
    '165 61': 'Hässelby',
    '165 62': 'Hässelby',
    '165 63': 'Hässelby',
    '165 64': 'Hässelby',
    '165 65': 'Hässelby',
    '165 70': 'Hässelby',
    '165 71': 'Hässelby',
    '165 72': 'Hässelby',
    '165 73': 'Hässelby',
    '165 74': 'Hässelby',
    '165 75': 'Hässelby',
    '165 76': 'Hässelby',
    '165 77': 'Hässelby',
    '167 00': 'Bromma',
    '167 14': 'Bromma',
    '167 15': 'Bromma',
    '167 17': 'Bromma',
    '167 19': 'Bromma',
    '167 21': 'Bromma',
    '167 22': 'Bromma',
    '167 24': 'Bromma',
    '167 31': 'Bromma',
    '167 32': 'Bromma',
    '167 33': 'Bromma',
    '167 35': 'Bromma',
    '167 36': 'Bromma',
    '167 37': 'Bromma',
    '167 38': 'Bromma',
    '167 39': 'Bromma',
    '167 40': 'Bromma',
    '167 41': 'Bromma',
    '167 43': 'Bromma',
    '167 44': 'Bromma',
    '167 45': 'Bromma',
    '167 51': 'Bromma',
    '167 52': 'Bromma',
    '167 53': 'Bromma',
    '167 54': 'Bromma',
    '167 55': 'Bromma',
    '167 56': 'Bromma',
    '167 57': 'Bromma',
    '167 61': 'Bromma',
    '167 62': 'Bromma',
    '167 63': 'Bromma',
    '167 64': 'Bromma',
    '167 65': 'Bromma',
    '167 66': 'Bromma',
    '167 67': 'Bromma',
    '167 71': 'Bromma',
    '167 72': 'Bromma',
    '167 73': 'Bromma',
    '167 74': 'Bromma',
    '167 75': 'Bromma',
    '167 76': 'Bromma',
    '167 80': 'Bromma',
    '167 81': 'Bromma',
    '168 00': 'Bromma',
    '168 10': 'Bromma',
    '168 27': 'Bromma',
    '168 30': 'Bromma',
    '168 31': 'Bromma',
    '168 32': 'Bromma',
    '168 33': 'Bromma',
    '168 34': 'Bromma',
    '168 35': 'Bromma',
    '168 36': 'Bromma',
    '168 37': 'Bromma',
    '168 38': 'Bromma',
    '168 39': 'Bromma',
    '168 40': 'Bromma',
    '168 41': 'Bromma',
    '168 43': 'Bromma',
    '168 44': 'Bromma',
    '168 45': 'Bromma',
    '168 46': 'Bromma',
    '168 47': 'Bromma',
    '168 48': 'Bromma',
    '168 49': 'Bromma',
    '168 50': 'Bromma',
    '168 51': 'Bromma',
    '168 52': 'Bromma',
    '168 53': 'Bromma',
    '168 54': 'Bromma',
    '168 55': 'Bromma',
    '168 56': 'Bromma',
    '168 57': 'Bromma',
    '168 58': 'Bromma',
    '168 59': 'Bromma',
    '168 60': 'Bromma',
    '168 64': 'Bromma',
    '168 65': 'Bromma',
    '168 66': 'Bromma',
    '168 67': 'Bromma',
    '168 68': 'Bromma',
    '168 69': 'Bromma',
    '168 73': 'Bromma',
    '168 74': 'Bromma',
    '168 75': 'Bromma',
    '168 76': 'Bromma',
    '168 77': 'Bromma',
    '168 80': 'Bromma',
    '169 82': 'Stockholm',
    '169 84': 'Stockholm',
    '169 85': 'Stockholm',
    '169 86': 'Stockholm',
    '171 16': 'Stockholm',
    '171 75': 'Stockholm',
    '171 76': 'Stockholm',
    '171 77': 'Stockholm',
    '172 81': 'Stockholm',
    '172 90': 'Stockholm',
    '173 50': 'Stockholm',
    '173 80': 'Stockholm',
    '173 81': 'Stockholm',
    '173 82': 'Stockholm',
    '173 83': 'Stockholm',
    '182 88': 'Stockholm',
    '150 21': 'Mölnbo',
    '150 23': 'Enhörna',
    '151 00': 'Södertälje',
    '151 01': 'Södertälje',
    '151 02': 'Södertälje',
    '151 04': 'Södertälje',
    '151 06': 'Södertälje',
    '151 08': 'Södertälje',
    '151 09': 'Södertälje',
    '151 10': 'Södertälje',
    '151 21': 'Södertälje',
    '151 22': 'Södertälje',
    '151 23': 'Södertälje',
    '151 24': 'Södertälje',
    '151 26': 'Södertälje',
    '151 27': 'Södertälje',
    '151 29': 'Södertälje',
    '151 32': 'Södertälje',
    '151 33': 'Södertälje',
    '151 34': 'Södertälje',
    '151 35': 'Södertälje',
    '151 36': 'Södertälje',
    '151 37': 'Södertälje',
    '151 38': 'Södertälje',
    '151 39': 'Södertälje',
    '151 40': 'Södertälje',
    '151 44': 'Södertälje',
    '151 45': 'Södertälje',
    '151 46': 'Södertälje',
    '151 47': 'Södertälje',
    '151 48': 'Södertälje',
    '151 52': 'Södertälje',
    '151 53': 'Södertälje',
    '151 54': 'Södertälje',
    '151 55': 'Södertälje',
    '151 59': 'Södertälje',
    '151 60': 'Södertälje',
    '151 61': 'Södertälje',
    '151 62': 'Södertälje',
    '151 63': 'Södertälje',
    '151 64': 'Södertälje',
    '151 65': 'Södertälje',
    '151 68': 'Södertälje',
    '151 71': 'Södertälje',
    '151 72': 'Södertälje',
    '151 73': 'Södertälje',
    '151 81': 'Södertälje',
    '151 82': 'Södertälje',
    '151 83': 'Södertälje',
    '151 85': 'Södertälje',
    '151 87': 'Södertälje',
    '151 88': 'Södertälje',
    '151 89': 'Södertälje',
    '151 92': 'Södertälje',
    '152 00': 'Södertälje',
    '152 05': 'Södertälje',
    '152 07': 'Södertälje',
    '152 25': 'Södertälje',
    '152 26': 'Södertälje',
    '152 27': 'Södertälje',
    '152 30': 'Södertälje',
    '152 31': 'Södertälje',
    '152 40': 'Södertälje',
    '152 41': 'Södertälje',
    '152 42': 'Södertälje',
    '152 43': 'Södertälje',
    '152 48': 'Södertälje',
    '152 49': 'Södertälje',
    '152 50': 'Södertälje',
    '152 51': 'Södertälje',
    '152 52': 'Södertälje',
    '152 54': 'Södertälje',
    '152 56': 'Södertälje',
    '152 57': 'Södertälje',
    '152 58': 'Södertälje',
    '152 70': 'Södertälje',
    '152 71': 'Södertälje',
    '152 86': 'Södertälje',
    '152 95': 'Södertälje',
    '152 97': 'Södertälje',
    '153 00': 'Järna',
    '153 07': 'Hölö',
    '153 21': 'Järna',
    '153 22': 'Järna',
    '153 30': 'Järna',
    '153 31': 'Järna',
    '153 32': 'Järna',
    '153 35': 'Järna',
    '153 36': 'Järna',
    '153 37': 'Järna',
    '153 38': 'Järna',
    '153 71': 'Hölö',
    '153 81': 'Järna',
    '153 91': 'Järna',
    '153 92': 'Hölö',
    '153 93': 'Hölö',
    '153 94': 'Hölö',
    '153 95': 'Järna',
    '131 00': 'Nacka',
    '131 02': 'Nacka',
    '131 03': 'Nacka',
    '131 04': 'Nacka',
    '131 05': 'Nacka',
    '131 06': 'Nacka',
    '131 07': 'Nacka',
    '131 08': 'Nacka',
    '131 21': 'Nacka',
    '131 22': 'Nacka',
    '131 23': 'Nacka',
    '131 24': 'Nacka',
    '131 25': 'Nacka',
    '131 26': 'Nacka Strand',
    '131 27': 'Nacka Strand',
    '131 28': 'Nacka Strand',
    '131 30': 'Nacka',
    '131 31': 'Nacka',
    '131 32': 'Nacka',
    '131 33': 'Nacka',
    '131 34': 'Nacka',
    '131 35': 'Nacka',
    '131 36': 'Nacka',
    '131 37': 'Nacka',
    '131 38': 'Nacka',
    '131 39': 'Nacka',
    '131 40': 'Nacka',
    '131 41': 'Nacka',
    '131 42': 'Nacka',
    '131 43': 'Nacka',
    '131 44': 'Nacka',
    '131 45': 'Nacka',
    '131 46': 'Nacka',
    '131 47': 'Nacka',
    '131 48': 'Nacka',
    '131 49': 'Nacka',
    '131 50': 'Saltsjö-Duvnäs',
    '131 52': 'Nacka Strand',
    '131 60': 'Nacka',
    '131 61': 'Nacka',
    '131 65': 'Nacka',
    '131 71': 'Nacka',
    '131 80': 'Nacka Strand',
    '131 81': 'Nacka',
    '131 82': 'Nacka',
    '131 83': 'Nacka',
    '131 86': 'Nacka Strand',
    '131 87': 'Nacka Strand',
    '131 90': 'Nacka Strand',
    '132 00': 'Saltsjö-Boo',
    '132 02': 'Saltsjö-Boo',
    '132 04': 'Saltsjö-Boo',
    '132 21': 'Saltsjö-Boo',
    '132 22': 'Saltsjö-Boo',
    '132 23': 'Saltsjö-Boo',
    '132 25': 'Saltsjö-Boo',
    '132 26': 'Saltsjö-Boo',
    '132 27': 'Saltsjö-Boo',
    '132 30': 'Saltsjö-Boo',
    '132 31': 'Saltsjö-Boo',
    '132 32': 'Saltsjö-Boo',
    '132 33': 'Saltsjö-Boo',
    '132 34': 'Saltsjö-Boo',
    '132 35': 'Saltsjö-Boo',
    '132 36': 'Saltsjö-Boo',
    '132 37': 'Saltsjö-Boo',
    '132 38': 'Saltsjö-Boo',
    '132 39': 'Saltsjö-Boo',
    '132 40': 'Saltsjö-Boo',
    '132 41': 'Saltsjö-Boo',
    '132 42': 'Saltsjö-Boo',
    '132 43': 'Saltsjö-Boo',
    '132 44': 'Saltsjö-Boo',
    '132 45': 'Saltsjö-Boo',
    '132 46': 'Saltsjö-Boo',
    '132 47': 'Saltsjö-Boo',
    '132 48': 'Saltsjö-Boo',
    '132 52': 'Saltsjö-Boo',
    '132 81': 'Saltsjö-Boo',
    '133 00': 'Saltsjöbaden',
    '133 02': 'Saltsjöbaden',
    '133 04': 'Saltsjöbaden',
    '133 21': 'Saltsjöbaden',
    '133 22': 'Saltsjöbaden',
    '133 23': 'Saltsjöbaden',
    '133 31': 'Saltsjöbaden',
    '133 32': 'Saltsjöbaden',
    '133 33': 'Saltsjöbaden',
    '133 34': 'Saltsjöbaden',
    '133 35': 'Saltsjöbaden',
    '133 36': 'Saltsjöbaden',
    '133 37': 'Saltsjöbaden',
    '133 38': 'Saltsjöbaden',
    '133 41': 'Saltsjöbaden',
    '133 42': 'Saltsjöbaden',
    '133 43': 'Saltsjöbaden',
    '133 44': 'Saltsjöbaden',
    '133 48': 'Saltsjöbaden',
    '133 49': 'Saltsjöbaden',
    '133 80': 'Saltsjöbaden',
    '133 81': 'Saltsjöbaden',
    '133 83': 'Saltsjöbaden',
    '138 00': 'Älta',
    '138 21': 'Älta',
    '138 22': 'Älta',
    '138 30': 'Älta',
    '138 31': 'Älta',
    '138 32': 'Älta',
    '138 33': 'Älta',
    '138 34': 'Älta',
    '138 35': 'Älta',
    '138 36': 'Älta',
    '138 37': 'Älta',
    '138 40': 'Älta',
    '172 00': 'Sundbyberg',
    '172 13': 'Sundbyberg',
    '172 21': 'Sundbyberg',
    '172 22': 'Sundbyberg',
    '172 23': 'Sundbyberg',
    '172 24': 'Sundbyberg',
    '172 25': 'Sundbyberg',
    '172 26': 'Sundbyberg',
    '172 27': 'Sundbyberg',
    '172 29': 'Sundbyberg',
    '172 30': 'Sundbyberg',
    '172 31': 'Sundbyberg',
    '172 32': 'Sundbyberg',
    '172 33': 'Sundbyberg',
    '172 34': 'Sundbyberg',
    '172 35': 'Sundbyberg',
    '172 36': 'Sundbyberg',
    '172 37': 'Sundbyberg',
    '172 38': 'Sundbyberg',
    '172 39': 'Sundbyberg',
    '172 40': 'Sundbyberg',
    '172 41': 'Sundbyberg',
    '172 62': 'Sundbyberg',
    '172 63': 'Sundbyberg',
    '172 64': 'Sundbyberg',
    '172 65': 'Sundbyberg',
    '172 66': 'Sundbyberg',
    '172 67': 'Sundbyberg',
    '172 68': 'Sundbyberg',
    '172 69': 'Sundbyberg',
    '172 70': 'Sundbyberg',
    '172 71': 'Sundbyberg',
    '172 72': 'Sundbyberg',
    '172 73': 'Sundbyberg',
    '172 74': 'Sundbyberg',
    '172 75': 'Sundbyberg',
    '172 76': 'Sundbyberg',
    '172 77': 'Sundbyberg',
    '172 78': 'Sundbyberg',
    '172 79': 'Sundbyberg',
    '172 82': 'Sundbyberg',
    '172 84': 'Sundbyberg',
    '172 85': 'Sundbyberg',
    '172 87': 'Sundbyberg',
    '172 88': 'Sundbyberg',
    '172 92': 'Sundbyberg',
    '172 99': 'Sundbyberg',
    '174 00': 'Sundbyberg',
    '174 02': 'Sundbyberg',
    '174 07': 'Sundbyberg',
    '174 13': 'Sundbyberg',
    '174 27': 'Sundbyberg',
    '174 42': 'Sundbyberg',
    '174 43': 'Sundbyberg',
    '174 44': 'Sundbyberg',
    '174 45': 'Sundbyberg',
    '174 46': 'Sundbyberg',
    '174 47': 'Sundbyberg',
    '174 48': 'Sundbyberg',
    '174 49': 'Sundbyberg',
    '174 50': 'Sundbyberg',
    '174 51': 'Sundbyberg',
    '174 52': 'Sundbyberg',
    '174 53': 'Sundbyberg',
    '174 54': 'Sundbyberg',
    '174 55': 'Sundbyberg',
    '174 56': 'Sundbyberg',
    '174 57': 'Sundbyberg',
    '174 58': 'Sundbyberg',
    '174 59': 'Sundbyberg',
    '174 60': 'Sundbyberg',
    '105 04': 'Solna',
    '169 00': 'Solna',
    '169 02': 'Solna',
    '169 03': 'Solna',
    '169 05': 'Solna',
    '169 26': 'Solna',
    '169 27': 'Solna',
    '169 28': 'Solna',
    '169 29': 'Solna',
    '169 30': 'Solna',
    '169 31': 'Solna',
    '169 32': 'Solna',
    '169 33': 'Solna',
    '169 34': 'Solna',
    '169 35': 'Solna',
    '169 36': 'Solna',
    '169 37': 'Solna',
    '169 38': 'Solna',
    '169 39': 'Solna',
    '169 50': 'Solna',
    '169 51': 'Solna',
    '169 52': 'Solna',
    '169 53': 'Solna',
    '169 54': 'Solna',
    '169 55': 'Solna',
    '169 56': 'Solna',
    '169 57': 'Solna',
    '169 58': 'Solna',
    '169 59': 'Solna',
    '169 60': 'Solna',
    '169 61': 'Solna',
    '169 62': 'Solna',
    '169 63': 'Solna',
    '169 64': 'Solna',
    '169 65': 'Solna',
    '169 66': 'Solna',
    '169 67': 'Solna',
    '169 68': 'Solna',
    '169 69': 'Solna',
    '169 70': 'Solna',
    '169 71': 'Solna',
    '169 72': 'Solna',
    '169 73': 'Solna',
    '169 80': 'Solna',
    '169 81': 'Solna',
    '169 83': 'Solna',
    '169 88': 'Solna',
    '170 00': 'Solna',
    '170 07': 'Solna',
    '170 08': 'Solna',
    '170 09': 'Solna',
    '170 63': 'Solna',
    '170 64': 'Solna',
    '170 65': 'Solna',
    '170 66': 'Solna',
    '170 67': 'Solna',
    '170 68': 'Solna',
    '170 70': 'Solna',
    '170 71': 'Solna',
    '170 72': 'Solna',
    '170 73': 'Solna',
    '170 74': 'Solna',
    '170 75': 'Solna',
    '170 76': 'Solna',
    '170 77': 'Solna',
    '170 78': 'Solna',
    '170 79': 'Solna',
    '170 80': 'Solna',
    '170 81': 'Solna',
    '170 82': 'Solna',
    '170 85': 'Solna',
    '171 00': 'Solna',
    '171 01': 'Solna',
    '171 04': 'Solna',
    '171 05': 'Solna',
    '171 06': 'Solna',
    '171 09': 'Solna',
    '171 11': 'Solna',
    '171 13': 'Solna',
    '171 14': 'Solna',
    '171 17': 'Solna',
    '171 18': 'Solna',
    '171 19': 'Solna',
    '171 21': 'Solna',
    '171 22': 'Solna',
    '171 23': 'Solna',
    '171 24': 'Solna',
    '171 25': 'Solna',
    '171 26': 'Solna',
    '171 27': 'Solna',
    '171 28': 'Solna',
    '171 29': 'Solna',
    '171 41': 'Solna',
    '171 42': 'Solna',
    '171 43': 'Solna',
    '171 44': 'Solna',
    '171 45': 'Solna',
    '171 47': 'Solna',
    '171 48': 'Solna',
    '171 49': 'Solna',
    '171 50': 'Solna',
    '171 51': 'Solna',
    '171 52': 'Solna',
    '171 53': 'Solna',
    '171 54': 'Solna',
    '171 55': 'Solna',
    '171 56': 'Solna',
    '171 57': 'Solna',
    '171 58': 'Solna',
    '171 59': 'Solna',
    '171 60': 'Solna',
    '171 61': 'Solna',
    '171 62': 'Solna',
    '171 63': 'Solna',
    '171 64': 'Solna',
    '171 65': 'Solna',
    '171 66': 'Solna',
    '171 67': 'Solna',
    '171 68': 'Solna',
    '171 70': 'Solna',
    '171 71': 'Solna',
    '171 72': 'Solna',
    '171 73': 'Solna',
    '171 74': 'Solna',
    '171 78': 'Solna',
    '171 81': 'Solna',
    '171 82': 'Solna',
    '171 84': 'Solna',
    '171 85': 'Solna',
    '171 86': 'Solna',
    '171 87': 'Solna',
    '171 89': 'Solna',
    '171 90': 'Solna',
    '171 91': 'Solna',
    '171 94': 'Solna',
    '171 95': 'Solna',
    '171 96': 'Solna',
    '171 97': 'Solna',
    '173 00': 'Tomteboda',
    '173 01': 'Tomteboda',
    '173 02': 'Tomteboda',
    '173 05': 'Tomteboda',
    '173 06': 'Tomteboda',
    '173 10': 'Tomteboda',
    '173 13': 'Tomteboda',
    '173 15': 'Tomteboda',
    '173 17': 'Tomteboda',
    '173 18': 'Tomteboda',
    '173 21': 'Tomteboda',
    '173 22': 'Tomteboda',
    '173 85': 'Tomteboda',
    '181 00': 'Lidingö',
    '181 01': 'Lidingö',
    '181 03': 'Lidingö',
    '181 04': 'Lidingö',
    '181 06': 'Lidingö',
    '181 10': 'Lidingö',
    '181 18': 'Lidingö',
    '181 21': 'Lidingö',
    '181 22': 'Lidingö',
    '181 23': 'Lidingö',
    '181 24': 'Lidingö',
    '181 25': 'Lidingö',
    '181 26': 'Lidingö',
    '181 30': 'Lidingö',
    '181 31': 'Lidingö',
    '181 32': 'Lidingö',
    '181 33': 'Lidingö',
    '181 34': 'Lidingö',
    '181 35': 'Lidingö',
    '181 36': 'Lidingö',
    '181 37': 'Lidingö',
    '181 38': 'Lidingö',
    '181 39': 'Lidingö',
    '181 40': 'Lidingö',
    '181 41': 'Lidingö',
    '181 42': 'Lidingö',
    '181 43': 'Lidingö',
    '181 44': 'Lidingö',
    '181 46': 'Lidingö',
    '181 47': 'Lidingö',
    '181 48': 'Lidingö',
    '181 56': 'Lidingö',
    '181 57': 'Lidingö',
    '181 58': 'Lidingö',
    '181 59': 'Lidingö',
    '181 60': 'Lidingö',
    '181 61': 'Lidingö',
    '181 62': 'Lidingö',
    '181 63': 'Lidingö',
    '181 64': 'Lidingö',
    '181 65': 'Lidingö',
    '181 66': 'Lidingö',
    '181 70': 'Lidingö',
    '181 71': 'Lidingö',
    '181 72': 'Lidingö',
    '181 73': 'Lidingö',
    '181 74': 'Lidingö',
    '181 75': 'Lidingö',
    '181 76': 'Lidingö',
    '181 77': 'Lidingö',
    '181 78': 'Lidingö',
    '181 79': 'Lidingö',
    '181 81': 'Lidingö',
    '181 82': 'Lidingö',
    '181 85': 'Lidingö',
    '181 90': 'Lidingö',
    '134 32': 'Gustavsberg',
    '185 00': 'Vaxholm',
    '185 21': 'Vaxholm',
    '185 22': 'Vaxholm',
    '185 23': 'Vaxholm',
    '185 31': 'Vaxholm',
    '185 32': 'Vaxholm',
    '185 33': 'Vaxholm',
    '185 34': 'Vaxholm',
    '185 35': 'Vaxholm',
    '185 37': 'Vaxholm',
    '185 39': 'Vaxholm',
    '185 41': 'Vaxholm',
    '185 51': 'Vaxholm',
    '185 81': 'Vaxholm',
    '185 82': 'Vaxholm',
    '185 83': 'Vaxholm',
    '185 91': 'Vaxholm',
    '185 92': 'Vaxholm',
    '185 93': 'Vaxholm',
    '185 94': 'Vaxholm',
    '185 99': 'Vaxholm',
    '760 10': 'Bergshamra',
    '760 15': 'Gräddö',
    '760 17': 'Blidö',
    '760 18': 'Yxlan',
    '760 19': 'Furusund',
    '760 21': 'Vätö',
    '760 40': 'Väddö',
    '760 41': 'Arholma',
    '760 42': 'Björkö',
    '760 44': 'Barnens Ö',
    '760 45': 'Grisslehamn',
    '760 49': 'Herräng',
    '761 00': 'Norrtälje',
    '761 10': 'Norrtälje',
    '761 21': 'Norrtälje',
    '761 22': 'Norrtälje',
    '761 23': 'Norrtälje',
    '761 24': 'Norrtälje',
    '761 28': 'Norrtälje',
    '761 29': 'Norrtälje',
    '761 30': 'Norrtälje',
    '761 31': 'Norrtälje',
    '761 32': 'Norrtälje',
    '761 40': 'Norrtälje',
    '761 41': 'Norrtälje',
    '761 42': 'Norrtälje',
    '761 43': 'Norrtälje',
    '761 44': 'Norrtälje',
    '761 45': 'Norrtälje',
    '761 46': 'Norrtälje',
    '761 50': 'Norrtälje',
    '761 51': 'Norrtälje',
    '761 52': 'Norrtälje',
    '761 53': 'Norrtälje',
    '761 60': 'Norrtälje',
    '761 61': 'Norrtälje',
    '761 62': 'Norrtälje',
    '761 63': 'Norrtälje',
    '761 64': 'Norrtälje',
    '761 65': 'Norrtälje',
    '761 71': 'Norrtälje',
    '761 72': 'Norrtälje',
    '761 73': 'Norrtälje',
    '761 74': 'Norrtälje',
    '761 75': 'Norrtälje',
    '761 76': 'Norrtälje',
    '761 80': 'Norrtälje',
    '761 84': 'Norrtälje',
    '761 88': 'Norrtälje',
    '761 91': 'Norrtälje',
    '761 92': 'Norrtälje',
    '761 93': 'Norrtälje',
    '761 94': 'Norrtälje',
    '762 00': 'Rimbo',
    '762 21': 'Rimbo',
    '762 22': 'Rimbo',
    '762 23': 'Rimbo',
    '762 31': 'Rimbo',
    '762 41': 'Rimbo',
    '762 51': 'Rimbo',
    '762 61': 'Rimbo',
    '762 71': 'Rimbo',
    '762 91': 'Rimbo',
    '762 92': 'Rimbo',
    '762 93': 'Rimbo',
    '762 94': 'Rimbo',
    '762 95': 'Rimbo',
    '763 00': 'Hallstavik',
    '763 21': 'Hallstavik',
    '763 22': 'Hallstavik',
    '763 23': 'Hallstavik',
    '763 30': 'Hallstavik',
    '763 34': 'Hallstavik',
    '763 35': 'Hallstavik',
    '763 40': 'Hallstavik',
    '763 41': 'Hallstavik',
    '763 42': 'Hallstavik',
    '763 43': 'Hallstavik',
    '763 81': 'Hallstavik',
    '763 82': 'Hallstavik',
    '763 91': 'Hallstavik',
    '763 92': 'Hallstavik',
    '763 93': 'Skebobruk',
    '763 94': 'Hallstavik',
    '137 94': 'Västerhaninge',
    '147 91': 'Grödinge',
    '148 00': 'Ösmo',
    '148 02': 'Sorunda',
    '148 21': 'Ösmo',
    '148 22': 'Ösmo',
    '148 30': 'Ösmo',
    '148 31': 'Ösmo',
    '148 32': 'Ösmo',
    '148 33': 'Ösmo',
    '148 34': 'Ösmo',
    '148 35': 'Ösmo',
    '148 60': 'Stora Vika',
    '148 70': 'Sorunda',
    '148 71': 'Sorunda',
    '148 91': 'Ösmo',
    '148 92': 'Ösmo',
    '148 96': 'Sorunda',
    '148 97': 'Sorunda',
    '149 00': 'Nynäshamn',
    '149 02': 'Nynäshamn',
    '149 21': 'Nynäshamn',
    '149 22': 'Nynäshamn',
    '149 23': 'Nynäshamn',
    '149 25': 'Nynäshamn',
    '149 30': 'Nynäshamn',
    '149 31': 'Nynäshamn',
    '149 32': 'Nynäshamn',
    '149 33': 'Nynäshamn',
    '149 34': 'Nynäshamn',
    '149 35': 'Nynäshamn',
    '149 40': 'Nynäshamn',
    '149 41': 'Nynäshamn',
    '149 43': 'Nynäshamn',
    '149 45': 'Nynäshamn',
    '149 50': 'Nynäshamn',
    '149 51': 'Nynäshamn',
    '149 80': 'Nynäshamn',
    '149 81': 'Nynäshamn',
    '149 82': 'Nynäshamn',
    '149 91': 'Nynäshamn',
    '149 92': 'Nynäshamn',
    '149 95': 'Nynäshamn',
    '101 01': 'Stockholm',
    '101 53': 'Stockholm',
    '101 54': 'Stockholm',
    '101 59': 'Stockholm',
    '102 15': 'Stockholm',
    '102 16': 'Stockholm',
    '102 18': 'Stockholm',
    '103 76': 'Stockholm',
    '103 79': 'Stockholm',
    '104 06': 'Stockholm',
    '105 47': 'Stockholm',
    '105 49': 'Stockholm',
    '105 50': 'Stockholm',
    '105 69': 'Stockholm',
    '105 73': 'Stockholm',
    '105 74': 'Stockholm',
    '106 29': 'Stockholm',
    '106 37': 'Stockholm',
    '106 43': 'Stockholm',
    '106 53': 'Stockholm',
    '106 63': 'Stockholm',
    '107 10': 'Stockholm',
    '107 60': 'Stockholm',
    '107 90': 'Stockholm',
    '109 00': 'Stockholm',
    '109 01': 'Stockholm',
    '109 02': 'Stockholm',
    '109 03': 'Stockholm',
    '109 04': 'Stockholm',
    '109 05': 'Stockholm',
    '109 06': 'Stockholm',
    '109 07': 'Stockholm',
    '109 08': 'Stockholm',
    '109 09': 'Stockholm',
    '109 10': 'Stockholm',
    '109 11': 'Stockholm',
    '109 12': 'Stockholm',
    '109 13': 'Stockholm',
    '109 14': 'Stockholm',
    '109 15': 'Stockholm',
    '109 16': 'Stockholm',
    '109 17': 'Stockholm',
    '109 18': 'Stockholm',
    '109 19': 'Stockholm',
    '109 20': 'Stockholm',
    '109 21': 'Stockholm',
    '109 22': 'Stockholm',
    '109 23': 'Stockholm',
    '109 24': 'Stockholm',
    '109 25': 'Stockholm',
    '109 26': 'Stockholm',
    '109 27': 'Stockholm',
    '109 28': 'Stockholm',
    '109 29': 'Stockholm',
    '109 30': 'Stockholm',
    '109 31': 'Stockholm',
    '109 32': 'Stockholm',
    '109 33': 'Stockholm',
    '109 34': 'Stockholm',
    '109 35': 'Stockholm',
    '109 36': 'Stockholm',
    '109 37': 'Stockholm',
    '109 38': 'Stockholm',
    '109 39': 'Stockholm',
    '109 40': 'Stockholm',
    '109 41': 'Stockholm',
    '109 42': 'Stockholm',
    '109 43': 'Stockholm',
    '109 44': 'Stockholm',
    '109 45': 'Stockholm',
    '109 46': 'Stockholm',
    '109 47': 'Stockholm',
    '109 48': 'Stockholm',
    '109 49': 'Stockholm',
    '109 50': 'Stockholm',
    '109 51': 'Stockholm',
    '109 52': 'Stockholm',
    '109 53': 'Stockholm',
    '109 54': 'Stockholm',
    '109 55': 'Stockholm',
    '109 56': 'Stockholm',
    '109 57': 'Stockholm',
    '109 58': 'Stockholm',
    '109 59': 'Stockholm',
    '109 60': 'Stockholm',
    '109 61': 'Stockholm',
    '109 62': 'Stockholm',
    '109 63': 'Stockholm',
    '109 64': 'Stockholm',
    '109 65': 'Stockholm',
    '109 66': 'Stockholm',
    '109 67': 'Stockholm',
    '109 68': 'Stockholm',
    '109 69': 'Stockholm',
    '109 70': 'Stockholm',
    '109 71': 'Stockholm',
    '109 72': 'Stockholm',
    '109 73': 'Stockholm',
    '109 74': 'Stockholm',
    '109 75': 'Stockholm',
    '109 76': 'Stockholm',
    '109 77': 'Stockholm',
    '109 78': 'Stockholm',
    '109 79': 'Stockholm',
    '109 80': 'Stockholm',
    '109 81': 'Stockholm',
    '109 82': 'Stockholm',
    '109 83': 'Stockholm',
    '109 84': 'Stockholm',
    '109 85': 'Stockholm',
    '109 86': 'Stockholm',
    '109 87': 'Stockholm',
    '109 88': 'Stockholm',
    '109 89': 'Stockholm',
    '109 90': 'Stockholm',
    '109 91': 'Stockholm',
    '109 92': 'Stockholm',
    '109 93': 'Stockholm',
    '109 94': 'Stockholm',
    '109 95': 'Stockholm',
    '109 96': 'Stockholm',
    '109 97': 'Stockholm',
    '109 98': 'Stockholm',
    '109 99': 'Stockholm',
    '110 00': 'Stockholm',
    '110 01': 'Stockholm',
    '110 02': 'Stockholm',
    '110 03': 'Stockholm',
    '110 04': 'Stockholm',
    '110 05': 'Stockholm',
    '110 06': 'Stockholm',
    '110 07': 'Stockholm',
    '110 08': 'Stockholm',
    '110 09': 'Stockholm',
    '110 10': 'Stockholm',
    '110 11': 'Stockholm',
    '110 12': 'Stockholm',
    '110 13': 'Stockholm',
    '110 15': 'Stockholm',
    '110 16': 'Stockholm',
    '110 17': 'Stockholm',
    '110 19': 'Stockholm',
    '110 20': 'Stockholm',
    '110 22': 'Stockholm',
    '110 23': 'Stockholm',
    '110 25': 'Stockholm',
    '110 26': 'Stockholm',
    '110 27': 'Stockholm',
    '110 29': 'Stockholm',
    '110 30': 'Stockholm',
    '110 34': 'Stockholm',
    '110 37': 'Stockholm',
    '110 39': 'Stockholm',
    '110 43': 'Stockholm',
    '110 45': 'Stockholm',
    '110 46': 'Stockholm',
    '110 49': 'Stockholm',
    '110 50': 'Stockholm',
    '110 51': 'Stockholm',
    '110 52': 'Stockholm',
    '110 53': 'Stockholm',
    '110 54': 'Stockholm',
    '110 55': 'Stockholm',
    '110 56': 'Stockholm',
    '110 57': 'Stockholm',
    '110 58': 'Stockholm',
    '110 59': 'Stockholm',
    '110 69': 'Stockholm',
    '110 70': 'Stockholm',
    '110 75': 'Stockholm',
    '110 76': 'Stockholm',
    '110 77': 'Stockholm',
    '110 78': 'Stockholm',
    '110 79': 'Stockholm',
    '110 80': 'Stockholm',
    '110 81': 'Stockholm',
    '110 82': 'Stockholm',
    '110 83': 'Stockholm',
    '110 84': 'Stockholm',
    '110 85': 'Stockholm',
    '110 86': 'Stockholm',
    '110 87': 'Stockholm',
    '110 88': 'Stockholm',
    '110 89': 'Stockholm',
    '110 90': 'Stockholm',
    '110 91': 'Stockholm',
    '110 92': 'Stockholm',
    '110 93': 'Stockholm',
    '110 94': 'Stockholm',
    '110 95': 'Stockholm',
    '110 96': 'Stockholm',
    '110 97': 'Stockholm',
    '110 98': 'Stockholm',
    '110 99': 'Stockholm',
    '112 72': 'Stockholm',
    '112 79': 'Stockholm',
    '112 80': 'Stockholm',
    '113 78': 'Stockholm',
    '113 79': 'Stockholm',
    '114 02': 'Stockholm',
    '114 11': 'Stockholm',
    '114 73': 'Stockholm',
    '114 90': 'Stockholm',
    '115 51': 'Stockholm',
    '115 76': 'Stockholm',
    '115 96': 'Stockholm',
    '115 97': 'Stockholm',
    '116 81': 'Stockholm',
    '117 01': 'Stockholm',
    '117 58': 'Stockholm',
    '117 59': 'Stockholm',
    '117 80': 'Stockholm',
    '117 96': 'Stockholm',
    '118 81': 'Stockholm',
    '120 20': 'Stockholm',
    '120 61': 'Stockholm',
    '120 62': 'Stockholm',
    '120 63': 'Stockholm',
    '120 69': 'Stockholm',
    '120 70': 'Stockholm',
    '120 81': 'Stockholm',
    '121 19': 'Johanneshov',
    '121 20': 'Johanneshov',
    '122 20': 'Enskede',
    '123 20': 'Farsta',
    '123 87': 'Farsta',
    '124 20': 'Bandhagen',
    '125 20': 'Älvsjö',
    '125 45': 'Älvsjö',
    '126 19': 'Stockholm',
    '126 20': 'Stockholm',
    '126 81': 'Stockholm',
    '127 20': 'Skärholmen',
    '128 20': 'Skarpnäck',
    '129 20': 'Hägersten',
    '131 10': 'Stockholm',
    '131 20': 'Nacka',
    '131 56': 'Nacka',
    '131 59': 'Nacka',
    '131 62': 'Nacka',
    '131 72': 'Nacka',
    '131 73': 'Nacka',
    '131 85': 'Stockholm',
    '131 89': 'Stockholm',
    '131 92': 'Nacka',
    '131 93': 'Nacka',
    '132 03': 'Saltsjö-Boo',
    '132 05': 'Saltsjö-Boo',
    '132 85': 'Saltsjö-Boo',
    '133 20': 'Saltsjöbaden',
    '133 39': 'Saltsjöbaden',
    '134 20': 'Gustavsberg',
    '134 39': 'Gustavsberg',
    '135 20': 'Tyresö',
    '136 12': 'Haninge',
    '136 20': 'Haninge',
    '137 20': 'Västerhaninge',
    '137 42': 'Västerhaninge',
    '138 20': 'Älta',
    '139 00': 'Värmdö',
    '139 20': 'Värmdö',
    '139 31': 'Värmdö',
    '141 20': 'Huddinge',
    '142 20': 'Skogås',
    '143 20': 'Vårby',
    '144 20': 'Rönninge',
    '144 52': 'Rönninge',
    '145 20': 'Norsborg',
    '146 20': 'Tullinge',
    '147 20': 'Tumba',
    '147 45': 'Tumba',
    '148 20': 'Ösmo',
    '149 20': 'Nynäshamn',
    '151 13': 'Södertälje',
    '151 20': 'Södertälje',
    '151 56': 'Södertälje',
    '151 84': 'Södertälje',
    '152 20': 'Södertälje',
    '152 28': 'Södertälje',
    '152 59': 'Södertälje',
    '153 20': 'Järna',
    '153 26': 'Järna',
    '155 20': 'Nykvarn',
    '155 35': 'Nykvarn',
    '155 81': 'Nykvarn',
    '161 20': 'Bromma',
    '162 01': 'Vällingby',
    '162 13': 'Vällingby',
    '163 20': 'Spånga',
    '163 88': 'Spånga',
    '164 20': 'Kista',
    '164 23': 'Kista',
    '164 24': 'Kista',
    '164 29': 'Kista',
    '164 90': 'Stockholm',
    '164 95': 'Kista',
    '165 20': 'Hässelby',
    '167 20': 'Bromma',
    '167 58': 'Bromma',
    '168 20': 'Bromma',
    '169 20': 'Solna',
    '169 40': 'Solna',
    '169 74': 'Solna',
    '169 87': 'Stockholm',
    '169 89': 'Solna',
    '170 20': 'Solna',
    '170 84': 'Solna',
    '170 87': 'Stockholm',
    '171 08': 'Solna',
    '171 12': 'Solna',
    '171 20': 'Solna',
    '171 79': 'Solna',
    '171 83': 'Solna',
    '171 88': 'Solna',
    '171 93': 'Solna',
    '171 99': 'Solna',
    '172 05': 'Sundbyberg',
    '172 14': 'Sundbyberg',
    '172 15': 'Sundbyberg',
    '172 20': 'Sundbyberg',
    '173 07': 'Tomteboda',
    '173 08': 'Tomteboda',
    '173 11': 'Stockholm',
    '173 20': 'Tomteboda',
    '173 23': 'Tomteboda',
    '173 24': 'Tomteboda',
    '173 25': 'Tomteboda',
    '173 26': 'Tomteboda',
    '173 27': 'Tomteboda',
    '173 28': 'Tomteboda',
    '173 29': 'Tomteboda',
    '173 30': 'Solna',
    '173 31': 'Solna',
    '173 32': 'Stockholm',
    '173 33': 'Stockholm',
    '173 34': 'Stockholm',
    '173 35': 'Stockholm',
    '173 36': 'Stockholm',
    '173 37': 'Stockholm',
    '173 38': 'Stockholm',
    '173 39': 'Stockholm',
    '173 40': 'Stockholm',
    '173 41': 'Stockholm',
    '173 42': 'Stockholm',
    '173 43': 'Stockholm',
    '173 44': 'Stockholm',
    '173 45': 'Stockholm',
    '173 51': 'Stockholm',
    '173 60': 'Tomteboda',
    '173 86': 'Stockholm',
    '174 20': 'Sundbyberg',
    '174 87': 'Sundbyberg',
    '174 88': 'Sundbyberg',
    '175 20': 'Järfälla',
    '175 72': 'Järfälla',
    '177 00': 'Järfälla',
    '177 20': 'Järfälla',
    '178 17': 'Ekerö',
    '178 20': 'Ekerö',
    '181 05': 'Lidingö',
    '181 20': 'Lidingö',
    '181 86': 'Lidingö',
    '181 87': 'Lidingö',
    '182 20': 'Danderyd',
    '183 10': 'Täby',
    '183 20': 'Täby',
    '183 68': 'Täby',
    '184 20': 'Åkersberga',
    '185 20': 'Vaxholm',
    '186 20': 'Vallentuna',
    '186 47': 'Vallentuna',
    '186 56': 'Vallentuna',
    '187 15': 'Täby',
    '187 20': 'Täby',
    '187 26': 'Täby',
    '187 27': 'Täby',
    '187 28': 'Täby',
    '187 29': 'Täby',
    '187 30': 'Täby',
    '187 31': 'Täby',
    '187 32': 'Täby',
    '187 33': 'Täby',
    '187 34': 'Täby',
    '187 35': 'Täby',
    '187 36': 'Täby',
    '187 37': 'Täby',
    '187 38': 'Täby',
    '187 40': 'Täby',
    '187 41': 'Täby',
    '187 42': 'Täby',
    '187 43': 'Täby',
    '187 44': 'Täby',
    '187 45': 'Täby',
    '187 46': 'Täby',
    '187 50': 'Täby',
    '187 51': 'Täby',
    '187 52': 'Täby',
    '187 53': 'Täby',
    '187 54': 'Täby',
    '187 62': 'Täby',
    '187 63': 'Täby',
    '187 64': 'Täby',
    '187 65': 'Täby',
    '187 66': 'Täby',
    '187 67': 'Täby',
    '187 68': 'Täby',
    '187 69': 'Täby',
    '187 70': 'Täby',
    '187 72': 'Täby',
    '187 73': 'Täby',
    '187 74': 'Täby',
    '187 75': 'Täby',
    '187 76': 'Täby',
    '187 77': 'Täby',
    '187 80': 'Täby',
    '191 00': 'Sollentuna',
    '191 02': 'Sollentuna',
    '191 18': 'Sollentuna',
    '191 20': 'Sollentuna',
    '191 21': 'Sollentuna',
    '191 22': 'Sollentuna',
    '191 23': 'Sollentuna',
    '191 24': 'Sollentuna',
    '191 26': 'Sollentuna',
    '191 27': 'Sollentuna',
    '191 28': 'Sollentuna',
    '191 29': 'Sollentuna',
    '191 31': 'Sollentuna',
    '191 33': 'Sollentuna',
    '191 34': 'Sollentuna',
    '191 35': 'Sollentuna',
    '191 36': 'Sollentuna',
    '191 38': 'Sollentuna',
    '191 39': 'Sollentuna',
    '191 40': 'Sollentuna',
    '191 41': 'Sollentuna',
    '191 42': 'Sollentuna',
    '191 43': 'Sollentuna',
    '191 44': 'Sollentuna',
    '191 45': 'Sollentuna',
    '191 46': 'Sollentuna',
    '191 47': 'Sollentuna',
    '191 49': 'Sollentuna',
    '191 50': 'Sollentuna',
    '191 60': 'Sollentuna',
    '191 61': 'Sollentuna',
    '191 62': 'Sollentuna',
    '191 63': 'Sollentuna',
    '191 81': 'Sollentuna',
    '191 83': 'Sollentuna',
    '191 84': 'Sollentuna',
    '191 85': 'Sollentuna',
    '191 86': 'Sollentuna',
    '191 89': 'Sollentuna',
    '191 90': 'Sollentuna',
    '191 97': 'Sollentuna',
    '192 00': 'Sollentuna',
    '192 05': 'Sollentuna',
    '192 06': 'Sollentuna',
    '192 07': 'Sollentuna',
    '192 08': 'Sollentuna',
    '192 10': 'Sollentuna',
    '192 20': 'Sollentuna',
    '192 21': 'Sollentuna',
    '192 30': 'Sollentuna',
    '192 48': 'Sollentuna',
    '192 51': 'Sollentuna',
    '192 52': 'Sollentuna',
    '192 53': 'Sollentuna',
    '192 54': 'Sollentuna',
    '192 55': 'Sollentuna',
    '192 56': 'Sollentuna',
    '192 57': 'Sollentuna',
    '192 58': 'Sollentuna',
    '192 59': 'Sollentuna',
    '192 66': 'Sollentuna',
    '192 67': 'Sollentuna',
    '192 68': 'Sollentuna',
    '192 69': 'Sollentuna',
    '192 70': 'Sollentuna',
    '192 71': 'Sollentuna',
    '192 72': 'Sollentuna',
    '192 73': 'Sollentuna',
    '192 74': 'Sollentuna',
    '192 75': 'Sollentuna',
    '192 76': 'Sollentuna',
    '192 77': 'Sollentuna',
    '192 78': 'Sollentuna',
    '192 79': 'Sollentuna',
    '192 80': 'Sollentuna',
    '192 81': 'Sollentuna',
    '193 00': 'Sigtuna',
    '193 20': 'Sigtuna',
    '193 21': 'Sigtuna',
    '193 22': 'Sigtuna',
    '193 23': 'Sigtuna',
    '193 24': 'Sigtuna',
    '193 28': 'Sigtuna',
    '193 30': 'Sigtuna',
    '193 31': 'Sigtuna',
    '193 32': 'Sigtuna',
    '193 33': 'Sigtuna',
    '193 34': 'Sigtuna',
    '193 35': 'Sigtuna',
    '193 36': 'Sigtuna',
    '193 40': 'Sigtuna',
    '193 41': 'Sigtuna',
    '193 91': 'Sigtuna',
    '194 00': 'Upplands Väsby',
    '194 02': 'Upplands Väsby',
    '194 04': 'Upplands Väsby',
    '194 05': 'Upplands Väsby',
    '194 18': 'Upplands Väsby',
    '194 20': 'Upplands Väsby',
    '194 21': 'Upplands Väsby',
    '194 22': 'Upplands Väsby',
    '194 23': 'Upplands Väsby',
    '194 26': 'Upplands Väsby',
    '194 27': 'Upplands Väsby',
    '194 28': 'Upplands Väsby',
    '194 29': 'Upplands Väsby',
    '194 30': 'Upplands Väsby',
    '194 31': 'Upplands Väsby',
    '194 32': 'Upplands Väsby',
    '194 33': 'Upplands Väsby',
    '194 34': 'Upplands Väsby',
    '194 35': 'Upplands Väsby',
    '194 36': 'Upplands Väsby',
    '194 37': 'Upplands Väsby',
    '194 38': 'Upplands Väsby',
    '194 39': 'Upplands Väsby',
    '194 40': 'Upplands Väsby',
    '194 41': 'Upplands Väsby',
    '194 42': 'Upplands Väsby',
    '194 43': 'Upplands Väsby',
    '194 44': 'Upplands Väsby',
    '194 45': 'Upplands Väsby',
    '194 46': 'Upplands Väsby',
    '194 47': 'Upplands Väsby',
    '194 51': 'Upplands Väsby',
    '194 52': 'Upplands Väsby',
    '194 53': 'Upplands Väsby',
    '194 54': 'Upplands Väsby',
    '194 55': 'Upplands Väsby',
    '194 56': 'Upplands Väsby',
    '194 57': 'Upplands Väsby',
    '194 60': 'Upplands Väsby',
    '194 61': 'Upplands Väsby',
    '194 62': 'Upplands Väsby',
    '194 63': 'Upplands Väsby',
    '194 64': 'Upplands Väsby',
    '194 65': 'Upplands Väsby',
    '194 66': 'Upplands Väsby',
    '194 67': 'Upplands Väsby',
    '194 68': 'Upplands Väsby',
    '194 71': 'Upplands Väsby',
    '194 72': 'Upplands Väsby',
    '194 73': 'Upplands Väsby',
    '194 74': 'Upplands Väsby',
    '194 75': 'Upplands Väsby',
    '194 76': 'Upplands Väsby',
    '194 77': 'Upplands Väsby',
    '194 78': 'Upplands Väsby',
    '194 79': 'Upplands Väsby',
    '194 80': 'Upplands Väsby',
    '194 81': 'Upplands Väsby',
    '194 82': 'Upplands Väsby',
    '194 83': 'Upplands Väsby',
    '194 84': 'Upplands Väsby',
    '194 86': 'Upplands Väsby',
    '194 87': 'Upplands Väsby',
    '194 88': 'Upplands Väsby',
    '194 89': 'Upplands Väsby',
    '194 91': 'Upplands Väsby',
    '194 92': 'Upplands Väsby',
    '194 98': 'Upplands Väsby',
    '195 00': 'Märsta',
    '195 02': 'Märsta',
    '195 03': 'Märsta',
    '195 04': 'Rosersberg',
    '195 10': 'Märsta',
    '195 20': 'Märsta',
    '195 21': 'Märsta',
    '195 22': 'Märsta',
    '195 23': 'Märsta',
    '195 24': 'Märsta',
    '195 25': 'Märsta',
    '195 30': 'Märsta',
    '195 31': 'Märsta',
    '195 32': 'Märsta',
    '195 33': 'Märsta',
    '195 34': 'Märsta',
    '195 35': 'Märsta',
    '195 36': 'Märsta',
    '195 40': 'Märsta',
    '195 41': 'Märsta',
    '195 42': 'Märsta',
    '195 43': 'Märsta',
    '195 44': 'Märsta',
    '195 45': 'Märsta',
    '195 46': 'Märsta',
    '195 47': 'Märsta',
    '195 50': 'Märsta',
    '195 51': 'Märsta',
    '195 52': 'Märsta',
    '195 53': 'Märsta',
    '195 54': 'Märsta',
    '195 55': 'Märsta',
    '195 56': 'Märsta',
    '195 57': 'Märsta',
    '195 58': 'Märsta',
    '195 70': 'Rosersberg',
    '195 71': 'Rosersberg',
    '195 72': 'Rosersberg',
    '195 80': 'Märsta',
    '195 81': 'Märsta',
    '195 82': 'Märsta',
    '195 85': 'Märsta',
    '195 91': 'Märsta',
    '195 92': 'Märsta',
    '195 93': 'Märsta',
    '195 94': 'Märsta',
    '195 95': 'Rosersberg',
    '195 96': 'Rosersberg',
    '196 00': 'Kungsängen',
    '196 01': 'Kungsängen',
    '196 02': 'Kungsängen',
    '196 20': 'Kungsängen',
    '196 21': 'Kungsängen',
    '196 22': 'Kungsängen',
    '196 23': 'Kungsängen',
    '196 24': 'Kungsängen',
    '196 28': 'Kungsängen',
    '196 30': 'Kungsängen',
    '196 31': 'Kungsängen',
    '196 32': 'Kungsängen',
    '196 33': 'Kungsängen',
    '196 34': 'Kungsängen',
    '196 35': 'Kungsängen',
    '196 36': 'Kungsängen',
    '196 37': 'Kungsängen',
    '196 38': 'Kungsängen',
    '196 40': 'Kungsängen',
    '196 41': 'Kungsängen',
    '196 80': 'Kungsängen',
    '196 81': 'Kungsängen',
    '196 85': 'Kungsängen',
    '196 91': 'Kungsängen',
    '196 92': 'Kungsängen',
    '196 93': 'Kungsängen',
    '197 00': 'Bro',
    '197 20': 'Bro',
    '197 21': 'Bro',
    '197 22': 'Bro',
    '197 25': 'Bro',
    '197 30': 'Bro',
    '197 31': 'Bro',
    '197 32': 'Bro',
    '197 34': 'Bro',
    '197 35': 'Bro',
    '197 36': 'Bro',
    '197 40': 'Bro',
    '197 91': 'Bro',
    '197 92': 'Bro',
    '197 93': 'Bro',
    '761 20': 'Norrtälje',
    '762 20': 'Rimbo',
    '763 20': 'Hallstavik',
    '890 54': 'Trehörningsjö',
    '914 01': 'Nordmaling',
    '914 21': 'Nordmaling',
    '914 22': 'Nordmaling',
    '914 29': 'Rundvik',
    '914 31': 'Nordmaling',
    '914 32': 'Nordmaling',
    '914 33': 'Nordmaling',
    '914 41': 'Rundvik',
    '914 81': 'Nordmaling',
    '914 90': 'Nordmaling',
    '914 91': 'Nordmaling',
    '914 92': 'Lögdeå',
    '914 95': 'Gräsmyr',
    '914 96': 'Håknäs',
    '914 93': 'Norrfors',
    '914 94': 'Nyåker',
    '916 01': 'Bjurholm',
    '916 21': 'Bjurholm',
    '916 31': 'Bjurholm',
    '916 81': 'Bjurholm',
    '916 91': 'Bjurholm',
    '916 92': 'Bjurholm',
    '916 93': 'Bjurholm',
    '922 01': 'Vindeln',
    '922 21': 'Vindeln',
    '922 31': 'Vindeln',
    '922 32': 'Vindeln',
    '922 73': 'Hällnäs',
    '922 75': 'Åmsele',
    '922 81': 'Vindeln',
    '922 82': 'Vindeln',
    '922 91': 'Vindeln',
    '922 92': 'Vindeln',
    '922 93': 'Vindeln',
    '922 94': 'Tvärålund',
    '922 95': 'Granö',
    '915 01': 'Robertsfors',
    '915 21': 'Robertsfors',
    '915 23': 'Robertsfors',
    '915 31': 'Robertsfors',
    '915 32': 'Robertsfors',
    '915 34': 'Ånäset',
    '915 81': 'Robertsfors',
    '915 91': 'Robertsfors',
    '915 92': 'Robertsfors',
    '915 93': 'Robertsfors',
    '915 94': 'Ånäset',
    '915 95': 'Ånäset',
    '915 96': 'Flarken',
    '918 93': 'Bygdeå',
    '918 94': 'Bygdeå',
    '935 01': 'Norsjö',
    '935 21': 'Norsjö',
    '935 31': 'Norsjö',
    '935 32': 'Norsjö',
    '935 81': 'Norsjö',
    '935 91': 'Norsjö',
    '935 92': 'Norsjö',
    '935 93': 'Norsjö',
    '930 70': 'Malå',
    '930 71': 'Rentjärn',
    '930 72': 'Adak',
    '920 51': 'Gunnarn',
    '920 64': 'Tärnaby',
    '920 66': 'Hemavan',
    '923 01': 'Storuman',
    '923 21': 'Storuman',
    '923 22': 'Storuman',
    '923 23': 'Storuman',
    '923 24': 'Storuman',
    '923 26': 'Storuman',
    '923 31': 'Storuman',
    '923 32': 'Storuman',
    '923 41': 'Stensele',
    '923 81': 'Storuman',
    '923 96': 'Barsele',
    '923 97': 'Slussfors',
    '923 98': 'Storuman',
    '923 99': 'Storuman',
    '920 70': 'Sorsele',
    '920 72': 'Blattnicksele',
    '920 73': 'Gargnäs',
    '920 75': 'Ammarnäs',
    '830 81': 'Norråker',
    '917 01': 'Dorotea',
    '917 02': 'Högland',
    '917 03': 'Risbäck',
    '917 04': 'Borgafjäll',
    '917 21': 'Dorotea',
    '917 22': 'Dorotea',
    '917 31': 'Dorotea',
    '917 32': 'Dorotea',
    '917 81': 'Dorotea',
    '917 82': 'Dorotea',
    '917 91': 'Dorotea',
    '917 93': 'Lajksjöberg',
    '917 94': 'Lavsjön',
    '917 95': 'Svanavattnet',
    '917 99': 'Dorotea',
    '911 01': 'Vännäs',
    '911 21': 'Vännäs',
    '911 30': 'Vännäs',
    '911 31': 'Vännäs',
    '911 32': 'Vännäs',
    '911 33': 'Vännäs',
    '911 34': 'Vännäs',
    '911 35': 'Vännäsby',
    '911 36': 'Vännäsby',
    '911 81': 'Vännäs',
    '911 82': 'Vännäs',
    '911 91': 'Vännäs',
    '911 92': 'Vännäs',
    '911 93': 'Vännäs',
    '911 94': 'Vännäs',
    '910 88': 'Marsfjäll',
    '910 94': 'Dikanäs',
    '912 01': 'Vilhelmina',
    '912 21': 'Vilhelmina',
    '912 22': 'Vilhelmina',
    '912 23': 'Vilhelmina',
    '912 24': 'Vilhelmina',
    '912 31': 'Vilhelmina',
    '912 32': 'Vilhelmina',
    '912 33': 'Vilhelmina',
    '912 34': 'Vilhelmina',
    '912 81': 'Vilhelmina',
    '912 90': 'Vilhelmina',
    '912 91': 'Vilhelmina',
    '912 92': 'Vilhelmina',
    '912 93': 'Vilhelmina',
    '912 94': 'Latikberg',
    '912 95': 'Storseleby',
    '912 97': 'Malgomaj',
    '912 98': 'Klimpfjäll',
    '912 99': 'Vilhelmina',
    '895 99': 'Solberg',
    '910 50': 'Fredrika',
    '910 52': 'Långbäcken',
    '910 60': 'Åsele',
    '910 63': 'Hällaström',
    '900 01': 'Umeå',
    '900 02': 'Umeå',
    '901 00': 'Umeå',
    '901 01': 'Umeå',
    '901 02': 'Umeå',
    '901 03': 'Umeå',
    '901 04': 'Umeå',
    '901 05': 'Umeå',
    '901 06': 'Umeå',
    '901 07': 'Umeå',
    '901 08': 'Umeå',
    '901 09': 'Umeå',
    '901 10': 'Umeå',
    '901 19': 'Umeå',
    '901 20': 'Umeå',
    '901 21': 'Umeå',
    '901 22': 'Umeå',
    '901 23': 'Umeå',
    '901 24': 'Umeå',
    '901 25': 'Umeå',
    '901 30': 'Umeå',
    '901 31': 'Umeå',
    '901 32': 'Umeå',
    '901 33': 'Umeå',
    '901 37': 'Umeå',
    '901 70': 'Umeå',
    '901 73': 'Umeå',
    '901 74': 'Umeå',
    '901 75': 'Umeå',
    '901 76': 'Umeå',
    '901 77': 'Umeå',
    '901 78': 'Umeå',
    '901 79': 'Umeå',
    '901 80': 'Umeå',
    '901 81': 'Umeå',
    '901 82': 'Umeå',
    '901 83': 'Umeå',
    '901 84': 'Umeå',
    '901 85': 'Umeå',
    '901 86': 'Umeå',
    '901 87': 'Umeå',
    '901 88': 'Umeå',
    '901 89': 'Umeå',
    '903 00': 'Umeå',
    '903 01': 'Umeå',
    '903 02': 'Umeå',
    '903 03': 'Umeå',
    '903 04': 'Umeå',
    '903 10': 'Umeå',
    '903 11': 'Umeå',
    '903 13': 'Umeå',
    '903 14': 'Umeå',
    '903 20': 'Umeå',
    '903 21': 'Umeå',
    '903 22': 'Umeå',
    '903 23': 'Umeå',
    '903 25': 'Umeå',
    '903 26': 'Umeå',
    '903 27': 'Umeå',
    '903 28': 'Umeå',
    '903 29': 'Umeå',
    '903 30': 'Umeå',
    '903 31': 'Umeå',
    '903 32': 'Umeå',
    '903 33': 'Umeå',
    '903 36': 'Umeå',
    '903 37': 'Umeå',
    '903 38': 'Umeå',
    '903 39': 'Umeå',
    '903 42': 'Umeå',
    '903 43': 'Umeå',
    '903 44': 'Umeå',
    '903 45': 'Umeå',
    '903 46': 'Umeå',
    '903 47': 'Umeå',
    '903 52': 'Umeå',
    '903 53': 'Umeå',
    '903 54': 'Umeå',
    '903 55': 'Umeå',
    '903 60': 'Umeå',
    '903 61': 'Umeå',
    '903 62': 'Umeå',
    '903 63': 'Umeå',
    '903 64': 'Umeå',
    '903 65': 'Umeå',
    '904 00': 'Umeå',
    '904 01': 'Umeå',
    '904 02': 'Umeå',
    '904 03': 'Umeå',
    '904 04': 'Umeå',
    '904 05': 'Umeå',
    '904 06': 'Umeå',
    '904 20': 'Umeå',
    '904 21': 'Umeå',
    '904 22': 'Umeå',
    '904 31': 'Umeå',
    '904 32': 'Umeå',
    '904 33': 'Umeå',
    '904 34': 'Umeå',
    '904 35': 'Umeå',
    '904 36': 'Umeå',
    '904 40': 'Röbäck',
    '904 41': 'Röbäck',
    '905 80': 'Umeå',
    '905 81': 'Umeå',
    '905 82': 'Umeå',
    '905 83': 'Umeå',
    '905 86': 'Umeå',
    '905 87': 'Umeå',
    '905 88': 'Umeå',
    '905 91': 'Umeå',
    '905 92': 'Umeå',
    '905 93': 'Umeå',
    '905 94': 'Umeå',
    '905 95': 'Umeå',
    '905 96': 'Umeå',
    '906 00': 'Umeå',
    '906 02': 'Umeå',
    '906 03': 'Umeå',
    '906 04': 'Umeå',
    '906 05': 'Umeå',
    '906 12': 'Umeå',
    '906 13': 'Umeå',
    '906 15': 'Umeå',
    '906 20': 'Umeå',
    '906 21': 'Umeå',
    '906 22': 'Umeå',
    '906 24': 'Umeå',
    '906 25': 'Umeå',
    '906 26': 'Umeå',
    '906 27': 'Umeå',
    '906 28': 'Umeå',
    '906 29': 'Umeå',
    '906 37': 'Umeå',
    '906 40': 'Umeå',
    '906 41': 'Umeå',
    '906 42': 'Umeå',
    '906 43': 'Umeå',
    '906 51': 'Umeå',
    '906 52': 'Umeå',
    '906 53': 'Umeå',
    '906 54': 'Umeå',
    '906 55': 'Umeå',
    '907 00': 'Umeå',
    '907 02': 'Umeå',
    '907 03': 'Umeå',
    '907 04': 'Umeå',
    '907 12': 'Umeå',
    '907 13': 'Umeå',
    '907 19': 'Umeå',
    '907 30': 'Umeå',
    '907 31': 'Umeå',
    '907 32': 'Umeå',
    '907 33': 'Umeå',
    '907 34': 'Umeå',
    '907 35': 'Umeå',
    '907 36': 'Umeå',
    '907 37': 'Umeå',
    '907 38': 'Umeå',
    '907 40': 'Umeå',
    '907 41': 'Umeå',
    '907 42': 'Umeå',
    '907 43': 'Umeå',
    '907 46': 'Umeå',
    '907 49': 'Umeå',
    '907 50': 'Umeå',
    '907 51': 'Umeå',
    '907 52': 'Umeå',
    '907 88': 'Täfteå',
    '910 20': 'Hörnefors',
    '913 01': 'Holmsund',
    '913 21': 'Holmsund',
    '913 22': 'Holmsund',
    '913 31': 'Holmsund',
    '913 32': 'Holmsund',
    '913 33': 'Holmsund',
    '913 34': 'Holmsund',
    '913 35': 'Holmsund',
    '913 41': 'Obbola',
    '913 42': 'Obbola',
    '913 80': 'Obbola',
    '918 01': 'Sävar',
    '918 03': 'Holmön',
    '918 21': 'Sävar',
    '918 31': 'Sävar',
    '918 32': 'Sävar',
    '918 91': 'Sävar',
    '918 92': 'Bullmark',
    '922 61': 'Tavelsjö',
    '922 62': 'Tavelsjö',
    '922 63': 'Tavelsjö',
    '922 66': 'Tavelsjö',
    '922 67': 'Tavelsjö',
    '922 76': 'Botsmark',
    '922 77': 'Botsmark',
    '916 95': 'Örträsk',
    '920 34': 'Vormsele',
    '920 38': 'Kristineberg',
    '920 39': 'Rökå',
    '920 40': 'Kristineberg',
    '920 41': 'Björksele',
    '920 42': 'Vindelgransele',
    '921 01': 'Lycksele',
    '921 21': 'Lycksele',
    '921 22': 'Lycksele',
    '921 23': 'Lycksele',
    '921 24': 'Lycksele',
    '921 27': 'Lycksele',
    '921 28': 'Lycksele',
    '921 29': 'Hornmyr',
    '921 31': 'Lycksele',
    '921 32': 'Lycksele',
    '921 33': 'Lycksele',
    '921 34': 'Lycksele',
    '921 35': 'Lycksele',
    '921 36': 'Lycksele',
    '921 37': 'Lycksele',
    '921 41': 'Lycksele',
    '921 42': 'Lycksele',
    '921 45': 'Lycksele',
    '921 46': 'Lycksele',
    '921 81': 'Lycksele',
    '921 82': 'Lycksele',
    '921 91': 'Lycksele',
    '921 92': 'Lycksele',
    '921 93': 'Lycksele',
    '921 94': 'Rusksele',
    '921 95': 'Blåviksjön',
    '921 99': 'Lycksele',
    '922 78': 'Botsmark',
    '930 10': 'Lövånger',
    '930 15': 'Bureå',
    '930 27': 'Kalvträsk',
    '930 47': 'Byske',
    '930 52': 'Fällfors',
    '930 55': 'Jörn',
    '930 61': 'Bastuträsk',
    '931 00': 'Skellefteå',
    '931 01': 'Skellefteå',
    '931 02': 'Skellefteå',
    '931 21': 'Skellefteå',
    '931 22': 'Skellefteå',
    '931 23': 'Skellefteå',
    '931 24': 'Skellefteå',
    '931 27': 'Skellefteå',
    '931 28': 'Skellefteå',
    '931 30': 'Skellefteå',
    '931 31': 'Skellefteå',
    '931 32': 'Skellefteå',
    '931 33': 'Skellefteå',
    '931 34': 'Skellefteå',
    '931 35': 'Skellefteå',
    '931 36': 'Skellefteå',
    '931 37': 'Skellefteå',
    '931 38': 'Skellefteå',
    '931 39': 'Skellefteå',
    '931 40': 'Skellefteå',
    '931 41': 'Skellefteå',
    '931 42': 'Skellefteå',
    '931 44': 'Skellefteå',
    '931 45': 'Skellefteå',
    '931 46': 'Skellefteå',
    '931 48': 'Skellefteå',
    '931 49': 'Skellefteå',
    '931 50': 'Skellefteå',
    '931 51': 'Skellefteå',
    '931 52': 'Skellefteå',
    '931 53': 'Skellefteå',
    '931 54': 'Skellefteå',
    '931 55': 'Skellefteå',
    '931 56': 'Skellefteå',
    '931 57': 'Skellefteå',
    '931 58': 'Skellefteå',
    '931 61': 'Skellefteå',
    '931 62': 'Skellefteå',
    '931 63': 'Skellefteå',
    '931 64': 'Skellefteå',
    '931 65': 'Skellefteå',
    '931 70': 'Skellefteå',
    '931 71': 'Skellefteå',
    '931 76': 'Skellefteå',
    '931 77': 'Skellefteå',
    '931 78': 'Skellefteå',
    '931 80': 'Skellefteå',
    '931 85': 'Skellefteå',
    '931 86': 'Skellefteå',
    '931 87': 'Skellefteå',
    '931 91': 'Skellefteå',
    '931 92': 'Skellefteå',
    '931 93': 'Skellefteå',
    '931 94': 'Skellefteå',
    '931 95': 'Skellefteå',
    '931 96': 'Skellefteå',
    '931 97': 'Skellefteå',
    '931 98': 'Skellefteå',
    '931 99': 'Skellefteå',
    '932 01': 'Skelleftehamn',
    '932 21': 'Skelleftehamn',
    '932 31': 'Skelleftehamn',
    '932 32': 'Skelleftehamn',
    '932 33': 'Skelleftehamn',
    '932 34': 'Örviken',
    '932 35': 'Ursviken',
    '932 36': 'Ursviken',
    '932 37': 'Ursviken',
    '932 81': 'Skelleftehamn',
    '932 82': 'Ursviken',
    '932 83': 'Ursviken',
    '934 01': 'Kåge',
    '934 21': 'Kåge',
    '934 31': 'Kåge',
    '934 32': 'Kåge',
    '934 41': 'Ersmark',
    '934 81': 'Ersmark',
    '934 82': 'Ersmark',
    '934 91': 'Kåge',
    '934 92': 'Kåge',
    '934 93': 'Kusmark',
    '934 94': 'Kåge',
    '934 95': 'Kåge',
    '934 96': 'Kåge',
    '936 01': 'Boliden',
    '936 21': 'Boliden',
    '936 31': 'Boliden',
    '936 32': 'Boliden',
    '936 81': 'Boliden',
    '936 91': 'Boliden',
    '936 92': 'Boliden',
    '936 93': 'Boliden',
    '937 01': 'Burträsk',
    '937 21': 'Burträsk',
    '937 31': 'Burträsk',
    '937 32': 'Burträsk',
    '937 33': 'Bygdsiljum',
    '937 80': 'Bygdsiljum',
    '937 91': 'Burträsk',
    '937 92': 'Burträsk',
    '937 93': 'Burträsk',
    '937 94': 'Burträsk',
    '937 95': 'Burträsk',
    '937 96': 'Bygdsiljum',
    '903 40': 'Umeå',
    '906 38': 'Umeå',
    '906 50': 'Umeå',
    '906 60': 'Umeå',
    '907 28': 'Umeå',
    '907 29': 'Umeå',
    '907 39': 'Umeå',
    '908 01': 'Umeå',
    '908 02': 'Umeå',
    '908 03': 'Umeå',
    '908 04': 'Umeå',
    '908 05': 'Umeå',
    '908 06': 'Umeå',
    '908 07': 'Umeå',
    '908 09': 'Umeå',
    '908 10': 'Umeå',
    '908 11': 'Umeå',
    '908 12': 'Umeå',
    '908 13': 'Umeå',
    '908 14': 'Umeå',
    '908 15': 'Umeå',
    '908 50': 'Umeå',
    '911 02': 'Vännäsby',
    '911 20': 'Vännäs',
    '912 20': 'Vilhelmina',
    '913 20': 'Holmsund',
    '914 20': 'Nordmaling',
    '915 20': 'Robertsfors',
    '915 97': 'Bygdeå',
    '915 98': 'Bygdeå',
    '916 20': 'Bjurholm',
    '917 20': 'Dorotea',
    '918 20': 'Sävar',
    '920 37': 'Kristineberg',
    '921 20': 'Lycksele',
    '922 20': 'Vindeln',
    '923 20': 'Storuman',
    '931 20': 'Skellefteå',
    '932 20': 'Skelleftehamn',
    '932 22': 'Ursviken',
    '934 20': 'Kåge',
    '935 20': 'Norsjö',
    '936 20': 'Boliden',
    '937 20': 'Burträsk',
    '930 81': 'Glommersträsk',
    '930 82': 'Abborrträsk',
    '930 86': 'Moskosel',
    '933 01': 'Arvidsjaur',
    '933 21': 'Arvidsjaur',
    '933 22': 'Arvidsjaur',
    '933 27': 'Arvidsjaur',
    '933 31': 'Arvidsjaur',
    '933 32': 'Arvidsjaur',
    '933 33': 'Arvidsjaur',
    '933 34': 'Arvidsjaur',
    '933 81': 'Arvidsjaur',
    '933 82': 'Arvidsjaur',
    '933 83': 'Arvidsjaur',
    '933 91': 'Arvidsjaur',
    '933 99': 'Arvidsjaur',
    '930 89': 'Arjeplog',
    '930 90': 'Arjeplog',
    '930 91': 'Slagnäs',
    '930 92': 'Mellanström',
    '930 93': 'Laisvall',
    '930 95': 'Jäckvik',
    '960 30': 'Vuollerim',
    '960 32': 'Snesudden',
    '960 33': 'Murjek',
    '962 01': 'Jokkmokk',
    '962 02': 'Kvikkjokk',
    '962 03': 'Mattisudden',
    '962 05': 'Kåbdalis',
    '962 21': 'Jokkmokk',
    '962 22': 'Jokkmokk',
    '962 23': 'Jokkmokk',
    '962 24': 'Jokkmokk',
    '962 25': 'Jokkmokk',
    '962 26': 'Jokkmokk',
    '962 31': 'Jokkmokk',
    '962 32': 'Jokkmokk',
    '962 33': 'Jokkmokk',
    '962 85': 'Jokkmokk',
    '962 98': 'Randijaur',
    '962 99': 'Jokkmokk',
    '982 60': 'Porjus',
    '956 01': 'Överkalix',
    '956 21': 'Överkalix',
    '956 22': 'Överkalix',
    '956 23': 'Överkalix',
    '956 31': 'Överkalix',
    '956 32': 'Överkalix',
    '956 81': 'Överkalix',
    '956 91': 'Överkalix',
    '956 92': 'Överkalix',
    '956 93': 'Överkalix',
    '956 99': 'Överkalix',
    '950 40': 'Töre',
    '950 42': 'Morjärv',
    '952 00': 'Kalix',
    '952 04': 'Påläng',
    '952 21': 'Kalix',
    '952 22': 'Kalix',
    '952 23': 'Kalix',
    '952 27': 'Kalix',
    '952 31': 'Kalix',
    '952 32': 'Kalix',
    '952 33': 'Kalix',
    '952 34': 'Kalix',
    '952 35': 'Kalix',
    '952 36': 'Kalix',
    '952 41': 'Kalix',
    '952 42': 'Kalix',
    '952 50': 'Kalix-Nyborg',
    '952 51': 'Kalix',
    '952 61': 'Kalix',
    '952 62': 'Kalix',
    '952 63': 'Kalix',
    '952 70': 'Risögrund',
    '952 71': 'Karlsborgsverken',
    '952 72': 'Sangis',
    '952 81': 'Kalix',
    '952 82': 'Kalix',
    '952 83': 'Karlsborgsverken',
    '952 91': 'Kalix',
    '952 92': 'Kalix',
    '952 93': 'Kalix',
    '952 94': 'Kalix',
    '952 95': 'Båtskärsnäs',
    '952 97': 'Kalix',
    '957 01': 'Övertorneå',
    '957 21': 'Övertorneå',
    '957 22': 'Övertorneå',
    '957 23': 'Juoksengi',
    '957 31': 'Övertorneå',
    '957 32': 'Övertorneå',
    '957 85': 'Övertorneå',
    '957 91': 'Övertorneå',
    '957 92': 'Övertorneå',
    '957 93': 'Pello',
    '957 94': 'Övertorneå',
    '957 95': 'Hedenäset',
    '957 99': 'Övertorneå',
    '980 60': 'Korpilombolo',
    '980 61': 'Tärendö',
    '980 62': 'Junosuando',
    '980 63': 'Kangos',
    '980 65': 'Kainulasjärvi',
    '984 01': 'Pajala',
    '984 21': 'Pajala',
    '984 22': 'Pajala',
    '984 23': 'Pajala',
    '984 28': 'Pajala',
    '984 31': 'Pajala',
    '984 32': 'Pajala',
    '984 85': 'Pajala',
    '984 91': 'Pajala',
    '984 92': 'Pajala',
    '984 94': 'Lovikka',
    '984 95': 'Muodoslompolo',
    '984 99': 'Pajala',
    '956 98': 'Lansjärv',
    '980 41': 'Hakkas',
    '980 42': 'Ullatti',
    '980 45': 'Vettasjärvi',
    '982 01': 'Gällivare',
    '982 03': 'Gällivare',
    '982 04': 'Gällivare',
    '982 05': 'Gällivare',
    '982 06': 'Nattavaara',
    '982 07': 'Nattavaaraby',
    '982 21': 'Gällivare',
    '982 22': 'Gällivare',
    '982 28': 'Gällivare',
    '982 31': 'Gällivare',
    '982 32': 'Gällivare',
    '982 34': 'Gällivare',
    '982 35': 'Gällivare',
    '982 36': 'Gällivare',
    '982 38': 'Gällivare',
    '982 39': 'Gällivare',
    '982 81': 'Gällivare',
    '982 82': 'Gällivare',
    '982 91': 'Gällivare',
    '982 92': 'Gällivare',
    '982 99': 'Gällivare',
    '983 03': 'Koskullskulle',
    '983 21': 'Malmberget',
    '983 22': 'Malmberget',
    '983 25': 'Malmberget',
    '983 28': 'Koskullskulle',
    '983 31': 'Malmberget',
    '983 32': 'Malmberget',
    '983 33': 'Malmberget',
    '983 34': 'Malmberget',
    '983 35': 'Malmberget',
    '983 36': 'Malmberget',
    '983 41': 'Koskullskulle',
    '983 81': 'Malmberget',
    '942 01': 'Älvsbyn',
    '942 21': 'Älvsbyn',
    '942 22': 'Älvsbyn',
    '942 28': 'Älvsbyn',
    '942 31': 'Älvsbyn',
    '942 32': 'Älvsbyn',
    '942 33': 'Älvsbyn',
    '942 34': 'Älvsbyn',
    '942 35': 'Älvsbyn',
    '942 36': 'Älvsbyn',
    '942 81': 'Älvsbyn',
    '942 82': 'Älvsbyn',
    '942 85': 'Älvsbyn',
    '942 91': 'Vistträsk',
    '942 92': 'Älvsbyn',
    '942 93': 'Älvsbyn',
    '942 99': 'Älvsbyn',
    '954 01': 'Gammelstad',
    '954 21': 'Gammelstad',
    '954 22': 'Gammelstad',
    '954 23': 'Gammelstad',
    '954 31': 'Gammelstad',
    '954 32': 'Gammelstad',
    '954 33': 'Gammelstad',
    '954 34': 'Gammelstad',
    '954 35': 'Gammelstad',
    '954 41': 'Södra Sunderbyn',
    '954 42': 'Södra Sunderbyn',
    '955 01': 'Råneå',
    '955 21': 'Råneå',
    '955 22': 'Råneå',
    '955 31': 'Råneå',
    '955 32': 'Råneå',
    '955 91': 'Råneå',
    '955 92': 'Råneå',
    '955 96': 'Vitå',
    '971 00': 'Luleå',
    '971 02': 'Luleå',
    '971 03': 'Luleå',
    '971 04': 'Luleå',
    '971 05': 'Luleå',
    '971 06': 'Luleå',
    '971 07': 'Luleå',
    '971 08': 'Luleå',
    '971 09': 'Luleå',
    '971 10': 'Luleå',
    '971 25': 'Luleå',
    '971 26': 'Luleå',
    '971 27': 'Luleå',
    '971 28': 'Luleå',
    '971 29': 'Luleå',
    '971 71': 'Luleå',
    '971 72': 'Luleå',
    '971 73': 'Luleå',
    '971 74': 'Luleå',
    '971 76': 'Luleå',
    '971 77': 'Luleå',
    '971 79': 'Luleå',
    '971 80': 'Luleå',
    '971 81': 'Luleå',
    '971 82': 'Luleå',
    '971 83': 'Luleå',
    '971 85': 'Luleå',
    '971 86': 'Luleå',
    '971 87': 'Luleå',
    '971 88': 'Luleå',
    '971 89': 'Luleå',
    '972 00': 'Luleå',
    '972 21': 'Luleå',
    '972 22': 'Luleå',
    '972 23': 'Luleå',
    '972 31': 'Luleå',
    '972 32': 'Luleå',
    '972 33': 'Luleå',
    '972 34': 'Luleå',
    '972 35': 'Luleå',
    '972 36': 'Luleå',
    '972 38': 'Luleå',
    '972 39': 'Luleå',
    '972 41': 'Luleå',
    '972 42': 'Luleå',
    '972 45': 'Luleå',
    '972 51': 'Luleå',
    '972 52': 'Luleå',
    '972 53': 'Luleå',
    '972 54': 'Luleå',
    '973 00': 'Luleå',
    '973 18': 'Luleå',
    '973 21': 'Luleå',
    '973 22': 'Luleå',
    '973 23': 'Luleå',
    '973 24': 'Luleå',
    '973 25': 'Luleå',
    '973 27': 'Luleå',
    '973 28': 'Luleå',
    '973 29': 'Luleå',
    '973 31': 'Luleå',
    '973 32': 'Luleå',
    '973 33': 'Luleå',
    '973 34': 'Luleå',
    '973 41': 'Luleå',
    '973 42': 'Luleå',
    '973 43': 'Luleå',
    '973 44': 'Luleå',
    '973 45': 'Luleå',
    '973 46': 'Luleå',
    '973 47': 'Luleå',
    '974 00': 'Luleå',
    '974 21': 'Luleå',
    '974 22': 'Luleå',
    '974 23': 'Luleå',
    '974 32': 'Luleå',
    '974 33': 'Luleå',
    '974 34': 'Luleå',
    '974 35': 'Luleå',
    '974 36': 'Luleå',
    '974 37': 'Luleå',
    '974 38': 'Luleå',
    '974 39': 'Luleå',
    '974 41': 'Luleå',
    '974 42': 'Luleå',
    '974 51': 'Luleå',
    '974 52': 'Luleå',
    '974 53': 'Luleå',
    '974 54': 'Luleå',
    '974 55': 'Luleå',
    '974 99': 'Luleå',
    '975 91': 'Luleå',
    '975 92': 'Luleå',
    '975 93': 'Luleå',
    '975 94': 'Luleå',
    '975 95': 'Luleå',
    '975 96': 'Luleå',
    '975 97': 'Luleå',
    '975 98': 'Luleå',
    '976 01': 'Luleå',
    '976 26': 'Luleå',
    '976 27': 'Luleå',
    '976 31': 'Luleå',
    '976 32': 'Luleå',
    '976 33': 'Luleå',
    '976 34': 'Luleå',
    '977 21': 'Luleå',
    '977 22': 'Luleå',
    '977 51': 'Luleå',
    '977 52': 'Luleå',
    '977 53': 'Luleå',
    '977 54': 'Luleå',
    '977 55': 'Luleå',
    '977 75': 'Luleå',
    '941 01': 'Piteå',
    '941 02': 'Piteå',
    '941 18': 'Piteå',
    '941 21': 'Piteå',
    '941 22': 'Piteå',
    '941 23': 'Piteå',
    '941 24': 'Piteå',
    '941 25': 'Piteå',
    '941 26': 'Piteå',
    '941 28': 'Piteå',
    '941 31': 'Piteå',
    '941 32': 'Piteå',
    '941 33': 'Piteå',
    '941 34': 'Piteå',
    '941 35': 'Piteå',
    '941 36': 'Piteå',
    '941 37': 'Piteå',
    '941 38': 'Piteå',
    '941 39': 'Piteå',
    '941 40': 'Piteå',
    '941 41': 'Piteå',
    '941 42': 'Piteå',
    '941 43': 'Piteå',
    '941 46': 'Piteå',
    '941 47': 'Piteå',
    '941 48': 'Piteå',
    '941 50': 'Piteå',
    '941 51': 'Piteå',
    '941 52': 'Piteå',
    '941 53': 'Piteå',
    '941 61': 'Piteå',
    '941 62': 'Piteå',
    '941 63': 'Piteå',
    '941 64': 'Piteå',
    '941 65': 'Piteå',
    '941 66': 'Piteå',
    '941 80': 'Piteå',
    '941 85': 'Piteå',
    '941 86': 'Piteå',
    '941 87': 'Piteå',
    '941 91': 'Piteå',
    '941 92': 'Arnemark',
    '941 99': 'Piteå',
    '942 03': 'Koler',
    '942 04': 'Storsund',
    '942 05': 'Långträsk',
    '942 94': 'Sikfors',
    '942 98': 'Koler',
    '943 01': 'Öjebyn',
    '943 21': 'Öjebyn',
    '943 22': 'Öjebyn',
    '943 23': 'Öjebyn',
    '943 28': 'Öjebyn',
    '943 31': 'Öjebyn',
    '943 32': 'Öjebyn',
    '943 33': 'Öjebyn',
    '943 35': 'Öjebyn',
    '943 36': 'Öjebyn',
    '944 01': 'Hortlax',
    '944 21': 'Hortlax',
    '944 31': 'Hortlax',
    '944 32': 'Hortlax',
    '944 71': 'Piteå',
    '944 72': 'Piteå',
    '944 73': 'Piteå',
    '944 91': 'Hortlax',
    '944 92': 'Blåsmark',
    '944 93': 'Hemmingsmark',
    '944 94': 'Jävrebyn',
    '945 01': 'Norrfjärden',
    '945 21': 'Norrfjärden',
    '945 23': 'Rosvik',
    '945 31': 'Norrfjärden',
    '945 32': 'Norrfjärden',
    '945 33': 'Rosvik',
    '945 34': 'Rosvik',
    '945 91': 'Norrfjärden',
    '945 92': 'Altersbruk',
    '945 93': 'Sjulsmark',
    '946 31': 'Roknäs',
    '946 40': 'Svensbyn',
    '946 91': 'Roknäs',
    '946 92': 'Lillpite',
    '946 99': 'Roknäs',
    '942 95': 'Vidsel',
    '955 95': 'Niemisel',
    '955 99': 'Råneå',
    '960 24': 'Harads',
    '961 00': 'Boden',
    '961 01': 'Boden',
    '961 03': 'Boden',
    '961 17': 'Boden',
    '961 19': 'Boden',
    '961 21': 'Boden',
    '961 22': 'Boden',
    '961 23': 'Boden',
    '961 28': 'Boden',
    '961 31': 'Boden',
    '961 32': 'Boden',
    '961 33': 'Boden',
    '961 34': 'Boden',
    '961 35': 'Boden',
    '961 36': 'Boden',
    '961 37': 'Boden',
    '961 38': 'Boden',
    '961 39': 'Boden',
    '961 40': 'Boden',
    '961 42': 'Boden',
    '961 43': 'Boden',
    '961 44': 'Boden',
    '961 45': 'Boden',
    '961 46': 'Boden',
    '961 47': 'Boden',
    '961 48': 'Boden',
    '961 49': 'Boden',
    '961 50': 'Boden',
    '961 51': 'Boden',
    '961 61': 'Boden',
    '961 62': 'Boden',
    '961 64': 'Boden',
    '961 67': 'Boden',
    '961 68': 'Boden',
    '961 73': 'Boden',
    '961 74': 'Boden',
    '961 75': 'Boden',
    '961 76': 'Boden',
    '961 77': 'Boden',
    '961 86': 'Boden',
    '961 91': 'Boden',
    '961 92': 'Boden',
    '961 93': 'Boden',
    '961 95': 'Boden',
    '961 96': 'Boden',
    '961 97': 'Gunnarsbyn',
    '961 98': 'Boden',
    '952 96': 'Lappträsk',
    '953 01': 'Haparanda',
    '953 21': 'Haparanda',
    '953 22': 'Haparanda',
    '953 23': 'Haparanda',
    '953 24': 'Haparanda',
    '953 28': 'Haparanda',
    '953 31': 'Haparanda',
    '953 32': 'Haparanda',
    '953 33': 'Haparanda',
    '953 34': 'Haparanda',
    '953 35': 'Haparanda',
    '953 36': 'Haparanda',
    '953 37': 'Haparanda',
    '953 85': 'Haparanda',
    '953 91': 'Haparanda',
    '953 92': 'Haparanda',
    '953 93': 'Karungi',
    '953 94': 'Seskarö',
    '953 95': 'Nikkala',
    '980 10': 'Vittangi',
    '980 13': 'Lannavaara',
    '980 14': 'Övre Soppero',
    '980 16': 'Karesuando',
    '980 20': 'Svappavaara',
    '981 01': 'Kiruna',
    '981 02': 'Kiruna',
    '981 05': 'Kiruna',
    '981 07': 'Abisko',
    '981 21': 'Kiruna',
    '981 22': 'Kiruna',
    '981 23': 'Kiruna',
    '981 24': 'Kiruna',
    '981 28': 'Kiruna',
    '981 29': 'Kiruna',
    '981 31': 'Kiruna',
    '981 32': 'Kiruna',
    '981 33': 'Kiruna',
    '981 34': 'Kiruna',
    '981 35': 'Kiruna',
    '981 36': 'Kiruna',
    '981 37': 'Kiruna',
    '981 38': 'Kiruna',
    '981 39': 'Kiruna',
    '981 40': 'Kiruna',
    '981 41': 'Kiruna',
    '981 42': 'Kiruna',
    '981 43': 'Kiruna',
    '981 44': 'Kiruna',
    '981 45': 'Kiruna',
    '981 80': 'Kiruna',
    '981 81': 'Kiruna',
    '981 84': 'Kiruna',
    '981 85': 'Kiruna',
    '981 86': 'Kiruna',
    '981 87': 'Kiruna',
    '981 91': 'Jukkasjärvi',
    '981 92': 'Kiruna',
    '981 93': 'Björkliden',
    '981 94': 'Riksgränsen',
    '930 87': 'Arjeplog',
    '930 88': 'Arjeplog',
    '933 20': 'Arvidsjaur',
    '941 19': 'Piteå',
    '941 20': 'Piteå',
    '942 20': 'Älvsbyn',
    '943 20': 'Öjebyn',
    '944 20': 'Hortlax',
    '945 20': 'Norrfjärden',
    '952 01': 'Kalix',
    '952 20': 'Kalix',
    '953 20': 'Haparanda',
    '954 20': 'Gammelstad',
    '955 20': 'Råneå',
    '956 20': 'Överkalix',
    '957 20': 'Övertorneå',
    '961 20': 'Boden',
    '962 20': 'Jokkmokk',
    '962 80': 'Jokkmokk',
    '971 70': 'Luleå',
    '971 75': 'Luleå',
    '974 43': 'Luleå',
    '978 00': 'Luleå',
    '978 20': 'Luleå',
    '981 15': 'Kiruna',
    '981 16': 'Kiruna',
    '981 17': 'Kiruna',
    '981 18': 'Kiruna',
    '981 19': 'Kiruna',
    '981 20': 'Kiruna',
    '981 27': 'Kiruna',
    '982 20': 'Gällivare',
    '983 20': 'Malmberget',
    '984 20': 'Pajala',
    '746 01': 'Bålsta',
    '746 21': 'Bålsta',
    '746 22': 'Bålsta',
    '746 23': 'Bålsta',
    '746 24': 'Bålsta',
    '746 25': 'Bålsta',
    '746 26': 'Bålsta',
    '746 27': 'Bålsta',
    '746 29': 'Bålsta',
    '746 30': 'Bålsta',
    '746 31': 'Bålsta',
    '746 32': 'Bålsta',
    '746 33': 'Bålsta',
    '746 34': 'Bålsta',
    '746 35': 'Bålsta',
    '746 36': 'Bålsta',
    '746 37': 'Bålsta',
    '746 38': 'Bålsta',
    '746 39': 'Bålsta',
    '746 40': 'Bålsta',
    '746 41': 'Bålsta',
    '746 50': 'Bålsta',
    '746 51': 'Bålsta',
    '746 52': 'Bålsta',
    '746 80': 'Bålsta',
    '746 91': 'Bålsta',
    '746 92': 'Bålsta',
    '746 93': 'Bålsta',
    '746 94': 'Bålsta',
    '746 95': 'Skokloster',
    '746 96': 'Skokloster',
    '814 00': 'Skutskär',
    '814 21': 'Skutskär',
    '814 22': 'Skutskär',
    '814 23': 'Skutskär',
    '814 24': 'Skutskär',
    '814 25': 'Älvkarleby',
    '814 26': 'Älvkarleby',
    '814 27': 'Älvkarleby',
    '814 30': 'Skutskär',
    '814 31': 'Skutskär',
    '814 32': 'Skutskär',
    '814 40': 'Skutskär',
    '814 41': 'Skutskär',
    '814 42': 'Skutskär',
    '814 70': 'Älvkarleby',
    '814 81': 'Skutskär',
    '814 92': 'Skutskär',
    '814 93': 'Skutskär',
    '814 94': 'Älvkarleby',
    '814 95': 'Älvkarleby',
    '741 01': 'Knivsta',
    '741 21': 'Knivsta',
    '741 22': 'Knivsta',
    '741 23': 'Knivsta',
    '741 24': 'Knivsta',
    '741 25': 'Knivsta',
    '741 30': 'Knivsta',
    '741 31': 'Knivsta',
    '741 40': 'Knivsta',
    '741 41': 'Knivsta',
    '741 42': 'Knivsta',
    '741 43': 'Knivsta',
    '741 44': 'Knivsta',
    '741 71': 'Knivsta',
    '741 72': 'Knivsta',
    '741 73': 'Knivsta',
    '741 75': 'Knivsta',
    '741 80': 'Knivsta',
    '741 90': 'Knivsta',
    '741 91': 'Knivsta',
    '741 92': 'Knivsta',
    '741 93': 'Knivsta',
    '741 94': 'Knivsta',
    '741 95': 'Knivsta',
    '741 96': 'Knivsta',
    '748 02': 'Örbyhus',
    '748 40': 'Örbyhus',
    '748 41': 'Örbyhus',
    '748 42': 'Örbyhus',
    '748 50': 'Tobo',
    '748 94': 'Örbyhus',
    '748 95': 'Örbyhus',
    '748 96': 'Tobo',
    '810 64': 'Karlholmsbruk',
    '815 00': 'Tierp',
    '815 03': 'Månkarbo',
    '815 04': 'Söderfors',
    '815 21': 'Tierp',
    '815 22': 'Tierp',
    '815 23': 'Tierp',
    '815 24': 'Tierp',
    '815 25': 'Tierp',
    '815 26': 'Tierp',
    '815 27': 'Tierp',
    '815 28': 'Tierp',
    '815 36': 'Tierp',
    '815 37': 'Tierp',
    '815 38': 'Tierp',
    '815 40': 'Tierp',
    '815 41': 'Tierp',
    '815 44': 'Tierp',
    '815 45': 'Tierp',
    '815 69': 'Månkarbo',
    '815 75': 'Söderfors',
    '815 76': 'Söderfors',
    '815 80': 'Tierp',
    '815 82': 'Söderfors',
    '815 83': 'Tierp',
    '815 91': 'Tierp',
    '815 92': 'Tierp',
    '815 93': 'Tierp',
    '815 94': 'Tierp',
    '815 95': 'Månkarbo',
    '740 10': 'Almunge',
    '740 11': 'Länna',
    '740 12': 'Knutby',
    '740 20': 'Vänge',
    '740 22': 'Bälinge',
    '740 30': 'Björklinge',
    '741 74': 'Uppsala',
    '743 01': 'Storvreta',
    '743 21': 'Storvreta',
    '743 22': 'Storvreta',
    '743 30': 'Storvreta',
    '743 32': 'Storvreta',
    '743 34': 'Storvreta',
    '743 35': 'Storvreta',
    '743 40': 'Storvreta',
    '743 41': 'Storvreta',
    '743 50': 'Vattholma',
    '743 91': 'Storvreta',
    '743 92': 'Vattholma',
    '743 93': 'Vattholma',
    '743 94': 'Skyttorp',
    '747 93': 'Alunda',
    '747 94': 'Alunda',
    '750 02': 'Uppsala',
    '750 03': 'Uppsala',
    '750 06': 'Uppsala',
    '750 07': 'Uppsala',
    '750 08': 'Uppsala',
    '750 09': 'Uppsala',
    '750 10': 'Uppsala',
    '750 11': 'Uppsala',
    '750 13': 'Uppsala',
    '750 15': 'Uppsala',
    '750 16': 'Uppsala',
    '750 17': 'Uppsala',
    '750 20': 'Uppsala',
    '750 21': 'Uppsala',
    '750 22': 'Uppsala',
    '750 23': 'Uppsala',
    '750 24': 'Uppsala',
    '750 25': 'Uppsala',
    '750 26': 'Uppsala',
    '751 00': 'Uppsala',
    '751 01': 'Uppsala',
    '751 03': 'Uppsala',
    '751 04': 'Uppsala',
    '751 05': 'Uppsala',
    '751 06': 'Uppsala',
    '751 08': 'Uppsala',
    '751 09': 'Uppsala',
    '751 10': 'Uppsala',
    '751 20': 'Uppsala',
    '751 21': 'Uppsala',
    '751 22': 'Uppsala',
    '751 23': 'Uppsala',
    '751 24': 'Uppsala',
    '751 25': 'Uppsala',
    '751 26': 'Uppsala',
    '751 27': 'Uppsala',
    '751 28': 'Uppsala',
    '751 29': 'Uppsala',
    '751 30': 'Uppsala',
    '751 31': 'Uppsala',
    '751 32': 'Uppsala',
    '751 33': 'Uppsala',
    '751 34': 'Uppsala',
    '751 35': 'Uppsala',
    '751 36': 'Uppsala',
    '751 37': 'Uppsala',
    '751 38': 'Uppsala',
    '751 40': 'Uppsala',
    '751 41': 'Uppsala',
    '751 42': 'Uppsala',
    '751 43': 'Uppsala',
    '751 44': 'Uppsala',
    '751 45': 'Uppsala',
    '751 46': 'Uppsala',
    '751 47': 'Uppsala',
    '751 48': 'Uppsala',
    '751 49': 'Uppsala',
    '751 70': 'Uppsala',
    '751 71': 'Uppsala',
    '751 72': 'Uppsala',
    '751 73': 'Uppsala',
    '751 74': 'Uppsala',
    '751 81': 'Uppsala',
    '751 82': 'Uppsala',
    '751 83': 'Uppsala',
    '751 84': 'Uppsala',
    '751 85': 'Uppsala',
    '751 86': 'Uppsala',
    '751 87': 'Uppsala',
    '751 88': 'Uppsala',
    '751 89': 'Uppsala',
    '752 00': 'Uppsala',
    '752 17': 'Uppsala',
    '752 18': 'Uppsala',
    '752 22': 'Uppsala',
    '752 23': 'Uppsala',
    '752 24': 'Uppsala',
    '752 25': 'Uppsala',
    '752 26': 'Uppsala',
    '752 27': 'Uppsala',
    '752 28': 'Uppsala',
    '752 29': 'Uppsala',
    '752 30': 'Uppsala',
    '752 31': 'Uppsala',
    '752 32': 'Uppsala',
    '752 33': 'Uppsala',
    '752 34': 'Uppsala',
    '752 36': 'Uppsala',
    '752 37': 'Uppsala',
    '752 38': 'Uppsala',
    '752 39': 'Uppsala',
    '752 40': 'Uppsala',
    '752 41': 'Uppsala',
    '752 42': 'Uppsala',
    '752 43': 'Uppsala',
    '752 44': 'Uppsala',
    '752 58': 'Uppsala',
    '752 60': 'Uppsala',
    '752 61': 'Uppsala',
    '752 62': 'Uppsala',
    '752 63': 'Uppsala',
    '752 64': 'Uppsala',
    '752 66': 'Uppsala',
    '752 67': 'Uppsala',
    '752 72': 'Uppsala',
    '752 73': 'Uppsala',
    '752 75': 'Uppsala',
    '753 00': 'Uppsala',
    '753 09': 'Uppsala',
    '753 10': 'Uppsala',
    '753 11': 'Uppsala',
    '753 12': 'Uppsala',
    '753 13': 'Uppsala',
    '753 14': 'Uppsala',
    '753 15': 'Uppsala',
    '753 18': 'Uppsala',
    '753 19': 'Uppsala',
    '753 20': 'Uppsala',
    '753 21': 'Uppsala',
    '753 22': 'Uppsala',
    '753 23': 'Uppsala',
    '753 24': 'Uppsala',
    '753 25': 'Uppsala',
    '753 26': 'Uppsala',
    '753 27': 'Uppsala',
    '753 28': 'Uppsala',
    '753 29': 'Uppsala',
    '753 30': 'Uppsala',
    '753 31': 'Uppsala',
    '753 32': 'Uppsala',
    '753 33': 'Uppsala',
    '753 34': 'Uppsala',
    '753 35': 'Uppsala',
    '753 36': 'Uppsala',
    '753 37': 'Uppsala',
    '753 50': 'Uppsala',
    '753 75': 'Uppsala',
    '753 80': 'Uppsala',
    '753 81': 'Uppsala',
    '753 82': 'Uppsala',
    '753 83': 'Uppsala',
    '754 00': 'Uppsala',
    '754 19': 'Uppsala',
    '754 20': 'Uppsala',
    '754 21': 'Uppsala',
    '754 22': 'Uppsala',
    '754 23': 'Uppsala',
    '754 24': 'Uppsala',
    '754 25': 'Uppsala',
    '754 26': 'Uppsala',
    '754 27': 'Uppsala',
    '754 28': 'Uppsala',
    '754 29': 'Uppsala',
    '754 30': 'Uppsala',
    '754 31': 'Uppsala',
    '754 32': 'Uppsala',
    '754 33': 'Uppsala',
    '754 34': 'Uppsala',
    '754 35': 'Uppsala',
    '754 36': 'Uppsala',
    '754 37': 'Uppsala',
    '754 39': 'Uppsala',
    '754 40': 'Uppsala',
    '754 41': 'Uppsala',
    '754 42': 'Uppsala',
    '754 45': 'Uppsala',
    '754 46': 'Uppsala',
    '754 47': 'Uppsala',
    '754 48': 'Uppsala',
    '754 49': 'Uppsala',
    '754 50': 'Uppsala',
    '754 51': 'Uppsala',
    '754 52': 'Uppsala',
    '755 00': 'Uppsala',
    '755 91': 'Uppsala',
    '755 92': 'Uppsala',
    '755 93': 'Uppsala',
    '755 94': 'Uppsala',
    '755 95': 'Uppsala',
    '755 96': 'Uppsala',
    '755 97': 'Uppsala',
    '755 98': 'Uppsala',
    '756 00': 'Uppsala',
    '756 43': 'Uppsala',
    '756 45': 'Uppsala',
    '756 46': 'Uppsala',
    '756 47': 'Uppsala',
    '756 48': 'Uppsala',
    '756 49': 'Uppsala',
    '756 50': 'Uppsala',
    '756 51': 'Uppsala',
    '756 52': 'Uppsala',
    '756 53': 'Uppsala',
    '756 54': 'Uppsala',
    '756 55': 'Uppsala',
    '757 00': 'Uppsala',
    '757 52': 'Uppsala',
    '757 53': 'Uppsala',
    '757 54': 'Uppsala',
    '757 55': 'Uppsala',
    '757 56': 'Uppsala',
    '757 57': 'Uppsala',
    '757 58': 'Uppsala',
    '757 59': 'Uppsala',
    '759 00': 'Uppsala',
    '760 31': 'Edsbro',
    '762 96': 'Rånäs',
    '740 81': 'Grillby',
    '740 82': 'Örsundsbro',
    '745 00': 'Enköping',
    '745 02': 'Enköping',
    '745 03': 'Enköping',
    '745 10': 'Enköping',
    '745 21': 'Enköping',
    '745 22': 'Enköping',
    '745 23': 'Enköping',
    '745 25': 'Enköping',
    '745 26': 'Enköping',
    '745 27': 'Enköping',
    '745 28': 'Enköping',
    '745 30': 'Enköping',
    '745 31': 'Enköping',
    '745 32': 'Enköping',
    '745 33': 'Enköping',
    '745 34': 'Enköping',
    '745 35': 'Enköping',
    '745 36': 'Enköping',
    '745 37': 'Enköping',
    '745 38': 'Enköping',
    '745 39': 'Enköping',
    '745 40': 'Enköping',
    '745 41': 'Enköping',
    '745 42': 'Enköping',
    '745 44': 'Enköping',
    '745 45': 'Enköping',
    '745 47': 'Enköping',
    '745 60': 'Enköping',
    '745 61': 'Enköping',
    '745 62': 'Enköping',
    '745 63': 'Enköping',
    '745 71': 'Enköping',
    '745 80': 'Enköping',
    '745 82': 'Enköping',
    '745 84': 'Enköping',
    '745 85': 'Enköping',
    '745 88': 'Enköping',
    '745 91': 'Enköping',
    '745 92': 'Enköping',
    '745 93': 'Enköping',
    '745 94': 'Enköping',
    '745 95': 'Enköping',
    '745 96': 'Enköping',
    '745 97': 'Enköping',
    '745 98': 'Enköping',
    '745 99': 'Enköping',
    '740 71': 'Öregrund',
    '742 00': 'Östhammar',
    '742 01': 'Östhammar',
    '742 03': 'Östhammar',
    '742 21': 'Östhammar',
    '742 31': 'Östhammar',
    '742 33': 'Östhammar',
    '742 34': 'Östhammar',
    '742 35': 'Östhammar',
    '742 36': 'Östhammar',
    '742 50': 'Hargshamn',
    '742 91': 'Östhammar',
    '742 92': 'Östhammar',
    '742 93': 'Östhammar',
    '742 94': 'Östhammar',
    '742 95': 'Hargshamn',
    '747 00': 'Alunda',
    '747 02': 'Gimo',
    '747 21': 'Alunda',
    '747 30': 'Alunda',
    '747 31': 'Alunda',
    '747 32': 'Alunda',
    '747 40': 'Gimo',
    '747 41': 'Gimo',
    '747 42': 'Gimo',
    '747 43': 'Gimo',
    '747 44': 'Gimo',
    '747 80': 'Gimo',
    '747 91': 'Alunda',
    '747 92': 'Alunda',
    '747 95': 'Gimo',
    '748 01': 'Österbybruk',
    '748 21': 'Österbybruk',
    '748 22': 'Österbybruk',
    '748 30': 'Österbybruk',
    '748 31': 'Österbybruk',
    '748 32': 'Österbybruk',
    '748 91': 'Österbybruk',
    '748 92': 'Österbybruk',
    '748 93': 'Österbybruk',
    '810 65': 'Skärplinge',
    '741 20': 'Knivsta',
    '741 45': 'Knivsta',
    '742 19': 'Hargshamn',
    '742 20': 'Östhammar',
    '742 32': 'Östhammar',
    '743 19': 'Vattholma',
    '743 20': 'Storvreta',
    '745 20': 'Enköping',
    '745 81': 'Enköping',
    '745 83': 'Enköping',
    '745 86': 'Enköping',
    '746 20': 'Bålsta',
    '747 19': 'Gimo',
    '747 20': 'Alunda',
    '748 18': 'Tobo',
    '748 19': 'Örbyhus',
    '748 20': 'Österbybruk',
    '749 06': 'Örsundsbro',
    '749 35': 'Enköping',
    '749 40': 'Enköping',
    '749 41': 'Enköping',
    '749 42': 'Enköping',
    '749 44': 'Enköping',
    '749 45': 'Enköping',
    '749 46': 'Enköping',
    '749 47': 'Enköping',
    '749 48': 'Enköping',
    '749 49': 'Enköping',
    '749 52': 'Grillby',
    '749 60': 'Örsundsbro',
    '749 61': 'Örsundsbro',
    '749 62': 'Örsundsbro',
    '749 81': 'Enköping',
    '750 04': 'Uppsala',
    '752 19': 'Uppsala',
    '752 20': 'Uppsala',
    '752 65': 'Uppsala',
    '752 68': 'Uppsala',
    '752 71': 'Uppsala',
    '753 08': 'Uppsala',
    '753 16': 'Uppsala',
    '753 17': 'Uppsala',
    '754 18': 'Uppsala',
    '754 43': 'Uppsala',
    '754 53': 'Uppsala',
    '754 54': 'Uppsala',
    '756 42': 'Uppsala',
    '758 00': 'Uppsala',
    '758 01': 'Uppsala',
    '758 02': 'Uppsala',
    '758 03': 'Uppsala',
    '758 04': 'Uppsala',
    '758 06': 'Uppsala',
    '758 08': 'Uppsala',
    '758 09': 'Uppsala',
    '758 10': 'Uppsala',
    '758 13': 'Uppsala',
    '758 15': 'Uppsala',
    '758 16': 'Uppsala',
    '758 22': 'Uppsala',
    '758 23': 'Uppsala',
    '758 24': 'Uppsala',
    '758 25': 'Uppsala',
    '758 26': 'Uppsala',
    '758 52': 'Uppsala',
    '758 53': 'Uppsala',
    '758 54': 'Uppsala',
    '758 55': 'Uppsala',
    '758 56': 'Uppsala',
    '758 57': 'Uppsala',
    '758 71': 'Uppsala',
    '758 72': 'Uppsala',
    '758 73': 'Uppsala',
    '814 20': 'Skutskär',
    '815 19': 'Söderfors',
    '815 20': 'Tierp',
    '815 35': 'Tierp',
    '815 81': 'Tierp',
    '643 01': 'Vingåker',
    '643 21': 'Vingåker',
    '643 22': 'Vingåker',
    '643 30': 'Vingåker',
    '643 31': 'Vingåker',
    '643 32': 'Vingåker',
    '643 33': 'Vingåker',
    '643 80': 'Vingåker',
    '643 91': 'Vingåker',
    '643 92': 'Vingåker',
    '643 93': 'Vingåker',
    '643 94': 'Vingåker',
    '643 95': 'Vingåker',
    '646 01': 'Gnesta',
    '646 21': 'Gnesta',
    '646 22': 'Gnesta',
    '646 23': 'Gnesta',
    '646 30': 'Gnesta',
    '646 31': 'Gnesta',
    '646 32': 'Gnesta',
    '646 33': 'Gnesta',
    '646 34': 'Gnesta',
    '646 35': 'Gnesta',
    '646 80': 'Gnesta',
    '646 91': 'Gnesta',
    '646 92': 'Gnesta',
    '646 93': 'Gnesta',
    '610 53': 'Enstaberga',
    '610 54': 'Nävekvarn',
    '610 55': 'Stigtomta',
    '610 56': 'Vrena',
    '611 00': 'Nyköping',
    '611 01': 'Nyköping',
    '611 02': 'Nyköping',
    '611 03': 'Nyköping',
    '611 04': 'Nyköping',
    '611 05': 'Nyköping',
    '611 10': 'Nyköping',
    '611 22': 'Nyköping',
    '611 23': 'Nyköping',
    '611 24': 'Nyköping',
    '611 25': 'Nyköping',
    '611 26': 'Nyköping',
    '611 27': 'Nyköping',
    '611 29': 'Nyköping',
    '611 30': 'Nyköping',
    '611 31': 'Nyköping',
    '611 32': 'Nyköping',
    '611 33': 'Nyköping',
    '611 34': 'Nyköping',
    '611 35': 'Nyköping',
    '611 36': 'Nyköping',
    '611 37': 'Nyköping',
    '611 38': 'Nyköping',
    '611 39': 'Nyköping',
    '611 44': 'Nyköping',
    '611 45': 'Nyköping',
    '611 46': 'Nyköping',
    '611 50': 'Nyköping',
    '611 55': 'Nyköping',
    '611 56': 'Nyköping',
    '611 57': 'Nyköping',
    '611 60': 'Nyköping',
    '611 61': 'Nyköping',
    '611 62': 'Nyköping',
    '611 63': 'Nyköping',
    '611 65': 'Nyköping',
    '611 66': 'Nyköping',
    '611 79': 'Nyköping',
    '611 80': 'Nyköping',
    '611 81': 'Nyköping',
    '611 82': 'Nyköping',
    '611 83': 'Nyköping',
    '611 84': 'Nyköping',
    '611 85': 'Nyköping',
    '611 86': 'Nyköping',
    '611 88': 'Nyköping',
    '611 89': 'Nyköping',
    '611 91': 'Nyköping',
    '611 92': 'Nyköping',
    '611 93': 'Nyköping',
    '611 94': 'Nyköping',
    '611 95': 'Nyköping',
    '640 50': 'Björnlunda',
    '613 00': 'Oxelösund',
    '613 10': 'Oxelösund',
    '613 11': 'Oxelösund',
    '613 21': 'Oxelösund',
    '613 22': 'Oxelösund',
    '613 23': 'Oxelösund',
    '613 24': 'Oxelösund',
    '613 30': 'Oxelösund',
    '613 31': 'Oxelösund',
    '613 32': 'Oxelösund',
    '613 33': 'Oxelösund',
    '613 35': 'Oxelösund',
    '613 36': 'Oxelösund',
    '613 37': 'Oxelösund',
    '613 38': 'Oxelösund',
    '613 40': 'Oxelösund',
    '613 41': 'Oxelösund',
    '613 51': 'Oxelösund',
    '613 80': 'Oxelösund',
    '613 81': 'Oxelösund',
    '640 31': 'Mellösa',
    '640 34': 'Sparreholm',
    '640 51': 'Stjärnhov',
    '642 01': 'Flen',
    '642 21': 'Flen',
    '642 22': 'Flen',
    '642 23': 'Flen',
    '642 24': 'Flen',
    '642 28': 'Flen',
    '642 31': 'Flen',
    '642 32': 'Flen',
    '642 33': 'Flen',
    '642 34': 'Flen',
    '642 35': 'Flen',
    '642 36': 'Flen',
    '642 37': 'Flen',
    '642 81': 'Flen',
    '642 82': 'Flen',
    '642 83': 'Flen',
    '642 84': 'Flen',
    '642 91': 'Flen',
    '642 92': 'Flen',
    '642 93': 'Flen',
    '642 94': 'Flen',
    '642 95': 'Flen',
    '618 95': 'Stavsjö',
    '640 20': 'Björkvik',
    '640 23': 'Valla',
    '640 24': 'Sköldinge',
    '640 30': 'Hälleforsnäs',
    '640 33': 'Bettna',
    '641 01': 'Katrineholm',
    '641 02': 'Katrineholm',
    '641 03': 'Katrineholm',
    '641 21': 'Katrineholm',
    '641 22': 'Katrineholm',
    '641 23': 'Katrineholm',
    '641 27': 'Katrineholm',
    '641 29': 'Katrineholm',
    '641 30': 'Katrineholm',
    '641 31': 'Katrineholm',
    '641 32': 'Katrineholm',
    '641 33': 'Katrineholm',
    '641 34': 'Katrineholm',
    '641 35': 'Katrineholm',
    '641 36': 'Katrineholm',
    '641 37': 'Katrineholm',
    '641 39': 'Katrineholm',
    '641 45': 'Katrineholm',
    '641 46': 'Katrineholm',
    '641 47': 'Katrineholm',
    '641 48': 'Katrineholm',
    '641 49': 'Katrineholm',
    '641 50': 'Katrineholm',
    '641 51': 'Katrineholm',
    '641 52': 'Katrineholm',
    '641 53': 'Katrineholm',
    '641 80': 'Katrineholm',
    '641 81': 'Katrineholm',
    '641 82': 'Katrineholm',
    '641 91': 'Katrineholm',
    '641 92': 'Katrineholm',
    '641 93': 'Katrineholm',
    '641 94': 'Katrineholm',
    '641 95': 'Katrineholm',
    '641 96': 'Katrineholm',
    '641 97': 'Katrineholm',
    '630 03': 'Eskilstuna',
    '630 04': 'Eskilstuna',
    '630 05': 'Eskilstuna',
    '630 06': 'Eskilstuna',
    '630 07': 'Eskilstuna',
    '630 08': 'Eskilstuna',
    '630 09': 'Skogstorp',
    '630 12': 'Eskilstuna',
    '630 14': 'Eskilstuna',
    '631 01': 'Eskilstuna',
    '631 02': 'Eskilstuna',
    '631 03': 'Eskilstuna',
    '631 04': 'Eskilstuna',
    '631 05': 'Eskilstuna',
    '631 06': 'Eskilstuna',
    '631 07': 'Eskilstuna',
    '631 08': 'Eskilstuna',
    '631 80': 'Eskilstuna',
    '631 81': 'Eskilstuna',
    '631 84': 'Eskilstuna',
    '631 85': 'Eskilstuna',
    '631 86': 'Eskilstuna',
    '631 87': 'Eskilstuna',
    '631 88': 'Eskilstuna',
    '631 89': 'Eskilstuna',
    '631 93': 'Eskilstuna',
    '631 94': 'Eskilstuna',
    '632 01': 'Eskilstuna',
    '632 20': 'Eskilstuna',
    '632 21': 'Eskilstuna',
    '632 22': 'Eskilstuna',
    '632 23': 'Eskilstuna',
    '632 24': 'Eskilstuna',
    '632 25': 'Eskilstuna',
    '632 26': 'Eskilstuna',
    '632 27': 'Eskilstuna',
    '632 28': 'Eskilstuna',
    '632 29': 'Eskilstuna',
    '632 30': 'Eskilstuna',
    '632 31': 'Eskilstuna',
    '632 32': 'Eskilstuna',
    '632 33': 'Eskilstuna',
    '632 34': 'Eskilstuna',
    '632 35': 'Eskilstuna',
    '632 36': 'Eskilstuna',
    '632 37': 'Eskilstuna',
    '632 39': 'Eskilstuna',
    '633 01': 'Eskilstuna',
    '633 40': 'Eskilstuna',
    '633 41': 'Eskilstuna',
    '633 42': 'Eskilstuna',
    '633 43': 'Eskilstuna',
    '633 44': 'Eskilstuna',
    '633 45': 'Eskilstuna',
    '633 46': 'Eskilstuna',
    '633 47': 'Eskilstuna',
    '633 48': 'Eskilstuna',
    '633 49': 'Eskilstuna',
    '633 50': 'Eskilstuna',
    '633 51': 'Eskilstuna',
    '633 52': 'Eskilstuna',
    '633 53': 'Eskilstuna',
    '633 54': 'Eskilstuna',
    '633 55': 'Eskilstuna',
    '633 56': 'Eskilstuna',
    '633 57': 'Eskilstuna',
    '633 58': 'Eskilstuna',
    '633 59': 'Eskilstuna',
    '633 62': 'Eskilstuna',
    '633 69': 'Skogstorp',
    '633 70': 'Hållsta',
    '634 01': 'Eskilstuna',
    '635 01': 'Eskilstuna',
    '635 02': 'Eskilstuna',
    '635 03': 'Eskilstuna',
    '635 04': 'Eskilstuna',
    '635 05': 'Eskilstuna',
    '635 06': 'Eskilstuna',
    '635 07': 'Eskilstuna',
    '635 08': 'Eskilstuna',
    '635 09': 'Eskilstuna',
    '635 10': 'Eskilstuna',
    '635 11': 'Eskilstuna',
    '635 12': 'Eskilstuna',
    '635 13': 'Eskilstuna',
    '635 14': 'Eskilstuna',
    '635 17': 'Näshulta',
    '635 18': 'Husby-Rekarne',
    '635 19': 'Eskilstuna',
    '635 20': 'Eskilstuna',
    '640 25': 'Julita',
    '640 40': 'Stora Sundby',
    '640 43': 'Ärla',
    '644 01': 'Torshälla',
    '644 21': 'Torshälla',
    '644 22': 'Torshälla',
    '644 30': 'Torshälla',
    '644 31': 'Torshälla',
    '644 32': 'Torshälla',
    '644 33': 'Torshälla',
    '644 34': 'Torshälla',
    '644 35': 'Torshälla',
    '644 36': 'Torshälla',
    '644 80': 'Torshälla',
    '640 32': 'Malmköping',
    '640 60': 'Åkers Styckebruk',
    '640 61': 'Stallarholmen',
    '645 01': 'Strängnäs',
    '645 02': 'Strängnäs',
    '645 21': 'Strängnäs',
    '645 22': 'Strängnäs',
    '645 23': 'Strängnäs',
    '645 24': 'Strängnäs',
    '645 25': 'Strängnäs',
    '645 30': 'Strängnäs',
    '645 31': 'Strängnäs',
    '645 32': 'Strängnäs',
    '645 33': 'Strängnäs',
    '645 34': 'Strängnäs',
    '645 40': 'Strängnäs',
    '645 41': 'Strängnäs',
    '645 42': 'Strängnäs',
    '645 43': 'Strängnäs',
    '645 44': 'Strängnäs',
    '645 47': 'Strängnäs',
    '645 50': 'Strängnäs',
    '645 51': 'Strängnäs',
    '645 59': 'Strängnäs',
    '645 80': 'Strängnäs',
    '645 82': 'Strängnäs',
    '645 83': 'Strängnäs',
    '645 84': 'Strängnäs',
    '645 87': 'Strängnäs',
    '645 91': 'Strängnäs',
    '645 92': 'Strängnäs',
    '645 93': 'Strängnäs',
    '645 94': 'Strängnäs',
    '647 01': 'Mariefred',
    '647 21': 'Mariefred',
    '647 22': 'Mariefred',
    '647 23': 'Mariefred',
    '647 24': 'Mariefred',
    '647 30': 'Mariefred',
    '647 31': 'Mariefred',
    '647 32': 'Mariefred',
    '647 35': 'Mariefred',
    '647 81': 'Mariefred',
    '647 91': 'Mariefred',
    '647 92': 'Mariefred',
    '647 93': 'Mariefred',
    '610 60': 'Tystberga',
    '610 70': 'Vagnhärad',
    '610 71': 'Vagnhärad',
    '610 72': 'Vagnhärad',
    '610 73': 'Vagnhärad',
    '610 74': 'Vagnhärad',
    '610 75': 'Västerljung',
    '619 01': 'Trosa',
    '619 21': 'Trosa',
    '619 22': 'Trosa',
    '619 23': 'Trosa',
    '619 30': 'Trosa',
    '619 31': 'Trosa',
    '619 32': 'Trosa',
    '619 33': 'Trosa',
    '619 34': 'Trosa',
    '619 35': 'Trosa',
    '619 80': 'Trosa',
    '619 91': 'Trosa',
    '619 92': 'Trosa',
    '611 20': 'Nyköping',
    '613 20': 'Oxelösund',
    '619 20': 'Trosa',
    '631 00': 'Eskilstuna',
    '631 92': 'Eskilstuna',
    '638 01': 'Eskilstuna',
    '638 02': 'Eskilstuna',
    '638 03': 'Eskilstuna',
    '638 05': 'Eskilstuna',
    '638 07': 'Eskilstuna',
    '638 08': 'Eskilstuna',
    '638 09': 'Eskilstuna',
    '638 10': 'Eskilstuna',
    '638 11': 'Eskilstuna',
    '638 12': 'Eskilstuna',
    '638 14': 'Eskilstuna',
    '638 21': 'Eskilstuna',
    '641 20': 'Katrineholm',
    '642 20': 'Flen',
    '643 20': 'Vingåker',
    '644 20': 'Torshälla',
    '645 20': 'Strängnäs',
    '645 35': 'Strängnäs',
    '646 20': 'Gnesta',
    '647 20': 'Mariefred',
    '599 01': 'Ödeshög',
    '599 21': 'Ödeshög',
    '599 22': 'Ödeshög',
    '599 23': 'Ödeshög',
    '599 31': 'Ödeshög',
    '599 32': 'Ödeshög',
    '599 33': 'Ödeshög',
    '599 80': 'Ödeshög',
    '599 91': 'Ödeshög',
    '599 92': 'Ödeshög',
    '599 93': 'Ödeshög',
    '599 94': 'Ödeshög',
    '570 60': 'Österbymo',
    '590 10': 'Boxholm',
    '590 11': 'Boxholm',
    '590 12': 'Boxholm',
    '590 13': 'Boxholm',
    '590 14': 'Boxholm',
    '590 15': 'Boxholm',
    '590 16': 'Boxholm',
    '597 00': 'Åtvidaberg',
    '597 21': 'Åtvidaberg',
    '597 22': 'Åtvidaberg',
    '597 23': 'Åtvidaberg',
    '597 24': 'Åtvidaberg',
    '597 25': 'Åtvidaberg',
    '597 26': 'Åtvidaberg',
    '597 30': 'Åtvidaberg',
    '597 31': 'Åtvidaberg',
    '597 32': 'Åtvidaberg',
    '597 40': 'Åtvidaberg',
    '597 41': 'Åtvidaberg',
    '597 42': 'Åtvidaberg',
    '597 43': 'Åtvidaberg',
    '597 50': 'Åtvidaberg',
    '597 51': 'Åtvidaberg',
    '597 52': 'Åtvidaberg',
    '597 53': 'Åtvidaberg',
    '597 80': 'Åtvidaberg',
    '597 91': 'Åtvidaberg',
    '597 92': 'Åtvidaberg',
    '597 93': 'Åtvidaberg',
    '597 94': 'Åtvidaberg',
    '597 95': 'Åtvidaberg',
    '597 96': 'Åtvidaberg',
    '597 97': 'Åtvidaberg',
    '610 11': 'Grytgöl',
    '610 12': 'Hällestad',
    '610 13': 'Lotorp',
    '610 14': 'Rejmyre',
    '612 01': 'Finspång',
    '612 02': 'Finspång',
    '612 21': 'Finspång',
    '612 22': 'Finspång',
    '612 23': 'Finspång',
    '612 24': 'Finspång',
    '612 25': 'Finspång',
    '612 30': 'Finspång',
    '612 31': 'Finspång',
    '612 32': 'Finspång',
    '612 33': 'Finspång',
    '612 34': 'Finspång',
    '612 35': 'Finspång',
    '612 36': 'Finspång',
    '612 37': 'Finspång',
    '612 38': 'Finspång',
    '612 40': 'Finspång',
    '612 41': 'Finspång',
    '612 42': 'Finspång',
    '612 43': 'Finspång',
    '612 44': 'Finspång',
    '612 45': 'Finspång',
    '612 46': 'Finspång',
    '612 47': 'Finspång',
    '612 80': 'Finspång',
    '612 81': 'Finspång',
    '612 82': 'Finspång',
    '612 91': 'Finspång',
    '612 92': 'Finspång',
    '612 93': 'Finspång',
    '612 94': 'Finspång',
    '612 95': 'Finspång',
    '612 96': 'Finspång',
    '610 42': 'Gryt',
    '615 01': 'Valdemarsvik',
    '615 21': 'Valdemarsvik',
    '615 22': 'Valdemarsvik',
    '615 23': 'Valdemarsvik',
    '615 24': 'Valdemarsvik',
    '615 30': 'Valdemarsvik',
    '615 31': 'Valdemarsvik',
    '615 32': 'Valdemarsvik',
    '615 33': 'Valdemarsvik',
    '615 80': 'Valdemarsvik',
    '615 91': 'Valdemarsvik',
    '615 92': 'Valdemarsvik',
    '615 93': 'Valdemarsvik',
    '615 94': 'Valdemarsvik',
    '615 95': 'Valdemarsvik',
    '580 02': 'Linköping',
    '580 03': 'Linköping',
    '580 05': 'Linköping',
    '580 06': 'Linköping',
    '580 07': 'Linköping',
    '580 08': 'Linköping',
    '580 09': 'Linköping',
    '580 10': 'Linköping',
    '580 11': 'Linköping',
    '580 13': 'Linköping',
    '580 15': 'Linköping',
    '581 00': 'Linköping',
    '581 01': 'Linköping',
    '581 02': 'Linköping',
    '581 03': 'Linköping',
    '581 04': 'Linköping',
    '581 05': 'Linköping',
    '581 06': 'Linköping',
    '581 07': 'Linköping',
    '581 10': 'Linköping',
    '581 11': 'Linköping',
    '581 12': 'Linköping',
    '581 13': 'Linköping',
    '581 14': 'Linköping',
    '581 15': 'Linköping',
    '581 16': 'Linköping',
    '581 17': 'Linköping',
    '581 18': 'Linköping',
    '581 19': 'Linköping',
    '581 21': 'Linköping',
    '581 28': 'Linköping',
    '581 31': 'Linköping',
    '581 33': 'Linköping',
    '581 80': 'Linköping',
    '581 81': 'Linköping',
    '581 82': 'Linköping',
    '581 83': 'Linköping',
    '581 84': 'Linköping',
    '581 85': 'Linköping',
    '581 86': 'Linköping',
    '581 87': 'Linköping',
    '581 88': 'Linköping',
    '581 89': 'Linköping',
    '581 90': 'Linköping',
    '581 91': 'Linköping',
    '581 92': 'Linköping',
    '581 93': 'Linköping',
    '581 94': 'Linköping',
    '581 95': 'Linköping',
    '581 96': 'Linköping',
    '581 97': 'Linköping',
    '581 98': 'Linköping',
    '582 00': 'Linköping',
    '582 01': 'Linköping',
    '582 02': 'Linköping',
    '582 10': 'Linköping',
    '582 12': 'Linköping',
    '582 14': 'Linköping',
    '582 15': 'Linköping',
    '582 16': 'Linköping',
    '582 17': 'Linköping',
    '582 18': 'Linköping',
    '582 19': 'Linköping',
    '582 20': 'Linköping',
    '582 21': 'Linköping',
    '582 22': 'Linköping',
    '582 23': 'Linköping',
    '582 24': 'Linköping',
    '582 25': 'Linköping',
    '582 26': 'Linköping',
    '582 27': 'Linköping',
    '582 28': 'Linköping',
    '582 29': 'Linköping',
    '582 30': 'Linköping',
    '582 31': 'Linköping',
    '582 32': 'Linköping',
    '582 33': 'Linköping',
    '582 34': 'Linköping',
    '582 35': 'Linköping',
    '582 36': 'Linköping',
    '582 37': 'Linköping',
    '582 42': 'Linköping',
    '582 43': 'Linköping',
    '582 45': 'Linköping',
    '582 46': 'Linköping',
    '582 47': 'Linköping',
    '582 52': 'Linköping',
    '582 53': 'Linköping',
    '582 54': 'Linköping',
    '582 72': 'Linköping',
    '582 73': 'Linköping',
    '582 74': 'Linköping',
    '582 75': 'Linköping',
    '582 77': 'Linköping',
    '582 78': 'Linköping',
    '583 00': 'Linköping',
    '583 30': 'Linköping',
    '583 31': 'Linköping',
    '583 32': 'Linköping',
    '583 33': 'Linköping',
    '583 34': 'Linköping',
    '583 35': 'Linköping',
    '583 36': 'Linköping',
    '583 37': 'Linköping',
    '584 22': 'Linköping',
    '584 31': 'Linköping',
    '584 32': 'Linköping',
    '584 34': 'Linköping',
    '584 35': 'Linköping',
    '584 36': 'Linköping',
    '584 37': 'Linköping',
    '584 39': 'Linköping',
    '585 00': 'Linköping',
    '585 91': 'Linköping',
    '585 92': 'Linköping',
    '585 93': 'Linköping',
    '585 94': 'Linköping',
    '585 95': 'Linköping',
    '585 96': 'Linköping',
    '585 97': 'Linköping',
    '585 98': 'Linköping',
    '585 99': 'Linköping',
    '586 00': 'Linköping',
    '586 43': 'Linköping',
    '586 44': 'Linköping',
    '586 46': 'Linköping',
    '586 47': 'Linköping',
    '586 48': 'Linköping',
    '586 62': 'Linköping',
    '586 63': 'Linköping',
    '586 65': 'Linköping',
    '586 66': 'Linköping',
    '587 21': 'Linköping',
    '587 23': 'Linköping',
    '587 24': 'Linköping',
    '587 25': 'Linköping',
    '587 27': 'Linköping',
    '587 29': 'Linköping',
    '587 31': 'Linköping',
    '587 33': 'Linköping',
    '587 34': 'Linköping',
    '587 35': 'Linköping',
    '587 37': 'Linköping',
    '587 39': 'Linköping',
    '589 00': 'Linköping',
    '589 21': 'Linköping',
    '589 23': 'Linköping',
    '589 25': 'Linköping',
    '589 27': 'Linköping',
    '589 29': 'Linköping',
    '589 31': 'Linköping',
    '589 33': 'Linköping',
    '589 35': 'Linköping',
    '589 37': 'Linköping',
    '589 41': 'Linköping',
    '589 43': 'Linköping',
    '589 51': 'Linköping',
    '589 53': 'Linköping',
    '589 55': 'Linköping',
    '589 57': 'Linköping',
    '590 40': 'Kisa',
    '590 49': 'Vikingstad',
    '590 50': 'Vikingstad',
    '590 51': 'Vikingstad',
    '590 52': 'Nykil',
    '590 53': 'Ulrika',
    '590 54': 'Sturefors',
    '590 55': 'Sturefors',
    '590 69': 'Ljungsbro',
    '590 70': 'Ljungsbro',
    '590 71': 'Ljungsbro',
    '590 72': 'Ljungsbro',
    '590 73': 'Ljungsbro',
    '590 74': 'Ljungsbro',
    '590 77': 'Vreta Kloster',
    '590 79': 'Vreta Kloster',
    '590 62': 'Linghem',
    '590 78': 'Vreta Kloster',
    '600 02': 'Norrköping',
    '600 03': 'Norrköping',
    '600 04': 'Norrköping',
    '600 05': 'Norrköping',
    '600 06': 'Norrköping',
    '600 07': 'Norrköping',
    '600 11': 'Norrköping',
    '600 12': 'Norrköping',
    '600 13': 'Norrköping',
    '600 40': 'Norrköping',
    '600 41': 'Norrköping',
    '600 42': 'Norrköping',
    '600 43': 'Norrköping',
    '600 44': 'Norrköping',
    '600 45': 'Norrköping',
    '600 46': 'Norrköping',
    '600 47': 'Norrköping',
    '601 00': 'Norrköping',
    '601 02': 'Norrköping',
    '601 03': 'Norrköping',
    '601 04': 'Norrköping',
    '601 05': 'Norrköping',
    '601 07': 'Norrköping',
    '601 10': 'Norrköping',
    '601 14': 'Norrköping',
    '601 15': 'Norrköping',
    '601 16': 'Norrköping',
    '601 17': 'Norrköping',
    '601 19': 'Norrköping',
    '601 70': 'Norrköping',
    '601 71': 'Norrköping',
    '601 72': 'Norrköping',
    '601 74': 'Norrköping',
    '601 75': 'Norrköping',
    '601 76': 'Norrköping',
    '601 77': 'Norrköping',
    '601 78': 'Norrköping',
    '601 79': 'Norrköping',
    '601 80': 'Norrköping',
    '601 81': 'Norrköping',
    '601 82': 'Norrköping',
    '601 83': 'Norrköping',
    '601 84': 'Norrköping',
    '601 85': 'Norrköping',
    '601 86': 'Norrköping',
    '601 88': 'Norrköping',
    '601 89': 'Norrköping',
    '602 00': 'Norrköping',
    '602 08': 'Norrköping',
    '602 09': 'Norrköping',
    '602 10': 'Norrköping',
    '602 11': 'Norrköping',
    '602 12': 'Norrköping',
    '602 13': 'Norrköping',
    '602 14': 'Norrköping',
    '602 15': 'Norrköping',
    '602 16': 'Norrköping',
    '602 17': 'Norrköping',
    '602 18': 'Norrköping',
    '602 19': 'Norrköping',
    '602 20': 'Norrköping',
    '602 21': 'Norrköping',
    '602 22': 'Norrköping',
    '602 23': 'Norrköping',
    '602 24': 'Norrköping',
    '602 25': 'Norrköping',
    '602 26': 'Norrköping',
    '602 27': 'Norrköping',
    '602 28': 'Norrköping',
    '602 29': 'Norrköping',
    '602 30': 'Norrköping',
    '602 31': 'Norrköping',
    '602 32': 'Norrköping',
    '602 33': 'Norrköping',
    '602 34': 'Norrköping',
    '602 35': 'Norrköping',
    '602 36': 'Norrköping',
    '602 37': 'Norrköping',
    '602 38': 'Norrköping',
    '602 39': 'Norrköping',
    '602 40': 'Norrköping',
    '603 00': 'Norrköping',
    '603 50': 'Norrköping',
    '603 51': 'Norrköping',
    '603 52': 'Norrköping',
    '603 53': 'Norrköping',
    '603 54': 'Norrköping',
    '603 55': 'Norrköping',
    '603 56': 'Norrköping',
    '603 57': 'Norrköping',
    '603 58': 'Norrköping',
    '603 59': 'Norrköping',
    '603 60': 'Norrköping',
    '603 61': 'Norrköping',
    '603 62': 'Norrköping',
    '603 63': 'Norrköping',
    '603 64': 'Norrköping',
    '603 65': 'Norrköping',
    '603 66': 'Norrköping',
    '603 67': 'Norrköping',
    '603 68': 'Norrköping',
    '603 69': 'Norrköping',
    '603 70': 'Norrköping',
    '603 71': 'Norrköping',
    '603 72': 'Norrköping',
    '603 74': 'Norrköping',
    '603 75': 'Norrköping',
    '603 76': 'Norrköping',
    '603 77': 'Norrköping',
    '603 78': 'Norrköping',
    '603 79': 'Norrköping',
    '603 80': 'Norrköping',
    '603 81': 'Norrköping',
    '603 85': 'Norrköping',
    '603 86': 'Norrköping',
    '604 00': 'Norrköping',
    '605 00': 'Norrköping',
    '605 91': 'Norrköping',
    '605 92': 'Norrköping',
    '605 93': 'Norrköping',
    '605 94': 'Norrköping',
    '605 95': 'Norrköping',
    '605 96': 'Norrköping',
    '605 97': 'Norrköping',
    '605 98': 'Norrköping',
    '605 99': 'Norrköping',
    '606 00': 'Norrköping',
    '607 00': 'Norrköping',
    '608 00': 'Norrköping',
    '610 20': 'Kimstad',
    '610 24': 'Vikbolandet',
    '610 26': 'Vikbolandet',
    '610 27': 'Vikbolandet',
    '610 28': 'Vikbolandet',
    '610 29': 'Vikbolandet',
    '610 30': 'Vikbolandet',
    '610 31': 'Vikbolandet',
    '610 32': 'Vikbolandet',
    '610 33': 'Vikbolandet',
    '610 50': 'Jönåker',
    '616 21': 'Åby',
    '616 22': 'Åby',
    '616 30': 'Åby',
    '616 31': 'Åby',
    '616 32': 'Åby',
    '616 33': 'Åby',
    '616 34': 'Åby',
    '616 90': 'Åby',
    '616 91': 'Åby',
    '617 01': 'Skärblacka',
    '617 10': 'Skärblacka',
    '617 21': 'Skärblacka',
    '617 22': 'Skärblacka',
    '617 30': 'Skärblacka',
    '617 31': 'Skärblacka',
    '617 32': 'Skärblacka',
    '617 34': 'Skärblacka',
    '617 90': 'Skärblacka',
    '618 01': 'Kolmården',
    '618 21': 'Kolmården',
    '618 22': 'Kolmården',
    '618 23': 'Kolmården',
    '618 30': 'Kolmården',
    '618 31': 'Kolmården',
    '618 32': 'Kolmården',
    '618 33': 'Kolmården',
    '618 34': 'Kolmården',
    '618 35': 'Kolmården',
    '618 92': 'Kolmården',
    '618 93': 'Kolmården',
    '610 21': 'Norsholm',
    '610 25': 'Vikbolandet',
    '610 40': 'Gusum',
    '610 41': 'Ringarum',
    '614 01': 'Söderköping',
    '614 21': 'Söderköping',
    '614 22': 'Söderköping',
    '614 30': 'Söderköping',
    '614 31': 'Söderköping',
    '614 32': 'Söderköping',
    '614 33': 'Söderköping',
    '614 34': 'Söderköping',
    '614 80': 'Söderköping',
    '614 81': 'Söderköping',
    '614 82': 'Söderköping',
    '614 90': 'Söderköping',
    '614 92': 'Söderköping',
    '614 93': 'Söderköping',
    '614 94': 'Söderköping',
    '614 95': 'Söderköping',
    '614 96': 'Östra Ryd',
    '614 97': 'Söderköping',
    '614 98': 'Sankt Anna',
    '614 99': 'Söderköping',
    '590 30': 'Borensberg',
    '590 31': 'Borensberg',
    '590 32': 'Borensberg',
    '590 34': 'Tjällmo',
    '590 75': 'Ljungsbro',
    '590 76': 'Vreta Kloster',
    '591 00': 'Motala',
    '591 01': 'Motala',
    '591 02': 'Motala',
    '591 04': 'Motala',
    '591 05': 'Motala',
    '591 21': 'Motala',
    '591 22': 'Motala',
    '591 23': 'Motala',
    '591 24': 'Motala',
    '591 29': 'Motala',
    '591 30': 'Motala',
    '591 31': 'Motala',
    '591 32': 'Motala',
    '591 33': 'Motala',
    '591 34': 'Motala',
    '591 35': 'Motala',
    '591 36': 'Motala',
    '591 37': 'Motala',
    '591 45': 'Motala',
    '591 46': 'Motala',
    '591 50': 'Motala',
    '591 51': 'Motala',
    '591 52': 'Motala',
    '591 53': 'Motala',
    '591 54': 'Motala',
    '591 60': 'Motala',
    '591 61': 'Motala',
    '591 62': 'Motala',
    '591 70': 'Motala',
    '591 71': 'Motala',
    '591 72': 'Motala',
    '591 81': 'Motala',
    '591 82': 'Motala',
    '591 84': 'Motala',
    '591 85': 'Motala',
    '591 86': 'Motala',
    '591 91': 'Motala',
    '591 92': 'Motala',
    '591 93': 'Motala',
    '591 94': 'Motala',
    '591 95': 'Motala',
    '591 96': 'Motala',
    '591 97': 'Motala',
    '591 98': 'Motala',
    '591 99': 'Motala',
    '592 01': 'Vadstena',
    '592 21': 'Vadstena',
    '592 22': 'Vadstena',
    '592 23': 'Vadstena',
    '592 24': 'Vadstena',
    '592 29': 'Vadstena',
    '592 30': 'Vadstena',
    '592 31': 'Vadstena',
    '592 32': 'Vadstena',
    '592 40': 'Vadstena',
    '592 41': 'Vadstena',
    '592 42': 'Vadstena',
    '592 80': 'Vadstena',
    '592 81': 'Vadstena',
    '592 91': 'Vadstena',
    '592 92': 'Vadstena',
    '592 93': 'Borghamn',
    '592 94': 'Vadstena',
    '590 17': 'Mantorp',
    '590 18': 'Mantorp',
    '590 19': 'Mantorp',
    '590 20': 'Mantorp',
    '590 21': 'Väderstad',
    '590 33': 'Borensberg',
    '590 47': 'Vikingstad',
    '590 48': 'Vikingstad',
    '595 01': 'Mjölby',
    '595 21': 'Mjölby',
    '595 22': 'Mjölby',
    '595 23': 'Mjölby',
    '595 24': 'Mjölby',
    '595 29': 'Mjölby',
    '595 30': 'Mjölby',
    '595 31': 'Mjölby',
    '595 32': 'Mjölby',
    '595 33': 'Mjölby',
    '595 34': 'Mjölby',
    '595 35': 'Mjölby',
    '595 40': 'Mjölby',
    '595 41': 'Mjölby',
    '595 42': 'Mjölby',
    '595 43': 'Mjölby',
    '595 44': 'Mjölby',
    '595 50': 'Mjölby',
    '595 51': 'Mjölby',
    '595 52': 'Mjölby',
    '595 53': 'Mjölby',
    '595 54': 'Mjölby',
    '595 80': 'Mjölby',
    '595 81': 'Mjölby',
    '595 82': 'Mjölby',
    '595 83': 'Mjölby',
    '595 91': 'Mjölby',
    '595 92': 'Mjölby',
    '595 93': 'Mjölby',
    '595 94': 'Mjölby',
    '595 95': 'Mjölby',
    '595 96': 'Mjölby',
    '596 01': 'Skänninge',
    '596 21': 'Skänninge',
    '596 22': 'Skänninge',
    '596 23': 'Skänninge',
    '596 31': 'Skänninge',
    '596 32': 'Skänninge',
    '596 33': 'Skänninge',
    '596 34': 'Skänninge',
    '596 91': 'Skänninge',
    '596 92': 'Skänninge',
    '596 93': 'Skänninge',
    '581 99': 'Linköping',
    '583 29': 'Linköping',
    '587 50': 'Linköping',
    '587 58': 'Linköping',
    '588 01': 'Linköping',
    '588 02': 'Linköping',
    '588 03': 'Linköping',
    '588 05': 'Linköping',
    '588 06': 'Linköping',
    '588 07': 'Linköping',
    '588 08': 'Linköping',
    '588 09': 'Linköping',
    '588 10': 'Linköping',
    '588 11': 'Linköping',
    '588 13': 'Linköping',
    '588 15': 'Linköping',
    '588 18': 'Linköping',
    '588 20': 'Linköping',
    '591 20': 'Motala',
    '592 20': 'Vadstena',
    '592 82': 'Vadstena',
    '595 20': 'Mjölby',
    '596 20': 'Skänninge',
    '597 20': 'Åtvidaberg',
    '599 20': 'Ödeshög',
    '601 69': 'Norrköping',
    '601 73': 'Norrköping',
    '601 87': 'Norrköping',
    '608 01': 'Norrköping',
    '608 02': 'Norrköping',
    '608 03': 'Norrköping',
    '608 04': 'Norrköping',
    '608 06': 'Norrköping',
    '608 07': 'Norrköping',
    '608 10': 'Norrköping',
    '608 11': 'Norrköping',
    '608 12': 'Norrköping',
    '608 13': 'Norrköping',
    '608 14': 'Norrköping',
    '608 15': 'Norrköping',
    '608 49': 'Norrköping',
    '612 20': 'Finspång',
    '612 83': 'Finspång',
    '614 20': 'Söderköping',
    '615 20': 'Valdemarsvik',
    '616 20': 'Åby',
    '617 20': 'Skärblacka',
    '618 20': 'Kolmården',
    '573 73': 'Sunhultsbrunn',
    '578 01': 'Aneby',
    '578 21': 'Aneby',
    '578 22': 'Aneby',
    '578 23': 'Aneby',
    '578 24': 'Aneby',
    '578 25': 'Aneby',
    '578 31': 'Aneby',
    '578 32': 'Aneby',
    '578 33': 'Aneby',
    '578 74': 'Frinnaryd',
    '578 91': 'Aneby',
    '578 92': 'Aneby',
    '578 93': 'Aneby',
    '578 94': 'Aneby',
    '330 33': 'Hillerstorp',
    '335 01': 'Gnosjö',
    '335 21': 'Gnosjö',
    '335 22': 'Gnosjö',
    '335 23': 'Gnosjö',
    '335 24': 'Gnosjö',
    '335 25': 'Gnosjö',
    '335 28': 'Gnosjö',
    '335 30': 'Gnosjö',
    '335 31': 'Gnosjö',
    '335 32': 'Gnosjö',
    '335 33': 'Gnosjö',
    '335 80': 'Gnosjö',
    '335 91': 'Gnosjö',
    '335 92': 'Nissafors',
    '335 93': 'Åsenhöga',
    '565 01': 'Mullsjö',
    '565 18': 'Mullsjö',
    '565 21': 'Mullsjö',
    '565 22': 'Mullsjö',
    '565 23': 'Mullsjö',
    '565 24': 'Mullsjö',
    '565 25': 'Mullsjö',
    '565 28': 'Mullsjö',
    '565 29': 'Mullsjö',
    '565 31': 'Mullsjö',
    '565 32': 'Mullsjö',
    '565 33': 'Mullsjö',
    '565 91': 'Mullsjö',
    '565 92': 'Mullsjö',
    '565 93': 'Mullsjö',
    '566 01': 'Habo',
    '566 21': 'Habo',
    '566 22': 'Habo',
    '566 23': 'Habo',
    '566 24': 'Habo',
    '566 28': 'Habo',
    '566 31': 'Habo',
    '566 32': 'Habo',
    '566 33': 'Habo',
    '566 34': 'Habo',
    '566 80': 'Habo',
    '566 91': 'Habo',
    '566 92': 'Habo',
    '566 93': 'Brandstorp',
    '330 21': 'Reftele',
    '330 26': 'Burseryd',
    '332 01': 'Gislaved',
    '332 21': 'Gislaved',
    '332 22': 'Gislaved',
    '332 23': 'Gislaved',
    '332 24': 'Gislaved',
    '332 25': 'Gislaved',
    '332 28': 'Gislaved',
    '332 30': 'Gislaved',
    '332 31': 'Gislaved',
    '332 32': 'Gislaved',
    '332 33': 'Gislaved',
    '332 34': 'Gislaved',
    '332 35': 'Gislaved',
    '332 36': 'Gislaved',
    '332 37': 'Gislaved',
    '332 80': 'Gislaved',
    '332 81': 'Gislaved',
    '332 91': 'Gislaved',
    '332 92': 'Gislaved',
    '333 01': 'Smålandsstenar',
    '333 21': 'Smålandsstenar',
    '333 22': 'Smålandsstenar',
    '333 23': 'Smålandsstenar',
    '333 24': 'Smålandsstenar',
    '333 28': 'Smålandsstenar',
    '333 30': 'Smålandsstenar',
    '333 31': 'Smålandsstenar',
    '333 32': 'Smålandsstenar',
    '333 33': 'Smålandsstenar',
    '333 91': 'Smålandsstenar',
    '333 92': 'Broaryd',
    '334 01': 'Anderstorp',
    '334 21': 'Anderstorp',
    '334 22': 'Anderstorp',
    '334 23': 'Anderstorp',
    '334 24': 'Anderstorp',
    '334 25': 'Anderstorp',
    '334 31': 'Anderstorp',
    '334 32': 'Anderstorp',
    '334 33': 'Anderstorp',
    '334 80': 'Anderstorp',
    '334 91': 'Anderstorp',
    '567 01': 'Vaggeryd',
    '567 21': 'Vaggeryd',
    '567 22': 'Vaggeryd',
    '567 23': 'Vaggeryd',
    '567 24': 'Vaggeryd',
    '567 30': 'Vaggeryd',
    '567 31': 'Vaggeryd',
    '567 32': 'Vaggeryd',
    '567 33': 'Vaggeryd',
    '567 91': 'Vaggeryd',
    '567 92': 'Vaggeryd',
    '568 21': 'Skillingaryd',
    '568 22': 'Skillingaryd',
    '568 23': 'Skillingaryd',
    '568 24': 'Skillingaryd',
    '568 30': 'Skillingaryd',
    '568 31': 'Skillingaryd',
    '568 32': 'Skillingaryd',
    '568 91': 'Skillingaryd',
    '568 93': 'Skillingaryd',
    '550 01': 'Jönköping',
    '550 02': 'Jönköping',
    '550 03': 'Jönköping',
    '550 04': 'Jönköping',
    '550 05': 'Jönköping',
    '550 06': 'Jönköping',
    '550 08': 'Jönköping',
    '550 09': 'Jönköping',
    '550 10': 'Jönköping',
    '550 11': 'Jönköping',
    '550 12': 'Jönköping',
    '551 00': 'Jönköping',
    '551 01': 'Jönköping',
    '551 10': 'Jönköping',
    '551 11': 'Jönköping',
    '551 12': 'Jönköping',
    '551 13': 'Jönköping',
    '551 14': 'Jönköping',
    '551 15': 'Jönköping',
    '551 16': 'Jönköping',
    '551 17': 'Jönköping',
    '551 18': 'Jönköping',
    '551 19': 'Jönköping',
    '551 20': 'Jönköping',
    '551 21': 'Jönköping',
    '551 22': 'Jönköping',
    '551 23': 'Jönköping',
    '551 26': 'Jönköping',
    '551 27': 'Jönköping',
    '551 40': 'Jönköping',
    '551 80': 'Jönköping',
    '551 81': 'Jönköping',
    '551 82': 'Jönköping',
    '551 83': 'Jönköping',
    '551 84': 'Jönköping',
    '551 85': 'Jönköping',
    '551 86': 'Jönköping',
    '551 89': 'Jönköping',
    '551 90': 'Jönköping',
    '551 91': 'Jönköping',
    '551 92': 'Jönköping',
    '551 93': 'Jönköping',
    '551 95': 'Jönköping',
    '551 99': 'Jönköping',
    '553 02': 'Jönköping',
    '553 03': 'Jönköping',
    '553 05': 'Jönköping',
    '553 07': 'Jönköping',
    '553 08': 'Jönköping',
    '553 10': 'Jönköping',
    '553 11': 'Jönköping',
    '553 12': 'Jönköping',
    '553 13': 'Jönköping',
    '553 15': 'Jönköping',
    '553 16': 'Jönköping',
    '553 17': 'Jönköping',
    '553 18': 'Jönköping',
    '553 20': 'Jönköping',
    '553 21': 'Jönköping',
    '553 22': 'Jönköping',
    '553 23': 'Jönköping',
    '553 24': 'Jönköping',
    '553 31': 'Jönköping',
    '553 32': 'Jönköping',
    '553 33': 'Jönköping',
    '553 34': 'Jönköping',
    '553 35': 'Jönköping',
    '553 36': 'Jönköping',
    '553 37': 'Jönköping',
    '553 38': 'Jönköping',
    '553 39': 'Jönköping',
    '554 38': 'Jönköping',
    '554 39': 'Jönköping',
    '554 45': 'Jönköping',
    '554 46': 'Jönköping',
    '554 47': 'Jönköping',
    '554 48': 'Jönköping',
    '554 50': 'Jönköping',
    '554 51': 'Jönköping',
    '554 52': 'Jönköping',
    '554 53': 'Jönköping',
    '554 54': 'Jönköping',
    '554 56': 'Jönköping',
    '554 57': 'Jönköping',
    '554 58': 'Jönköping',
    '554 63': 'Jönköping',
    '554 64': 'Jönköping',
    '554 65': 'Jönköping',
    '554 66': 'Jönköping',
    '554 72': 'Jönköping',
    '554 73': 'Jönköping',
    '554 74': 'Jönköping',
    '555 92': 'Jönköping',
    '555 93': 'Jönköping',
    '555 94': 'Jönköping',
    '556 10': 'Jönköping',
    '556 11': 'Jönköping',
    '556 12': 'Jönköping',
    '556 14': 'Jönköping',
    '556 25': 'Jönköping',
    '556 26': 'Jönköping',
    '556 27': 'Jönköping',
    '556 28': 'Jönköping',
    '556 29': 'Jönköping',
    '556 31': 'Jönköping',
    '556 32': 'Jönköping',
    '556 33': 'Jönköping',
    '556 50': 'Jönköping',
    '556 52': 'Jönköping',
    '560 27': 'Tenhult',
    '560 28': 'Lekeryd',
    '560 34': 'Visingsö',
    '561 01': 'Huskvarna',
    '561 02': 'Huskvarna',
    '561 04': 'Huskvarna',
    '561 05': 'Huskvarna',
    '561 21': 'Huskvarna',
    '561 22': 'Huskvarna',
    '561 23': 'Huskvarna',
    '561 24': 'Huskvarna',
    '561 25': 'Huskvarna',
    '561 31': 'Huskvarna',
    '561 32': 'Huskvarna',
    '561 33': 'Huskvarna',
    '561 34': 'Huskvarna',
    '561 35': 'Huskvarna',
    '561 36': 'Huskvarna',
    '561 38': 'Huskvarna',
    '561 39': 'Huskvarna',
    '561 41': 'Huskvarna',
    '561 42': 'Huskvarna',
    '561 43': 'Huskvarna',
    '561 44': 'Huskvarna',
    '561 46': 'Huskvarna',
    '561 48': 'Huskvarna',
    '561 49': 'Huskvarna',
    '561 50': 'Huskvarna',
    '561 51': 'Huskvarna',
    '561 82': 'Huskvarna',
    '561 83': 'Huskvarna',
    '561 84': 'Huskvarna',
    '561 85': 'Huskvarna',
    '561 91': 'Huskvarna',
    '561 92': 'Huskvarna',
    '561 93': 'Huskvarna',
    '562 01': 'Norrahammar',
    '562 02': 'Taberg',
    '562 21': 'Norrahammar',
    '562 22': 'Norrahammar',
    '562 23': 'Norrahammar',
    '562 24': 'Norrahammar',
    '562 28': 'Norrahammar',
    '562 30': 'Norrahammar',
    '562 31': 'Norrahammar',
    '562 32': 'Norrahammar',
    '562 40': 'Taberg',
    '562 41': 'Taberg',
    '562 42': 'Taberg',
    '562 50': 'Månsarp',
    '562 80': 'Norrahammar',
    '562 91': 'Månsarp',
    '563 01': 'Gränna',
    '563 21': 'Gränna',
    '563 22': 'Gränna',
    '563 31': 'Gränna',
    '563 32': 'Gränna',
    '563 93': 'Gränna',
    '564 21': 'Bankeryd',
    '564 22': 'Bankeryd',
    '564 23': 'Bankeryd',
    '564 24': 'Bankeryd',
    '564 28': 'Bankeryd',
    '564 31': 'Bankeryd',
    '564 32': 'Bankeryd',
    '564 33': 'Bankeryd',
    '564 34': 'Bankeryd',
    '564 35': 'Bankeryd',
    '564 36': 'Bankeryd',
    '564 91': 'Bankeryd',
    '570 21': 'Malmbäck',
    '570 23': 'Anneberg',
    '571 00': 'Nässjö',
    '571 03': 'Forserum',
    '571 06': 'Bodafors',
    '571 10': 'Nässjö',
    '571 19': 'Nässjö',
    '571 21': 'Nässjö',
    '571 22': 'Nässjö',
    '571 23': 'Nässjö',
    '571 24': 'Nässjö',
    '571 25': 'Nässjö',
    '571 29': 'Nässjö',
    '571 31': 'Nässjö',
    '571 32': 'Nässjö',
    '571 33': 'Nässjö',
    '571 34': 'Nässjö',
    '571 35': 'Nässjö',
    '571 36': 'Nässjö',
    '571 37': 'Nässjö',
    '571 38': 'Nässjö',
    '571 39': 'Nässjö',
    '571 40': 'Nässjö',
    '571 41': 'Nässjö',
    '571 42': 'Nässjö',
    '571 43': 'Nässjö',
    '571 61': 'Bodafors',
    '571 62': 'Bodafors',
    '571 63': 'Bodafors',
    '571 64': 'Sandsjöfors',
    '571 65': 'Grimstorp',
    '571 66': 'Bodafors',
    '571 67': 'Bodafors',
    '571 72': 'Ormaryd',
    '571 73': 'Stensjön',
    '571 74': 'Äng',
    '571 75': 'Fredriksdal',
    '571 76': 'Solberga',
    '571 77': 'Forserum',
    '571 78': 'Forserum',
    '571 80': 'Nässjö',
    '571 81': 'Nässjö',
    '571 82': 'Nässjö',
    '571 83': 'Nässjö',
    '571 85': 'Nässjö',
    '571 88': 'Nässjö',
    '571 91': 'Nässjö',
    '571 92': 'Nässjö',
    '571 93': 'Nässjö',
    '571 94': 'Nässjö',
    '571 95': 'Nässjö',
    '571 97': 'Forserum',
    '578 75': 'Flisby',
    '578 95': 'Flisby',
    '330 10': 'Bredaryd',
    '330 12': 'Forsheda',
    '330 15': 'Bor',
    '330 18': 'Horda',
    '330 31': 'Kulltorp',
    '331 01': 'Värnamo',
    '331 02': 'Värnamo',
    '331 21': 'Värnamo',
    '331 22': 'Värnamo',
    '331 23': 'Värnamo',
    '331 24': 'Värnamo',
    '331 25': 'Värnamo',
    '331 26': 'Värnamo',
    '331 27': 'Värnamo',
    '331 29': 'Värnamo',
    '331 30': 'Värnamo',
    '331 31': 'Värnamo',
    '331 32': 'Värnamo',
    '331 33': 'Värnamo',
    '331 34': 'Värnamo',
    '331 35': 'Värnamo',
    '331 40': 'Värnamo',
    '331 41': 'Värnamo',
    '331 42': 'Värnamo',
    '331 43': 'Värnamo',
    '331 50': 'Värnamo',
    '331 51': 'Värnamo',
    '331 52': 'Värnamo',
    '331 53': 'Värnamo',
    '331 54': 'Värnamo',
    '331 81': 'Värnamo',
    '331 82': 'Värnamo',
    '331 83': 'Värnamo',
    '331 84': 'Värnamo',
    '331 85': 'Värnamo',
    '331 91': 'Värnamo',
    '331 92': 'Värnamo',
    '331 93': 'Värnamo',
    '331 94': 'Värnamo',
    '331 95': 'Värnamo',
    '331 96': 'Värnamo',
    '331 97': 'Värnamo',
    '331 98': 'Värnamo',
    '568 92': 'Skillingaryd',
    '560 13': 'Hok',
    '570 01': 'Rörvik',
    '570 02': 'Stockaryd',
    '570 03': 'Vrigstad',
    '576 01': 'Sävsjö',
    '576 21': 'Sävsjö',
    '576 22': 'Sävsjö',
    '576 23': 'Sävsjö',
    '576 24': 'Sävsjö',
    '576 25': 'Sävsjö',
    '576 31': 'Sävsjö',
    '576 32': 'Sävsjö',
    '576 33': 'Sävsjö',
    '576 35': 'Sävsjö',
    '576 36': 'Sävsjö',
    '576 80': 'Sävsjö',
    '576 91': 'Sävsjö',
    '576 92': 'Sävsjö',
    '570 13': 'Myresjö',
    '570 15': 'Holsbybrunn',
    '570 19': 'Pauliström',
    '574 00': 'Vetlanda',
    '574 02': 'Ekenässjön',
    '574 21': 'Vetlanda',
    '574 22': 'Vetlanda',
    '574 23': 'Vetlanda',
    '574 28': 'Vetlanda',
    '574 31': 'Vetlanda',
    '574 32': 'Vetlanda',
    '574 33': 'Vetlanda',
    '574 34': 'Vetlanda',
    '574 35': 'Vetlanda',
    '574 36': 'Vetlanda',
    '574 37': 'Vetlanda',
    '574 38': 'Vetlanda',
    '574 39': 'Vetlanda',
    '574 40': 'Vetlanda',
    '574 41': 'Vetlanda',
    '574 50': 'Ekenässjön',
    '574 74': 'Ramkvilla',
    '574 80': 'Vetlanda',
    '574 81': 'Vetlanda',
    '574 85': 'Vetlanda',
    '574 91': 'Vetlanda',
    '574 92': 'Vetlanda',
    '574 93': 'Vetlanda',
    '574 94': 'Vetlanda',
    '574 95': 'Björköby',
    '574 96': 'Vetlanda',
    '574 97': 'Vetlanda',
    '574 98': 'Nye',
    '570 31': 'Ingatorp',
    '570 32': 'Hjältevad',
    '570 33': 'Mariannelund',
    '570 34': 'Bruzaholm',
    '575 01': 'Eksjö',
    '575 21': 'Eksjö',
    '575 22': 'Eksjö',
    '575 23': 'Eksjö',
    '575 28': 'Eksjö',
    '575 31': 'Eksjö',
    '575 32': 'Eksjö',
    '575 33': 'Eksjö',
    '575 34': 'Eksjö',
    '575 35': 'Eksjö',
    '575 36': 'Eksjö',
    '575 37': 'Eksjö',
    '575 38': 'Eksjö',
    '575 39': 'Eksjö',
    '575 80': 'Eksjö',
    '575 81': 'Eksjö',
    '575 82': 'Eksjö',
    '575 91': 'Eksjö',
    '575 92': 'Hult',
    '575 93': 'Eksjö',
    '575 94': 'Eksjö',
    '575 95': 'Eksjö',
    '575 96': 'Eksjö',
    '563 91': 'Gränna',
    '563 92': 'Gränna',
    '573 00': 'Tranås',
    '573 02': 'Sommen',
    '573 21': 'Tranås',
    '573 22': 'Tranås',
    '573 23': 'Tranås',
    '573 24': 'Tranås',
    '573 25': 'Tranås',
    '573 26': 'Tranås',
    '573 28': 'Tranås',
    '573 29': 'Tranås',
    '573 31': 'Tranås',
    '573 32': 'Tranås',
    '573 33': 'Tranås',
    '573 34': 'Tranås',
    '573 35': 'Tranås',
    '573 36': 'Tranås',
    '573 37': 'Tranås',
    '573 38': 'Tranås',
    '573 39': 'Tranås',
    '573 40': 'Tranås',
    '573 41': 'Tranås',
    '573 42': 'Tranås',
    '573 43': 'Tranås',
    '573 61': 'Sommen',
    '573 82': 'Tranås',
    '573 83': 'Tranås',
    '573 91': 'Tranås',
    '573 92': 'Tranås',
    '573 93': 'Tranås',
    '573 94': 'Tranås',
    '573 95': 'Tranås',
    '573 96': 'Tranås',
    '573 97': 'Tranås',
    '573 98': 'Tranås',
    '573 99': 'Tranås',
    '331 20': 'Värnamo',
    '332 20': 'Gislaved',
    '333 20': 'Smålandsstenar',
    '334 20': 'Anderstorp',
    '335 20': 'Gnosjö',
    '551 30': 'Jönköping',
    '553 00': 'Jönköping',
    '553 09': 'Jönköping',
    '553 14': 'Jönköping',
    '554 00': 'Jönköping',
    '554 59': 'Jönköping',
    '556 00': 'Jönköping',
    '558 00': 'Jönköping',
    '558 10': 'Jönköping',
    '561 20': 'Huskvarna',
    '562 20': 'Norrahammar',
    '563 20': 'Gränna',
    '564 20': 'Bankeryd',
    '565 20': 'Mullsjö',
    '566 18': 'Habo',
    '566 20': 'Habo',
    '566 35': 'Habo',
    '567 20': 'Vaggeryd',
    '568 20': 'Skillingaryd',
    '571 20': 'Nässjö',
    '573 20': 'Tranås',
    '574 20': 'Vetlanda',
    '575 20': 'Eksjö',
    '576 20': 'Sävsjö',
    '578 20': 'Aneby',
    '360 70': 'Åseda',
    '360 72': 'Klavreström',
    '360 74': 'Sävsjöström',
    '360 75': 'Alstermo',
    '360 76': 'Älghult',
    '570 10': 'Korsberga',
    '360 50': 'Lessebo',
    '360 13': 'Urshult',
    '362 00': 'Tingsryd',
    '362 01': 'Tingsryd',
    '362 21': 'Tingsryd',
    '362 22': 'Tingsryd',
    '362 23': 'Tingsryd',
    '362 30': 'Tingsryd',
    '362 31': 'Tingsryd',
    '362 32': 'Tingsryd',
    '362 40': 'Konga',
    '362 91': 'Tingsryd',
    '362 92': 'Tingsryd',
    '362 93': 'Tingsryd',
    '362 94': 'Tingsryd',
    '330 17': 'Rydaholm',
    '340 37': 'Torpsbruk',
    '342 00': 'Alvesta',
    '342 01': 'Alvesta',
    '342 10': 'Alvesta',
    '342 11': 'Alvesta',
    '342 21': 'Alvesta',
    '342 22': 'Alvesta',
    '342 23': 'Alvesta',
    '342 30': 'Alvesta',
    '342 31': 'Alvesta',
    '342 32': 'Alvesta',
    '342 33': 'Alvesta',
    '342 34': 'Alvesta',
    '342 35': 'Alvesta',
    '342 36': 'Alvesta',
    '342 80': 'Alvesta',
    '342 90': 'Alvesta',
    '342 91': 'Alvesta',
    '342 92': 'Alvesta',
    '342 93': 'Hjortsberga',
    '342 94': 'Alvesta',
    '342 95': 'Alvesta',
    '343 01': 'Älmhult',
    '343 21': 'Älmhult',
    '343 22': 'Älmhult',
    '343 23': 'Älmhult',
    '343 24': 'Älmhult',
    '343 30': 'Älmhult',
    '343 31': 'Älmhult',
    '343 32': 'Älmhult',
    '343 34': 'Älmhult',
    '343 35': 'Älmhult',
    '343 36': 'Älmhult',
    '343 37': 'Älmhult',
    '343 71': 'Diö',
    '343 72': 'Eneryda',
    '343 73': 'Virestad',
    '343 74': 'Liatorp',
    '343 75': 'Diö',
    '343 76': 'Liatorp',
    '343 81': 'Älmhult',
    '343 90': 'Älmhult',
    '343 91': 'Älmhult',
    '343 92': 'Häradsbäck',
    '343 93': 'Älmhult',
    '343 94': 'Älmhult',
    '343 95': 'Älmhult',
    '343 96': 'Hallaryd',
    '343 97': 'Älmhult',
    '285 01': 'Markaryd',
    '285 21': 'Markaryd',
    '285 22': 'Markaryd',
    '285 23': 'Markaryd',
    '285 24': 'Markaryd',
    '285 31': 'Markaryd',
    '285 32': 'Markaryd',
    '285 33': 'Markaryd',
    '285 34': 'Markaryd',
    '285 35': 'Markaryd',
    '285 36': 'Markaryd',
    '285 37': 'Markaryd',
    '285 38': 'Markaryd',
    '285 40': 'Markaryd',
    '285 91': 'Markaryd',
    '285 93': 'Markaryd',
    '287 02': 'Traryd',
    '287 21': 'Strömsnäsbruk',
    '287 22': 'Strömsnäsbruk',
    '287 24': 'Strömsnäsbruk',
    '287 31': 'Strömsnäsbruk',
    '287 32': 'Strömsnäsbruk',
    '287 33': 'Strömsnäsbruk',
    '287 34': 'Strömsnäsbruk',
    '287 72': 'Traryd',
    '287 81': 'Strömsnäsbruk',
    '287 91': 'Strömsnäsbruk',
    '340 30': 'Vislanda',
    '340 32': 'Grimslöv',
    '340 36': 'Moheda',
    '350 02': 'Växjö',
    '350 03': 'Växjö',
    '350 04': 'Växjö',
    '350 05': 'Växjö',
    '350 06': 'Växjö',
    '350 07': 'Växjö',
    '350 08': 'Växjö',
    '350 33': 'Växjö',
    '350 43': 'Växjö',
    '350 53': 'Växjö',
    '351 01': 'Växjö',
    '351 03': 'Växjö',
    '351 04': 'Växjö',
    '351 05': 'Växjö',
    '351 06': 'Växjö',
    '351 12': 'Växjö',
    '351 15': 'Växjö',
    '351 70': 'Växjö',
    '351 80': 'Växjö',
    '351 81': 'Växjö',
    '351 82': 'Växjö',
    '351 84': 'Växjö',
    '351 85': 'Växjö',
    '351 86': 'Växjö',
    '351 88': 'Växjö',
    '351 89': 'Växjö',
    '351 94': 'Växjö',
    '351 95': 'Växjö',
    '351 96': 'Växjö',
    '351 97': 'Växjö',
    '352 30': 'Växjö',
    '352 31': 'Växjö',
    '352 32': 'Växjö',
    '352 33': 'Växjö',
    '352 34': 'Växjö',
    '352 35': 'Växjö',
    '352 36': 'Växjö',
    '352 37': 'Växjö',
    '352 38': 'Växjö',
    '352 39': 'Växjö',
    '352 40': 'Växjö',
    '352 41': 'Växjö',
    '352 42': 'Växjö',
    '352 43': 'Växjö',
    '352 44': 'Växjö',
    '352 45': 'Växjö',
    '352 46': 'Växjö',
    '352 47': 'Växjö',
    '352 48': 'Växjö',
    '352 49': 'Växjö',
    '352 50': 'Växjö',
    '352 51': 'Växjö',
    '352 52': 'Växjö',
    '352 53': 'Växjö',
    '352 54': 'Växjö',
    '352 55': 'Växjö',
    '352 56': 'Växjö',
    '352 57': 'Växjö',
    '352 60': 'Växjö',
    '352 61': 'Växjö',
    '352 62': 'Växjö',
    '352 63': 'Växjö',
    '352 70': 'Växjö',
    '355 91': 'Växjö',
    '355 92': 'Växjö',
    '355 93': 'Växjö',
    '355 94': 'Vederslöv',
    '355 95': 'Tävelsås',
    '355 96': 'Kalvsvik',
    '355 97': 'Växjö',
    '360 14': 'Väckelsång',
    '360 24': 'Linneryd',
    '360 30': 'Lammhult',
    '360 32': 'Gemla',
    '360 40': 'Rottne',
    '360 42': 'Braås',
    '360 43': 'Åryd',
    '360 44': 'Ingelstad',
    '360 47': 'Värends Nöbbele',
    '360 51': 'Hovmantorp',
    '360 71': 'Norrhult',
    '360 73': 'Lenhovda',
    '570 12': 'Landsbro',
    '287 92': 'Traryd',
    '287 93': 'Strömsnäsbruk',
    '340 12': 'Annerstad',
    '340 13': 'Hamneda',
    '340 14': 'Lagan',
    '340 15': 'Vittaryd',
    '341 01': 'Ljungby',
    '341 18': 'Ljungby',
    '341 21': 'Ljungby',
    '341 22': 'Ljungby',
    '341 23': 'Ljungby',
    '341 24': 'Ljungby',
    '341 25': 'Ljungby',
    '341 26': 'Ljungby',
    '341 30': 'Ljungby',
    '341 31': 'Ljungby',
    '341 32': 'Ljungby',
    '341 33': 'Ljungby',
    '341 34': 'Ljungby',
    '341 35': 'Ljungby',
    '341 36': 'Ljungby',
    '341 37': 'Ljungby',
    '341 38': 'Ljungby',
    '341 39': 'Ljungby',
    '341 60': 'Ljungby',
    '341 70': 'Ljungby',
    '341 76': 'Ryssby',
    '341 77': 'Agunnaryd',
    '341 80': 'Ljungby',
    '341 81': 'Ljungby',
    '341 82': 'Ljungby',
    '341 83': 'Ljungby',
    '341 84': 'Ljungby',
    '341 91': 'Ljungby',
    '341 94': 'Ljungby',
    '341 95': 'Ljungby',
    '341 96': 'Ljungby',
    '285 20': 'Markaryd',
    '341 20': 'Ljungby',
    '341 40': 'Ljungby',
    '342 05': 'Vislanda',
    '342 06': 'Moheda',
    '342 20': 'Alvesta',
    '342 50': 'Vislanda',
    '342 51': 'Vislanda',
    '342 60': 'Moheda',
    '342 61': 'Moheda',
    '343 20': 'Älmhult',
    '351 83': 'Växjö',
    '351 87': 'Växjö',
    '352 59': 'Växjö',
    '358 01': 'Växjö',
    '358 03': 'Växjö',
    '358 10': 'Växjö',
    '362 20': 'Tingsryd',
    '570 72': 'Fagerhult',
    '570 75': 'Fågelfors',
    '579 00': 'Högsby',
    '579 02': 'Berga',
    '579 21': 'Högsby',
    '579 22': 'Högsby',
    '579 23': 'Högsby',
    '579 30': 'Högsby',
    '579 31': 'Högsby',
    '579 32': 'Högsby',
    '579 33': 'Högsby',
    '579 40': 'Berga',
    '579 80': 'Högsby',
    '579 92': 'Högsby',
    '385 01': 'Torsås',
    '385 02': 'Bergkvara',
    '385 03': 'Söderåkra',
    '385 21': 'Torsås',
    '385 22': 'Torsås',
    '385 25': 'Torsås',
    '385 30': 'Torsås',
    '385 31': 'Torsås',
    '385 32': 'Torsås',
    '385 33': 'Torsås',
    '385 34': 'Torsås',
    '385 40': 'Bergkvara',
    '385 41': 'Bergkvara',
    '385 42': 'Bergkvara',
    '385 50': 'Söderåkra',
    '385 51': 'Söderåkra',
    '385 90': 'Söderåkra',
    '385 91': 'Torsås',
    '385 92': 'Gullabo',
    '385 93': 'Torsås',
    '385 94': 'Bergkvara',
    '385 95': 'Torsås',
    '385 98': 'Bergkvara',
    '385 99': 'Torsås',
    '380 62': 'Mörbylånga',
    '380 65': 'Degerhamn',
    '386 01': 'Färjestaden',
    '386 21': 'Färjestaden',
    '386 22': 'Färjestaden',
    '386 23': 'Färjestaden',
    '386 30': 'Färjestaden',
    '386 31': 'Färjestaden',
    '386 32': 'Färjestaden',
    '386 33': 'Färjestaden',
    '386 34': 'Färjestaden',
    '386 35': 'Färjestaden',
    '386 90': 'Färjestaden',
    '386 92': 'Färjestaden',
    '386 93': 'Färjestaden',
    '386 96': 'Färjestaden',
    '570 16': 'Kvillsfors',
    '570 80': 'Virserum',
    '570 81': 'Järnforsen',
    '570 82': 'Målilla',
    '570 83': 'Rosenfors',
    '570 84': 'Mörlunda',
    '577 01': 'Hultsfred',
    '577 02': 'Silverdalen',
    '577 21': 'Hultsfred',
    '577 22': 'Hultsfred',
    '577 23': 'Hultsfred',
    '577 24': 'Hultsfred',
    '577 25': 'Hultsfred',
    '577 26': 'Hultsfred',
    '577 30': 'Hultsfred',
    '577 31': 'Hultsfred',
    '577 32': 'Hultsfred',
    '577 33': 'Hultsfred',
    '577 34': 'Hultsfred',
    '577 35': 'Hultsfred',
    '577 36': 'Hultsfred',
    '577 37': 'Hultsfred',
    '577 38': 'Hultsfred',
    '577 39': 'Hultsfred',
    '577 50': 'Silverdalen',
    '577 90': 'Hultsfred',
    '577 91': 'Hultsfred',
    '577 92': 'Hultsfred',
    '577 93': 'Hultsfred',
    '380 52': 'Timmernabben',
    '380 53': 'Fliseryd',
    '383 01': 'Mönsterås',
    '383 21': 'Mönsterås',
    '383 22': 'Mönsterås',
    '383 23': 'Mönsterås',
    '383 24': 'Mönsterås',
    '383 25': 'Mönsterås',
    '383 30': 'Mönsterås',
    '383 31': 'Mönsterås',
    '383 32': 'Mönsterås',
    '383 33': 'Mönsterås',
    '383 34': 'Mönsterås',
    '383 35': 'Mönsterås',
    '383 36': 'Mönsterås',
    '383 37': 'Mönsterås',
    '383 38': 'Mönsterås',
    '383 39': 'Mönsterås',
    '383 91': 'Mönsterås',
    '383 92': 'Mönsterås',
    '384 02': 'Ålem',
    '384 21': 'Blomstermåla',
    '384 22': 'Blomstermåla',
    '384 30': 'Blomstermåla',
    '384 31': 'Blomstermåla',
    '384 40': 'Ålem',
    '384 91': 'Blomstermåla',
    '384 92': 'Ålem',
    '570 76': 'Ruda',
    '360 23': 'Älmeboda',
    '360 52': 'Kosta',
    '360 53': 'Skruv',
    '361 01': 'Emmaboda',
    '361 21': 'Emmaboda',
    '361 22': 'Emmaboda',
    '361 30': 'Emmaboda',
    '361 31': 'Emmaboda',
    '361 32': 'Emmaboda',
    '361 33': 'Emmaboda',
    '361 42': 'Lindås',
    '361 53': 'Broakulla',
    '361 80': 'Emmaboda',
    '361 91': 'Emmaboda',
    '361 92': 'Emmaboda',
    '361 93': 'Broakulla',
    '361 94': 'Eriksmåla',
    '361 95': 'Långasjö',
    '385 96': 'Gullabo',
    '360 60': 'Vissefjärda',
    '384 93': 'Ålem',
    '385 97': 'Söderåkra',
    '388 01': 'Ljungbyholm',
    '388 04': 'Trekanten',
    '388 21': 'Ljungbyholm',
    '388 22': 'Ljungbyholm',
    '388 30': 'Ljungbyholm',
    '388 31': 'Ljungbyholm',
    '388 32': 'Ljungbyholm',
    '388 40': 'Trekanten',
    '388 41': 'Trekanten',
    '388 50': 'Påryd',
    '388 91': 'Vassmolösa',
    '388 92': 'Ljungbyholm',
    '388 93': 'Ljungbyholm',
    '388 94': 'Vassmolösa',
    '388 95': 'Halltorp',
    '388 96': 'Ljungbyholm',
    '388 97': 'Halltorp',
    '390 02': 'Kalmar',
    '390 03': 'Kalmar',
    '390 04': 'Kalmar',
    '390 06': 'Kalmar',
    '390 07': 'Kalmar',
    '390 08': 'Kalmar',
    '391 00': 'Kalmar',
    '391 01': 'Kalmar',
    '391 20': 'Kalmar',
    '391 21': 'Kalmar',
    '391 22': 'Kalmar',
    '391 23': 'Kalmar',
    '391 25': 'Kalmar',
    '391 26': 'Kalmar',
    '391 27': 'Kalmar',
    '391 28': 'Kalmar',
    '391 29': 'Kalmar',
    '391 82': 'Kalmar',
    '391 83': 'Kalmar',
    '391 84': 'Kalmar',
    '391 85': 'Kalmar',
    '391 86': 'Kalmar',
    '391 87': 'Kalmar',
    '391 88': 'Kalmar',
    '392 30': 'Kalmar',
    '392 31': 'Kalmar',
    '392 32': 'Kalmar',
    '392 33': 'Kalmar',
    '392 34': 'Kalmar',
    '392 35': 'Kalmar',
    '392 36': 'Kalmar',
    '392 37': 'Kalmar',
    '392 38': 'Kalmar',
    '392 39': 'Kalmar',
    '392 41': 'Kalmar',
    '392 43': 'Kalmar',
    '392 44': 'Kalmar',
    '392 45': 'Kalmar',
    '392 46': 'Kalmar',
    '392 47': 'Kalmar',
    '393 00': 'Kalmar',
    '393 50': 'Kalmar',
    '393 51': 'Kalmar',
    '393 52': 'Kalmar',
    '393 53': 'Kalmar',
    '393 54': 'Kalmar',
    '393 55': 'Kalmar',
    '393 57': 'Kalmar',
    '393 58': 'Kalmar',
    '393 59': 'Kalmar',
    '393 63': 'Kalmar',
    '393 64': 'Kalmar',
    '393 65': 'Kalmar',
    '394 70': 'Kalmar',
    '394 71': 'Kalmar',
    '394 77': 'Kalmar',
    '395 90': 'Kalmar',
    '360 65': 'Boda Glasbruk',
    '360 77': 'Fröseke',
    '380 30': 'Rockneby',
    '380 31': 'Läckeby',
    '380 40': 'Orrefors',
    '380 41': 'Gullaskruv',
    '380 42': 'Målerås',
    '380 44': 'Alsterbro',
    '382 01': 'Nybro',
    '382 21': 'Nybro',
    '382 22': 'Nybro',
    '382 23': 'Nybro',
    '382 24': 'Nybro',
    '382 28': 'Nybro',
    '382 30': 'Nybro',
    '382 31': 'Nybro',
    '382 32': 'Nybro',
    '382 33': 'Nybro',
    '382 34': 'Nybro',
    '382 35': 'Nybro',
    '382 36': 'Nybro',
    '382 37': 'Nybro',
    '382 38': 'Nybro',
    '382 39': 'Nybro',
    '382 40': 'Nybro',
    '382 41': 'Nybro',
    '382 42': 'Nybro',
    '382 43': 'Nybro',
    '382 44': 'Nybro',
    '382 45': 'Nybro',
    '382 46': 'Nybro',
    '382 80': 'Nybro',
    '382 90': 'Örsjö',
    '382 91': 'Nybro',
    '382 92': 'Nybro',
    '382 93': 'Nybro',
    '382 94': 'Nybro',
    '382 96': 'Nybro',
    '382 97': 'Örsjö',
    '388 98': 'Trekanten',
    '388 99': 'Påryd',
    '579 93': 'Grönskåra',
    '570 90': 'Påskallavik',
    '570 91': 'Kristdala',
    '572 00': 'Oskarshamn',
    '572 02': 'Oskarshamn',
    '572 04': 'Oskarshamn',
    '572 05': 'Figeholm',
    '572 06': 'Fårbo',
    '572 21': 'Oskarshamn',
    '572 22': 'Oskarshamn',
    '572 23': 'Oskarshamn',
    '572 24': 'Oskarshamn',
    '572 25': 'Oskarshamn',
    '572 26': 'Oskarshamn',
    '572 27': 'Oskarshamn',
    '572 28': 'Oskarshamn',
    '572 29': 'Oskarshamn',
    '572 30': 'Oskarshamn',
    '572 31': 'Oskarshamn',
    '572 32': 'Oskarshamn',
    '572 33': 'Oskarshamn',
    '572 34': 'Oskarshamn',
    '572 35': 'Oskarshamn',
    '572 36': 'Oskarshamn',
    '572 37': 'Oskarshamn',
    '572 40': 'Oskarshamn',
    '572 41': 'Oskarshamn',
    '572 50': 'Oskarshamn',
    '572 51': 'Oskarshamn',
    '572 60': 'Oskarshamn',
    '572 61': 'Oskarshamn',
    '572 62': 'Oskarshamn',
    '572 63': 'Oskarshamn',
    '572 75': 'Figeholm',
    '572 76': 'Fårbo',
    '572 83': 'Oskarshamn',
    '572 91': 'Oskarshamn',
    '572 92': 'Oskarshamn',
    '572 93': 'Oskarshamn',
    '572 95': 'Figeholm',
    '579 90': 'Berga',
    '572 96': 'Fårbo',
    '590 41': 'Rimforsa',
    '590 93': 'Gunnebo',
    '590 95': 'Loftahammar',
    '590 96': 'Överum',
    '590 98': 'Edsbruk',
    '593 00': 'Västervik',
    '593 02': 'Västervik',
    '593 03': 'Västervik',
    '593 21': 'Västervik',
    '593 22': 'Västervik',
    '593 23': 'Västervik',
    '593 24': 'Västervik',
    '593 25': 'Västervik',
    '593 26': 'Västervik',
    '593 30': 'Västervik',
    '593 31': 'Västervik',
    '593 32': 'Västervik',
    '593 33': 'Västervik',
    '593 34': 'Västervik',
    '593 35': 'Västervik',
    '593 36': 'Västervik',
    '593 37': 'Västervik',
    '593 38': 'Västervik',
    '593 39': 'Västervik',
    '593 40': 'Västervik',
    '593 41': 'Västervik',
    '593 42': 'Västervik',
    '593 43': 'Västervik',
    '593 50': 'Västervik',
    '593 51': 'Västervik',
    '593 52': 'Västervik',
    '593 53': 'Västervik',
    '593 54': 'Västervik',
    '593 61': 'Västervik',
    '593 62': 'Västervik',
    '593 80': 'Västervik',
    '593 81': 'Västervik',
    '593 82': 'Västervik',
    '593 83': 'Västervik',
    '593 84': 'Västervik',
    '593 85': 'Västervik',
    '593 87': 'Västervik',
    '593 91': 'Västervik',
    '593 92': 'Västervik',
    '593 93': 'Västervik',
    '593 95': 'Västervik',
    '593 96': 'Västervik',
    '594 01': 'Gamleby',
    '594 21': 'Gamleby',
    '594 22': 'Gamleby',
    '594 23': 'Gamleby',
    '594 30': 'Gamleby',
    '594 31': 'Gamleby',
    '594 32': 'Gamleby',
    '594 91': 'Gamleby',
    '594 92': 'Gamleby',
    '594 93': 'Gamleby',
    '570 30': 'Mariannelund',
    '577 51': 'Silverdalen',
    '577 94': 'Lönneberga',
    '590 42': 'Horn',
    '590 80': 'Södra Vi',
    '590 81': 'Gullringen',
    '590 83': 'Storebro',
    '590 90': 'Ankarsrum',
    '590 91': 'Hjorted',
    '590 92': 'Totebo',
    '590 94': 'Blackstad',
    '594 94': 'Gamleby',
    '598 01': 'Vimmerby',
    '598 21': 'Vimmerby',
    '598 22': 'Vimmerby',
    '598 23': 'Vimmerby',
    '598 24': 'Vimmerby',
    '598 30': 'Vimmerby',
    '598 31': 'Vimmerby',
    '598 32': 'Vimmerby',
    '598 34': 'Vimmerby',
    '598 35': 'Vimmerby',
    '598 36': 'Vimmerby',
    '598 37': 'Vimmerby',
    '598 38': 'Vimmerby',
    '598 39': 'Vimmerby',
    '598 40': 'Vimmerby',
    '598 80': 'Vimmerby',
    '598 81': 'Vimmerby',
    '598 82': 'Vimmerby',
    '598 83': 'Vimmerby',
    '598 84': 'Vimmerby',
    '598 85': 'Vimmerby',
    '598 86': 'Vimmerby',
    '598 91': 'Vimmerby',
    '598 92': 'Vimmerby',
    '598 93': 'Vimmerby',
    '598 94': 'Vimmerby',
    '598 95': 'Vimmerby',
    '598 96': 'Vimmerby',
    '380 74': 'Löttorp',
    '380 75': 'Byxelkrok',
    '386 94': 'Färjestaden',
    '386 95': 'Färjestaden',
    '387 01': 'Borgholm',
    '387 21': 'Borgholm',
    '387 22': 'Borgholm',
    '387 23': 'Borgholm',
    '387 24': 'Borgholm',
    '387 30': 'Borgholm',
    '387 31': 'Borgholm',
    '387 32': 'Borgholm',
    '387 33': 'Borgholm',
    '387 34': 'Borgholm',
    '387 35': 'Borgholm',
    '387 36': 'Borgholm',
    '387 37': 'Borgholm',
    '387 38': 'Borgholm',
    '387 50': 'Köpingsvik',
    '387 51': 'Köpingsvik',
    '387 52': 'Köpingsvik',
    '387 88': 'Borgholm',
    '387 90': 'Köpingsvik',
    '387 91': 'Borgholm',
    '387 92': 'Borgholm',
    '387 93': 'Borgholm',
    '387 94': 'Borgholm',
    '387 95': 'Köpingsvik',
    '387 96': 'Köpingsvik',
    '361 20': 'Emmaboda',
    '382 20': 'Nybro',
    '383 20': 'Mönsterås',
    '384 20': 'Blomstermåla',
    '385 20': 'Torsås',
    '386 20': 'Färjestaden',
    '387 20': 'Borgholm',
    '388 20': 'Ljungbyholm',
    '393 49': 'Kalmar',
    '393 56': 'Kalmar',
    '398 00': 'Kalmar',
    '398 01': 'Kalmar',
    '398 02': 'Kalmar',
    '398 03': 'Kalmar',
    '398 04': 'Kalmar',
    '398 06': 'Kalmar',
    '398 07': 'Kalmar',
    '398 08': 'Kalmar',
    '572 20': 'Oskarshamn',
    '577 20': 'Hultsfred',
    '579 20': 'Högsby',
    '593 20': 'Västervik',
    '593 86': 'Västervik',
    '594 20': 'Gamleby',
    '598 20': 'Vimmerby',
    '620 10': 'Burgsvik',
    '620 11': 'Havdhem',
    '620 12': 'Hemse',
    '620 13': 'Stånga',
    '620 16': 'Ljugarn',
    '620 20': 'Klintehamn',
    '620 23': 'Romakloster',
    '620 24': 'Dalhem',
    '620 30': 'Slite',
    '620 33': 'Tingstäde',
    '620 34': 'Lärbro',
    '620 35': 'Fårösund',
    '621 00': 'Visby',
    '621 05': 'Visby',
    '621 11': 'Visby',
    '621 12': 'Visby',
    '621 13': 'Visby',
    '621 14': 'Visby',
    '621 15': 'Visby',
    '621 16': 'Visby',
    '621 21': 'Visby',
    '621 22': 'Visby',
    '621 23': 'Visby',
    '621 24': 'Visby',
    '621 25': 'Visby',
    '621 26': 'Visby',
    '621 41': 'Visby',
    '621 42': 'Visby',
    '621 43': 'Visby',
    '621 44': 'Visby',
    '621 45': 'Visby',
    '621 46': 'Visby',
    '621 47': 'Visby',
    '621 48': 'Visby',
    '621 49': 'Visby',
    '621 50': 'Visby',
    '621 51': 'Visby',
    '621 52': 'Visby',
    '621 53': 'Visby',
    '621 54': 'Visby',
    '621 55': 'Visby',
    '621 56': 'Visby',
    '621 57': 'Visby',
    '621 58': 'Visby',
    '621 65': 'Visby',
    '621 67': 'Visby',
    '621 70': 'Visby',
    '621 71': 'Visby',
    '621 72': 'Visby',
    '621 73': 'Visby',
    '621 74': 'Visby',
    '621 75': 'Visby',
    '621 76': 'Visby',
    '621 77': 'Visby',
    '621 78': 'Visby',
    '621 79': 'Visby',
    '621 80': 'Visby',
    '621 81': 'Visby',
    '621 82': 'Visby',
    '621 83': 'Visby',
    '621 84': 'Visby',
    '621 85': 'Visby',
    '621 86': 'Visby',
    '621 87': 'Visby',
    '621 88': 'Visby',
    '621 89': 'Visby',
    '621 90': 'Visby',
    '621 91': 'Visby',
    '621 92': 'Visby',
    '621 93': 'Visby',
    '621 94': 'Visby',
    '621 95': 'Visby',
    '621 96': 'Visby',
    '621 97': 'Visby',
    '621 98': 'Visby',
    '621 99': 'Visby',
    '621 19': 'Visby',
    '621 20': 'Visby',
    '621 29': 'Visby',
    '621 37': 'Visby',
    '621 38': 'Visby',
    '621 39': 'Visby',
    '622 21': 'Romakloster',
    '622 34': 'Romakloster',
    '622 36': 'Romakloster',
    '622 38': 'Romakloster',
    '622 40': 'Romakloster',
    '622 42': 'Romakloster',
    '622 44': 'Romakloster',
    '622 46': 'Romakloster',
    '622 48': 'Romakloster',
    '622 50': 'Romakloster',
    '622 52': 'Romakloster',
    '622 54': 'Romakloster',
    '622 56': 'Dalhem',
    '622 58': 'Romakloster',
    '622 59': 'Visby',
    '622 60': 'Visby',
    '622 75': 'Visby',
    '622 76': 'Visby',
    '623 00': 'Hemse',
    '623 11': 'Hemse',
    '623 12': 'Hemse',
    '623 13': 'Hemse',
    '623 14': 'Klintehamn',
    '623 15': 'Stånga',
    '623 16': 'Havdhem',
    '623 20': 'Hemse',
    '623 21': 'Ljugarn',
    '623 25': 'Ljugarn',
    '623 30': 'Burgsvik',
    '623 31': 'Burgsvik',
    '623 32': 'Burgsvik',
    '623 33': 'Burgsvik',
    '623 35': 'Burgsvik',
    '623 36': 'Burgsvik',
    '623 37': 'Havdhem',
    '623 38': 'Havdhem',
    '623 40': 'Havdhem',
    '623 41': 'Havdhem',
    '623 42': 'Havdhem',
    '623 43': 'Havdhem',
    '623 44': 'Klintehamn',
    '623 46': 'Hemse',
    '623 47': 'Hemse',
    '623 48': 'Stånga',
    '623 49': 'Stånga',
    '623 50': 'Hemse',
    '623 51': 'Hemse',
    '623 52': 'Hemse',
    '623 53': 'Hemse',
    '623 54': 'Klintehamn',
    '623 55': 'Klintehamn',
    '623 56': 'Hemse',
    '623 57': 'Hemse',
    '623 59': 'Hemse',
    '623 60': 'Stånga',
    '623 61': 'Stånga',
    '623 62': 'Ljugarn',
    '623 63': 'Ljugarn',
    '623 64': 'Ljugarn',
    '623 65': 'Ljugarn',
    '623 66': 'Ljugarn',
    '623 67': 'Katthammarsvik',
    '623 68': 'Katthammarsvik',
    '623 69': 'Katthammarsvik',
    '623 70': 'Katthammarsvik',
    '623 71': 'Katthammarsvik',
    '623 72': 'Katthammarsvik',
    '623 74': 'Stånga',
    '623 75': 'Klintehamn',
    '623 76': 'Klintehamn',
    '623 77': 'Klintehamn',
    '623 78': 'Klintehamn',
    '623 79': 'Klintehamn',
    '624 00': 'Lärbro',
    '624 20': 'Slite',
    '624 21': 'Slite',
    '624 22': 'Slite',
    '624 30': 'Slite',
    '624 32': 'Slite',
    '624 34': 'Slite',
    '624 36': 'Slite',
    '624 38': 'Tingstäde',
    '624 40': 'Tingstäde',
    '624 42': 'Tingstäde',
    '624 44': 'Tingstäde',
    '624 46': 'Slite',
    '624 48': 'Slite',
    '624 49': 'Slite',
    '624 50': 'Lärbro',
    '624 52': 'Lärbro',
    '624 53': 'Lärbro',
    '624 54': 'Lärbro',
    '624 55': 'Lärbro',
    '624 56': 'Lärbro',
    '624 58': 'Lärbro',
    '624 60': 'Lärbro',
    '624 62': 'Fårösund',
    '624 64': 'Fårösund',
    '624 65': 'Fårösund',
    '624 70': 'Fårösund',
    '293 00': 'Olofström',
    '293 02': 'Jämshög',
    '293 21': 'Olofström',
    '293 22': 'Olofström',
    '293 23': 'Olofström',
    '293 24': 'Olofström',
    '293 31': 'Olofström',
    '293 32': 'Olofström',
    '293 33': 'Olofström',
    '293 34': 'Olofström',
    '293 35': 'Olofström',
    '293 36': 'Olofström',
    '293 37': 'Olofström',
    '293 38': 'Olofström',
    '293 39': 'Olofström',
    '293 40': 'Olofström',
    '293 41': 'Olofström',
    '293 42': 'Olofström',
    '293 73': 'Jämshög',
    '293 80': 'Olofström',
    '293 91': 'Olofström',
    '293 93': 'Olofström',
    '360 10': 'Ryd',
    '370 22': 'Drottningskär',
    '370 23': 'Hasslö',
    '370 30': 'Rödeby',
    '370 42': 'Torhamn',
    '370 43': 'Sturkö',
    '370 45': 'Fågelmara',
    '371 00': 'Karlskrona',
    '371 01': 'Karlskrona',
    '371 02': 'Karlskrona',
    '371 04': 'Karlskrona',
    '371 05': 'Karlskrona',
    '371 06': 'Karlskrona',
    '371 08': 'Karlskrona',
    '371 21': 'Karlskrona',
    '371 22': 'Karlskrona',
    '371 23': 'Karlskrona',
    '371 24': 'Karlskrona',
    '371 25': 'Karlskrona',
    '371 30': 'Karlskrona',
    '371 31': 'Karlskrona',
    '371 32': 'Karlskrona',
    '371 33': 'Karlskrona',
    '371 34': 'Karlskrona',
    '371 35': 'Karlskrona',
    '371 36': 'Karlskrona',
    '371 37': 'Karlskrona',
    '371 38': 'Karlskrona',
    '371 39': 'Karlskrona',
    '371 40': 'Karlskrona',
    '371 41': 'Karlskrona',
    '371 42': 'Karlskrona',
    '371 43': 'Karlskrona',
    '371 44': 'Karlskrona',
    '371 45': 'Karlskrona',
    '371 46': 'Karlskrona',
    '371 47': 'Karlskrona',
    '371 48': 'Karlskrona',
    '371 49': 'Karlskrona',
    '371 50': 'Karlskrona',
    '371 51': 'Karlskrona',
    '371 52': 'Karlskrona',
    '371 53': 'Karlskrona',
    '371 54': 'Karlskrona',
    '371 60': 'Lyckeby',
    '371 61': 'Lyckeby',
    '371 62': 'Lyckeby',
    '371 63': 'Lyckeby',
    '371 70': 'Karlskrona',
    '371 75': 'Karlskrona',
    '371 79': 'Karlskrona',
    '371 80': 'Karlskrona',
    '371 81': 'Karlskrona',
    '371 82': 'Karlskrona',
    '371 83': 'Karlskrona',
    '371 84': 'Karlskrona',
    '371 85': 'Karlskrona',
    '371 86': 'Karlskrona',
    '371 87': 'Karlskrona',
    '371 88': 'Karlskrona',
    '371 89': 'Karlskrona',
    '371 91': 'Karlskrona',
    '371 92': 'Karlskrona',
    '371 93': 'Karlskrona',
    '371 94': 'Lyckeby',
    '373 00': 'Jämjö',
    '373 02': 'Ramdala',
    '370 10': 'Bräkne-Hoby',
    '370 11': 'Backaryd',
    '370 17': 'Eringsboda',
    '370 24': 'Nättraby',
    '370 33': 'Tving',
    '370 34': 'Holmsjö',
    '372 00': 'Ronneby',
    '372 01': 'Ronneby',
    '372 02': 'Kallinge',
    '372 21': 'Ronneby',
    '372 22': 'Ronneby',
    '372 25': 'Ronneby',
    '372 30': 'Ronneby',
    '372 31': 'Ronneby',
    '372 32': 'Ronneby',
    '372 33': 'Ronneby',
    '372 34': 'Ronneby',
    '372 35': 'Ronneby',
    '372 36': 'Ronneby',
    '372 37': 'Ronneby',
    '372 38': 'Ronneby',
    '372 39': 'Ronneby',
    '372 40': 'Ronneby',
    '372 50': 'Kallinge',
    '372 51': 'Kallinge',
    '372 52': 'Kallinge',
    '372 53': 'Kallinge',
    '372 73': 'Ronneby',
    '372 74': 'Listerby',
    '372 75': 'Johannishus',
    '372 80': 'Ronneby',
    '372 81': 'Ronneby',
    '372 91': 'Ronneby',
    '372 92': 'Kallinge',
    '372 94': 'Listerby',
    '372 95': 'Johannishus',
    '372 97': 'Ronneby',
    '290 60': 'Kyrkhult',
    '370 12': 'Hallabro',
    '374 00': 'Karlshamn',
    '374 01': 'Karlshamn',
    '374 02': 'Asarum',
    '374 21': 'Karlshamn',
    '374 22': 'Karlshamn',
    '374 23': 'Karlshamn',
    '374 24': 'Karlshamn',
    '374 30': 'Karlshamn',
    '374 31': 'Karlshamn',
    '374 32': 'Karlshamn',
    '374 33': 'Karlshamn',
    '374 34': 'Karlshamn',
    '374 35': 'Karlshamn',
    '374 36': 'Karlshamn',
    '374 37': 'Karlshamn',
    '374 38': 'Karlshamn',
    '374 39': 'Karlshamn',
    '374 40': 'Karlshamn',
    '374 41': 'Karlshamn',
    '374 50': 'Asarum',
    '374 51': 'Asarum',
    '374 52': 'Asarum',
    '374 53': 'Asarum',
    '374 70': 'Trensum',
    '374 80': 'Karlshamn',
    '374 81': 'Karlshamn',
    '374 82': 'Karlshamn',
    '374 83': 'Karlshamn',
    '374 91': 'Asarum',
    '374 92': 'Asarum',
    '374 93': 'Karlshamn',
    '374 94': 'Trensum',
    '374 95': 'Trensum',
    '374 96': 'Trensum',
    '375 21': 'Mörrum',
    '375 22': 'Mörrum',
    '375 30': 'Mörrum',
    '375 31': 'Mörrum',
    '375 32': 'Mörrum',
    '375 33': 'Mörrum',
    '375 34': 'Mörrum',
    '375 85': 'Mörrum',
    '375 86': 'Mörrum',
    '375 90': 'Mörrum',
    '375 91': 'Mörrum',
    '376 23': 'Svängsta',
    '376 24': 'Svängsta',
    '376 35': 'Svängsta',
    '376 36': 'Svängsta',
    '376 37': 'Svängsta',
    '376 81': 'Svängsta',
    '376 92': 'Svängsta',
    '376 93': 'Svängsta',
    '294 00': 'Sölvesborg',
    '294 02': 'Sölvesborg',
    '294 05': 'Sölvesborg',
    '294 07': 'Sölvesborg',
    '294 21': 'Sölvesborg',
    '294 22': 'Sölvesborg',
    '294 23': 'Sölvesborg',
    '294 25': 'Sölvesborg',
    '294 31': 'Sölvesborg',
    '294 32': 'Sölvesborg',
    '294 33': 'Sölvesborg',
    '294 34': 'Sölvesborg',
    '294 35': 'Sölvesborg',
    '294 36': 'Sölvesborg',
    '294 37': 'Sölvesborg',
    '294 38': 'Sölvesborg',
    '294 39': 'Sölvesborg',
    '294 71': 'Sölvesborg',
    '294 72': 'Sölvesborg',
    '294 74': 'Sölvesborg',
    '294 75': 'Sölvesborg',
    '294 76': 'Sölvesborg',
    '294 77': 'Sölvesborg',
    '294 80': 'Sölvesborg',
    '294 81': 'Sölvesborg',
    '294 91': 'Sölvesborg',
    '294 92': 'Sölvesborg',
    '294 93': 'Sölvesborg',
    '294 94': 'Sölvesborg',
    '294 95': 'Sölvesborg',
    '293 20': 'Olofström',
    '294 20': 'Sölvesborg',
    '371 07': 'Lyckeby',
    '371 10': 'Karlskrona',
    '371 20': 'Karlskrona',
    '371 55': 'Karlskrona',
    '371 64': 'Lyckeby',
    '371 65': 'Lyckeby',
    '372 18': 'Ronneby',
    '372 19': 'Ronneby',
    '372 20': 'Ronneby',
    '374 20': 'Karlshamn',
    '375 20': 'Mörrum',
    '376 20': 'Svängsta',
    '260 24': 'Röstånga',
    '268 01': 'Svalöv',
    '268 21': 'Svalöv',
    '268 22': 'Svalöv',
    '268 31': 'Svalöv',
    '268 32': 'Svalöv',
    '268 33': 'Svalöv',
    '268 34': 'Svalöv',
    '268 80': 'Svalöv',
    '268 81': 'Svalöv',
    '268 90': 'Svalöv',
    '245 01': 'Staffanstorp',
    '245 02': 'Hjärup',
    '245 18': 'Staffanstorp',
    '245 21': 'Staffanstorp',
    '245 22': 'Staffanstorp',
    '245 23': 'Staffanstorp',
    '245 24': 'Staffanstorp',
    '245 25': 'Staffanstorp',
    '245 31': 'Staffanstorp',
    '245 32': 'Staffanstorp',
    '245 33': 'Staffanstorp',
    '245 34': 'Staffanstorp',
    '245 35': 'Staffanstorp',
    '245 37': 'Staffanstorp',
    '245 38': 'Staffanstorp',
    '245 41': 'Staffanstorp',
    '245 42': 'Staffanstorp',
    '245 43': 'Staffanstorp',
    '245 44': 'Staffanstorp',
    '245 45': 'Staffanstorp',
    '245 61': 'Staffanstorp',
    '245 62': 'Hjärup',
    '245 63': 'Hjärup',
    '245 64': 'Hjärup',
    '245 65': 'Hjärup',
    '245 80': 'Staffanstorp',
    '245 91': 'Staffanstorp',
    '245 92': 'Staffanstorp',
    '245 93': 'Staffanstorp',
    '232 01': 'Arlöv',
    '232 02': 'Åkarp',
    '232 21': 'Arlöv',
    '232 22': 'Arlöv',
    '232 23': 'Arlöv',
    '232 24': 'Arlöv',
    '232 31': 'Arlöv',
    '232 32': 'Arlöv',
    '232 33': 'Arlöv',
    '232 34': 'Arlöv',
    '232 35': 'Arlöv',
    '232 36': 'Arlöv',
    '232 37': 'Arlöv',
    '232 38': 'Arlöv',
    '232 39': 'Arlöv',
    '232 51': 'Åkarp',
    '232 52': 'Åkarp',
    '232 53': 'Åkarp',
    '232 54': 'Åkarp',
    '235 01': 'Vellinge',
    '235 21': 'Vellinge',
    '235 22': 'Vellinge',
    '235 25': 'Vellinge',
    '235 31': 'Vellinge',
    '235 32': 'Vellinge',
    '235 33': 'Vellinge',
    '235 34': 'Vellinge',
    '235 35': 'Vellinge',
    '235 36': 'Vellinge',
    '235 37': 'Vellinge',
    '235 38': 'Vellinge',
    '235 39': 'Vellinge',
    '235 41': 'Vellinge',
    '235 81': 'Vellinge',
    '235 82': 'Vellinge',
    '235 83': 'Vellinge',
    '235 91': 'Vellinge',
    '235 92': 'Vellinge',
    '235 93': 'Vellinge',
    '235 94': 'Vellinge',
    '235 99': 'Vellinge',
    '236 01': 'Höllviken',
    '236 21': 'Höllviken',
    '236 22': 'Höllviken',
    '236 23': 'Höllviken',
    '236 24': 'Höllviken',
    '236 25': 'Höllviken',
    '236 31': 'Höllviken',
    '236 32': 'Höllviken',
    '236 33': 'Höllviken',
    '236 34': 'Höllviken',
    '236 35': 'Höllviken',
    '236 36': 'Höllviken',
    '236 37': 'Höllviken',
    '236 38': 'Höllviken',
    '236 41': 'Höllviken',
    '236 42': 'Höllviken',
    '236 51': 'Höllviken',
    '236 61': 'Höllviken',
    '236 91': 'Höllviken',
    '239 01': 'Skanör',
    '239 21': 'Skanör',
    '239 22': 'Skanör',
    '239 24': 'Skanör',
    '239 30': 'Skanör',
    '239 31': 'Skanör',
    '239 32': 'Skanör',
    '239 33': 'Skanör',
    '239 34': 'Skanör',
    '239 35': 'Skanör',
    '239 40': 'Falsterbo',
    '239 41': 'Falsterbo',
    '239 42': 'Falsterbo',
    '280 60': 'Broby',
    '280 63': 'Sibbhult',
    '289 05': 'Hanaskog',
    '289 21': 'Knislinge',
    '289 31': 'Knislinge',
    '289 32': 'Knislinge',
    '289 33': 'Knislinge',
    '289 50': 'Hanaskog',
    '289 89': 'Hanaskog',
    '289 90': 'Knislinge',
    '286 01': 'Örkelljunga',
    '286 21': 'Örkelljunga',
    '286 22': 'Örkelljunga',
    '286 31': 'Örkelljunga',
    '286 32': 'Örkelljunga',
    '286 33': 'Örkelljunga',
    '286 34': 'Örkelljunga',
    '286 35': 'Örkelljunga',
    '286 36': 'Örkelljunga',
    '286 37': 'Örkelljunga',
    '286 72': 'Åsljunga',
    '286 80': 'Örkelljunga',
    '286 85': 'Örkelljunga',
    '286 91': 'Örkelljunga',
    '286 92': 'Örkelljunga',
    '286 95': 'Eket',
    '260 50': 'Billesholm',
    '267 01': 'Bjuv',
    '267 21': 'Bjuv',
    '267 22': 'Bjuv',
    '267 23': 'Bjuv',
    '267 25': 'Bjuv',
    '267 31': 'Bjuv',
    '267 32': 'Bjuv',
    '267 33': 'Bjuv',
    '267 34': 'Bjuv',
    '267 35': 'Bjuv',
    '267 36': 'Bjuv',
    '267 37': 'Bjuv',
    '267 38': 'Bjuv',
    '267 39': 'Bjuv',
    '267 40': 'Bjuv',
    '267 41': 'Bjuv',
    '267 81': 'Bjuv',
    '267 90': 'Bjuv',
    '244 01': 'Kävlinge',
    '244 02': 'Furulund',
    '244 21': 'Kävlinge',
    '244 22': 'Kävlinge',
    '244 23': 'Kävlinge',
    '244 24': 'Kävlinge',
    '244 30': 'Kävlinge',
    '244 31': 'Kävlinge',
    '244 32': 'Kävlinge',
    '244 33': 'Kävlinge',
    '244 36': 'Kävlinge',
    '244 38': 'Kävlinge',
    '244 39': 'Kävlinge',
    '244 41': 'Kävlinge',
    '244 60': 'Furulund',
    '244 62': 'Furulund',
    '244 63': 'Furulund',
    '244 65': 'Furulund',
    '244 66': 'Furulund',
    '244 71': 'Dösjebro',
    '244 80': 'Kävlinge',
    '244 82': 'Kävlinge',
    '244 91': 'Kävlinge',
    '244 93': 'Kävlinge',
    '244 94': 'Furulund',
    '244 95': 'Dösjebro',
    '246 01': 'Löddeköpinge',
    '246 02': 'Löddeköpinge',
    '246 21': 'Löddeköpinge',
    '246 22': 'Löddeköpinge',
    '246 25': 'Löddeköpinge',
    '246 30': 'Löddeköpinge',
    '246 31': 'Löddeköpinge',
    '246 32': 'Löddeköpinge',
    '246 33': 'Löddeköpinge',
    '246 34': 'Löddeköpinge',
    '246 35': 'Löddeköpinge',
    '246 36': 'Löddeköpinge',
    '246 41': 'Löddeköpinge',
    '246 42': 'Löddeköpinge',
    '246 43': 'Löddeköpinge',
    '246 50': 'Löddeköpinge',
    '246 51': 'Löddeköpinge',
    '246 52': 'Löddeköpinge',
    '246 55': 'Löddeköpinge',
    '246 57': 'Barsebäck',
    '234 01': 'Lomma',
    '234 21': 'Lomma',
    '234 22': 'Lomma',
    '234 23': 'Lomma',
    '234 27': 'Lomma',
    '234 31': 'Lomma',
    '234 32': 'Lomma',
    '234 33': 'Lomma',
    '234 34': 'Lomma',
    '234 35': 'Lomma',
    '234 36': 'Lomma',
    '234 37': 'Lomma',
    '234 38': 'Lomma',
    '234 41': 'Lomma',
    '234 42': 'Lomma',
    '234 43': 'Lomma',
    '234 81': 'Lomma',
    '237 01': 'Bjärred',
    '237 21': 'Bjärred',
    '237 22': 'Bjärred',
    '237 23': 'Bjärred',
    '237 24': 'Bjärred',
    '237 25': 'Bjärred',
    '237 31': 'Bjärred',
    '237 32': 'Bjärred',
    '237 33': 'Bjärred',
    '237 34': 'Bjärred',
    '237 35': 'Bjärred',
    '237 36': 'Bjärred',
    '237 37': 'Bjärred',
    '237 41': 'Bjärred',
    '237 91': 'Bjärred',
    '233 01': 'Svedala',
    '233 21': 'Svedala',
    '233 22': 'Svedala',
    '233 23': 'Svedala',
    '233 24': 'Svedala',
    '233 25': 'Svedala',
    '233 26': 'Svedala',
    '233 31': 'Svedala',
    '233 32': 'Svedala',
    '233 33': 'Svedala',
    '233 34': 'Svedala',
    '233 35': 'Svedala',
    '233 36': 'Svedala',
    '233 37': 'Svedala',
    '233 38': 'Svedala',
    '233 39': 'Svedala',
    '233 41': 'Svedala',
    '233 42': 'Svedala',
    '233 51': 'Svedala',
    '233 80': 'Svedala',
    '233 81': 'Svedala',
    '233 91': 'Svedala',
    '233 92': 'Svedala',
    '233 93': 'Svedala',
    '233 94': 'Svedala',
    '274 01': 'Skurup',
    '274 02': 'Skivarp',
    '274 03': 'Rydsgård',
    '274 04': 'Abbekås',
    '274 21': 'Skurup',
    '274 22': 'Skurup',
    '274 23': 'Skurup',
    '274 24': 'Skurup',
    '274 30': 'Skurup',
    '274 31': 'Skurup',
    '274 32': 'Skurup',
    '274 33': 'Skurup',
    '274 34': 'Skurup',
    '274 35': 'Skurup',
    '274 36': 'Skurup',
    '274 37': 'Skurup',
    '274 50': 'Skivarp',
    '274 51': 'Skivarp',
    '274 53': 'Skivarp',
    '274 54': 'Skivarp',
    '274 56': 'Abbekås',
    '274 60': 'Rydsgård',
    '274 61': 'Rydsgård',
    '274 63': 'Rydsgård',
    '274 80': 'Skurup',
    '274 91': 'Skurup',
    '274 92': 'Skurup',
    '274 93': 'Skurup',
    '274 94': 'Skurup',
    '273 70': 'Lövestad',
    '273 71': 'Lövestad',
    '273 72': 'Lövestad',
    '273 75': 'Lövestad',
    '275 00': 'Sjöbo',
    '275 21': 'Sjöbo',
    '275 22': 'Sjöbo',
    '275 23': 'Sjöbo',
    '275 30': 'Sjöbo',
    '275 31': 'Sjöbo',
    '275 32': 'Sjöbo',
    '275 33': 'Sjöbo',
    '275 34': 'Sjöbo',
    '275 35': 'Sjöbo',
    '275 36': 'Sjöbo',
    '275 37': 'Sjöbo',
    '275 38': 'Sjöbo',
    '275 39': 'Sjöbo',
    '275 80': 'Sjöbo',
    '275 91': 'Sjöbo',
    '275 92': 'Sjöbo',
    '275 93': 'Sjöbo',
    '275 94': 'Sjöbo',
    '275 95': 'Sjöbo',
    '275 96': 'Sövde',
    '242 01': 'Hörby',
    '242 21': 'Hörby',
    '242 22': 'Hörby',
    '242 24': 'Hörby',
    '242 30': 'Hörby',
    '242 31': 'Hörby',
    '242 32': 'Hörby',
    '242 33': 'Hörby',
    '242 34': 'Hörby',
    '242 35': 'Hörby',
    '242 80': 'Hörby',
    '242 91': 'Hörby',
    '242 92': 'Hörby',
    '242 93': 'Hörby',
    '242 94': 'Hörby',
    '242 95': 'Hörby',
    '242 96': 'Hörby',
    '242 97': 'Hörby',
    '242 98': 'Hörby',
    '270 33': 'Vollsjö',
    '243 01': 'Höör',
    '243 21': 'Höör',
    '243 22': 'Höör',
    '243 23': 'Höör',
    '243 26': 'Höör',
    '243 30': 'Höör',
    '243 31': 'Höör',
    '243 32': 'Höör',
    '243 33': 'Höör',
    '243 34': 'Höör',
    '243 35': 'Höör',
    '243 36': 'Höör',
    '243 39': 'Höör',
    '243 91': 'Höör',
    '243 92': 'Höör',
    '243 93': 'Höör',
    '243 94': 'Höör',
    '243 95': 'Höör',
    '270 31': 'Äsperöd',
    '273 01': 'Tomelilla',
    '273 03': 'Tomelilla',
    '273 21': 'Tomelilla',
    '273 22': 'Tomelilla',
    '273 30': 'Tomelilla',
    '273 31': 'Tomelilla',
    '273 32': 'Tomelilla',
    '273 33': 'Tomelilla',
    '273 34': 'Tomelilla',
    '273 35': 'Tomelilla',
    '273 36': 'Tomelilla',
    '273 80': 'Tomelilla',
    '273 91': 'Tomelilla',
    '273 92': 'Tomelilla',
    '273 93': 'Tomelilla',
    '273 94': 'Tomelilla',
    '273 95': 'Tomelilla',
    '273 96': 'Tomelilla',
    '273 97': 'Tomelilla',
    '277 50': 'Brösarp',
    '277 57': 'Brösarp',
    '293 72': 'Jämshög',
    '293 92': 'Jämshög',
    '295 00': 'Bromölla',
    '295 04': 'Näsum',
    '295 21': 'Bromölla',
    '295 22': 'Bromölla',
    '295 23': 'Bromölla',
    '295 31': 'Bromölla',
    '295 32': 'Bromölla',
    '295 34': 'Bromölla',
    '295 35': 'Bromölla',
    '295 36': 'Bromölla',
    '295 37': 'Bromölla',
    '295 38': 'Bromölla',
    '295 39': 'Bromölla',
    '295 72': 'Gualöv',
    '295 73': 'Nymölla',
    '295 74': 'Näsum',
    '295 80': 'Nymölla',
    '295 91': 'Bromölla',
    '280 64': 'Glimåkra',
    '280 70': 'Lönsboda',
    '280 72': 'Killeberg',
    '283 01': 'Osby',
    '283 21': 'Osby',
    '283 22': 'Osby',
    '283 23': 'Osby',
    '283 31': 'Osby',
    '283 32': 'Osby',
    '283 33': 'Osby',
    '283 34': 'Osby',
    '283 35': 'Osby',
    '283 36': 'Osby',
    '283 41': 'Osby',
    '283 42': 'Osby',
    '283 43': 'Osby',
    '283 44': 'Osby',
    '283 45': 'Osby',
    '283 50': 'Osby',
    '283 80': 'Osby',
    '283 83': 'Osby',
    '283 91': 'Osby',
    '283 95': 'Visseltofta',
    '284 01': 'Perstorp',
    '284 21': 'Perstorp',
    '284 22': 'Perstorp',
    '284 31': 'Perstorp',
    '284 32': 'Perstorp',
    '284 33': 'Perstorp',
    '284 34': 'Perstorp',
    '284 35': 'Perstorp',
    '284 36': 'Perstorp',
    '284 37': 'Perstorp',
    '284 38': 'Perstorp',
    '284 80': 'Perstorp',
    '284 85': 'Perstorp',
    '284 91': 'Perstorp',
    '284 92': 'Perstorp',
    '260 23': 'Kågeröd',
    '260 70': 'Ljungbyhed',
    '264 01': 'Klippan',
    '264 02': 'Östra Ljungby',
    '264 21': 'Klippan',
    '264 22': 'Klippan',
    '264 23': 'Klippan',
    '264 31': 'Klippan',
    '264 32': 'Klippan',
    '264 33': 'Klippan',
    '264 34': 'Klippan',
    '264 35': 'Klippan',
    '264 36': 'Klippan',
    '264 37': 'Klippan',
    '264 38': 'Klippan',
    '264 39': 'Klippan',
    '264 70': 'Klippan',
    '264 71': 'Östra Ljungby',
    '264 80': 'Klippan',
    '264 91': 'Klippan',
    '264 92': 'Klippan',
    '264 93': 'Klippan',
    '264 94': 'Klippan',
    '260 60': 'Kvidinge',
    '260 61': 'Hyllinge',
    '265 01': 'Åstorp',
    '265 21': 'Åstorp',
    '265 22': 'Åstorp',
    '265 24': 'Åstorp',
    '265 25': 'Åstorp',
    '265 31': 'Åstorp',
    '265 32': 'Åstorp',
    '265 33': 'Åstorp',
    '265 34': 'Åstorp',
    '265 35': 'Åstorp',
    '265 36': 'Åstorp',
    '265 37': 'Åstorp',
    '265 38': 'Åstorp',
    '265 39': 'Åstorp',
    '265 40': 'Åstorp',
    '265 50': 'Nyvång',
    '265 80': 'Åstorp',
    '265 81': 'Åstorp',
    '265 82': 'Åstorp',
    '265 90': 'Åstorp',
    '260 92': 'Förslöv',
    '260 93': 'Torekov',
    '269 01': 'Båstad',
    '269 02': 'Grevie',
    '269 21': 'Båstad',
    '269 22': 'Båstad',
    '269 25': 'Båstad',
    '269 31': 'Båstad',
    '269 32': 'Båstad',
    '269 33': 'Båstad',
    '269 34': 'Båstad',
    '269 35': 'Båstad',
    '269 36': 'Båstad',
    '269 37': 'Båstad',
    '269 38': 'Båstad',
    '269 39': 'Båstad',
    '269 40': 'Båstad',
    '269 41': 'Östra Karup',
    '269 42': 'Båstad',
    '269 62': 'Grevie',
    '269 74': 'Västra Karup',
    '269 80': 'Båstad',
    '269 81': 'Båstad',
    '269 82': 'Båstad',
    '269 83': 'Båstad',
    '269 84': 'Båstad',
    '269 91': 'Båstad',
    '269 92': 'Båstad',
    '269 93': 'Båstad',
    '269 94': 'Båstad',
    '269 95': 'Båstad',
    '269 96': 'Båstad',
    '232 91': 'Arlöv',
    '238 01': 'Oxie',
    '238 21': 'Oxie',
    '238 22': 'Oxie',
    '238 23': 'Oxie',
    '238 30': 'Oxie',
    '238 31': 'Oxie',
    '238 32': 'Oxie',
    '238 33': 'Oxie',
    '238 34': 'Oxie',
    '238 35': 'Oxie',
    '238 36': 'Oxie',
    '238 37': 'Oxie',
    '238 38': 'Oxie',
    '238 39': 'Oxie',
    '238 40': 'Oxie',
    '238 41': 'Oxie',
    '238 42': 'Oxie',
    '238 43': 'Oxie',
    '240 10': 'Dalby',
    '240 12': 'Torna-Hällestad',
    '240 13': 'Genarp',
    '240 14': 'Veberöd',
    '247 01': 'Södra Sandby',
    '247 21': 'Södra Sandby',
    '247 22': 'Södra Sandby',
    '247 23': 'Södra Sandby',
    '247 25': 'Södra Sandby',
    '247 31': 'Södra Sandby',
    '247 32': 'Södra Sandby',
    '247 33': 'Södra Sandby',
    '247 34': 'Södra Sandby',
    '247 35': 'Södra Sandby',
    '247 36': 'Södra Sandby',
    '247 41': 'Södra Sandby',
    '247 81': 'Södra Sandby',
    '247 82': 'Södra Sandby',
    '247 91': 'Södra Sandby',
    '247 92': 'Södra Sandby',
    '260 13': 'Sankt Ibb',
    '260 21': 'Billeberga',
    '261 00': 'Landskrona',
    '261 03': 'Landskrona',
    '261 04': 'Landskrona',
    '261 06': 'Landskrona',
    '261 07': 'Asmundtorp',
    '261 08': 'Glumslöv',
    '261 22': 'Landskrona',
    '261 23': 'Landskrona',
    '261 24': 'Landskrona',
    '261 25': 'Landskrona',
    '261 26': 'Landskrona',
    '261 27': 'Landskrona',
    '261 31': 'Landskrona',
    '261 32': 'Landskrona',
    '261 33': 'Landskrona',
    '261 34': 'Landskrona',
    '261 35': 'Landskrona',
    '261 36': 'Landskrona',
    '261 37': 'Landskrona',
    '261 38': 'Landskrona',
    '261 39': 'Landskrona',
    '261 40': 'Landskrona',
    '261 41': 'Landskrona',
    '261 42': 'Landskrona',
    '261 43': 'Landskrona',
    '261 44': 'Landskrona',
    '261 45': 'Landskrona',
    '261 46': 'Landskrona',
    '261 47': 'Landskrona',
    '261 51': 'Landskrona',
    '261 52': 'Landskrona',
    '261 53': 'Landskrona',
    '261 61': 'Landskrona',
    '261 62': 'Glumslöv',
    '261 63': 'Glumslöv',
    '261 65': 'Landskrona',
    '261 71': 'Landskrona',
    '261 75': 'Asmundtorp',
    '261 76': 'Asmundtorp',
    '261 80': 'Landskrona',
    '261 91': 'Landskrona',
    '261 92': 'Landskrona',
    '261 93': 'Landskrona',
    '261 94': 'Landskrona',
    '250 02': 'Helsingborg',
    '250 03': 'Helsingborg',
    '250 04': 'Helsingborg',
    '250 05': 'Helsingborg',
    '250 06': 'Helsingborg',
    '250 07': 'Helsingborg',
    '250 08': 'Helsingborg',
    '250 09': 'Helsingborg',
    '250 13': 'Helsingborg',
    '250 14': 'Helsingborg',
    '250 15': 'Helsingborg',
    '250 16': 'Råå',
    '250 18': 'Helsingborg',
    '250 19': 'Helsingborg',
    '250 20': 'Helsingborg',
    '250 22': 'Helsingborg',
    '250 23': 'Helsingborg',
    '250 24': 'Helsingborg',
    '250 25': 'Helsingborg',
    '250 53': 'Helsingborg',
    '250 54': 'Helsingborg',
    '251 00': 'Helsingborg',
    '251 01': 'Helsingborg',
    '251 06': 'Helsingborg',
    '251 07': 'Helsingborg',
    '251 08': 'Helsingborg',
    '251 09': 'Helsingborg',
    '251 10': 'Helsingborg',
    '251 11': 'Helsingborg',
    '251 12': 'Helsingborg',
    '251 13': 'Helsingborg',
    '251 14': 'Helsingborg',
    '251 81': 'Helsingborg',
    '251 82': 'Ramlösa',
    '251 83': 'Helsingborg',
    '251 85': 'Helsingborg',
    '251 87': 'Helsingborg',
    '251 89': 'Helsingborg',
    '252 00': 'Helsingborg',
    '252 01': 'Helsingborg',
    '252 02': 'Helsingborg',
    '252 20': 'Helsingborg',
    '252 21': 'Helsingborg',
    '252 22': 'Helsingborg',
    '252 23': 'Helsingborg',
    '252 24': 'Helsingborg',
    '252 25': 'Helsingborg',
    '252 26': 'Helsingborg',
    '252 27': 'Helsingborg',
    '252 28': 'Helsingborg',
    '252 29': 'Helsingborg',
    '252 30': 'Helsingborg',
    '252 31': 'Helsingborg',
    '252 32': 'Helsingborg',
    '252 34': 'Helsingborg',
    '252 44': 'Helsingborg',
    '252 45': 'Helsingborg',
    '252 46': 'Helsingborg',
    '252 47': 'Helsingborg',
    '252 48': 'Helsingborg',
    '252 49': 'Helsingborg',
    '252 50': 'Helsingborg',
    '252 51': 'Helsingborg',
    '252 52': 'Helsingborg',
    '252 63': 'Helsingborg',
    '252 67': 'Helsingborg',
    '252 68': 'Helsingborg',
    '252 69': 'Råå',
    '252 70': 'Råå',
    '252 71': 'Råå',
    '252 75': 'Helsingborg',
    '252 76': 'Helsingborg',
    '252 77': 'Helsingborg',
    '252 78': 'Helsingborg',
    '252 79': 'Helsingborg',
    '252 80': 'Helsingborg',
    '252 84': 'Helsingborg',
    '252 85': 'Helsingborg',
    '252 86': 'Helsingborg',
    '253 00': 'Helsingborg',
    '253 60': 'Ramlösa',
    '253 61': 'Helsingborg',
    '253 62': 'Helsingborg',
    '253 68': 'Helsingborg',
    '253 73': 'Gantofta',
    '253 74': 'Helsingborg',
    '253 75': 'Helsingborg',
    '254 00': 'Helsingborg',
    '254 01': 'Helsingborg',
    '254 33': 'Helsingborg',
    '254 35': 'Helsingborg',
    '254 36': 'Helsingborg',
    '254 37': 'Helsingborg',
    '254 38': 'Helsingborg',
    '254 39': 'Helsingborg',
    '254 40': 'Helsingborg',
    '254 41': 'Helsingborg',
    '254 42': 'Helsingborg',
    '254 43': 'Helsingborg',
    '254 44': 'Helsingborg',
    '254 50': 'Helsingborg',
    '254 51': 'Helsingborg',
    '254 52': 'Helsingborg',
    '254 53': 'Helsingborg',
    '254 54': 'Helsingborg',
    '254 55': 'Helsingborg',
    '254 56': 'Helsingborg',
    '254 57': 'Helsingborg',
    '254 58': 'Helsingborg',
    '254 60': 'Helsingborg',
    '254 61': 'Helsingborg',
    '254 62': 'Helsingborg',
    '254 63': 'Helsingborg',
    '254 64': 'Helsingborg',
    '254 65': 'Helsingborg',
    '254 66': 'Helsingborg',
    '254 67': 'Helsingborg',
    '254 68': 'Helsingborg',
    '255 91': 'Helsingborg',
    '255 92': 'Helsingborg',
    '256 00': 'Helsingborg',
    '256 54': 'Ramlösa',
    '256 55': 'Helsingborg',
    '256 56': 'Helsingborg',
    '256 57': 'Ramlösa',
    '256 58': 'Helsingborg',
    '256 59': 'Helsingborg',
    '256 61': 'Helsingborg',
    '256 62': 'Helsingborg',
    '256 63': 'Helsingborg',
    '256 64': 'Helsingborg',
    '256 65': 'Helsingborg',
    '256 66': 'Helsingborg',
    '256 67': 'Helsingborg',
    '256 68': 'Helsingborg',
    '256 69': 'Helsingborg',
    '257 21': 'Rydebäck',
    '257 30': 'Rydebäck',
    '257 31': 'Rydebäck',
    '257 32': 'Rydebäck',
    '257 33': 'Rydebäck',
    '260 22': 'Tågarp',
    '260 30': 'Vallåkra',
    '260 33': 'Påarp',
    '260 34': 'Mörarp',
    '260 35': 'Ödåkra',
    '260 36': 'Ödåkra-Väla',
    '260 38': 'Kattarp',
    '260 51': 'Ekeby',
    '260 41': 'Nyhamnsläge',
    '260 42': 'Mölle',
    '260 43': 'Arild',
    '263 00': 'Höganäs',
    '263 02': 'Jonstorp',
    '263 21': 'Höganäs',
    '263 22': 'Höganäs',
    '263 23': 'Höganäs',
    '263 25': 'Höganäs',
    '263 31': 'Höganäs',
    '263 32': 'Höganäs',
    '263 33': 'Höganäs',
    '263 34': 'Höganäs',
    '263 36': 'Höganäs',
    '263 37': 'Höganäs',
    '263 38': 'Höganäs',
    '263 39': 'Höganäs',
    '263 51': 'Höganäs',
    '263 52': 'Lerberget',
    '263 53': 'Lerberget',
    '263 54': 'Lerberget',
    '263 57': 'Höganäs',
    '263 58': 'Höganäs',
    '263 61': 'Viken',
    '263 62': 'Viken',
    '263 63': 'Viken',
    '263 65': 'Viken',
    '263 71': 'Jonstorp',
    '263 82': 'Höganäs',
    '263 83': 'Höganäs',
    '263 91': 'Höganäs',
    '263 92': 'Jonstorp',
    '263 93': 'Höganäs',
    '240 30': 'Marieholm',
    '240 32': 'Flyinge',
    '240 33': 'Löberöd',
    '240 35': 'Harlösa',
    '240 36': 'Stehag',
    '241 03': 'Billinge',
    '241 10': 'Eslöv',
    '241 21': 'Eslöv',
    '241 22': 'Eslöv',
    '241 23': 'Eslöv',
    '241 24': 'Eslöv',
    '241 25': 'Eslöv',
    '241 26': 'Eslöv',
    '241 30': 'Eslöv',
    '241 31': 'Eslöv',
    '241 32': 'Eslöv',
    '241 33': 'Eslöv',
    '241 34': 'Eslöv',
    '241 35': 'Eslöv',
    '241 36': 'Eslöv',
    '241 37': 'Eslöv',
    '241 38': 'Eslöv',
    '241 39': 'Eslöv',
    '241 80': 'Eslöv',
    '241 81': 'Eslöv',
    '241 91': 'Eslöv',
    '241 92': 'Eslöv',
    '241 93': 'Eslöv',
    '241 94': 'Eslöv',
    '241 95': 'Billinge',
    '241 96': 'Stockamöllan',
    '260 20': 'Teckomatorp',
    '270 21': 'Glemmingebro',
    '270 22': 'Köpingebro',
    '270 35': 'Blentarp',
    '271 00': 'Ystad',
    '271 01': 'Ystad',
    '271 02': 'Ystad',
    '271 21': 'Ystad',
    '271 22': 'Ystad',
    '271 23': 'Ystad',
    '271 24': 'Ystad',
    '271 25': 'Ystad',
    '271 31': 'Ystad',
    '271 32': 'Ystad',
    '271 33': 'Ystad',
    '271 34': 'Ystad',
    '271 35': 'Ystad',
    '271 36': 'Ystad',
    '271 37': 'Ystad',
    '271 38': 'Ystad',
    '271 39': 'Ystad',
    '271 40': 'Ystad',
    '271 41': 'Ystad',
    '271 42': 'Ystad',
    '271 43': 'Ystad',
    '271 44': 'Ystad',
    '271 45': 'Ystad',
    '271 50': 'Ystad',
    '271 51': 'Ystad',
    '271 52': 'Ystad',
    '271 53': 'Ystad',
    '271 54': 'Ystad',
    '271 55': 'Ystad',
    '271 60': 'Ystad',
    '271 80': 'Ystad',
    '271 81': 'Ystad',
    '271 82': 'Ystad',
    '271 83': 'Ystad',
    '271 91': 'Ystad',
    '271 92': 'Ystad',
    '271 93': 'Ystad',
    '271 94': 'Ystad',
    '271 95': 'Ystad',
    '271 96': 'Ystad',
    '271 97': 'Ystad',
    '271 98': 'Ystad',
    '271 99': 'Ystad',
    '274 62': 'Rydsgård',
    '276 40': 'Löderup',
    '276 45': 'Löderup',
    '276 46': 'Löderup',
    '231 03': 'Trelleborg',
    '231 05': 'Smygehamn',
    '231 06': 'Beddingestrand',
    '231 08': 'Anderslöv',
    '231 10': 'Trelleborg',
    '231 21': 'Trelleborg',
    '231 22': 'Trelleborg',
    '231 23': 'Trelleborg',
    '231 24': 'Trelleborg',
    '231 25': 'Trelleborg',
    '231 27': 'Trelleborg',
    '231 32': 'Trelleborg',
    '231 33': 'Trelleborg',
    '231 34': 'Trelleborg',
    '231 36': 'Trelleborg',
    '231 38': 'Trelleborg',
    '231 42': 'Trelleborg',
    '231 43': 'Trelleborg',
    '231 44': 'Trelleborg',
    '231 45': 'Trelleborg',
    '231 51': 'Trelleborg',
    '231 52': 'Trelleborg',
    '231 53': 'Trelleborg',
    '231 54': 'Trelleborg',
    '231 55': 'Trelleborg',
    '231 56': 'Trelleborg',
    '231 61': 'Trelleborg',
    '231 62': 'Trelleborg',
    '231 63': 'Trelleborg',
    '231 64': 'Trelleborg',
    '231 65': 'Trelleborg',
    '231 66': 'Trelleborg',
    '231 68': 'Trelleborg',
    '231 70': 'Anderslöv',
    '231 72': 'Anderslöv',
    '231 73': 'Anderslöv',
    '231 75': 'Beddingestrand',
    '231 76': 'Beddingestrand',
    '231 78': 'Smygehamn',
    '231 79': 'Smygehamn',
    '231 81': 'Trelleborg',
    '231 83': 'Trelleborg',
    '231 85': 'Trelleborg',
    '231 87': 'Trelleborg',
    '231 91': 'Trelleborg',
    '231 92': 'Trelleborg',
    '231 93': 'Trelleborg',
    '231 94': 'Trelleborg',
    '231 95': 'Trelleborg',
    '231 96': 'Trelleborg',
    '231 97': 'Klagstorp',
    '231 98': 'Klagstorp',
    '231 99': 'Klagstorp',
    '290 10': 'Tollarp',
    '290 11': 'Linderöd',
    '290 34': 'Fjälkinge',
    '290 37': 'Arkelstorp',
    '290 38': 'Villands Vånga',
    '290 62': 'Vilshult',
    '291 00': 'Kristianstad',
    '291 02': 'Kristianstad',
    '291 03': 'Kristianstad',
    '291 05': 'Kristianstad',
    '291 07': 'Kristianstad',
    '291 08': 'Kristianstad',
    '291 09': 'Kristianstad',
    '291 11': 'Färlöv',
    '291 12': 'Önnestad',
    '291 13': 'Gärds Köpinge',
    '291 14': 'Rinkaby',
    '291 15': 'Kristianstad',
    '291 16': 'Kristianstad',
    '291 21': 'Kristianstad',
    '291 22': 'Kristianstad',
    '291 23': 'Kristianstad',
    '291 25': 'Kristianstad',
    '291 26': 'Kristianstad',
    '291 31': 'Kristianstad',
    '291 32': 'Kristianstad',
    '291 33': 'Kristianstad',
    '291 34': 'Kristianstad',
    '291 35': 'Kristianstad',
    '291 36': 'Kristianstad',
    '291 37': 'Kristianstad',
    '291 38': 'Kristianstad',
    '291 39': 'Kristianstad',
    '291 40': 'Kristianstad',
    '291 41': 'Kristianstad',
    '291 42': 'Kristianstad',
    '291 43': 'Kristianstad',
    '291 44': 'Kristianstad',
    '291 45': 'Kristianstad',
    '291 46': 'Kristianstad',
    '291 47': 'Kristianstad',
    '291 50': 'Kristianstad',
    '291 51': 'Kristianstad',
    '291 53': 'Kristianstad',
    '291 54': 'Kristianstad',
    '291 59': 'Kristianstad',
    '291 60': 'Kristianstad',
    '291 61': 'Kristianstad',
    '291 62': 'Kristianstad',
    '291 63': 'Kristianstad',
    '291 65': 'Kristianstad',
    '291 66': 'Kristianstad',
    '291 70': 'Kristianstad',
    '291 73': 'Önnestad',
    '291 75': 'Färlöv',
    '291 76': 'Rinkaby',
    '291 77': 'Gärds Köpinge',
    '291 80': 'Kristianstad',
    '291 81': 'Kristianstad',
    '291 84': 'Kristianstad',
    '291 85': 'Kristianstad',
    '291 86': 'Kristianstad',
    '291 87': 'Kristianstad',
    '291 88': 'Kristianstad',
    '291 89': 'Kristianstad',
    '291 91': 'Kristianstad',
    '291 92': 'Kristianstad',
    '291 93': 'Önnestad',
    '291 94': 'Kristianstad',
    '291 95': 'Färlöv',
    '291 97': 'Gärds Köpinge',
    '295 94': 'Näsum',
    '296 00': 'Åhus',
    '296 02': 'Yngsjö',
    '296 03': 'Yngsjö',
    '296 21': 'Åhus',
    '296 22': 'Åhus',
    '296 23': 'Åhus',
    '296 24': 'Åhus',
    '296 25': 'Åhus',
    '296 31': 'Åhus',
    '296 32': 'Åhus',
    '296 33': 'Åhus',
    '296 34': 'Åhus',
    '296 35': 'Åhus',
    '296 37': 'Åhus',
    '296 38': 'Åhus',
    '296 72': 'Yngsjö',
    '296 80': 'Åhus',
    '296 81': 'Åhus',
    '296 85': 'Åhus',
    '296 86': 'Åhus',
    '296 91': 'Åhus',
    '296 92': 'Yngsjö',
    '297 01': 'Degeberga',
    '297 02': 'Everöd',
    '297 21': 'Degeberga',
    '297 22': 'Degeberga',
    '297 31': 'Degeberga',
    '297 32': 'Degeberga',
    '297 72': 'Everöd',
    '297 91': 'Vittskövle',
    '297 92': 'Everöd',
    '297 93': 'Huaröd',
    '297 94': 'Degeberga',
    '297 96': 'Östra Sönnarslöv',
    '272 00': 'Simrishamn',
    '272 03': 'Gärsnäs',
    '272 21': 'Simrishamn',
    '272 22': 'Simrishamn',
    '272 23': 'Simrishamn',
    '272 24': 'Simrishamn',
    '272 31': 'Simrishamn',
    '272 32': 'Simrishamn',
    '272 33': 'Simrishamn',
    '272 34': 'Simrishamn',
    '272 35': 'Simrishamn',
    '272 36': 'Simrishamn',
    '272 37': 'Simrishamn',
    '272 38': 'Brantevik',
    '272 39': 'Simrishamn',
    '272 61': 'Gärsnäs',
    '272 80': 'Simrishamn',
    '272 81': 'Simrishamn',
    '272 91': 'Simrishamn',
    '272 92': 'Simrishamn',
    '272 93': 'Tommarp',
    '272 94': 'Simrishamn',
    '272 95': 'Simrishamn',
    '272 96': 'Gärsnäs',
    '272 97': 'Gärsnäs',
    '273 98': 'Smedstorp',
    '276 02': 'Hammenhög',
    '276 03': 'Skillinge',
    '276 21': 'Borrby',
    '276 30': 'Borrby',
    '276 35': 'Borrby',
    '276 36': 'Borrby',
    '276 37': 'Borrby',
    '276 50': 'Hammenhög',
    '276 55': 'Hammenhög',
    '276 56': 'Hammenhög',
    '276 60': 'Skillinge',
    '277 21': 'Kivik',
    '277 30': 'Kivik',
    '277 31': 'Kivik',
    '277 32': 'Kivik',
    '277 35': 'Kivik',
    '277 36': 'Vitaby',
    '277 37': 'Kivik',
    '277 40': 'Sankt Olof',
    '277 45': 'Sankt Olof',
    '277 55': 'Brösarp',
    '277 56': 'Brösarp',
    '297 95': 'Degeberga',
    '260 39': 'Hasslarp',
    '260 83': 'Vejbystrand',
    '260 91': 'Förslöv',
    '262 00': 'Ängelholm',
    '262 01': 'Ängelholm',
    '262 21': 'Ängelholm',
    '262 22': 'Ängelholm',
    '262 23': 'Ängelholm',
    '262 24': 'Ängelholm',
    '262 31': 'Ängelholm',
    '262 32': 'Ängelholm',
    '262 33': 'Ängelholm',
    '262 34': 'Ängelholm',
    '262 35': 'Ängelholm',
    '262 41': 'Ängelholm',
    '262 42': 'Ängelholm',
    '262 43': 'Ängelholm',
    '262 44': 'Ängelholm',
    '262 51': 'Ängelholm',
    '262 52': 'Ängelholm',
    '262 53': 'Ängelholm',
    '262 54': 'Ängelholm',
    '262 57': 'Ängelholm',
    '262 61': 'Ängelholm',
    '262 62': 'Ängelholm',
    '262 63': 'Ängelholm',
    '262 64': 'Ängelholm',
    '262 65': 'Ängelholm',
    '262 70': 'Strövelstorp',
    '262 71': 'Ängelholm',
    '262 72': 'Ängelholm',
    '262 73': 'Ängelholm',
    '262 79': 'Ängelholm',
    '262 80': 'Ängelholm',
    '262 81': 'Ängelholm',
    '262 82': 'Ängelholm',
    '262 84': 'Ängelholm',
    '262 91': 'Ängelholm',
    '262 92': 'Ängelholm',
    '262 93': 'Ängelholm',
    '262 94': 'Ängelholm',
    '262 95': 'Ängelholm',
    '262 96': 'Ängelholm',
    '266 07': 'Hjärnarp',
    '266 21': 'Munka-Ljungby',
    '266 31': 'Munka-Ljungby',
    '266 32': 'Munka-Ljungby',
    '266 33': 'Munka-Ljungby',
    '266 75': 'Hjärnarp',
    '266 91': 'Munka-Ljungby',
    '266 92': 'Munka-Ljungby',
    '266 93': 'Munka-Ljungby',
    '266 94': 'Munka-Ljungby',
    '266 97': 'Hjärnarp',
    '266 98': 'Hjärnarp',
    '240 40': 'Tjörnarp',
    '280 10': 'Sösdala',
    '280 20': 'Bjärnum',
    '280 22': 'Vittsjö',
    '280 23': 'Hästveda',
    '281 02': 'Hässleholm',
    '281 03': 'Tormestorp',
    '281 10': 'Hässleholm',
    '281 21': 'Hässleholm',
    '281 22': 'Hässleholm',
    '281 23': 'Hässleholm',
    '281 24': 'Hässleholm',
    '281 25': 'Hässleholm',
    '281 26': 'Hässleholm',
    '281 31': 'Hässleholm',
    '281 32': 'Hässleholm',
    '281 33': 'Hässleholm',
    '281 35': 'Hässleholm',
    '281 36': 'Hässleholm',
    '281 37': 'Hässleholm',
    '281 38': 'Hässleholm',
    '281 39': 'Hässleholm',
    '281 40': 'Hässleholm',
    '281 41': 'Hässleholm',
    '281 42': 'Hässleholm',
    '281 43': 'Hässleholm',
    '281 44': 'Stoby',
    '281 46': 'Tormestorp',
    '281 47': 'Hässleholm',
    '281 48': 'Hässleholm',
    '281 49': 'Hässleholm',
    '281 50': 'Hässleholm',
    '281 51': 'Hässleholm',
    '281 52': 'Hässleholm',
    '281 53': 'Finja',
    '281 80': 'Hässleholm',
    '281 81': 'Hässleholm',
    '281 82': 'Hässleholm',
    '281 83': 'Hässleholm',
    '281 85': 'Hässleholm',
    '281 87': 'Ballingslöv',
    '281 91': 'Hässleholm',
    '281 92': 'Hässleholm',
    '281 93': 'Finja',
    '281 95': 'Vankiva',
    '281 97': 'Ballingslöv',
    '282 21': 'Tyringe',
    '282 22': 'Tyringe',
    '282 23': 'Tyringe',
    '282 24': 'Tyringe',
    '282 31': 'Tyringe',
    '282 32': 'Tyringe',
    '282 33': 'Tyringe',
    '282 34': 'Tyringe',
    '282 35': 'Tyringe',
    '282 91': 'Tyringe',
    '282 92': 'Västra Torup',
    '282 93': 'Röke',
    '282 94': 'Hörja',
    '282 95': 'Tyringe',
    '288 21': 'Vinslöv',
    '288 22': 'Vinslöv',
    '288 31': 'Vinslöv',
    '288 32': 'Vinslöv',
    '288 33': 'Vinslöv',
    '288 34': 'Vinslöv',
    '288 90': 'Vinslöv',
    '288 93': 'Nävlinge',
    '200 01': 'Malmö',
    '200 02': 'Malmö',
    '200 03': 'Malmö',
    '200 04': 'Malmö',
    '200 05': 'Malmö',
    '200 06': 'Malmö',
    '200 07': 'Malmö',
    '200 10': 'Malmö',
    '200 11': 'Malmö',
    '200 21': 'Malmö',
    '200 22': 'Malmö',
    '200 24': 'Malmö',
    '200 25': 'Malmö',
    '200 31': 'Malmö',
    '200 32': 'Malmö',
    '200 39': 'Malmö',
    '200 40': 'Malmö',
    '200 41': 'Malmö',
    '200 42': 'Malmö',
    '200 43': 'Malmö',
    '200 45': 'Malmö',
    '200 46': 'Malmö',
    '200 49': 'Malmö',
    '200 60': 'Limhamn',
    '200 61': 'Limhamn',
    '200 62': 'Limhamn',
    '200 70': 'Malmö',
    '200 71': 'Malmö',
    '200 72': 'Malmö',
    '200 73': 'Malmö',
    '200 74': 'Malmö',
    '201 10': 'Malmö',
    '201 20': 'Malmö',
    '201 21': 'Malmö',
    '201 22': 'Malmö',
    '201 23': 'Malmö',
    '201 24': 'Malmö',
    '201 25': 'Malmö',
    '201 26': 'Malmö',
    '201 80': 'Malmö',
    '202 10': 'Malmö',
    '202 11': 'Malmö',
    '202 12': 'Malmö',
    '202 13': 'Malmö',
    '202 14': 'Malmö',
    '202 15': 'Malmö',
    '202 19': 'Malmö',
    '202 20': 'Malmö',
    '202 21': 'Malmö',
    '202 22': 'Malmö',
    '202 24': 'Malmö',
    '202 25': 'Malmö',
    '202 26': 'Malmö',
    '202 27': 'Malmö',
    '202 28': 'Malmö',
    '202 29': 'Malmö',
    '202 40': 'Malmö',
    '202 50': 'Malmö',
    '202 60': 'Malmö',
    '202 70': 'Malmö',
    '202 71': 'Malmö',
    '202 80': 'Malmö',
    '203 10': 'Malmö',
    '203 11': 'Malmö',
    '203 12': 'Malmö',
    '203 13': 'Malmö',
    '203 14': 'Malmö',
    '203 15': 'Malmö',
    '203 20': 'Malmö',
    '205 01': 'Malmö',
    '205 02': 'Malmö',
    '205 03': 'Malmö',
    '205 04': 'Malmö',
    '205 05': 'Malmö',
    '205 06': 'Malmö',
    '205 07': 'Malmö',
    '205 08': 'Malmö',
    '205 09': 'Malmö',
    '205 10': 'Malmö',
    '205 11': 'Malmö',
    '205 12': 'Malmö',
    '205 13': 'Malmö',
    '205 14': 'Malmö',
    '205 15': 'Malmö',
    '205 16': 'Malmö',
    '205 17': 'Malmö',
    '205 18': 'Malmö',
    '205 19': 'Malmö',
    '205 20': 'Malmö',
    '205 21': 'Malmö',
    '205 22': 'Malmö',
    '205 26': 'Malmö',
    '205 30': 'Malmö',
    '205 31': 'Malmö',
    '205 33': 'Malmö',
    '205 35': 'Malmö',
    '205 40': 'Malmö',
    '205 42': 'Malmö',
    '205 43': 'Malmö',
    '205 44': 'Malmö',
    '205 45': 'Malmö',
    '205 47': 'Malmö',
    '205 50': 'Malmö',
    '205 52': 'Malmö',
    '205 55': 'Malmö',
    '205 60': 'Malmö',
    '205 65': 'Malmö',
    '205 70': 'Malmö',
    '205 75': 'Malmö',
    '205 77': 'Malmö',
    '205 79': 'Malmö',
    '205 80': 'Malmö',
    '205 81': 'Malmö',
    '205 85': 'Malmö',
    '205 90': 'Malmö',
    '205 95': 'Malmö',
    '208 00': 'Malmö',
    '208 01': 'Malmö',
    '208 02': 'Malmö',
    '208 03': 'Malmö',
    '208 04': 'Malmö',
    '208 05': 'Malmö',
    '208 06': 'Malmö',
    '208 07': 'Malmö',
    '208 08': 'Malmö',
    '208 09': 'Malmö',
    '208 10': 'Malmö',
    '208 11': 'Malmö',
    '208 12': 'Malmö',
    '208 13': 'Malmö',
    '208 14': 'Malmö',
    '208 15': 'Malmö',
    '208 16': 'Malmö',
    '208 17': 'Malmö',
    '208 18': 'Malmö',
    '208 19': 'Malmö',
    '208 20': 'Malmö',
    '208 21': 'Malmö',
    '208 22': 'Malmö',
    '208 23': 'Malmö',
    '208 24': 'Malmö',
    '208 25': 'Malmö',
    '208 26': 'Malmö',
    '208 27': 'Malmö',
    '208 28': 'Malmö',
    '208 29': 'Malmö',
    '208 30': 'Malmö',
    '208 31': 'Malmö',
    '208 32': 'Malmö',
    '208 33': 'Malmö',
    '208 34': 'Malmö',
    '208 35': 'Malmö',
    '208 36': 'Malmö',
    '208 37': 'Malmö',
    '208 38': 'Malmö',
    '208 39': 'Malmö',
    '208 40': 'Malmö',
    '208 41': 'Malmö',
    '208 42': 'Malmö',
    '208 43': 'Malmö',
    '208 44': 'Malmö',
    '208 45': 'Malmö',
    '208 46': 'Malmö',
    '208 47': 'Malmö',
    '208 48': 'Malmö',
    '208 49': 'Malmö',
    '208 50': 'Malmö',
    '208 51': 'Malmö',
    '208 52': 'Malmö',
    '208 53': 'Malmö',
    '208 54': 'Malmö',
    '208 55': 'Malmö',
    '208 56': 'Malmö',
    '208 57': 'Malmö',
    '208 58': 'Malmö',
    '208 59': 'Malmö',
    '208 60': 'Malmö',
    '208 61': 'Malmö',
    '208 62': 'Malmö',
    '208 63': 'Malmö',
    '208 64': 'Malmö',
    '208 65': 'Malmö',
    '208 66': 'Malmö',
    '208 67': 'Malmö',
    '208 68': 'Malmö',
    '208 69': 'Malmö',
    '208 70': 'Malmö',
    '208 71': 'Malmö',
    '208 72': 'Malmö',
    '208 73': 'Malmö',
    '208 74': 'Malmö',
    '208 75': 'Malmö',
    '208 76': 'Malmö',
    '208 77': 'Malmö',
    '208 78': 'Malmö',
    '208 79': 'Malmö',
    '208 80': 'Malmö',
    '208 81': 'Malmö',
    '208 82': 'Malmö',
    '208 83': 'Malmö',
    '208 84': 'Malmö',
    '208 85': 'Malmö',
    '208 86': 'Malmö',
    '208 87': 'Malmö',
    '208 88': 'Malmö',
    '208 89': 'Malmö',
    '208 90': 'Malmö',
    '208 91': 'Malmö',
    '208 92': 'Malmö',
    '208 93': 'Malmö',
    '208 94': 'Malmö',
    '208 95': 'Malmö',
    '208 96': 'Malmö',
    '208 97': 'Malmö',
    '208 98': 'Malmö',
    '208 99': 'Malmö',
    '209 00': 'Malmö',
    '209 01': 'Malmö',
    '209 02': 'Malmö',
    '209 03': 'Malmö',
    '209 04': 'Malmö',
    '209 05': 'Malmö',
    '209 06': 'Malmö',
    '209 07': 'Malmö',
    '209 08': 'Malmö',
    '209 09': 'Malmö',
    '209 10': 'Malmö',
    '209 11': 'Malmö',
    '209 12': 'Malmö',
    '209 13': 'Malmö',
    '209 14': 'Malmö',
    '209 15': 'Malmö',
    '209 16': 'Malmö',
    '209 17': 'Malmö',
    '209 18': 'Malmö',
    '209 19': 'Malmö',
    '209 20': 'Malmö',
    '209 21': 'Malmö',
    '209 22': 'Malmö',
    '209 23': 'Malmö',
    '209 24': 'Malmö',
    '209 25': 'Malmö',
    '209 26': 'Malmö',
    '209 27': 'Malmö',
    '209 28': 'Malmö',
    '209 29': 'Malmö',
    '209 30': 'Malmö',
    '209 31': 'Malmö',
    '209 32': 'Malmö',
    '209 33': 'Malmö',
    '209 34': 'Malmö',
    '209 35': 'Malmö',
    '209 36': 'Malmö',
    '209 37': 'Malmö',
    '209 38': 'Malmö',
    '209 39': 'Malmö',
    '209 40': 'Malmö',
    '209 41': 'Malmö',
    '209 42': 'Malmö',
    '209 43': 'Malmö',
    '209 44': 'Malmö',
    '209 45': 'Malmö',
    '209 46': 'Malmö',
    '209 47': 'Malmö',
    '209 48': 'Malmö',
    '209 49': 'Malmö',
    '209 50': 'Malmö',
    '209 51': 'Malmö',
    '209 52': 'Malmö',
    '209 53': 'Malmö',
    '209 54': 'Malmö',
    '209 55': 'Malmö',
    '209 56': 'Malmö',
    '209 57': 'Malmö',
    '209 58': 'Malmö',
    '209 59': 'Malmö',
    '209 60': 'Malmö',
    '209 61': 'Malmö',
    '209 62': 'Malmö',
    '209 63': 'Malmö',
    '209 64': 'Malmö',
    '209 65': 'Malmö',
    '209 66': 'Malmö',
    '209 67': 'Malmö',
    '209 68': 'Malmö',
    '209 69': 'Malmö',
    '209 70': 'Malmö',
    '209 71': 'Malmö',
    '209 72': 'Malmö',
    '209 73': 'Malmö',
    '209 74': 'Malmö',
    '209 75': 'Malmö',
    '209 76': 'Malmö',
    '209 77': 'Malmö',
    '209 78': 'Malmö',
    '209 79': 'Malmö',
    '209 80': 'Malmö',
    '209 81': 'Malmö',
    '209 82': 'Malmö',
    '209 83': 'Malmö',
    '209 84': 'Malmö',
    '209 85': 'Malmö',
    '209 86': 'Malmö',
    '209 87': 'Malmö',
    '209 88': 'Malmö',
    '209 89': 'Malmö',
    '209 90': 'Malmö',
    '209 91': 'Malmö',
    '209 92': 'Malmö',
    '209 93': 'Malmö',
    '209 94': 'Malmö',
    '209 95': 'Malmö',
    '209 96': 'Malmö',
    '209 97': 'Malmö',
    '209 98': 'Malmö',
    '209 99': 'Malmö',
    '211 00': 'Malmö',
    '211 01': 'Malmö',
    '211 03': 'Malmö',
    '211 05': 'Malmö',
    '211 12': 'Malmö',
    '211 14': 'Malmö',
    '211 15': 'Malmö',
    '211 16': 'Malmö',
    '211 17': 'Malmö',
    '211 18': 'Malmö',
    '211 19': 'Malmö',
    '211 20': 'Malmö',
    '211 21': 'Malmö',
    '211 22': 'Malmö',
    '211 24': 'Malmö',
    '211 25': 'Malmö',
    '211 26': 'Malmö',
    '211 27': 'Malmö',
    '211 28': 'Malmö',
    '211 29': 'Malmö',
    '211 30': 'Malmö',
    '211 31': 'Malmö',
    '211 32': 'Malmö',
    '211 33': 'Malmö',
    '211 34': 'Malmö',
    '211 35': 'Malmö',
    '211 36': 'Malmö',
    '211 37': 'Malmö',
    '211 38': 'Malmö',
    '211 39': 'Malmö',
    '211 40': 'Malmö',
    '211 41': 'Malmö',
    '211 42': 'Malmö',
    '211 43': 'Malmö',
    '211 44': 'Malmö',
    '211 45': 'Malmö',
    '211 46': 'Malmö',
    '211 47': 'Malmö',
    '211 48': 'Malmö',
    '211 49': 'Malmö',
    '211 50': 'Malmö',
    '211 51': 'Malmö',
    '211 52': 'Malmö',
    '211 53': 'Malmö',
    '211 54': 'Malmö',
    '211 55': 'Malmö',
    '211 56': 'Malmö',
    '211 57': 'Malmö',
    '211 58': 'Malmö',
    '211 59': 'Malmö',
    '211 60': 'Malmö',
    '211 65': 'Malmö',
    '212 00': 'Malmö',
    '212 01': 'Malmö',
    '212 02': 'Malmö',
    '212 07': 'Malmö',
    '212 08': 'Malmö',
    '212 09': 'Malmö',
    '212 10': 'Malmö',
    '212 11': 'Malmö',
    '212 12': 'Malmö',
    '212 13': 'Malmö',
    '212 14': 'Malmö',
    '212 15': 'Malmö',
    '212 16': 'Malmö',
    '212 17': 'Malmö',
    '212 18': 'Malmö',
    '212 19': 'Malmö',
    '212 20': 'Malmö',
    '212 21': 'Malmö',
    '212 22': 'Malmö',
    '212 23': 'Malmö',
    '212 24': 'Malmö',
    '212 25': 'Malmö',
    '212 26': 'Malmö',
    '212 27': 'Malmö',
    '212 28': 'Malmö',
    '212 29': 'Malmö',
    '212 30': 'Malmö',
    '212 31': 'Malmö',
    '212 32': 'Malmö',
    '212 33': 'Malmö',
    '212 34': 'Malmö',
    '212 35': 'Malmö',
    '212 36': 'Malmö',
    '212 37': 'Malmö',
    '212 38': 'Malmö',
    '212 39': 'Malmö',
    '212 40': 'Malmö',
    '212 41': 'Malmö',
    '212 42': 'Malmö',
    '212 43': 'Malmö',
    '212 60': 'Malmö',
    '212 91': 'Malmö',
    '213 00': 'Malmö',
    '213 61': 'Malmö',
    '213 62': 'Malmö',
    '213 63': 'Malmö',
    '213 64': 'Malmö',
    '213 65': 'Malmö',
    '213 66': 'Malmö',
    '213 67': 'Malmö',
    '213 68': 'Malmö',
    '213 69': 'Malmö',
    '213 70': 'Malmö',
    '213 71': 'Malmö',
    '213 72': 'Malmö',
    '213 73': 'Malmö',
    '213 74': 'Malmö',
    '213 75': 'Malmö',
    '213 76': 'Malmö',
    '213 77': 'Malmö',
    '214 00': 'Malmö',
    '214 02': 'Malmö',
    '214 04': 'Malmö',
    '214 10': 'Malmö',
    '214 11': 'Malmö',
    '214 12': 'Malmö',
    '214 20': 'Malmö',
    '214 21': 'Malmö',
    '214 22': 'Malmö',
    '214 23': 'Malmö',
    '214 24': 'Malmö',
    '214 25': 'Malmö',
    '214 26': 'Malmö',
    '214 27': 'Malmö',
    '214 28': 'Malmö',
    '214 29': 'Malmö',
    '214 30': 'Malmö',
    '214 31': 'Malmö',
    '214 32': 'Malmö',
    '214 33': 'Malmö',
    '214 34': 'Malmö',
    '214 35': 'Malmö',
    '214 36': 'Malmö',
    '214 37': 'Malmö',
    '214 38': 'Malmö',
    '214 39': 'Malmö',
    '214 40': 'Malmö',
    '214 41': 'Malmö',
    '214 42': 'Malmö',
    '214 43': 'Malmö',
    '214 44': 'Malmö',
    '214 45': 'Malmö',
    '214 46': 'Malmö',
    '214 47': 'Malmö',
    '214 48': 'Malmö',
    '214 49': 'Malmö',
    '214 50': 'Malmö',
    '214 54': 'Malmö',
    '214 55': 'Malmö',
    '214 56': 'Malmö',
    '214 57': 'Malmö',
    '214 58': 'Malmö',
    '214 59': 'Malmö',
    '214 63': 'Malmö',
    '214 66': 'Malmö',
    '214 67': 'Malmö',
    '215 00': 'Malmö',
    '215 10': 'Malmö',
    '215 26': 'Malmö',
    '215 27': 'Malmö',
    '215 50': 'Malmö',
    '215 51': 'Malmö',
    '215 52': 'Malmö',
    '215 53': 'Malmö',
    '215 58': 'Malmö',
    '215 59': 'Malmö',
    '215 60': 'Malmö',
    '215 61': 'Malmö',
    '215 62': 'Malmö',
    '215 63': 'Malmö',
    '215 64': 'Malmö',
    '215 65': 'Malmö',
    '215 66': 'Malmö',
    '215 67': 'Malmö',
    '215 68': 'Malmö',
    '215 69': 'Malmö',
    '215 70': 'Malmö',
    '215 71': 'Malmö',
    '215 72': 'Malmö',
    '215 73': 'Malmö',
    '215 74': 'Malmö',
    '215 78': 'Malmö',
    '215 79': 'Malmö',
    '215 80': 'Malmö',
    '215 81': 'Malmö',
    '215 82': 'Malmö',
    '215 83': 'Malmö',
    '215 84': 'Malmö',
    '215 85': 'Malmö',
    '215 86': 'Malmö',
    '216 00': 'Malmö',
    '216 08': 'Limhamn',
    '216 09': 'Limhamn',
    '216 10': 'Limhamn',
    '216 11': 'Limhamn',
    '216 12': 'Limhamn',
    '216 13': 'Limhamn',
    '216 14': 'Limhamn',
    '216 15': 'Limhamn',
    '216 16': 'Limhamn',
    '216 17': 'Limhamn',
    '216 18': 'Limhamn',
    '216 19': 'Malmö',
    '216 20': 'Malmö',
    '216 21': 'Malmö',
    '216 22': 'Limhamn',
    '216 23': 'Malmö',
    '216 24': 'Malmö',
    '216 25': 'Malmö',
    '216 41': 'Limhamn',
    '216 42': 'Limhamn',
    '217 00': 'Malmö',
    '217 01': 'Malmö',
    '217 41': 'Malmö',
    '217 42': 'Malmö',
    '217 43': 'Malmö',
    '217 44': 'Malmö',
    '217 45': 'Malmö',
    '217 46': 'Malmö',
    '217 47': 'Malmö',
    '217 48': 'Malmö',
    '217 49': 'Malmö',
    '217 51': 'Malmö',
    '217 52': 'Malmö',
    '217 53': 'Malmö',
    '217 54': 'Malmö',
    '217 55': 'Malmö',
    '217 56': 'Malmö',
    '217 57': 'Malmö',
    '217 58': 'Malmö',
    '217 59': 'Malmö',
    '217 61': 'Malmö',
    '217 62': 'Malmö',
    '217 63': 'Malmö',
    '217 64': 'Malmö',
    '217 65': 'Malmö',
    '217 66': 'Malmö',
    '217 67': 'Malmö',
    '217 71': 'Malmö',
    '217 72': 'Malmö',
    '217 73': 'Malmö',
    '217 74': 'Malmö',
    '217 75': 'Malmö',
    '218 00': 'Bunkeflostrand',
    '218 20': 'Limhamn',
    '218 21': 'Tygelsjö',
    '218 24': 'Bunkeflostrand',
    '218 31': 'Bunkeflostrand',
    '218 32': 'Bunkeflostrand',
    '218 33': 'Bunkeflostrand',
    '218 34': 'Bunkeflostrand',
    '218 36': 'Bunkeflostrand',
    '218 37': 'Bunkeflostrand',
    '218 38': 'Bunkeflostrand',
    '218 40': 'Bunkeflostrand',
    '218 41': 'Bunkeflostrand',
    '218 42': 'Bunkeflostrand',
    '218 44': 'Malmö',
    '218 46': 'Bunkeflostrand',
    '218 47': 'Bunkeflostrand',
    '218 51': 'Klagshamn',
    '218 52': 'Klagshamn',
    '218 53': 'Klagshamn',
    '218 54': 'Klagshamn',
    '218 55': 'Klagshamn',
    '218 71': 'Tygelsjö',
    '218 72': 'Tygelsjö',
    '218 73': 'Tygelsjö',
    '218 74': 'Tygelsjö',
    '218 75': 'Tygelsjö',
    '220 02': 'Lund',
    '220 07': 'Lund',
    '220 09': 'Lund',
    '220 10': 'Lund',
    '220 11': 'Lund',
    '220 13': 'Lund',
    '221 00': 'Lund',
    '221 01': 'Lund',
    '221 02': 'Lund',
    '221 03': 'Lund',
    '221 04': 'Lund',
    '221 05': 'Lund',
    '221 09': 'Lund',
    '221 82': 'Lund',
    '221 83': 'Lund',
    '221 84': 'Lund',
    '221 85': 'Lund',
    '221 86': 'Lund',
    '221 87': 'Lund',
    '221 88': 'Lund',
    '222 00': 'Lund',
    '222 20': 'Lund',
    '222 21': 'Lund',
    '222 22': 'Lund',
    '222 23': 'Lund',
    '222 24': 'Lund',
    '222 25': 'Lund',
    '222 26': 'Lund',
    '222 27': 'Lund',
    '222 28': 'Lund',
    '222 29': 'Lund',
    '222 37': 'Lund',
    '222 40': 'Lund',
    '222 41': 'Lund',
    '222 42': 'Lund',
    '222 70': 'Lund',
    '223 00': 'Lund',
    '223 50': 'Lund',
    '223 51': 'Lund',
    '223 52': 'Lund',
    '223 53': 'Lund',
    '223 54': 'Lund',
    '223 55': 'Lund',
    '223 58': 'Lund',
    '223 59': 'Lund',
    '223 61': 'Lund',
    '223 62': 'Lund',
    '223 63': 'Lund',
    '223 69': 'Lund',
    '223 70': 'Lund',
    '224 00': 'Lund',
    '224 21': 'Lund',
    '224 22': 'Lund',
    '224 56': 'Lund',
    '224 57': 'Lund',
    '224 58': 'Lund',
    '224 60': 'Lund',
    '224 64': 'Lund',
    '224 65': 'Lund',
    '224 66': 'Lund',
    '224 67': 'Lund',
    '224 68': 'Lund',
    '224 71': 'Lund',
    '224 72': 'Lund',
    '224 73': 'Lund',
    '224 74': 'Lund',
    '224 75': 'Lund',
    '224 76': 'Lund',
    '224 77': 'Lund',
    '224 78': 'Lund',
    '224 79': 'Lund',
    '225 91': 'Lund',
    '225 92': 'Lund',
    '225 93': 'Lund',
    '225 94': 'Lund',
    '226 00': 'Lund',
    '226 39': 'Lund',
    '226 42': 'Lund',
    '226 43': 'Lund',
    '226 44': 'Lund',
    '226 45': 'Lund',
    '226 46': 'Lund',
    '226 47': 'Lund',
    '226 48': 'Lund',
    '226 49': 'Lund',
    '226 50': 'Lund',
    '226 51': 'Lund',
    '226 52': 'Lund',
    '226 53': 'Lund',
    '226 54': 'Lund',
    '226 55': 'Lund',
    '226 57': 'Lund',
    '226 60': 'Lund',
    '227 00': 'Lund',
    '227 21': 'Lund',
    '227 22': 'Lund',
    '227 30': 'Lund',
    '227 31': 'Lund',
    '227 32': 'Lund',
    '227 33': 'Lund',
    '227 34': 'Lund',
    '227 35': 'Lund',
    '227 36': 'Lund',
    '227 38': 'Lund',
    '227 60': 'Lund',
    '227 61': 'Lund',
    '227 62': 'Lund',
    '227 63': 'Lund',
    '227 64': 'Lund',
    '228 00': 'Lund',
    '228 10': 'Lund',
    '228 11': 'Lund',
    '228 12': 'Lund',
    '228 13': 'Lund',
    '228 14': 'Lund',
    '228 20': 'Lund',
    '230 42': 'Tygelsjö',
    '230 43': 'Klagshamn',
    '230 44': 'Bunkeflostrand',
    '231 02': 'Trelleborg',
    '231 20': 'Trelleborg',
    '231 31': 'Trelleborg',
    '231 67': 'Trelleborg',
    '232 20': 'Arlöv',
    '232 41': 'Arlöv',
    '233 20': 'Svedala',
    '233 43': 'Svedala',
    '234 20': 'Lomma',
    '234 39': 'Lomma',
    '235 20': 'Vellinge',
    '236 20': 'Höllviken',
    '237 20': 'Bjärred',
    '237 42': 'Bjärred',
    '238 20': 'Oxie',
    '239 20': 'Skanör',
    '241 20': 'Eslöv',
    '242 20': 'Hörby',
    '243 20': 'Höör',
    '244 20': 'Kävlinge',
    '245 20': 'Staffanstorp',
    '246 20': 'Löddeköpinge',
    '247 20': 'Södra Sandby',
    '254 49': 'Helsingborg',
    '257 34': 'Rydebäck',
    '258 00': 'Helsingborg',
    '258 01': 'Helsingborg',
    '258 02': 'Helsingborg',
    '258 03': 'Helsingborg',
    '258 04': 'Helsingborg',
    '258 05': 'Helsingborg',
    '258 06': 'Helsingborg',
    '258 07': 'Helsingborg',
    '258 08': 'Helsingborg',
    '258 09': 'Helsingborg',
    '258 10': 'Helsingborg',
    '258 11': 'Helsingborg',
    '258 12': 'Helsingborg',
    '258 13': 'Helsingborg',
    '258 14': 'Helsingborg',
    '258 15': 'Helsingborg',
    '258 16': 'Helsingborg',
    '258 17': 'Helsingborg',
    '258 18': 'Helsingborg',
    '258 19': 'Helsingborg',
    '258 20': 'Helsingborg',
    '258 21': 'Helsingborg',
    '258 22': 'Helsingborg',
    '258 23': 'Helsingborg',
    '258 24': 'Helsingborg',
    '258 25': 'Helsingborg',
    '258 26': 'Helsingborg',
    '258 27': 'Helsingborg',
    '258 28': 'Helsingborg',
    '258 29': 'Helsingborg',
    '258 30': 'Helsingborg',
    '258 35': 'Helsingborg',
    '258 40': 'Helsingborg',
    '258 45': 'Ramlösa',
    '258 50': 'Helsingborg',
    '258 51': 'Helsingborg',
    '258 52': 'Helsingborg',
    '258 53': 'Helsingborg',
    '258 54': 'Helsingborg',
    '258 55': 'Helsingborg',
    '258 56': 'Helsingborg',
    '258 57': 'Helsingborg',
    '258 58': 'Helsingborg',
    '258 59': 'Helsingborg',
    '258 60': 'Helsingborg',
    '258 70': 'Helsingborg',
    '258 90': 'Helsingborg',
    '258 95': 'Helsingborg',
    '258 96': 'Helsingborg',
    '259 60': 'Helsingborg',
    '259 61': 'Helsingborg',
    '259 62': 'Helsingborg',
    '259 63': 'Helsingborg',
    '259 64': 'Helsingborg',
    '259 65': 'Helsingborg',
    '259 66': 'Helsingborg',
    '259 67': 'Helsingborg',
    '259 68': 'Helsingborg',
    '259 69': 'Helsingborg',
    '259 70': 'Helsingborg',
    '259 71': 'Helsingborg',
    '259 72': 'Helsingborg',
    '259 73': 'Helsingborg',
    '259 74': 'Helsingborg',
    '259 75': 'Helsingborg',
    '259 76': 'Helsingborg',
    '259 77': 'Helsingborg',
    '259 78': 'Helsingborg',
    '259 79': 'Helsingborg',
    '259 80': 'Helsingborg',
    '259 81': 'Helsingborg',
    '259 82': 'Helsingborg',
    '259 83': 'Helsingborg',
    '259 84': 'Helsingborg',
    '259 85': 'Helsingborg',
    '259 86': 'Helsingborg',
    '259 87': 'Helsingborg',
    '259 88': 'Helsingborg',
    '259 89': 'Helsingborg',
    '259 90': 'Helsingborg',
    '259 91': 'Helsingborg',
    '259 92': 'Helsingborg',
    '259 93': 'Helsingborg',
    '259 94': 'Helsingborg',
    '259 95': 'Helsingborg',
    '260 37': 'Ödåkra',
    '261 20': 'Landskrona',
    '261 30': 'Landskrona',
    '262 20': 'Ängelholm',
    '262 58': 'Ängelholm',
    '262 74': 'Ängelholm',
    '263 20': 'Höganäs',
    '264 20': 'Klippan',
    '265 20': 'Åstorp',
    '267 20': 'Bjuv',
    '268 20': 'Svalöv',
    '269 20': 'Båstad',
    '271 20': 'Ystad',
    '271 46': 'Ystad',
    '271 57': 'Ystad',
    '271 72': 'Köpingebro',
    '271 73': 'Köpingebro',
    '272 20': 'Simrishamn',
    '273 20': 'Tomelilla',
    '274 20': 'Skurup',
    '275 20': 'Sjöbo',
    '277 01': 'Kivik',
    '281 00': 'Hässleholm',
    '281 01': 'Hässleholm',
    '281 20': 'Hässleholm',
    '282 01': 'Tyringe',
    '282 20': 'Tyringe',
    '283 20': 'Osby',
    '284 20': 'Perstorp',
    '286 20': 'Örkelljunga',
    '286 38': 'Örkelljunga',
    '288 20': 'Vinslöv',
    '289 20': 'Knislinge',
    '291 20': 'Kristianstad',
    '295 20': 'Bromölla',
    '296 20': 'Åhus',
    '297 20': 'Degeberga',
    '310 83': 'Unnaryd',
    '314 01': 'Hyltebruk',
    '314 03': 'Torup',
    '314 04': 'Landeryd',
    '314 05': 'Kinnared',
    '314 21': 'Hyltebruk',
    '314 22': 'Hyltebruk',
    '314 31': 'Hyltebruk',
    '314 32': 'Hyltebruk',
    '314 33': 'Hyltebruk',
    '314 34': 'Hyltebruk',
    '314 41': 'Torup',
    '314 42': 'Rydöbruk',
    '314 80': 'Hyltebruk',
    '314 81': 'Hyltebruk',
    '314 82': 'Hyltebruk',
    '314 91': 'Hyltebruk',
    '314 92': 'Långaryd',
    '314 93': 'Hyltebruk',
    '314 94': 'Landeryd',
    '314 95': 'Kinnared',
    '314 96': 'Torup',
    '314 98': 'Torup',
    '333 93': 'Skeppshult',
    '340 10': 'Lidhult',
    '300 04': 'Halmstad',
    '300 05': 'Halmstad',
    '300 07': 'Halmstad',
    '300 08': 'Halmstad',
    '300 10': 'Halmstad',
    '300 13': 'Halmstad',
    '301 02': 'Halmstad',
    '301 03': 'Halmstad',
    '301 04': 'Halmstad',
    '301 05': 'Halmstad',
    '301 06': 'Halmstad',
    '301 07': 'Halmstad',
    '301 08': 'Halmstad',
    '301 09': 'Halmstad',
    '301 10': 'Halmstad',
    '301 16': 'Halmstad',
    '301 17': 'Halmstad',
    '301 18': 'Halmstad',
    '301 19': 'Halmstad',
    '301 80': 'Halmstad',
    '301 81': 'Halmstad',
    '301 85': 'Halmstad',
    '301 86': 'Halmstad',
    '301 87': 'Halmstad',
    '302 01': 'Halmstad',
    '302 02': 'Halmstad',
    '302 03': 'Halmstad',
    '302 04': 'Halmstad',
    '302 27': 'Halmstad',
    '302 30': 'Halmstad',
    '302 31': 'Halmstad',
    '302 32': 'Halmstad',
    '302 33': 'Halmstad',
    '302 34': 'Halmstad',
    '302 35': 'Halmstad',
    '302 36': 'Halmstad',
    '302 37': 'Halmstad',
    '302 38': 'Halmstad',
    '302 39': 'Halmstad',
    '302 40': 'Halmstad',
    '302 41': 'Halmstad',
    '302 42': 'Halmstad',
    '302 43': 'Halmstad',
    '302 44': 'Halmstad',
    '302 45': 'Halmstad',
    '302 46': 'Halmstad',
    '302 47': 'Halmstad',
    '302 48': 'Halmstad',
    '302 49': 'Halmstad',
    '302 50': 'Halmstad',
    '302 51': 'Halmstad',
    '302 52': 'Halmstad',
    '302 53': 'Halmstad',
    '302 54': 'Halmstad',
    '302 55': 'Halmstad',
    '302 56': 'Halmstad',
    '302 57': 'Halmstad',
    '302 58': 'Halmstad',
    '302 59': 'Halmstad',
    '302 60': 'Halmstad',
    '302 61': 'Halmstad',
    '302 62': 'Halmstad',
    '302 63': 'Halmstad',
    '302 65': 'Halmstad',
    '302 70': 'Halmstad',
    '302 71': 'Halmstad',
    '302 72': 'Halmstad',
    '302 73': 'Halmstad',
    '302 74': 'Halmstad',
    '302 79': 'Holm',
    '305 91': 'Halmstad',
    '305 92': 'Holm',
    '305 93': 'Halmstad',
    '305 94': 'Halmstad',
    '305 95': 'Halmstad',
    '310 31': 'Eldsberga',
    '310 34': 'Kvibille',
    '310 38': 'Simlångsdalen',
    '310 40': 'Harplinge',
    '310 41': 'Gullbrandstorp',
    '313 01': 'Oskarström',
    '313 02': 'Sennan',
    '313 03': 'Åled',
    '313 21': 'Oskarström',
    '313 22': 'Oskarström',
    '313 30': 'Oskarström',
    '313 31': 'Oskarström',
    '313 32': 'Oskarström',
    '313 33': 'Oskarström',
    '313 50': 'Åled',
    '313 92': 'Oskarström',
    '313 94': 'Sennan',
    '313 95': 'Åled',
    '266 95': 'Munka-Ljungby',
    '280 40': 'Skånes Fagerhult',
    '310 20': 'Knäred',
    '310 21': 'Hishult',
    '312 00': 'Laholm',
    '312 02': 'Genevad',
    '312 03': 'Veinge',
    '312 04': 'Vallberga',
    '312 05': 'Skottorp',
    '312 06': 'Mellbystrand',
    '312 07': 'Våxtorp',
    '312 21': 'Laholm',
    '312 22': 'Laholm',
    '312 30': 'Laholm',
    '312 31': 'Laholm',
    '312 32': 'Laholm',
    '312 33': 'Laholm',
    '312 34': 'Laholm',
    '312 35': 'Laholm',
    '312 36': 'Laholm',
    '312 40': 'Genevad',
    '312 45': 'Veinge',
    '312 50': 'Vallberga',
    '312 60': 'Mellbystrand',
    '312 61': 'Mellbystrand',
    '312 71': 'Skummeslövsstrand',
    '312 72': 'Skummeslövsstrand',
    '312 75': 'Våxtorp',
    '312 80': 'Laholm',
    '312 91': 'Laholm',
    '312 92': 'Laholm',
    '312 93': 'Laholm',
    '312 94': 'Laholm',
    '312 95': 'Laholm',
    '312 96': 'Laholm',
    '312 97': 'Laholm',
    '312 98': 'Våxtorp',
    '310 42': 'Haverdal',
    '310 44': 'Getinge',
    '310 50': 'Slöinge',
    '310 61': 'Ätran',
    '310 62': 'Fegen',
    '310 63': 'Älvsered',
    '311 01': 'Falkenberg',
    '311 03': 'Långås',
    '311 04': 'Glommen',
    '311 05': 'Vinberg',
    '311 06': 'Heberg',
    '311 21': 'Falkenberg',
    '311 22': 'Falkenberg',
    '311 23': 'Falkenberg',
    '311 30': 'Falkenberg',
    '311 31': 'Falkenberg',
    '311 32': 'Falkenberg',
    '311 33': 'Falkenberg',
    '311 34': 'Falkenberg',
    '311 35': 'Falkenberg',
    '311 36': 'Falkenberg',
    '311 37': 'Falkenberg',
    '311 38': 'Falkenberg',
    '311 39': 'Falkenberg',
    '311 40': 'Falkenberg',
    '311 41': 'Falkenberg',
    '311 42': 'Falkenberg',
    '311 43': 'Falkenberg',
    '311 44': 'Falkenberg',
    '311 45': 'Falkenberg',
    '311 50': 'Falkenberg',
    '311 71': 'Falkenberg',
    '311 72': 'Falkenberg',
    '311 80': 'Falkenberg',
    '311 81': 'Falkenberg',
    '311 82': 'Falkenberg',
    '311 83': 'Falkenberg',
    '311 91': 'Falkenberg',
    '311 92': 'Falkenberg',
    '311 94': 'Falkenberg',
    '311 95': 'Falkenberg',
    '311 96': 'Heberg',
    '311 97': 'Falkenberg',
    '311 98': 'Glommen',
    '311 99': 'Vinberg',
    '313 91': 'Oskarström',
    '314 97': 'Torup',
    '310 58': 'Vessigebro',
    '310 60': 'Ullared',
    '311 93': 'Långås',
    '430 10': 'Tvååker',
    '430 16': 'Rolfstorp',
    '430 17': 'Skällinge',
    '430 22': 'Väröbacka',
    '430 24': 'Väröbacka',
    '430 25': 'Väröbacka',
    '432 01': 'Varberg',
    '432 02': 'Varberg',
    '432 03': 'Träslövsläge',
    '432 13': 'Varberg',
    '432 14': 'Varberg',
    '432 15': 'Varberg',
    '432 16': 'Varberg',
    '432 17': 'Varberg',
    '432 18': 'Varberg',
    '432 19': 'Varberg',
    '432 21': 'Varberg',
    '432 22': 'Varberg',
    '432 23': 'Varberg',
    '432 24': 'Varberg',
    '432 25': 'Varberg',
    '432 30': 'Varberg',
    '432 31': 'Varberg',
    '432 32': 'Varberg',
    '432 33': 'Varberg',
    '432 34': 'Varberg',
    '432 35': 'Varberg',
    '432 36': 'Varberg',
    '432 37': 'Varberg',
    '432 38': 'Varberg',
    '432 40': 'Varberg',
    '432 41': 'Varberg',
    '432 42': 'Varberg',
    '432 43': 'Varberg',
    '432 44': 'Varberg',
    '432 50': 'Varberg',
    '432 51': 'Varberg',
    '432 52': 'Varberg',
    '432 53': 'Varberg',
    '432 54': 'Varberg',
    '432 75': 'Träslövsläge',
    '432 80': 'Varberg',
    '432 81': 'Varberg',
    '432 82': 'Varberg',
    '432 84': 'Varberg',
    '432 91': 'Varberg',
    '432 92': 'Varberg',
    '432 93': 'Varberg',
    '432 94': 'Varberg',
    '432 95': 'Varberg',
    '432 96': 'Åskloster',
    '519 93': 'Kungsäter',
    '427 00': 'Billdal',
    '427 50': 'Billdal',
    '429 11': 'Särö',
    '429 12': 'Särö',
    '429 13': 'Särö',
    '429 21': 'Kullavik',
    '429 22': 'Kullavik',
    '429 23': 'Kullavik',
    '429 30': 'Kullavik',
    '429 31': 'Kullavik',
    '429 32': 'Kullavik',
    '429 33': 'Kullavik',
    '429 34': 'Kullavik',
    '429 35': 'Kullavik',
    '429 41': 'Särö',
    '429 42': 'Särö',
    '429 43': 'Särö',
    '429 44': 'Särö',
    '429 80': 'Särö',
    '430 20': 'Veddige',
    '430 30': 'Frillesås',
    '430 31': 'Åsa',
    '434 00': 'Kungsbacka',
    '434 01': 'Kungsbacka',
    '434 03': 'Vallda',
    '434 21': 'Kungsbacka',
    '434 22': 'Kungsbacka',
    '434 23': 'Kungsbacka',
    '434 24': 'Kungsbacka',
    '434 30': 'Kungsbacka',
    '434 31': 'Kungsbacka',
    '434 32': 'Kungsbacka',
    '434 33': 'Kungsbacka',
    '434 34': 'Kungsbacka',
    '434 35': 'Kungsbacka',
    '434 36': 'Kungsbacka',
    '434 37': 'Kungsbacka',
    '434 38': 'Kungsbacka',
    '434 39': 'Kungsbacka',
    '434 40': 'Kungsbacka',
    '434 41': 'Kungsbacka',
    '434 42': 'Kungsbacka',
    '434 43': 'Kungsbacka',
    '434 44': 'Kungsbacka',
    '434 45': 'Kungsbacka',
    '434 46': 'Kungsbacka',
    '434 47': 'Kungsbacka',
    '434 48': 'Kungsbacka',
    '434 80': 'Kungsbacka',
    '434 81': 'Kungsbacka',
    '434 88': 'Kungsbacka',
    '434 91': 'Kungsbacka',
    '434 92': 'Vallda',
    '434 93': 'Vallda',
    '434 94': 'Vallda',
    '434 95': 'Kungsbacka',
    '434 96': 'Kungsbacka',
    '434 97': 'Kungsbacka',
    '434 98': 'Kungsbacka',
    '439 00': 'Onsala',
    '439 21': 'Onsala',
    '439 22': 'Onsala',
    '439 23': 'Onsala',
    '439 24': 'Onsala',
    '439 25': 'Onsala',
    '439 26': 'Onsala',
    '439 30': 'Onsala',
    '439 31': 'Onsala',
    '439 32': 'Onsala',
    '439 33': 'Onsala',
    '439 34': 'Onsala',
    '439 35': 'Onsala',
    '439 36': 'Onsala',
    '439 37': 'Onsala',
    '439 91': 'Onsala',
    '439 92': 'Onsala',
    '439 93': 'Onsala',
    '439 94': 'Onsala',
    '302 24': 'Halmstad',
    '302 26': 'Halmstad',
    '302 29': 'Halmstad',
    '308 00': 'Halmstad',
    '308 01': 'Halmstad',
    '311 20': 'Falkenberg',
    '311 46': 'Falkenberg',
    '311 84': 'Falkenberg',
    '312 20': 'Laholm',
    '313 20': 'Oskarström',
    '314 20': 'Hyltebruk',
    '429 20': 'Kullavik',
    '432 00': 'Varberg',
    '432 10': 'Varberg',
    '434 20': 'Kungsbacka',
    '434 82': 'Kungsbacka',
    '439 20': 'Onsala',
    '435 00': 'Mölnlycke',
    '435 21': 'Mölnlycke',
    '435 22': 'Mölnlycke',
    '435 23': 'Mölnlycke',
    '435 24': 'Mölnlycke',
    '435 25': 'Mölnlycke',
    '435 30': 'Mölnlycke',
    '435 31': 'Mölnlycke',
    '435 32': 'Mölnlycke',
    '435 33': 'Mölnlycke',
    '435 35': 'Mölnlycke',
    '435 37': 'Mölnlycke',
    '435 38': 'Mölnlycke',
    '435 40': 'Mölnlycke',
    '435 41': 'Mölnlycke',
    '435 44': 'Mölnlycke',
    '435 80': 'Mölnlycke',
    '435 82': 'Mölnlycke',
    '435 83': 'Mölnlycke',
    '435 84': 'Mölnlycke',
    '435 85': 'Mölnlycke',
    '435 87': 'Mölnlycke',
    '438 00': 'Härryda',
    '438 11': 'Landvetter',
    '438 12': 'Landvetter',
    '438 13': 'Landvetter',
    '438 14': 'Landvetter',
    '438 21': 'Landvetter',
    '438 22': 'Landvetter',
    '438 23': 'Landvetter',
    '438 24': 'Landvetter',
    '438 32': 'Landvetter',
    '438 33': 'Landvetter',
    '438 34': 'Landvetter',
    '438 35': 'Landvetter',
    '438 36': 'Landvetter',
    '438 38': 'Landvetter',
    '438 80': 'Landvetter',
    '438 91': 'Landvetter',
    '438 92': 'Härryda',
    '438 94': 'Härryda',
    '433 00': 'Partille',
    '433 02': 'Sävedalen',
    '433 04': 'Jonsered',
    '433 21': 'Partille',
    '433 22': 'Partille',
    '433 23': 'Partille',
    '433 24': 'Partille',
    '433 25': 'Partille',
    '433 26': 'Partille',
    '433 28': 'Partille',
    '433 30': 'Partille',
    '433 31': 'Partille',
    '433 32': 'Partille',
    '433 33': 'Partille',
    '433 34': 'Partille',
    '433 35': 'Partille',
    '433 36': 'Partille',
    '433 37': 'Partille',
    '433 38': 'Partille',
    '433 41': 'Partille',
    '433 42': 'Partille',
    '433 43': 'Partille',
    '433 44': 'Partille',
    '433 45': 'Partille',
    '433 46': 'Partille',
    '433 47': 'Partille',
    '433 48': 'Partille',
    '433 49': 'Partille',
    '433 50': 'Öjersjö',
    '433 51': 'Öjersjö',
    '433 60': 'Sävedalen',
    '433 61': 'Sävedalen',
    '433 62': 'Sävedalen',
    '433 63': 'Sävedalen',
    '433 64': 'Sävedalen',
    '433 65': 'Sävedalen',
    '433 66': 'Sävedalen',
    '433 67': 'Sävedalen',
    '433 68': 'Sävedalen',
    '433 69': 'Sävedalen',
    '433 70': 'Sävedalen',
    '433 80': 'Partille',
    '433 81': 'Jonsered',
    '433 82': 'Partille',
    '433 83': 'Partille',
    '433 84': 'Partille',
    '433 86': 'Partille',
    '430 90': 'Öckerö',
    '430 91': 'Hönö',
    '430 92': 'Fotö',
    '430 93': 'Hälsö',
    '430 94': 'Bohus-Björkö',
    '430 95': 'Källö-Knippla',
    '430 96': 'Hyppeln',
    '430 97': 'Rörö',
    '444 00': 'Stenungsund',
    '444 02': 'Stora Höga',
    '444 21': 'Stenungsund',
    '444 22': 'Stenungsund',
    '444 23': 'Stenungsund',
    '444 24': 'Stenungsund',
    '444 26': 'Stenungsund',
    '444 28': 'Stenungsund',
    '444 30': 'Stenungsund',
    '444 31': 'Stenungsund',
    '444 32': 'Stenungsund',
    '444 40': 'Stenungsund',
    '444 41': 'Stenungsund',
    '444 42': 'Stenungsund',
    '444 43': 'Stenungsund',
    '444 44': 'Stenungsund',
    '444 45': 'Stenungsund',
    '444 46': 'Stenungsund',
    '444 47': 'Stenungsund',
    '444 48': 'Stenungsund',
    '444 53': 'Stenungsund',
    '444 55': 'Stenungsund',
    '444 60': 'Stora Höga',
    '444 65': 'Jörlanda',
    '444 82': 'Stenungsund',
    '444 83': 'Stenungsund',
    '444 84': 'Stenungsund',
    '444 85': 'Stenungsund',
    '444 86': 'Stenungsund',
    '444 87': 'Stenungsund',
    '444 91': 'Stenungsund',
    '444 92': 'Jörlanda',
    '444 93': 'Spekeröd',
    '444 94': 'Ucklum',
    '444 95': 'Ödsmål',
    '444 96': 'Ödsmål',
    '471 75': 'Hjälteby',
    '471 00': 'Kållekärr',
    '471 01': 'Skärhamn',
    '471 10': 'Rönnäng',
    '471 11': 'Rönnäng',
    '471 12': 'Åstol',
    '471 13': 'Åstol',
    '471 14': 'Dyrön',
    '471 15': 'Dyrön',
    '471 16': 'Myggenäs',
    '471 17': 'Myggenäs',
    '471 21': 'Skärhamn',
    '471 22': 'Skärhamn',
    '471 30': 'Skärhamn',
    '471 31': 'Skärhamn',
    '471 32': 'Skärhamn',
    '471 33': 'Skärhamn',
    '471 41': 'Rönnäng',
    '471 42': 'Rönnäng',
    '471 43': 'Dyrön',
    '471 44': 'Åstol',
    '471 51': 'Klädesholmen',
    '471 60': 'Myggenäs',
    '471 61': 'Myggenäs',
    '471 62': 'Höviksnäs',
    '471 70': 'Höviksnäs',
    '471 71': 'Hakenäset',
    '471 72': 'Hjälteby',
    '471 73': 'Hjälteby',
    '471 74': 'Hjälteby',
    '471 80': 'Skärhamn',
    '471 90': 'Kyrkesund',
    '471 91': 'Klövedal',
    '471 92': 'Klövedal',
    '471 93': 'Kållekärr',
    '471 94': 'Kållekärr',
    '471 95': 'Skärhamn',
    '471 96': 'Bleket',
    '471 97': 'Rönnäng',
    '471 98': 'Fagerfjäll',
    '471 99': 'Vallhamn',
    '472 00': 'Varekil',
    '472 01': 'Svanesund',
    '472 21': 'Svanesund',
    '472 22': 'Svanesund',
    '472 23': 'Svanesund',
    '472 31': 'Svanesund',
    '472 32': 'Svanesund',
    '472 91': 'Stillingsön',
    '472 92': 'Stillingsön',
    '472 93': 'Svanesund',
    '472 94': 'Svanesund',
    '472 95': 'Varekil',
    '472 96': 'Varekil',
    '472 97': 'Varekil',
    '473 01': 'Henån',
    '473 21': 'Henån',
    '473 22': 'Henån',
    '473 23': 'Henån',
    '473 31': 'Henån',
    '473 32': 'Henån',
    '473 33': 'Henån',
    '473 34': 'Henån',
    '473 80': 'Henån',
    '473 91': 'Henån',
    '473 93': 'Henån',
    '473 94': 'Henån',
    '473 95': 'Henån',
    '473 96': 'Henån',
    '473 97': 'Henån',
    '473 98': 'Henån',
    '473 99': 'Henån',
    '474 01': 'Ellös',
    '474 02': 'Mollösund',
    '474 04': 'Käringön',
    '474 11': 'Mollösund',
    '474 12': 'Mollösund',
    '474 13': 'Mollösund',
    '474 14': 'Gullholmen',
    '474 15': 'Gullholmen',
    '474 16': 'Gullholmen',
    '474 21': 'Ellös',
    '474 22': 'Ellös',
    '474 23': 'Ellös',
    '474 31': 'Ellös',
    '474 32': 'Ellös',
    '474 70': 'Mollösund',
    '474 71': 'Gullholmen',
    '474 92': 'Ellös',
    '474 93': 'Ellös',
    '474 94': 'Hälleviksstrand',
    '474 95': 'Hälleviksstrand',
    '474 96': 'Nösund',
    '450 43': 'Smögen',
    '450 45': 'Bohus-Malmön',
    '450 46': 'Hunnebostrand',
    '450 47': 'Bovallstrand',
    '456 01': 'Kungshamn',
    '456 21': 'Kungshamn',
    '456 22': 'Kungshamn',
    '456 23': 'Kungshamn',
    '456 24': 'Kungshamn',
    '456 31': 'Kungshamn',
    '456 32': 'Kungshamn',
    '456 33': 'Kungshamn',
    '456 34': 'Kungshamn',
    '456 42': 'Kungshamn',
    '456 43': 'Kungshamn',
    '456 44': 'Kungshamn',
    '456 80': 'Kungshamn',
    '456 81': 'Kungshamn',
    '456 91': 'Kungshamn',
    '450 54': 'Hedekas',
    '455 01': 'Munkedal',
    '455 21': 'Munkedal',
    '455 22': 'Munkedal',
    '455 30': 'Munkedal',
    '455 31': 'Munkedal',
    '455 32': 'Munkedal',
    '455 33': 'Munkedal',
    '455 34': 'Munkedal',
    '455 35': 'Munkedal',
    '455 80': 'Munkedal',
    '455 81': 'Munkedal',
    '455 82': 'Munkedal',
    '455 91': 'Munkedal',
    '455 92': 'Munkedal',
    '455 93': 'Munkedal',
    '450 53': 'Hällevadsholm',
    '450 70': 'Hamburgsund',
    '450 71': 'Fjällbacka',
    '450 73': 'Rabbalshede',
    '450 84': 'Bullaren',
    '457 00': 'Tanumshede',
    '457 01': 'Tanumshede',
    '457 02': 'Grebbestad',
    '457 18': 'Grebbestad',
    '457 21': 'Tanumshede',
    '457 22': 'Tanumshede',
    '457 23': 'Tanumshede',
    '457 30': 'Tanumshede',
    '457 31': 'Tanumshede',
    '457 32': 'Tanumshede',
    '457 72': 'Grebbestad',
    '457 73': 'Havstenssund',
    '457 80': 'Tanumshede',
    '457 81': 'Tanumshede',
    '457 82': 'Tanumshede',
    '457 91': 'Tanumshede',
    '457 93': 'Tanumshede',
    '457 95': 'Grebbestad',
    '457 97': 'Resö',
    '668 00': 'Ed',
    '668 01': 'Ed',
    '668 21': 'Ed',
    '668 22': 'Ed',
    '668 24': 'Ed',
    '668 30': 'Ed',
    '668 31': 'Ed',
    '668 32': 'Ed',
    '458 01': 'Färgelanda',
    '458 21': 'Färgelanda',
    '458 22': 'Färgelanda',
    '458 30': 'Färgelanda',
    '458 31': 'Färgelanda',
    '458 32': 'Färgelanda',
    '458 40': 'Ödeborg',
    '458 80': 'Färgelanda',
    '458 81': 'Färgelanda',
    '458 91': 'Färgelanda',
    '458 92': 'Färgelanda',
    '458 93': 'Färgelanda',
    '458 94': 'Stigen',
    '445 02': 'Surte',
    '445 21': 'Bohus',
    '445 22': 'Bohus',
    '445 23': 'Bohus',
    '445 32': 'Bohus',
    '445 33': 'Bohus',
    '445 36': 'Bohus',
    '445 37': 'Bohus',
    '445 52': 'Surte',
    '445 55': 'Surte',
    '445 56': 'Surte',
    '445 57': 'Surte',
    '445 80': 'Bohus',
    '446 00': 'Älvängen',
    '446 21': 'Älvängen',
    '446 22': 'Älvängen',
    '446 23': 'Älvängen',
    '446 32': 'Älvängen',
    '446 33': 'Älvängen',
    '446 35': 'Älvängen',
    '446 36': 'Älvängen',
    '446 37': 'Älvängen',
    '446 38': 'Älvängen',
    '446 40': 'Skepplanda',
    '446 41': 'Skepplanda',
    '446 92': 'Skepplanda',
    '446 94': 'Skepplanda',
    '446 95': 'Älvängen',
    '446 96': 'Hålanda',
    '446 97': 'Älvängen',
    '449 00': 'Nödinge',
    '449 11': 'Nol',
    '449 14': 'Alafors',
    '449 15': 'Alafors',
    '449 21': 'Nödinge',
    '449 22': 'Nödinge',
    '449 30': 'Nödinge',
    '449 31': 'Nödinge',
    '449 32': 'Nödinge',
    '449 33': 'Nödinge',
    '449 34': 'Nödinge',
    '449 40': 'Nol',
    '449 41': 'Nol',
    '449 42': 'Nol',
    '449 43': 'Nol',
    '449 44': 'Nol',
    '449 50': 'Alafors',
    '449 51': 'Alafors',
    '449 80': 'Alafors',
    '449 81': 'Nol',
    '433 75': 'Jonsered',
    '433 76': 'Jonsered',
    '443 00': 'Lerum',
    '443 03': 'Stenkullen',
    '443 04': 'Gråbo',
    '443 05': 'Sjövik',
    '443 11': 'Gråbo',
    '443 12': 'Gråbo',
    '443 13': 'Gråbo',
    '443 14': 'Gråbo',
    '443 15': 'Gråbo',
    '443 21': 'Lerum',
    '443 22': 'Lerum',
    '443 23': 'Lerum',
    '443 24': 'Lerum',
    '443 25': 'Lerum',
    '443 30': 'Lerum',
    '443 31': 'Lerum',
    '443 32': 'Lerum',
    '443 33': 'Lerum',
    '443 34': 'Lerum',
    '443 35': 'Lerum',
    '443 38': 'Lerum',
    '443 39': 'Lerum',
    '443 40': 'Gråbo',
    '443 41': 'Gråbo',
    '443 42': 'Gråbo',
    '443 43': 'Gråbo',
    '443 45': 'Sjövik',
    '443 50': 'Lerum',
    '443 51': 'Lerum',
    '443 60': 'Stenkullen',
    '443 61': 'Stenkullen',
    '443 70': 'Gråbo',
    '443 71': 'Gråbo',
    '443 72': 'Gråbo',
    '443 73': 'Gråbo',
    '443 74': 'Sjövik',
    '443 80': 'Lerum',
    '443 91': 'Lerum',
    '443 92': 'Lerum',
    '443 95': 'Stenkullen',
    '443 96': 'Stenkullen',
    '448 00': 'Floda',
    '448 02': 'Tollered',
    '448 21': 'Floda',
    '448 22': 'Floda',
    '448 23': 'Floda',
    '448 24': 'Floda',
    '448 30': 'Floda',
    '448 31': 'Floda',
    '448 32': 'Floda',
    '448 33': 'Floda',
    '448 34': 'Floda',
    '448 35': 'Floda',
    '448 36': 'Floda',
    '448 37': 'Floda',
    '448 51': 'Tollered',
    '448 91': 'Floda',
    '448 92': 'Floda',
    '449 90': 'Nol',
    '447 00': 'Vårgårda',
    '447 21': 'Vårgårda',
    '447 22': 'Vårgårda',
    '447 23': 'Vårgårda',
    '447 24': 'Vårgårda',
    '447 30': 'Vårgårda',
    '447 31': 'Vårgårda',
    '447 32': 'Vårgårda',
    '447 33': 'Vårgårda',
    '447 34': 'Vårgårda',
    '447 35': 'Vårgårda',
    '447 37': 'Vårgårda',
    '447 80': 'Vårgårda',
    '447 81': 'Vårgårda',
    '447 82': 'Vårgårda',
    '447 83': 'Vårgårda',
    '447 84': 'Vårgårda',
    '447 91': 'Vårgårda',
    '447 92': 'Vårgårda',
    '447 93': 'Vårgårda',
    '447 94': 'Vårgårda',
    '447 95': 'Vårgårda',
    '430 63': 'Hindås',
    '517 00': 'Bollebygd',
    '517 21': 'Bollebygd',
    '517 22': 'Bollebygd',
    '517 23': 'Bollebygd',
    '517 33': 'Bollebygd',
    '517 34': 'Bollebygd',
    '517 35': 'Bollebygd',
    '517 36': 'Bollebygd',
    '517 37': 'Bollebygd',
    '517 70': 'Töllsjö',
    '517 71': 'Olsfors',
    '517 81': 'Bollebygd',
    '517 82': 'Bollebygd',
    '517 83': 'Bollebygd',
    '517 91': 'Bollebygd',
    '517 92': 'Bollebygd',
    '517 93': 'Töllsjö',
    '517 94': 'Töllsjö',
    '467 01': 'Grästorp',
    '467 21': 'Grästorp',
    '467 22': 'Grästorp',
    '467 23': 'Grästorp',
    '467 30': 'Grästorp',
    '467 31': 'Grästorp',
    '467 32': 'Grästorp',
    '467 35': 'Grästorp',
    '467 40': 'Grästorp',
    '467 80': 'Grästorp',
    '467 81': 'Grästorp',
    '467 82': 'Grästorp',
    '467 83': 'Grästorp',
    '467 84': 'Grästorp',
    '467 91': 'Grästorp',
    '467 92': 'Grästorp',
    '467 93': 'Grästorp',
    '467 94': 'Grästorp',
    '467 95': 'Grästorp',
    '467 96': 'Grästorp',
    '465 01': 'Nossebro',
    '465 21': 'Nossebro',
    '465 30': 'Nossebro',
    '465 31': 'Nossebro',
    '465 80': 'Nossebro',
    '465 81': 'Nossebro',
    '465 82': 'Nossebro',
    '465 91': 'Nossebro',
    '465 94': 'Nossebro',
    '465 96': 'Nossebro',
    '465 97': 'Nossebro',
    '465 98': 'Nossebro',
    '546 01': 'Karlsborg',
    '546 18': 'Karlsborg',
    '546 21': 'Karlsborg',
    '546 22': 'Karlsborg',
    '546 23': 'Karlsborg',
    '546 30': 'Karlsborg',
    '546 31': 'Karlsborg',
    '546 32': 'Karlsborg',
    '546 33': 'Karlsborg',
    '546 34': 'Karlsborg',
    '546 72': 'Mölltorp',
    '546 73': 'Forsvik',
    '546 81': 'Karlsborg',
    '546 82': 'Karlsborg',
    '546 91': 'Karlsborg',
    '546 92': 'Mölltorp',
    '546 93': 'Undenäs',
    '546 94': 'Undenäs',
    '546 95': 'Karlsborg',
    '547 00': 'Gullspång',
    '547 02': 'Otterbäcken',
    '547 21': 'Gullspång',
    '547 22': 'Gullspång',
    '547 24': 'Gullspång',
    '547 25': 'Gullspång',
    '547 30': 'Gullspång',
    '547 31': 'Gullspång',
    '547 72': 'Otterbäcken',
    '547 81': 'Otterbäcken',
    '547 82': 'Otterbäcken',
    '547 91': 'Gullspång',
    '547 92': 'Gullspång',
    '547 93': 'Skagersvik',
    '548 00': 'Hova',
    '548 21': 'Hova',
    '548 22': 'Hova',
    '548 31': 'Hova',
    '548 32': 'Hova',
    '548 92': 'Hova',
    '330 27': 'Hestra',
    '514 00': 'Tranemo',
    '514 05': 'Länghem',
    '514 06': 'Dalstorp',
    '514 11': 'Limmared',
    '514 12': 'Limmared',
    '514 21': 'Tranemo',
    '514 22': 'Tranemo',
    '514 23': 'Tranemo',
    '514 24': 'Tranemo',
    '514 31': 'Tranemo',
    '514 32': 'Tranemo',
    '514 33': 'Tranemo',
    '514 34': 'Tranemo',
    '514 35': 'Tranemo',
    '514 40': 'Limmared',
    '514 41': 'Limmared',
    '514 42': 'Limmared',
    '514 43': 'Länghem',
    '514 44': 'Länghem',
    '514 50': 'Limmared',
    '514 52': 'Länghem',
    '514 53': 'Månstad',
    '514 54': 'Nittorp',
    '514 55': 'Ljungsarp',
    '514 60': 'Dalstorp',
    '514 61': 'Dalstorp',
    '514 62': 'Ölsremma',
    '514 63': 'Dalstorp',
    '514 70': 'Grimsås',
    '514 80': 'Tranemo',
    '514 81': 'Grimsås',
    '514 83': 'Limmared',
    '514 84': 'Ölsremma',
    '514 91': 'Tranemo',
    '514 93': 'Ambjörnarp',
    '514 95': 'Grimsås',
    '560 25': 'Bottnaryd',
    '450 63': 'Högsäter',
    '660 10': 'Dals Långed',
    '660 11': 'Billingsfors',
    '666 00': 'Bengtsfors',
    '666 01': 'Bengtsfors',
    '666 21': 'Bengtsfors',
    '666 22': 'Bengtsfors',
    '666 23': 'Bengtsfors',
    '666 24': 'Bengtsfors',
    '666 25': 'Bengtsfors',
    '666 30': 'Bengtsfors',
    '666 31': 'Bengtsfors',
    '666 32': 'Bengtsfors',
    '666 40': 'Skåpafors',
    '666 91': 'Bengtsfors',
    '666 92': 'Bengtsfors',
    '668 40': 'Bäckefors',
    '668 88': 'Bäckefors',
    '668 91': 'Ed',
    '668 92': 'Ed',
    '668 93': 'Ed',
    '668 95': 'Ödskölt',
    '464 01': 'Mellerud',
    '464 21': 'Mellerud',
    '464 22': 'Mellerud',
    '464 23': 'Mellerud',
    '464 30': 'Mellerud',
    '464 31': 'Mellerud',
    '464 32': 'Mellerud',
    '464 33': 'Mellerud',
    '464 34': 'Mellerud',
    '464 40': 'Åsensbruk',
    '464 50': 'Dals Rostock',
    '464 71': 'Köpmannebro',
    '464 80': 'Mellerud',
    '464 82': 'Åsensbruk',
    '464 91': 'Dals Rostock',
    '464 92': 'Mellerud',
    '464 93': 'Mellerud',
    '464 94': 'Mellerud',
    '464 95': 'Dalskog',
    '668 94': 'Bäckefors',
    '446 91': 'Alvhem',
    '446 93': 'Skepplanda',
    '460 11': 'Nygård',
    '460 12': 'Prässebo',
    '463 01': 'Lilla Edet',
    '463 21': 'Lilla Edet',
    '463 22': 'Lilla Edet',
    '463 23': 'Lilla Edet',
    '463 30': 'Lilla Edet',
    '463 31': 'Lilla Edet',
    '463 32': 'Lilla Edet',
    '463 33': 'Lilla Edet',
    '463 34': 'Lilla Edet',
    '463 35': 'Göta',
    '463 71': 'Lödöse',
    '463 75': 'Hjärtum',
    '463 80': 'Lilla Edet',
    '463 81': 'Lilla Edet',
    '463 82': 'Lilla Edet',
    '463 83': 'Lilla Edet',
    '463 91': 'Lilla Edet',
    '463 92': 'Lilla Edet',
    '463 93': 'Västerlanda',
    '463 94': 'Lilla Edet',
    '463 95': 'Lödöse',
    '430 33': 'Fjärås',
    '430 64': 'Hällingsjö',
    '430 65': 'Rävlanda',
    '510 21': 'Sätila',
    '510 22': 'Hyssna',
    '511 00': 'Kinna',
    '511 02': 'Skene',
    '511 03': 'Örby',
    '511 04': 'Kinnahult',
    '511 05': 'Rydal',
    '511 10': 'Fritsla',
    '511 21': 'Kinna',
    '511 22': 'Kinna',
    '511 23': 'Kinna',
    '511 31': 'Örby',
    '511 41': 'Kinna',
    '511 42': 'Kinnahult',
    '511 54': 'Kinna',
    '511 55': 'Kinna',
    '511 56': 'Kinna',
    '511 57': 'Kinna',
    '511 58': 'Kinna',
    '511 59': 'Kinna',
    '511 61': 'Skene',
    '511 62': 'Skene',
    '511 63': 'Skene',
    '511 70': 'Rydal',
    '511 71': 'Fritsla',
    '511 72': 'Fritsla',
    '511 73': 'Fritsla',
    '511 80': 'Kinna',
    '511 81': 'Skene',
    '511 82': 'Kinna',
    '511 91': 'Skene',
    '511 92': 'Örby',
    '511 93': 'Torestorp',
    '511 94': 'Älekulla',
    '511 95': 'Öxabäck',
    '511 96': 'Berghem',
    '511 97': 'Hajom',
    '519 00': 'Horred',
    '519 21': 'Horred',
    '519 30': 'Horred',
    '519 31': 'Horred',
    '519 81': 'Horred',
    '519 90': 'Horred',
    '519 91': 'Istorp',
    '519 92': 'Kungsäter',
    '519 94': 'Björketorp',
    '519 95': 'Tostared',
    '519 96': 'Fotskäl',
    '519 97': 'Öxnevalla',
    '512 00': 'Svenljunga',
    '512 04': 'Överlida',
    '512 21': 'Svenljunga',
    '512 22': 'Svenljunga',
    '512 23': 'Svenljunga',
    '512 24': 'Svenljunga',
    '512 50': 'Svenljunga',
    '512 51': 'Svenljunga',
    '512 52': 'Svenljunga',
    '512 53': 'Svenljunga',
    '512 54': 'Svenljunga',
    '512 60': 'Överlida',
    '512 61': 'Kalv',
    '512 62': 'Mårdaklev',
    '512 63': 'Östra Frölunda',
    '512 64': 'Holsljunga',
    '512 65': 'Mjöbäck',
    '512 76': 'Hillared',
    '512 77': 'Sexdrega',
    '512 80': 'Svenljunga',
    '512 81': 'Svenljunga',
    '512 83': 'Överlida',
    '512 84': 'Överlida',
    '512 85': 'Svenljunga',
    '512 90': 'Hillared',
    '512 92': 'Svenljunga',
    '512 93': 'Svenljunga',
    '512 94': 'Svenljunga',
    '512 95': 'Håcksvik',
    '514 92': 'Uddebo',
    '514 94': 'Sjötofta',
    '524 01': 'Herrljunga',
    '524 02': 'Ljung',
    '524 21': 'Herrljunga',
    '524 22': 'Herrljunga',
    '524 23': 'Herrljunga',
    '524 24': 'Herrljunga',
    '524 25': 'Herrljunga',
    '524 26': 'Herrljunga',
    '524 30': 'Herrljunga',
    '524 31': 'Herrljunga',
    '524 32': 'Herrljunga',
    '524 41': 'Ljung',
    '524 42': 'Ljung',
    '524 80': 'Ljung',
    '524 91': 'Herrljunga',
    '524 92': 'Herrljunga',
    '524 93': 'Herrljunga',
    '465 95': 'Nossebro',
    '530 10': 'Vedum',
    '534 01': 'Vara',
    '534 03': 'Stora Levene',
    '534 21': 'Vara',
    '534 22': 'Vara',
    '534 23': 'Vara',
    '534 24': 'Vara',
    '534 25': 'Vara',
    '534 30': 'Vara',
    '534 31': 'Vara',
    '534 32': 'Vara',
    '534 50': 'Vara',
    '534 73': 'Stora Levene',
    '534 81': 'Vara',
    '534 82': 'Vara',
    '534 90': 'Vara',
    '534 91': 'Vara',
    '534 92': 'Tråvad',
    '534 94': 'Vara',
    '534 95': 'Vara',
    '534 96': 'Vara',
    '535 21': 'Kvänum',
    '535 22': 'Kvänum',
    '535 23': 'Kvänum',
    '535 30': 'Kvänum',
    '535 91': 'Kvänum',
    '535 93': 'Kvänum',
    '533 01': 'Götene',
    '533 02': 'Lundsbrunn',
    '533 03': 'Källby',
    '533 04': 'Hällekis',
    '533 21': 'Götene',
    '533 22': 'Götene',
    '533 23': 'Götene',
    '533 30': 'Götene',
    '533 31': 'Götene',
    '533 32': 'Götene',
    '533 33': 'Götene',
    '533 34': 'Götene',
    '533 50': 'Götene',
    '533 72': 'Lundsbrunn',
    '533 73': 'Källby',
    '533 74': 'Hällekis',
    '533 80': 'Götene',
    '533 81': 'Källby',
    '533 82': 'Götene',
    '533 91': 'Götene',
    '533 93': 'Källby',
    '533 94': 'Hällekis',
    '533 95': 'Götene',
    '533 96': 'Götene',
    '533 97': 'Götene',
    '543 01': 'Tibro',
    '543 21': 'Tibro',
    '543 22': 'Tibro',
    '543 23': 'Tibro',
    '543 30': 'Tibro',
    '543 31': 'Tibro',
    '543 32': 'Tibro',
    '543 33': 'Tibro',
    '543 34': 'Tibro',
    '543 35': 'Tibro',
    '543 36': 'Tibro',
    '543 37': 'Tibro',
    '543 50': 'Tibro',
    '543 51': 'Tibro',
    '543 80': 'Tibro',
    '543 81': 'Tibro',
    '543 91': 'Tibro',
    '543 92': 'Tibro',
    '543 93': 'Tibro',
    '543 94': 'Tibro',
    '543 95': 'Fagersanna',
    '545 01': 'Töreboda',
    '545 02': 'Älgarås',
    '545 21': 'Töreboda',
    '545 22': 'Töreboda',
    '545 23': 'Töreboda',
    '545 30': 'Töreboda',
    '545 31': 'Töreboda',
    '545 32': 'Töreboda',
    '545 33': 'Töreboda',
    '545 34': 'Töreboda',
    '545 35': 'Töreboda',
    '545 72': 'Älgarås',
    '545 90': 'Töreboda',
    '545 91': 'Töreboda',
    '545 92': 'Älgarås',
    '545 93': 'Töreboda',
    '548 91': 'Hova',
    '549 02': 'Moholm',
    '549 03': 'Moholm',
    '549 04': 'Moholm',
    '549 05': 'Moholm',
    '549 06': 'Moholm',
    '549 41': 'Moholm',
    '549 93': 'Moholm',
    '400 10': 'Göteborg',
    '400 12': 'Göteborg',
    '400 13': 'Göteborg',
    '400 14': 'Göteborg',
    '400 15': 'Göteborg',
    '400 16': 'Göteborg',
    '400 18': 'Göteborg',
    '400 20': 'Göteborg',
    '400 22': 'Göteborg',
    '400 24': 'Göteborg',
    '400 25': 'Göteborg',
    '400 26': 'Göteborg',
    '400 27': 'Göteborg',
    '400 28': 'Göteborg',
    '400 29': 'Göteborg',
    '400 31': 'Göteborg',
    '400 32': 'Göteborg',
    '400 36': 'Göteborg',
    '400 40': 'Göteborg',
    '400 43': 'Göteborg',
    '400 50': 'Göteborg',
    '400 53': 'Göteborg',
    '400 60': 'Göteborg',
    '400 64': 'Göteborg',
    '400 70': 'Göteborg',
    '400 72': 'Göteborg',
    '400 91': 'Göteborg',
    '400 92': 'Göteborg',
    '400 93': 'Göteborg',
    '400 94': 'Göteborg',
    '400 95': 'Göteborg',
    '400 96': 'Göteborg',
    '400 97': 'Göteborg',
    '401 10': 'Göteborg',
    '401 11': 'Göteborg',
    '401 20': 'Göteborg',
    '401 21': 'Göteborg',
    '401 22': 'Göteborg',
    '401 23': 'Göteborg',
    '401 24': 'Göteborg',
    '401 25': 'Göteborg',
    '401 26': 'Göteborg',
    '401 27': 'Göteborg',
    '401 50': 'Göteborg',
    '401 51': 'Göteborg',
    '401 80': 'Göteborg',
    '401 82': 'Göteborg',
    '401 83': 'Göteborg',
    '401 84': 'Göteborg',
    '401 99': 'Göteborg',
    '402 21': 'Göteborg',
    '402 22': 'Göteborg',
    '402 23': 'Göteborg',
    '402 24': 'Göteborg',
    '402 25': 'Göteborg',
    '402 26': 'Göteborg',
    '402 27': 'Göteborg',
    '402 28': 'Göteborg',
    '402 29': 'Göteborg',
    '402 30': 'Göteborg',
    '402 31': 'Göteborg',
    '402 32': 'Göteborg',
    '402 33': 'Göteborg',
    '402 34': 'Göteborg',
    '402 35': 'Göteborg',
    '402 38': 'Göteborg',
    '402 41': 'Göteborg',
    '402 42': 'Göteborg',
    '402 43': 'Göteborg',
    '402 50': 'Göteborg',
    '402 51': 'Göteborg',
    '402 52': 'Göteborg',
    '402 54': 'Göteborg',
    '402 55': 'Göteborg',
    '402 57': 'Göteborg',
    '402 58': 'Göteborg',
    '402 59': 'Göteborg',
    '402 61': 'Göteborg',
    '402 64': 'Göteborg',
    '402 71': 'Göteborg',
    '402 72': 'Göteborg',
    '402 73': 'Göteborg',
    '402 74': 'Göteborg',
    '402 75': 'Göteborg',
    '402 76': 'Göteborg',
    '402 77': 'Göteborg',
    '402 78': 'Göteborg',
    '403 11': 'Göteborg',
    '403 12': 'Göteborg',
    '403 13': 'Göteborg',
    '403 14': 'Göteborg',
    '403 15': 'Göteborg',
    '403 16': 'Göteborg',
    '403 17': 'Göteborg',
    '403 20': 'Göteborg',
    '403 21': 'Göteborg',
    '403 30': 'Göteborg',
    '403 31': 'Göteborg',
    '403 36': 'Göteborg',
    '403 38': 'Göteborg',
    '403 39': 'Göteborg',
    '403 40': 'Göteborg',
    '404 01': 'Göteborg',
    '404 21': 'Göteborg',
    '404 22': 'Göteborg',
    '404 23': 'Göteborg',
    '404 24': 'Göteborg',
    '404 25': 'Göteborg',
    '404 26': 'Göteborg',
    '404 27': 'Göteborg',
    '404 28': 'Göteborg',
    '404 29': 'Göteborg',
    '404 30': 'Göteborg',
    '404 31': 'Göteborg',
    '404 32': 'Göteborg',
    '404 39': 'Göteborg',
    '404 80': 'Göteborg',
    '404 82': 'Göteborg',
    '404 83': 'Göteborg',
    '404 84': 'Göteborg',
    '404 85': 'Göteborg',
    '405 02': 'Göteborg',
    '405 03': 'Göteborg',
    '405 04': 'Göteborg',
    '405 05': 'Göteborg',
    '405 06': 'Göteborg',
    '405 07': 'Göteborg',
    '405 08': 'Göteborg',
    '405 09': 'Göteborg',
    '405 10': 'Göteborg',
    '405 12': 'Göteborg',
    '405 13': 'Göteborg',
    '405 14': 'Göteborg',
    '405 15': 'Göteborg',
    '405 16': 'Göteborg',
    '405 18': 'Göteborg',
    '405 19': 'Göteborg',
    '405 21': 'Göteborg',
    '405 22': 'Göteborg',
    '405 23': 'Göteborg',
    '405 24': 'Göteborg',
    '405 30': 'Göteborg',
    '405 31': 'Göteborg',
    '405 32': 'Göteborg',
    '405 33': 'Göteborg',
    '405 35': 'Göteborg',
    '405 36': 'Göteborg',
    '405 40': 'Göteborg',
    '405 44': 'Göteborg',
    '411 00': 'Göteborg',
    '411 01': 'Göteborg',
    '411 02': 'Göteborg',
    '411 03': 'Göteborg',
    '411 04': 'Göteborg',
    '411 05': 'Göteborg',
    '411 06': 'Göteborg',
    '411 07': 'Göteborg',
    '411 08': 'Göteborg',
    '411 09': 'Göteborg',
    '411 10': 'Göteborg',
    '411 11': 'Göteborg',
    '411 13': 'Göteborg',
    '411 14': 'Göteborg',
    '411 15': 'Göteborg',
    '411 16': 'Göteborg',
    '411 17': 'Göteborg',
    '411 18': 'Göteborg',
    '411 19': 'Göteborg',
    '411 20': 'Göteborg',
    '411 21': 'Göteborg',
    '411 22': 'Göteborg',
    '411 23': 'Göteborg',
    '411 24': 'Göteborg',
    '411 25': 'Göteborg',
    '411 26': 'Göteborg',
    '411 27': 'Göteborg',
    '411 28': 'Göteborg',
    '411 29': 'Göteborg',
    '411 30': 'Göteborg',
    '411 31': 'Göteborg',
    '411 32': 'Göteborg',
    '411 33': 'Göteborg',
    '411 34': 'Göteborg',
    '411 35': 'Göteborg',
    '411 36': 'Göteborg',
    '411 37': 'Göteborg',
    '411 38': 'Göteborg',
    '411 39': 'Göteborg',
    '411 40': 'Göteborg',
    '411 41': 'Göteborg',
    '411 43': 'Göteborg',
    '411 81': 'Göteborg',
    '411 85': 'Göteborg',
    '412 00': 'Göteborg',
    '412 20': 'Göteborg',
    '412 21': 'Göteborg',
    '412 22': 'Göteborg',
    '412 50': 'Göteborg',
    '412 51': 'Göteborg',
    '412 52': 'Göteborg',
    '412 53': 'Göteborg',
    '412 54': 'Göteborg',
    '412 55': 'Göteborg',
    '412 56': 'Göteborg',
    '412 57': 'Göteborg',
    '412 58': 'Göteborg',
    '412 59': 'Göteborg',
    '412 60': 'Göteborg',
    '412 61': 'Göteborg',
    '412 62': 'Göteborg',
    '412 63': 'Göteborg',
    '412 64': 'Göteborg',
    '412 65': 'Göteborg',
    '412 66': 'Göteborg',
    '412 67': 'Göteborg',
    '412 68': 'Göteborg',
    '412 69': 'Göteborg',
    '412 70': 'Göteborg',
    '412 71': 'Göteborg',
    '412 72': 'Göteborg',
    '412 73': 'Göteborg',
    '412 74': 'Göteborg',
    '412 75': 'Göteborg',
    '412 76': 'Göteborg',
    '412 79': 'Göteborg',
    '412 80': 'Göteborg',
    '412 81': 'Göteborg',
    '412 82': 'Göteborg',
    '412 85': 'Göteborg',
    '412 86': 'Göteborg',
    '412 87': 'Göteborg',
    '412 88': 'Göteborg',
    '412 90': 'Göteborg',
    '412 92': 'Göteborg',
    '412 94': 'Göteborg',
    '412 95': 'Göteborg',
    '412 96': 'Göteborg',
    '412 97': 'Göteborg',
    '413 00': 'Göteborg',
    '413 01': 'Göteborg',
    '413 02': 'Göteborg',
    '413 03': 'Göteborg',
    '413 04': 'Göteborg',
    '413 05': 'Göteborg',
    '413 06': 'Göteborg',
    '413 07': 'Göteborg',
    '413 08': 'Göteborg',
    '413 09': 'Göteborg',
    '413 10': 'Göteborg',
    '413 11': 'Göteborg',
    '413 12': 'Göteborg',
    '413 13': 'Göteborg',
    '413 14': 'Göteborg',
    '413 15': 'Göteborg',
    '413 16': 'Göteborg',
    '413 17': 'Göteborg',
    '413 18': 'Göteborg',
    '413 19': 'Göteborg',
    '413 20': 'Göteborg',
    '413 21': 'Göteborg',
    '413 22': 'Göteborg',
    '413 23': 'Göteborg',
    '413 24': 'Göteborg',
    '413 25': 'Göteborg',
    '413 26': 'Göteborg',
    '413 27': 'Göteborg',
    '413 28': 'Göteborg',
    '413 45': 'Göteborg',
    '413 46': 'Göteborg',
    '413 80': 'Göteborg',
    '413 85': 'Göteborg',
    '413 90': 'Göteborg',
    '414 00': 'Göteborg',
    '414 20': 'Göteborg',
    '414 51': 'Göteborg',
    '414 52': 'Göteborg',
    '414 53': 'Göteborg',
    '414 54': 'Göteborg',
    '414 55': 'Göteborg',
    '414 56': 'Göteborg',
    '414 57': 'Göteborg',
    '414 58': 'Göteborg',
    '414 59': 'Göteborg',
    '414 60': 'Göteborg',
    '414 61': 'Göteborg',
    '414 62': 'Göteborg',
    '414 63': 'Göteborg',
    '414 64': 'Göteborg',
    '414 65': 'Göteborg',
    '414 66': 'Göteborg',
    '414 67': 'Göteborg',
    '414 68': 'Göteborg',
    '414 69': 'Göteborg',
    '414 70': 'Göteborg',
    '414 71': 'Göteborg',
    '414 72': 'Göteborg',
    '414 73': 'Göteborg',
    '414 74': 'Göteborg',
    '414 75': 'Göteborg',
    '414 76': 'Göteborg',
    '414 77': 'Göteborg',
    '414 78': 'Göteborg',
    '414 79': 'Göteborg',
    '414 80': 'Göteborg',
    '414 81': 'Göteborg',
    '414 82': 'Göteborg',
    '414 83': 'Göteborg',
    '415 00': 'Göteborg',
    '415 01': 'Göteborg',
    '415 02': 'Göteborg',
    '415 03': 'Göteborg',
    '415 04': 'Göteborg',
    '415 05': 'Göteborg',
    '415 06': 'Göteborg',
    '415 07': 'Göteborg',
    '415 08': 'Göteborg',
    '415 09': 'Göteborg',
    '415 10': 'Göteborg',
    '415 11': 'Göteborg',
    '415 12': 'Göteborg',
    '415 13': 'Göteborg',
    '415 14': 'Göteborg',
    '415 15': 'Göteborg',
    '415 16': 'Göteborg',
    '415 17': 'Göteborg',
    '415 18': 'Göteborg',
    '415 19': 'Göteborg',
    '415 20': 'Göteborg',
    '415 21': 'Göteborg',
    '415 22': 'Göteborg',
    '415 23': 'Göteborg',
    '415 25': 'Göteborg',
    '415 26': 'Göteborg',
    '415 50': 'Göteborg',
    '415 80': 'Göteborg',
    '415 81': 'Göteborg',
    '415 82': 'Göteborg',
    '415 83': 'Göteborg',
    '415 90': 'Göteborg',
    '416 00': 'Göteborg',
    '416 51': 'Göteborg',
    '416 52': 'Göteborg',
    '416 53': 'Göteborg',
    '416 54': 'Göteborg',
    '416 55': 'Göteborg',
    '416 56': 'Göteborg',
    '416 57': 'Göteborg',
    '416 58': 'Göteborg',
    '416 59': 'Göteborg',
    '416 60': 'Göteborg',
    '416 61': 'Göteborg',
    '416 62': 'Göteborg',
    '416 63': 'Göteborg',
    '416 64': 'Göteborg',
    '416 65': 'Göteborg',
    '416 66': 'Göteborg',
    '416 67': 'Göteborg',
    '416 68': 'Göteborg',
    '416 69': 'Göteborg',
    '416 70': 'Göteborg',
    '416 71': 'Göteborg',
    '416 72': 'Göteborg',
    '416 73': 'Göteborg',
    '416 74': 'Göteborg',
    '416 75': 'Göteborg',
    '416 76': 'Göteborg',
    '416 77': 'Göteborg',
    '416 78': 'Göteborg',
    '416 79': 'Göteborg',
    '416 80': 'Göteborg',
    '416 81': 'Göteborg',
    '416 85': 'Göteborg',
    '417 00': 'Göteborg',
    '417 01': 'Göteborg',
    '417 02': 'Göteborg',
    '417 03': 'Göteborg',
    '417 04': 'Göteborg',
    '417 05': 'Göteborg',
    '417 06': 'Göteborg',
    '417 07': 'Göteborg',
    '417 13': 'Göteborg',
    '417 14': 'Göteborg',
    '417 15': 'Göteborg',
    '417 16': 'Göteborg',
    '417 17': 'Göteborg',
    '417 18': 'Göteborg',
    '417 19': 'Göteborg',
    '417 20': 'Göteborg',
    '417 21': 'Göteborg',
    '417 26': 'Göteborg',
    '417 27': 'Göteborg',
    '417 28': 'Göteborg',
    '417 29': 'Göteborg',
    '417 42': 'Göteborg',
    '417 43': 'Göteborg',
    '417 44': 'Göteborg',
    '417 45': 'Göteborg',
    '417 46': 'Göteborg',
    '417 47': 'Göteborg',
    '417 48': 'Göteborg',
    '417 49': 'Göteborg',
    '417 54': 'Göteborg',
    '417 55': 'Göteborg',
    '417 56': 'Göteborg',
    '417 57': 'Göteborg',
    '417 58': 'Göteborg',
    '417 60': 'Göteborg',
    '417 61': 'Göteborg',
    '417 62': 'Göteborg',
    '417 63': 'Göteborg',
    '417 64': 'Göteborg',
    '417 80': 'Göteborg',
    '417 81': 'Göteborg',
    '417 85': 'Göteborg',
    '418 03': 'Göteborg',
    '418 04': 'Göteborg',
    '418 05': 'Göteborg',
    '418 11': 'Göteborg',
    '418 12': 'Göteborg',
    '418 21': 'Göteborg',
    '418 22': 'Göteborg',
    '418 23': 'Göteborg',
    '418 24': 'Göteborg',
    '418 30': 'Göteborg',
    '418 31': 'Göteborg',
    '418 32': 'Göteborg',
    '418 33': 'Göteborg',
    '418 34': 'Göteborg',
    '418 35': 'Göteborg',
    '418 36': 'Göteborg',
    '418 37': 'Göteborg',
    '418 38': 'Göteborg',
    '418 39': 'Göteborg',
    '418 40': 'Göteborg',
    '418 41': 'Göteborg',
    '418 42': 'Göteborg',
    '418 43': 'Göteborg',
    '418 70': 'Göteborg',
    '418 71': 'Göteborg',
    '418 72': 'Göteborg',
    '418 73': 'Göteborg',
    '418 74': 'Göteborg',
    '418 75': 'Göteborg',
    '418 76': 'Göteborg',
    '418 77': 'Göteborg',
    '418 78': 'Göteborg',
    '418 79': 'Göteborg',
    '421 00': 'Västra Frölunda',
    '421 01': 'Västra Frölunda',
    '421 02': 'Västra Frölunda',
    '421 08': 'Västra Frölunda',
    '421 10': 'Västra Frölunda',
    '421 21': 'Västra Frölunda',
    '421 22': 'Västra Frölunda',
    '421 23': 'Västra Frölunda',
    '421 26': 'Västra Frölunda',
    '421 30': 'Västra Frölunda',
    '421 31': 'Västra Frölunda',
    '421 32': 'Västra Frölunda',
    '421 33': 'Västra Frölunda',
    '421 34': 'Västra Frölunda',
    '421 35': 'Västra Frölunda',
    '421 36': 'Västra Frölunda',
    '421 37': 'Västra Frölunda',
    '421 38': 'Västra Frölunda',
    '421 39': 'Västra Frölunda',
    '421 40': 'Västra Frölunda',
    '421 41': 'Västra Frölunda',
    '421 43': 'Västra Frölunda',
    '421 44': 'Västra Frölunda',
    '421 45': 'Västra Frölunda',
    '421 46': 'Västra Frölunda',
    '421 47': 'Västra Frölunda',
    '421 48': 'Västra Frölunda',
    '421 49': 'Västra Frölunda',
    '421 50': 'Västra Frölunda',
    '421 57': 'Västra Frölunda',
    '421 58': 'Västra Frölunda',
    '421 59': 'Västra Frölunda',
    '421 60': 'Västra Frölunda',
    '421 61': 'Västra Frölunda',
    '421 62': 'Västra Frölunda',
    '421 63': 'Västra Frölunda',
    '421 65': 'Västra Frölunda',
    '421 66': 'Västra Frölunda',
    '421 67': 'Västra Frölunda',
    '422 00': 'Hisings Backa',
    '422 02': 'Hisings Backa',
    '422 03': 'Hisings Backa',
    '422 04': 'Hisings Backa',
    '422 41': 'Hisings Backa',
    '422 42': 'Hisings Backa',
    '422 43': 'Hisings Backa',
    '422 44': 'Hisings Backa',
    '422 45': 'Hisings Backa',
    '422 46': 'Hisings Backa',
    '422 47': 'Hisings Backa',
    '422 48': 'Hisings Backa',
    '422 49': 'Hisings Backa',
    '422 50': 'Hisings Backa',
    '422 51': 'Hisings Backa',
    '422 52': 'Hisings Backa',
    '422 53': 'Hisings Backa',
    '422 54': 'Hisings Backa',
    '422 55': 'Hisings Backa',
    '422 56': 'Hisings Backa',
    '422 57': 'Hisings Backa',
    '422 58': 'Hisings Backa',
    '422 59': 'Hisings Backa',
    '422 80': 'Hisings Backa',
    '423 00': 'Torslanda',
    '423 21': 'Torslanda',
    '423 22': 'Torslanda',
    '423 23': 'Torslanda',
    '423 32': 'Torslanda',
    '423 33': 'Torslanda',
    '423 34': 'Torslanda',
    '423 35': 'Torslanda',
    '423 36': 'Torslanda',
    '423 37': 'Torslanda',
    '423 38': 'Torslanda',
    '423 39': 'Torslanda',
    '423 40': 'Torslanda',
    '423 41': 'Torslanda',
    '423 43': 'Torslanda',
    '423 44': 'Torslanda',
    '423 46': 'Torslanda',
    '423 47': 'Torslanda',
    '423 48': 'Torslanda',
    '423 49': 'Torslanda',
    '423 50': 'Torslanda',
    '423 51': 'Torslanda',
    '423 53': 'Torslanda',
    '423 54': 'Torslanda',
    '423 55': 'Torslanda',
    '423 56': 'Torslanda',
    '423 59': 'Torslanda',
    '423 61': 'Torslanda',
    '423 63': 'Torslanda',
    '423 70': 'Säve',
    '423 71': 'Säve',
    '423 72': 'Säve',
    '423 73': 'Säve',
    '424 00': 'Angered',
    '424 01': 'Angered',
    '424 02': 'Angered',
    '424 06': 'Angered',
    '424 10': 'Angered',
    '424 21': 'Angered',
    '424 22': 'Angered',
    '424 23': 'Angered',
    '424 24': 'Angered',
    '424 31': 'Angered',
    '424 32': 'Angered',
    '424 33': 'Angered',
    '424 34': 'Angered',
    '424 35': 'Angered',
    '424 36': 'Angered',
    '424 37': 'Angered',
    '424 38': 'Agnesberg',
    '424 39': 'Angered',
    '424 40': 'Angered',
    '424 41': 'Angered',
    '424 42': 'Angered',
    '424 43': 'Angered',
    '424 44': 'Angered',
    '424 45': 'Angered',
    '424 46': 'Angered',
    '424 47': 'Angered',
    '424 48': 'Angered',
    '424 49': 'Angered',
    '424 50': 'Angered',
    '424 53': 'Angered',
    '424 55': 'Angered',
    '424 56': 'Gunnilse',
    '424 57': 'Gunnilse',
    '424 61': 'Angered',
    '424 65': 'Angered',
    '424 66': 'Angered',
    '424 67': 'Angered',
    '424 68': 'Angered',
    '424 69': 'Angered',
    '424 70': 'Olofstorp',
    '424 71': 'Olofstorp',
    '424 72': 'Olofstorp',
    '424 80': 'Angered',
    '424 90': 'Olofstorp',
    '424 91': 'Olofstorp',
    '425 02': 'Hisings Kärra',
    '425 30': 'Hisings Kärra',
    '425 31': 'Hisings Kärra',
    '425 32': 'Hisings Kärra',
    '425 33': 'Hisings Kärra',
    '425 34': 'Hisings Kärra',
    '425 35': 'Hisings Kärra',
    '425 36': 'Hisings Kärra',
    '425 37': 'Hisings Kärra',
    '425 38': 'Hisings Kärra',
    '425 39': 'Hisings Kärra',
    '425 41': 'Hisings Kärra',
    '425 42': 'Hisings Kärra',
    '425 43': 'Hisings Kärra',
    '425 65': 'Hisings Kärra',
    '426 00': 'Västra Frölunda',
    '426 04': 'Västra Frölunda',
    '426 05': 'Västra Frölunda',
    '426 50': 'Västra Frölunda',
    '426 51': 'Västra Frölunda',
    '426 52': 'Västra Frölunda',
    '426 53': 'Västra Frölunda',
    '426 54': 'Västra Frölunda',
    '426 55': 'Västra Frölunda',
    '426 56': 'Västra Frölunda',
    '426 57': 'Västra Frölunda',
    '426 58': 'Västra Frölunda',
    '426 68': 'Västra Frölunda',
    '426 69': 'Västra Frölunda',
    '426 70': 'Västra Frölunda',
    '426 71': 'Västra Frölunda',
    '426 72': 'Västra Frölunda',
    '426 73': 'Västra Frölunda',
    '426 74': 'Västra Frölunda',
    '426 76': 'Västra Frölunda',
    '426 77': 'Västra Frölunda',
    '426 79': 'Västra Frölunda',
    '426 80': 'Västra Frölunda',
    '427 21': 'Billdal',
    '427 22': 'Billdal',
    '427 23': 'Billdal',
    '427 34': 'Billdal',
    '427 35': 'Billdal',
    '427 36': 'Billdal',
    '427 37': 'Billdal',
    '427 38': 'Billdal',
    '427 39': 'Billdal',
    '427 40': 'Billdal',
    '427 41': 'Billdal',
    '427 42': 'Billdal',
    '430 80': 'Asperö',
    '430 81': 'Köpstadsö',
    '430 82': 'Donsö',
    '430 83': 'Vrångö',
    '430 84': 'Styrsö',
    '430 85': 'Brännö',
    '436 00': 'Askim',
    '436 01': 'Askim',
    '436 02': 'Hovås',
    '436 21': 'Askim',
    '436 22': 'Askim',
    '436 23': 'Askim',
    '436 26': 'Askim',
    '436 32': 'Askim',
    '436 33': 'Askim',
    '436 34': 'Askim',
    '436 35': 'Askim',
    '436 36': 'Askim',
    '436 37': 'Askim',
    '436 38': 'Askim',
    '436 39': 'Askim',
    '436 40': 'Askim',
    '436 42': 'Askim',
    '436 43': 'Askim',
    '436 44': 'Askim',
    '436 45': 'Askim',
    '436 50': 'Hovås',
    '436 51': 'Hovås',
    '436 52': 'Hovås',
    '436 54': 'Hovås',
    '436 55': 'Hovås',
    '436 56': 'Hovås',
    '436 58': 'Hovås',
    '436 82': 'Askim',
    '436 83': 'Askim',
    '436 84': 'Askim',
    '445 34': 'Bohus',
    '449 70': 'Nödinge',
    '427 51': 'Billdal',
    '428 21': 'Kållered',
    '428 22': 'Kållered',
    '428 30': 'Kållered',
    '428 31': 'Kållered',
    '428 32': 'Kållered',
    '428 33': 'Kållered',
    '428 34': 'Kållered',
    '428 35': 'Kållered',
    '428 36': 'Kållered',
    '428 37': 'Kållered',
    '428 81': 'Kållered',
    '431 00': 'Mölndal',
    '431 01': 'Mölndal',
    '431 02': 'Mölndal',
    '431 04': 'Mölndal',
    '431 10': 'Mölndal',
    '431 21': 'Mölndal',
    '431 22': 'Mölndal',
    '431 23': 'Mölndal',
    '431 24': 'Mölndal',
    '431 25': 'Mölndal',
    '431 26': 'Mölndal',
    '431 30': 'Mölndal',
    '431 31': 'Mölndal',
    '431 32': 'Mölndal',
    '431 33': 'Mölndal',
    '431 34': 'Mölndal',
    '431 35': 'Mölndal',
    '431 36': 'Mölndal',
    '431 37': 'Mölndal',
    '431 38': 'Mölndal',
    '431 39': 'Mölndal',
    '431 40': 'Mölndal',
    '431 41': 'Mölndal',
    '431 42': 'Mölndal',
    '431 43': 'Mölndal',
    '431 44': 'Mölndal',
    '431 46': 'Mölndal',
    '431 47': 'Mölndal',
    '431 48': 'Mölndal',
    '431 49': 'Mölndal',
    '431 50': 'Mölndal',
    '431 51': 'Mölndal',
    '431 53': 'Mölndal',
    '431 59': 'Mölndal',
    '431 60': 'Mölndal',
    '431 61': 'Mölndal',
    '431 62': 'Mölndal',
    '431 63': 'Mölndal',
    '431 64': 'Mölndal',
    '431 66': 'Mölndal',
    '431 67': 'Mölndal',
    '431 68': 'Mölndal',
    '431 69': 'Mölndal',
    '431 80': 'Mölndal',
    '431 81': 'Mölndal',
    '431 82': 'Mölndal',
    '431 83': 'Mölndal',
    '431 84': 'Mölndal',
    '431 86': 'Mölndal',
    '431 87': 'Mölndal',
    '431 90': 'Mölndal',
    '435 39': 'Mölnlycke',
    '435 42': 'Mölnlycke',
    '435 43': 'Pixbo',
    '437 00': 'Lindome',
    '437 21': 'Lindome',
    '437 22': 'Lindome',
    '437 30': 'Lindome',
    '437 31': 'Lindome',
    '437 32': 'Lindome',
    '437 33': 'Lindome',
    '437 34': 'Lindome',
    '437 35': 'Lindome',
    '437 36': 'Lindome',
    '437 40': 'Lindome',
    '437 41': 'Lindome',
    '437 42': 'Lindome',
    '437 91': 'Lindome',
    '437 92': 'Lindome',
    '437 93': 'Lindome',
    '438 93': 'Landvetter',
    '440 30': 'Marstrand',
    '442 00': 'Kungälv',
    '442 02': 'Ytterby',
    '442 06': 'Kungälv',
    '442 10': 'Kungälv',
    '442 11': 'Kungälv',
    '442 12': 'Kungälv',
    '442 15': 'Kungälv',
    '442 16': 'Kungälv',
    '442 17': 'Kungälv',
    '442 18': 'Kungälv',
    '442 30': 'Kungälv',
    '442 31': 'Kungälv',
    '442 32': 'Kungälv',
    '442 33': 'Kungälv',
    '442 34': 'Kungälv',
    '442 35': 'Kungälv',
    '442 36': 'Kungälv',
    '442 37': 'Kungälv',
    '442 38': 'Kungälv',
    '442 39': 'Kungälv',
    '442 40': 'Kungälv',
    '442 41': 'Kungälv',
    '442 42': 'Kungälv',
    '442 43': 'Kungälv',
    '442 49': 'Kungälv',
    '442 50': 'Ytterby',
    '442 51': 'Ytterby',
    '442 52': 'Ytterby',
    '442 53': 'Ytterby',
    '442 54': 'Ytterby',
    '442 60': 'Kode',
    '442 70': 'Kärna',
    '442 71': 'Kärna',
    '442 72': 'Kärna',
    '442 73': 'Kärna',
    '442 74': 'Harestad',
    '442 75': 'Lycke',
    '442 77': 'Romelanda',
    '442 80': 'Kungälv',
    '442 81': 'Kungälv',
    '442 82': 'Kungälv',
    '442 83': 'Kungälv',
    '442 90': 'Kungälv',
    '442 91': 'Romelanda',
    '442 92': 'Romelanda',
    '442 93': 'Kareby',
    '442 94': 'Ytterby',
    '442 95': 'Hålta',
    '442 96': 'Kode',
    '442 97': 'Kode',
    '442 98': 'Kode',
    '450 33': 'Grundsund',
    '450 34': 'Fiskebäckskil',
    '450 52': 'Dingle',
    '453 00': 'Lysekil',
    '453 01': 'Lysekil',
    '453 18': 'Lysekil',
    '453 21': 'Lysekil',
    '453 22': 'Lysekil',
    '453 23': 'Lysekil',
    '453 24': 'Lysekil',
    '453 25': 'Lysekil',
    '453 29': 'Lysekil',
    '453 30': 'Lysekil',
    '453 31': 'Lysekil',
    '453 32': 'Lysekil',
    '453 33': 'Lysekil',
    '453 34': 'Lysekil',
    '453 35': 'Lysekil',
    '453 36': 'Lysekil',
    '453 37': 'Lysekil',
    '453 38': 'Lysekil',
    '453 40': 'Lysekil',
    '453 41': 'Lysekil',
    '453 50': 'Lysekil',
    '453 80': 'Lysekil',
    '453 81': 'Lysekil',
    '453 91': 'Lysekil',
    '453 92': 'Lysekil',
    '453 93': 'Lysekil',
    '454 21': 'Brastad',
    '454 30': 'Brastad',
    '454 31': 'Brastad',
    '454 32': 'Brastad',
    '454 80': 'Brastad',
    '454 91': 'Brastad',
    '454 92': 'Brastad',
    '454 93': 'Brastad',
    '454 94': 'Brastad',
    '454 95': 'Brastad',
    '474 91': 'Ellös',
    '444 97': 'Svenshögen',
    '451 01': 'Uddevalla',
    '451 03': 'Uddevalla',
    '451 05': 'Uddevalla',
    '451 07': 'Uddevalla',
    '451 10': 'Uddevalla',
    '451 15': 'Uddevalla',
    '451 16': 'Uddevalla',
    '451 17': 'Uddevalla',
    '451 18': 'Uddevalla',
    '451 19': 'Uddevalla',
    '451 21': 'Uddevalla',
    '451 22': 'Uddevalla',
    '451 23': 'Uddevalla',
    '451 24': 'Uddevalla',
    '451 25': 'Uddevalla',
    '451 26': 'Uddevalla',
    '451 27': 'Uddevalla',
    '451 30': 'Uddevalla',
    '451 31': 'Uddevalla',
    '451 32': 'Uddevalla',
    '451 33': 'Uddevalla',
    '451 34': 'Uddevalla',
    '451 40': 'Uddevalla',
    '451 41': 'Uddevalla',
    '451 42': 'Uddevalla',
    '451 43': 'Uddevalla',
    '451 44': 'Uddevalla',
    '451 50': 'Uddevalla',
    '451 51': 'Uddevalla',
    '451 52': 'Uddevalla',
    '451 53': 'Uddevalla',
    '451 54': 'Uddevalla',
    '451 55': 'Uddevalla',
    '451 60': 'Uddevalla',
    '451 61': 'Uddevalla',
    '451 62': 'Uddevalla',
    '451 63': 'Uddevalla',
    '451 70': 'Uddevalla',
    '451 71': 'Uddevalla',
    '451 72': 'Uddevalla',
    '451 73': 'Uddevalla',
    '451 75': 'Uddevalla',
    '451 76': 'Uddevalla',
    '451 77': 'Uddevalla',
    '451 80': 'Uddevalla',
    '451 81': 'Uddevalla',
    '451 82': 'Uddevalla',
    '451 83': 'Uddevalla',
    '451 84': 'Uddevalla',
    '451 85': 'Uddevalla',
    '451 91': 'Uddevalla',
    '451 92': 'Uddevalla',
    '451 93': 'Uddevalla',
    '451 94': 'Uddevalla',
    '451 95': 'Uddevalla',
    '451 96': 'Uddevalla',
    '451 97': 'Uddevalla',
    '459 01': 'Ljungskile',
    '459 21': 'Ljungskile',
    '459 22': 'Ljungskile',
    '459 23': 'Ljungskile',
    '459 30': 'Ljungskile',
    '459 31': 'Ljungskile',
    '459 32': 'Ljungskile',
    '459 33': 'Ljungskile',
    '459 80': 'Ljungskile',
    '459 91': 'Ljungskile',
    '459 93': 'Ljungskile',
    '459 94': 'Ljungskile',
    '473 92': 'Henån',
    '452 01': 'Strömstad',
    '452 04': 'Nordkoster',
    '452 05': 'Sydkoster',
    '452 21': 'Strömstad',
    '452 22': 'Strömstad',
    '452 23': 'Strömstad',
    '452 24': 'Strömstad',
    '452 30': 'Strömstad',
    '452 31': 'Strömstad',
    '452 32': 'Strömstad',
    '452 33': 'Strömstad',
    '452 34': 'Strömstad',
    '452 35': 'Strömstad',
    '452 36': 'Strömstad',
    '452 37': 'Strömstad',
    '452 38': 'Strömstad',
    '452 40': 'Strömstad',
    '452 50': 'Strömstad',
    '452 51': 'Strömstad',
    '452 52': 'Strömstad',
    '452 60': 'Skee',
    '452 80': 'Strömstad',
    '452 83': 'Strömstad',
    '452 85': 'Strömstad',
    '452 90': 'Strömstad',
    '452 91': 'Strömstad',
    '452 92': 'Strömstad',
    '452 93': 'Strömstad',
    '452 94': 'Strömstad',
    '452 95': 'Strömstad',
    '452 96': 'Strömstad',
    '452 97': 'Strömstad',
    '457 96': 'Lur',
    '460 64': 'Frändefors',
    '460 65': 'Brålanda',
    '461 93': 'Västra Tunhem',
    '462 00': 'Vänersborg',
    '462 01': 'Vänersborg',
    '462 02': 'Vänersborg',
    '462 21': 'Vänersborg',
    '462 22': 'Vänersborg',
    '462 23': 'Vänersborg',
    '462 24': 'Vänersborg',
    '462 28': 'Vänersborg',
    '462 30': 'Vänersborg',
    '462 31': 'Vänersborg',
    '462 32': 'Vänersborg',
    '462 33': 'Vänersborg',
    '462 34': 'Vänersborg',
    '462 35': 'Vänersborg',
    '462 36': 'Vänersborg',
    '462 37': 'Vänersborg',
    '462 38': 'Vänersborg',
    '462 40': 'Vänersborg',
    '462 41': 'Vänersborg',
    '462 42': 'Vänersborg',
    '462 50': 'Vänersborg',
    '462 51': 'Vänersborg',
    '462 52': 'Vänersborg',
    '462 53': 'Vänersborg',
    '462 54': 'Vänersborg',
    '462 55': 'Vänersborg',
    '462 56': 'Vänersborg',
    '462 60': 'Vänersborg',
    '462 61': 'Vänersborg',
    '462 73': 'Vänersborg',
    '462 80': 'Vänersborg',
    '462 81': 'Vänersborg',
    '462 82': 'Vänersborg',
    '462 85': 'Vänersborg',
    '462 87': 'Vänersborg',
    '462 91': 'Vänersborg',
    '462 93': 'Vänersborg',
    '468 21': 'Vargön',
    '468 22': 'Vargön',
    '468 30': 'Vargön',
    '468 31': 'Vargön',
    '468 32': 'Vargön',
    '468 33': 'Vargön',
    '468 34': 'Vargön',
    '468 80': 'Vargön',
    '468 81': 'Vargön',
    '468 82': 'Vargön',
    '468 90': 'Vargön',
    '460 20': 'Sjuntorp',
    '460 21': 'Upphärad',
    '461 00': 'Trollhättan',
    '461 01': 'Trollhättan',
    '461 04': 'Trollhättan',
    '461 06': 'Trollhättan',
    '461 12': 'Trollhättan',
    '461 21': 'Trollhättan',
    '461 22': 'Trollhättan',
    '461 23': 'Trollhättan',
    '461 24': 'Trollhättan',
    '461 25': 'Trollhättan',
    '461 26': 'Trollhättan',
    '461 27': 'Trollhättan',
    '461 29': 'Trollhättan',
    '461 30': 'Trollhättan',
    '461 31': 'Trollhättan',
    '461 32': 'Trollhättan',
    '461 33': 'Trollhättan',
    '461 34': 'Trollhättan',
    '461 35': 'Trollhättan',
    '461 37': 'Trollhättan',
    '461 38': 'Trollhättan',
    '461 39': 'Trollhättan',
    '461 40': 'Trollhättan',
    '461 41': 'Trollhättan',
    '461 42': 'Trollhättan',
    '461 43': 'Trollhättan',
    '461 44': 'Trollhättan',
    '461 50': 'Trollhättan',
    '461 51': 'Trollhättan',
    '461 52': 'Trollhättan',
    '461 53': 'Trollhättan',
    '461 54': 'Trollhättan',
    '461 55': 'Trollhättan',
    '461 57': 'Trollhättan',
    '461 58': 'Trollhättan',
    '461 59': 'Trollhättan',
    '461 60': 'Trollhättan',
    '461 61': 'Trollhättan',
    '461 62': 'Trollhättan',
    '461 63': 'Trollhättan',
    '461 64': 'Trollhättan',
    '461 65': 'Trollhättan',
    '461 66': 'Trollhättan',
    '461 67': 'Trollhättan',
    '461 70': 'Trollhättan',
    '461 71': 'Trollhättan',
    '461 73': 'Trollhättan',
    '461 80': 'Trollhättan',
    '461 81': 'Trollhättan',
    '461 82': 'Trollhättan',
    '461 83': 'Trollhättan',
    '461 84': 'Trollhättan',
    '461 85': 'Trollhättan',
    '461 88': 'Trollhättan',
    '461 91': 'Trollhättan',
    '461 95': 'Trollhättan',
    '461 98': 'Trollhättan',
    '441 00': 'Alingsås',
    '441 01': 'Alingsås',
    '441 15': 'Alingsås',
    '441 16': 'Alingsås',
    '441 17': 'Alingsås',
    '441 18': 'Alingsås',
    '441 20': 'Alingsås',
    '441 21': 'Alingsås',
    '441 22': 'Alingsås',
    '441 23': 'Alingsås',
    '441 24': 'Alingsås',
    '441 25': 'Alingsås',
    '441 26': 'Alingsås',
    '441 27': 'Alingsås',
    '441 28': 'Alingsås',
    '441 29': 'Alingsås',
    '441 30': 'Alingsås',
    '441 31': 'Alingsås',
    '441 32': 'Alingsås',
    '441 33': 'Alingsås',
    '441 34': 'Alingsås',
    '441 35': 'Alingsås',
    '441 36': 'Alingsås',
    '441 37': 'Alingsås',
    '441 38': 'Alingsås',
    '441 39': 'Alingsås',
    '441 40': 'Alingsås',
    '441 41': 'Alingsås',
    '441 42': 'Alingsås',
    '441 43': 'Alingsås',
    '441 44': 'Alingsås',
    '441 45': 'Alingsås',
    '441 50': 'Alingsås',
    '441 51': 'Alingsås',
    '441 55': 'Alingsås',
    '441 56': 'Alingsås',
    '441 57': 'Alingsås',
    '441 58': 'Alingsås',
    '441 60': 'Alingsås',
    '441 63': 'Alingsås',
    '441 65': 'Alingsås',
    '441 80': 'Alingsås',
    '441 81': 'Alingsås',
    '441 82': 'Alingsås',
    '441 83': 'Alingsås',
    '441 84': 'Alingsås',
    '441 85': 'Alingsås',
    '441 86': 'Alingsås',
    '441 91': 'Alingsås',
    '441 92': 'Alingsås',
    '441 93': 'Alingsås',
    '441 94': 'Alingsås',
    '441 95': 'Alingsås',
    '441 96': 'Alingsås',
    '448 50': 'Tollered',
    '448 95': 'Tollered',
    '448 96': 'Tollered',
    '465 93': 'Nossebro',
    '466 01': 'Sollebrunn',
    '466 21': 'Sollebrunn',
    '466 22': 'Sollebrunn',
    '466 30': 'Sollebrunn',
    '466 31': 'Sollebrunn',
    '466 32': 'Sollebrunn',
    '466 33': 'Sollebrunn',
    '466 80': 'Sollebrunn',
    '466 91': 'Sollebrunn',
    '466 92': 'Sollebrunn',
    '466 93': 'Sollebrunn',
    '466 94': 'Sollebrunn',
    '466 95': 'Sollebrunn',
    '501 10': 'Borås',
    '501 11': 'Borås',
    '501 12': 'Borås',
    '501 15': 'Borås',
    '501 17': 'Borås',
    '501 25': 'Borås',
    '501 26': 'Borås',
    '501 27': 'Borås',
    '501 77': 'Borås',
    '501 78': 'Borås',
    '501 79': 'Borås',
    '501 80': 'Borås',
    '501 81': 'Borås',
    '501 82': 'Borås',
    '501 83': 'Borås',
    '501 84': 'Borås',
    '501 85': 'Borås',
    '501 86': 'Borås',
    '501 87': 'Borås',
    '501 88': 'Borås',
    '501 89': 'Borås',
    '501 90': 'Borås',
    '503 00': 'Borås',
    '503 05': 'Borås',
    '503 06': 'Borås',
    '503 07': 'Borås',
    '503 08': 'Borås',
    '503 09': 'Borås',
    '503 10': 'Borås',
    '503 11': 'Borås',
    '503 12': 'Borås',
    '503 13': 'Borås',
    '503 14': 'Borås',
    '503 15': 'Borås',
    '503 16': 'Borås',
    '503 17': 'Borås',
    '503 18': 'Borås',
    '503 19': 'Borås',
    '503 30': 'Borås',
    '503 31': 'Borås',
    '503 32': 'Borås',
    '503 33': 'Borås',
    '503 34': 'Borås',
    '503 35': 'Borås',
    '503 36': 'Borås',
    '503 37': 'Borås',
    '503 38': 'Borås',
    '503 42': 'Borås',
    '503 43': 'Borås',
    '504 00': 'Borås',
    '504 02': 'Borås',
    '504 03': 'Borås',
    '504 04': 'Borås',
    '504 05': 'Borås',
    '504 10': 'Borås',
    '504 11': 'Borås',
    '504 12': 'Borås',
    '504 20': 'Borås',
    '504 30': 'Borås',
    '504 31': 'Borås',
    '504 32': 'Borås',
    '504 33': 'Borås',
    '504 34': 'Borås',
    '504 35': 'Borås',
    '504 37': 'Borås',
    '504 38': 'Borås',
    '504 39': 'Borås',
    '504 41': 'Borås',
    '504 42': 'Borås',
    '504 43': 'Borås',
    '504 44': 'Borås',
    '504 45': 'Borås',
    '504 46': 'Borås',
    '504 47': 'Borås',
    '504 50': 'Borås',
    '504 51': 'Borås',
    '504 52': 'Borås',
    '504 53': 'Borås',
    '504 54': 'Borås',
    '504 55': 'Borås',
    '504 56': 'Borås',
    '504 57': 'Borås',
    '504 58': 'Borås',
    '504 60': 'Borås',
    '504 61': 'Borås',
    '504 62': 'Borås',
    '504 63': 'Borås',
    '504 65': 'Borås',
    '504 66': 'Borås',
    '504 67': 'Borås',
    '504 68': 'Borås',
    '504 70': 'Borås',
    '504 71': 'Borås',
    '504 72': 'Borås',
    '504 75': 'Borås',
    '504 78': 'Sandhult',
    '504 80': 'Borås',
    '504 81': 'Borås',
    '504 82': 'Borås',
    '504 90': 'Borås',
    '504 91': 'Sandhult',
    '504 92': 'Hedared',
    '504 93': 'Borås',
    '504 94': 'Borås',
    '504 95': 'Borås',
    '504 96': 'Bredared',
    '504 97': 'Bredared',
    '506 00': 'Borås',
    '506 02': 'Borås',
    '506 03': 'Borås',
    '506 30': 'Borås',
    '506 31': 'Borås',
    '506 32': 'Borås',
    '506 34': 'Borås',
    '506 35': 'Borås',
    '506 36': 'Borås',
    '506 37': 'Borås',
    '506 38': 'Borås',
    '506 39': 'Borås',
    '506 40': 'Borås',
    '506 41': 'Borås',
    '506 42': 'Borås',
    '506 43': 'Borås',
    '506 44': 'Borås',
    '506 46': 'Borås',
    '506 47': 'Borås',
    '506 48': 'Borås',
    '506 49': 'Borås',
    '506 70': 'Frufällan',
    '507 00': 'Borås',
    '507 10': 'Borås',
    '507 11': 'Borås',
    '507 20': 'Brämhult',
    '507 30': 'Brämhult',
    '507 31': 'Brämhult',
    '507 32': 'Brämhult',
    '507 33': 'Brämhult',
    '507 34': 'Brämhult',
    '507 40': 'Borås',
    '507 41': 'Borås',
    '507 42': 'Borås',
    '507 43': 'Borås',
    '507 44': 'Borås',
    '507 45': 'Borås',
    '507 50': 'Borås',
    '507 51': 'Borås',
    '507 52': 'Borås',
    '507 53': 'Borås',
    '507 60': 'Borås',
    '507 61': 'Borås',
    '507 62': 'Borås',
    '507 63': 'Borås',
    '507 64': 'Borås',
    '507 65': 'Borås',
    '507 70': 'Gånghester',
    '507 71': 'Gånghester',
    '507 90': 'Brämhult',
    '511 74': 'Skephult',
    '512 91': 'Sexdrega',
    '513 00': 'Fristad',
    '513 21': 'Fristad',
    '513 32': 'Fristad',
    '513 33': 'Fristad',
    '513 34': 'Fristad',
    '513 35': 'Fristad',
    '513 50': 'Sparsör',
    '513 70': 'Borgstena',
    '513 80': 'Fristad',
    '513 81': 'Fristad',
    '513 82': 'Fristad',
    '513 91': 'Fristad',
    '513 92': 'Fristad',
    '513 93': 'Fristad',
    '513 94': 'Sparsör',
    '513 95': 'Fristad',
    '513 96': 'Fristad',
    '513 97': 'Borgstena',
    '515 00': 'Borås',
    '515 21': 'Viskafors',
    '515 22': 'Viskafors',
    '515 32': 'Viskafors',
    '515 33': 'Viskafors',
    '515 34': 'Viskafors',
    '515 35': 'Viskafors',
    '515 60': 'Svaneholm',
    '515 61': 'Svaneholm',
    '515 70': 'Rydboholm',
    '515 91': 'Viskafors',
    '515 92': 'Kinnarumma',
    '515 93': 'Seglora',
    '516 00': 'Dalsjöfors',
    '516 21': 'Dalsjöfors',
    '516 22': 'Dalsjöfors',
    '516 30': 'Dalsjöfors',
    '516 31': 'Dalsjöfors',
    '516 32': 'Dalsjöfors',
    '516 33': 'Dalsjöfors',
    '516 34': 'Dalsjöfors',
    '516 50': 'Aplared',
    '516 77': 'Målsryd',
    '516 80': 'Dalsjöfors',
    '516 81': 'Dalsjöfors',
    '516 90': 'Dalsjöfors',
    '516 91': 'Dalsjöfors',
    '516 92': 'Äspered',
    '516 93': 'Rångedala',
    '516 95': 'Målsryd',
    '516 96': 'Aplared',
    '517 95': 'Olsfors',
    '517 96': 'Hultafors',
    '518 02': 'Sjömarken',
    '518 21': 'Sandared',
    '518 22': 'Sandared',
    '518 30': 'Sandared',
    '518 31': 'Sandared',
    '518 32': 'Sandared',
    '518 33': 'Sandared',
    '518 40': 'Sjömarken',
    '518 41': 'Sjömarken',
    '518 42': 'Sjömarken',
    '518 90': 'Sandared',
    '518 91': 'Sjömarken',
    '524 95': 'Ljung',
    '516 94': 'Dannike',
    '520 10': 'Gällstad',
    '520 11': 'Vegby',
    '520 25': 'Dalum',
    '520 26': 'Trädet',
    '523 01': 'Ulricehamn',
    '523 02': 'Timmele',
    '523 04': 'Hökerum',
    '523 21': 'Ulricehamn',
    '523 22': 'Ulricehamn',
    '523 23': 'Ulricehamn',
    '523 24': 'Ulricehamn',
    '523 25': 'Ulricehamn',
    '523 26': 'Ulricehamn',
    '523 30': 'Ulricehamn',
    '523 31': 'Ulricehamn',
    '523 32': 'Ulricehamn',
    '523 33': 'Ulricehamn',
    '523 35': 'Ulricehamn',
    '523 36': 'Ulricehamn',
    '523 37': 'Ulricehamn',
    '523 38': 'Ulricehamn',
    '523 39': 'Ulricehamn',
    '523 72': 'Timmele',
    '523 74': 'Hökerum',
    '523 84': 'Ulricehamn',
    '523 85': 'Ulricehamn',
    '523 86': 'Ulricehamn',
    '523 90': 'Ulricehamn',
    '523 91': 'Ulricehamn',
    '523 92': 'Timmele',
    '523 93': 'Marbäck',
    '523 94': 'Tvärred',
    '523 95': 'Älmestad',
    '523 96': 'Grönahög',
    '523 97': 'Ulricehamn',
    '523 98': 'Hökerum',
    '523 99': 'Hökerum',
    '524 96': 'Ljung',
    '464 72': 'Håverud',
    '662 00': 'Åmål',
    '662 21': 'Åmål',
    '662 22': 'Åmål',
    '662 23': 'Åmål',
    '662 24': 'Åmål',
    '662 27': 'Åmål',
    '662 30': 'Åmål',
    '662 31': 'Åmål',
    '662 32': 'Åmål',
    '662 33': 'Åmål',
    '662 34': 'Åmål',
    '662 35': 'Åmål',
    '662 36': 'Åmål',
    '662 37': 'Åmål',
    '662 91': 'Åmål',
    '662 95': 'Fengersfors',
    '662 97': 'Ånimskog',
    '662 98': 'Tösse',
    '540 66': 'Sjötorp',
    '542 01': 'Mariestad',
    '542 18': 'Mariestad',
    '542 21': 'Mariestad',
    '542 22': 'Mariestad',
    '542 23': 'Mariestad',
    '542 24': 'Mariestad',
    '542 30': 'Mariestad',
    '542 31': 'Mariestad',
    '542 32': 'Mariestad',
    '542 33': 'Mariestad',
    '542 34': 'Mariestad',
    '542 35': 'Mariestad',
    '542 40': 'Mariestad',
    '542 41': 'Mariestad',
    '542 42': 'Mariestad',
    '542 43': 'Mariestad',
    '542 44': 'Mariestad',
    '542 45': 'Mariestad',
    '542 72': 'Mariestad',
    '542 73': 'Mariestad',
    '542 74': 'Mariestad',
    '542 75': 'Mariestad',
    '542 85': 'Mariestad',
    '542 86': 'Mariestad',
    '542 87': 'Mariestad',
    '542 88': 'Mariestad',
    '542 91': 'Torsö',
    '542 92': 'Mariestad',
    '542 93': 'Mariestad',
    '542 94': 'Mariestad',
    '542 95': 'Mariestad',
    '548 02': 'Lyrestad',
    '548 73': 'Lyrestad',
    '548 93': 'Lyrestad',
    '530 30': 'Tun',
    '530 32': 'Såtenäs',
    '531 01': 'Lidköping',
    '531 02': 'Lidköping',
    '531 03': 'Vinninga',
    '531 04': 'Järpås',
    '531 10': 'Lidköping',
    '531 16': 'Lidköping',
    '531 17': 'Lidköping',
    '531 18': 'Lidköping',
    '531 19': 'Lidköping',
    '531 29': 'Lidköping',
    '531 30': 'Lidköping',
    '531 31': 'Lidköping',
    '531 32': 'Lidköping',
    '531 33': 'Lidköping',
    '531 34': 'Lidköping',
    '531 35': 'Lidköping',
    '531 36': 'Lidköping',
    '531 37': 'Lidköping',
    '531 38': 'Lidköping',
    '531 39': 'Lidköping',
    '531 40': 'Lidköping',
    '531 41': 'Lidköping',
    '531 42': 'Lidköping',
    '531 43': 'Lidköping',
    '531 50': 'Lidköping',
    '531 51': 'Lidköping',
    '531 52': 'Lidköping',
    '531 53': 'Lidköping',
    '531 54': 'Lidköping',
    '531 55': 'Lidköping',
    '531 56': 'Lidköping',
    '531 57': 'Lidköping',
    '531 58': 'Lidköping',
    '531 59': 'Lidköping',
    '531 70': 'Lidköping',
    '531 71': 'Vinninga',
    '531 75': 'Järpås',
    '531 81': 'Lidköping',
    '531 85': 'Lidköping',
    '531 86': 'Lidköping',
    '531 87': 'Lidköping',
    '531 88': 'Lidköping',
    '531 91': 'Vinninga',
    '531 92': 'Lidköping',
    '531 93': 'Lidköping',
    '531 94': 'Järpås',
    '531 96': 'Lidköping',
    '531 97': 'Lidköping',
    '531 98': 'Lidköping',
    '531 99': 'Lidköping',
    '534 93': 'Stora Levene',
    '535 92': 'Kvänum',
    '532 01': 'Skara',
    '532 02': 'Axvall',
    '532 21': 'Skara',
    '532 22': 'Skara',
    '532 23': 'Skara',
    '532 24': 'Skara',
    '532 30': 'Skara',
    '532 31': 'Skara',
    '532 32': 'Skara',
    '532 33': 'Skara',
    '532 34': 'Skara',
    '532 35': 'Skara',
    '532 36': 'Skara',
    '532 37': 'Skara',
    '532 38': 'Skara',
    '532 72': 'Axvall',
    '532 74': 'Skara',
    '532 80': 'Skara',
    '532 86': 'Skara',
    '532 88': 'Skara',
    '532 89': 'Skara',
    '532 94': 'Skara',
    '532 95': 'Skara',
    '532 96': 'Skara',
    '533 92': 'Lundsbrunn',
    '532 92': 'Axvall',
    '540 15': 'Väring',
    '540 16': 'Timmersdala',
    '540 17': 'Lerdala',
    '541 01': 'Skövde',
    '541 02': 'Skövde',
    '541 05': 'Skövde',
    '541 06': 'Skövde',
    '541 07': 'Skövde',
    '541 12': 'Skövde',
    '541 18': 'Skövde',
    '541 21': 'Skövde',
    '541 22': 'Skövde',
    '541 23': 'Skövde',
    '541 24': 'Skövde',
    '541 25': 'Skövde',
    '541 26': 'Skövde',
    '541 27': 'Skövde',
    '541 28': 'Skövde',
    '541 29': 'Skövde',
    '541 30': 'Skövde',
    '541 31': 'Skövde',
    '541 32': 'Skövde',
    '541 33': 'Skövde',
    '541 34': 'Skövde',
    '541 35': 'Skövde',
    '541 36': 'Skövde',
    '541 38': 'Skövde',
    '541 39': 'Skövde',
    '541 40': 'Skövde',
    '541 42': 'Skövde',
    '541 43': 'Skövde',
    '541 44': 'Skövde',
    '541 45': 'Skövde',
    '541 46': 'Skövde',
    '541 47': 'Skövde',
    '541 48': 'Skövde',
    '541 50': 'Skövde',
    '541 52': 'Skövde',
    '541 53': 'Skövde',
    '541 54': 'Skövde',
    '541 55': 'Skövde',
    '541 56': 'Skövde',
    '541 57': 'Skövde',
    '541 62': 'Skövde',
    '541 63': 'Skövde',
    '541 64': 'Skövde',
    '541 65': 'Skövde',
    '541 70': 'Skövde',
    '541 77': 'Skövde',
    '541 78': 'Värsås',
    '541 80': 'Skövde',
    '541 83': 'Skövde',
    '541 84': 'Skövde',
    '541 85': 'Skövde',
    '541 86': 'Skövde',
    '541 87': 'Skövde',
    '541 91': 'Skövde',
    '541 92': 'Skövde',
    '541 93': 'Skövde',
    '541 94': 'Skövde',
    '549 21': 'Tidan',
    '549 31': 'Tidan',
    '549 91': 'Tidan',
    '549 92': 'Moholm',
    '541 98': 'Värsås',
    '544 01': 'Hjo',
    '544 21': 'Hjo',
    '544 22': 'Hjo',
    '544 30': 'Hjo',
    '544 31': 'Hjo',
    '544 32': 'Hjo',
    '544 33': 'Hjo',
    '544 50': 'Hjo',
    '544 81': 'Hjo',
    '544 91': 'Hjo',
    '544 92': 'Hjo',
    '544 93': 'Hjo',
    '544 94': 'Hjo',
    '522 01': 'Tidaholm',
    '522 21': 'Tidaholm',
    '522 22': 'Tidaholm',
    '522 23': 'Tidaholm',
    '522 24': 'Tidaholm',
    '522 25': 'Tidaholm',
    '522 26': 'Tidaholm',
    '522 30': 'Tidaholm',
    '522 31': 'Tidaholm',
    '522 32': 'Tidaholm',
    '522 33': 'Tidaholm',
    '522 34': 'Tidaholm',
    '522 35': 'Tidaholm',
    '522 81': 'Tidaholm',
    '522 82': 'Tidaholm',
    '522 83': 'Tidaholm',
    '522 84': 'Tidaholm',
    '522 85': 'Tidaholm',
    '522 91': 'Tidaholm',
    '522 92': 'Tidaholm',
    '522 93': 'Tidaholm',
    '522 94': 'Tidaholm',
    '520 24': 'Blidsberg',
    '520 40': 'Floby',
    '520 43': 'Åsarp',
    '520 50': 'Stenstorp',
    '521 01': 'Falköping',
    '521 02': 'Falköping',
    '521 03': 'Kinnarp',
    '521 04': 'Gudhem',
    '521 05': 'Vartofta',
    '521 18': 'Falköping',
    '521 21': 'Falköping',
    '521 22': 'Falköping',
    '521 23': 'Falköping',
    '521 24': 'Falköping',
    '521 29': 'Falköping',
    '521 30': 'Falköping',
    '521 31': 'Falköping',
    '521 32': 'Falköping',
    '521 33': 'Falköping',
    '521 34': 'Falköping',
    '521 35': 'Falköping',
    '521 36': 'Falköping',
    '521 40': 'Falköping',
    '521 41': 'Falköping',
    '521 42': 'Falköping',
    '521 43': 'Falköping',
    '521 44': 'Falköping',
    '521 45': 'Falköping',
    '521 72': 'Slutarp',
    '521 73': 'Kinnarp',
    '521 74': 'Gudhem',
    '521 75': 'Vartofta',
    '521 81': 'Falköping',
    '521 82': 'Falköping',
    '521 85': 'Falköping',
    '521 86': 'Falköping',
    '521 88': 'Kinnarp',
    '521 91': 'Falköping',
    '521 92': 'Falköping',
    '521 93': 'Falköping',
    '521 94': 'Falköping',
    '521 95': 'Kättilstorp',
    '521 96': 'Falköping',
    '521 97': 'Vartofta',
    '521 98': 'Broddetorp',
    '532 73': 'Varnhem',
    '532 93': 'Axvall',
    '560 42': 'Sandhem',
    '400 90': 'Göteborg',
    '400 99': 'Göteborg',
    '402 20': 'Göteborg',
    '402 40': 'Göteborg',
    '402 56': 'Göteborg',
    '402 62': 'Göteborg',
    '403 10': 'Göteborg',
    '403 32': 'Göteborg',
    '403 43': 'Göteborg',
    '404 20': 'Göteborg',
    '405 34': 'Göteborg',
    '405 37': 'Göteborg',
    '405 38': 'Göteborg',
    '407 00': 'Göteborg',
    '408 00': 'Göteborg',
    '408 01': 'Göteborg',
    '408 02': 'Göteborg',
    '408 03': 'Göteborg',
    '408 04': 'Göteborg',
    '408 05': 'Göteborg',
    '408 06': 'Göteborg',
    '408 07': 'Göteborg',
    '408 08': 'Göteborg',
    '408 09': 'Göteborg',
    '408 10': 'Göteborg',
    '408 11': 'Göteborg',
    '408 12': 'Göteborg',
    '408 13': 'Göteborg',
    '408 14': 'Göteborg',
    '408 15': 'Göteborg',
    '408 16': 'Göteborg',
    '408 17': 'Göteborg',
    '408 18': 'Göteborg',
    '408 19': 'Göteborg',
    '408 20': 'Göteborg',
    '408 21': 'Göteborg',
    '408 22': 'Göteborg',
    '408 23': 'Göteborg',
    '408 24': 'Göteborg',
    '408 25': 'Göteborg',
    '408 26': 'Göteborg',
    '408 27': 'Göteborg',
    '408 28': 'Göteborg',
    '408 29': 'Göteborg',
    '408 30': 'Göteborg',
    '408 31': 'Göteborg',
    '408 32': 'Göteborg',
    '408 33': 'Göteborg',
    '408 34': 'Göteborg',
    '408 35': 'Göteborg',
    '408 36': 'Göteborg',
    '408 37': 'Göteborg',
    '408 38': 'Göteborg',
    '408 39': 'Göteborg',
    '408 40': 'Göteborg',
    '408 41': 'Göteborg',
    '408 42': 'Göteborg',
    '408 43': 'Göteborg',
    '408 44': 'Göteborg',
    '408 45': 'Göteborg',
    '408 46': 'Göteborg',
    '408 47': 'Göteborg',
    '408 48': 'Göteborg',
    '408 49': 'Göteborg',
    '408 50': 'Göteborg',
    '408 51': 'Göteborg',
    '408 52': 'Göteborg',
    '408 53': 'Göteborg',
    '408 54': 'Göteborg',
    '408 55': 'Göteborg',
    '408 56': 'Göteborg',
    '408 57': 'Göteborg',
    '408 58': 'Göteborg',
    '408 59': 'Göteborg',
    '408 60': 'Göteborg',
    '408 61': 'Göteborg',
    '408 62': 'Göteborg',
    '408 63': 'Göteborg',
    '408 64': 'Göteborg',
    '408 65': 'Göteborg',
    '408 66': 'Göteborg',
    '408 67': 'Göteborg',
    '408 68': 'Göteborg',
    '408 69': 'Göteborg',
    '408 70': 'Göteborg',
    '408 71': 'Göteborg',
    '408 72': 'Göteborg',
    '408 73': 'Göteborg',
    '408 74': 'Göteborg',
    '408 75': 'Göteborg',
    '408 76': 'Göteborg',
    '408 77': 'Göteborg',
    '408 78': 'Göteborg',
    '408 79': 'Göteborg',
    '408 80': 'Göteborg',
    '408 81': 'Göteborg',
    '408 82': 'Göteborg',
    '408 83': 'Göteborg',
    '408 84': 'Göteborg',
    '408 85': 'Göteborg',
    '408 86': 'Göteborg',
    '408 87': 'Göteborg',
    '408 88': 'Göteborg',
    '408 89': 'Göteborg',
    '408 90': 'Göteborg',
    '408 91': 'Göteborg',
    '408 92': 'Göteborg',
    '408 93': 'Göteborg',
    '408 94': 'Göteborg',
    '408 95': 'Göteborg',
    '408 96': 'Göteborg',
    '408 97': 'Göteborg',
    '408 98': 'Göteborg',
    '408 99': 'Göteborg',
    '409 00': 'Göteborg',
    '409 01': 'Göteborg',
    '409 02': 'Göteborg',
    '409 03': 'Göteborg',
    '409 04': 'Göteborg',
    '409 05': 'Göteborg',
    '409 06': 'Göteborg',
    '409 07': 'Göteborg',
    '409 08': 'Göteborg',
    '409 09': 'Göteborg',
    '409 10': 'Göteborg',
    '409 11': 'Göteborg',
    '409 12': 'Göteborg',
    '409 13': 'Göteborg',
    '409 14': 'Göteborg',
    '409 15': 'Göteborg',
    '409 16': 'Göteborg',
    '409 17': 'Göteborg',
    '409 18': 'Göteborg',
    '409 19': 'Göteborg',
    '409 20': 'Göteborg',
    '409 21': 'Göteborg',
    '409 22': 'Göteborg',
    '409 23': 'Göteborg',
    '409 24': 'Göteborg',
    '409 25': 'Göteborg',
    '409 26': 'Göteborg',
    '409 27': 'Göteborg',
    '409 28': 'Göteborg',
    '409 29': 'Göteborg',
    '409 30': 'Göteborg',
    '409 31': 'Göteborg',
    '409 32': 'Göteborg',
    '409 33': 'Göteborg',
    '409 34': 'Göteborg',
    '409 35': 'Göteborg',
    '409 36': 'Göteborg',
    '409 37': 'Göteborg',
    '409 38': 'Göteborg',
    '409 39': 'Göteborg',
    '409 40': 'Göteborg',
    '409 41': 'Göteborg',
    '409 42': 'Göteborg',
    '409 43': 'Göteborg',
    '409 44': 'Göteborg',
    '409 45': 'Göteborg',
    '409 46': 'Göteborg',
    '409 47': 'Göteborg',
    '409 48': 'Göteborg',
    '409 49': 'Göteborg',
    '409 50': 'Göteborg',
    '409 51': 'Göteborg',
    '409 52': 'Göteborg',
    '409 53': 'Göteborg',
    '409 54': 'Göteborg',
    '409 55': 'Göteborg',
    '409 56': 'Göteborg',
    '409 57': 'Göteborg',
    '409 58': 'Göteborg',
    '409 59': 'Göteborg',
    '409 60': 'Göteborg',
    '409 61': 'Göteborg',
    '409 62': 'Göteborg',
    '409 63': 'Göteborg',
    '409 64': 'Göteborg',
    '409 65': 'Göteborg',
    '409 66': 'Göteborg',
    '409 67': 'Göteborg',
    '409 68': 'Göteborg',
    '409 69': 'Göteborg',
    '409 70': 'Göteborg',
    '409 71': 'Göteborg',
    '409 72': 'Göteborg',
    '409 73': 'Göteborg',
    '409 74': 'Göteborg',
    '409 75': 'Göteborg',
    '409 76': 'Göteborg',
    '409 77': 'Göteborg',
    '409 78': 'Göteborg',
    '409 79': 'Göteborg',
    '409 80': 'Göteborg',
    '409 81': 'Göteborg',
    '409 82': 'Göteborg',
    '409 83': 'Göteborg',
    '409 84': 'Göteborg',
    '409 85': 'Göteborg',
    '409 86': 'Göteborg',
    '409 87': 'Göteborg',
    '409 88': 'Göteborg',
    '409 89': 'Göteborg',
    '409 90': 'Göteborg',
    '409 91': 'Göteborg',
    '409 92': 'Göteborg',
    '409 93': 'Göteborg',
    '409 94': 'Göteborg',
    '409 95': 'Göteborg',
    '409 96': 'Göteborg',
    '409 97': 'Göteborg',
    '409 98': 'Göteborg',
    '409 99': 'Göteborg',
    '412 89': 'Göteborg',
    '412 93': 'Göteborg',
    '413 82': 'Göteborg',
    '415 24': 'Göteborg',
    '415 27': 'Göteborg',
    '416 20': 'Göteborg',
    '416 48': 'Göteborg',
    '416 49': 'Göteborg',
    '416 50': 'Göteborg',
    '416 82': 'Göteborg',
    '417 65': 'Göteborg',
    '418 00': 'Göteborg',
    '418 02': 'Göteborg',
    '421 20': 'Västra Frölunda',
    '422 20': 'Hisings Backa',
    '423 20': 'Torslanda',
    '424 04': 'Angered',
    '424 05': 'Angered',
    '424 08': 'Olofstorp',
    '424 20': 'Angered',
    '424 54': 'Angered',
    '426 20': 'Västra Frölunda',
    '428 20': 'Kållered',
    '428 80': 'Kållered',
    '431 20': 'Mölndal',
    '431 85': 'Mölndal',
    '433 20': 'Partille',
    '433 39': 'Partille',
    '435 20': 'Mölnlycke',
    '436 20': 'Askim',
    '437 20': 'Lindome',
    '438 10': 'Härryda',
    '438 20': 'Landvetter',
    '442 20': 'Kungälv',
    '442 44': 'Kungälv',
    '442 45': 'Kungälv',
    '443 20': 'Lerum',
    '444 20': 'Stenungsund',
    '444 52': 'Stenungsund',
    '446 20': 'Älvängen',
    '447 20': 'Vårgårda',
    '448 20': 'Floda',
    '449 20': 'Nödinge',
    '449 35': 'Nödinge',
    '449 36': 'Nödinge',
    '451 06': 'Uddevalla',
    '451 20': 'Uddevalla',
    '451 98': 'Uddevalla',
    '452 20': 'Strömstad',
    '452 88': 'Strömstad',
    '453 20': 'Lysekil',
    '454 20': 'Brastad',
    '455 20': 'Munkedal',
    '456 20': 'Kungshamn',
    '457 20': 'Tanumshede',
    '457 25': 'Tanumshede',
    '458 20': 'Färgelanda',
    '459 20': 'Ljungskile',
    '461 20': 'Trollhättan',
    '461 86': 'Trollhättan',
    '462 03': 'Vänersborg',
    '462 10': 'Vänersborg',
    '462 20': 'Vänersborg',
    '462 29': 'Vänersborg',
    '463 20': 'Lilla Edet',
    '463 24': 'Lilla Edet',
    '463 25': 'Lilla Edet',
    '464 02': 'Åsensbruk',
    '464 20': 'Mellerud',
    '465 20': 'Nossebro',
    '466 20': 'Sollebrunn',
    '467 20': 'Grästorp',
    '471 02': 'Rönnäng',
    '471 04': 'Åstol',
    '471 05': 'Dyrön',
    '471 06': 'Myggenäs',
    '471 20': 'Skärhamn',
    '472 20': 'Svanesund',
    '473 20': 'Henån',
    '474 20': 'Ellös',
    '474 74': 'Käringön',
    '504 83': 'Borås',
    '507 15': 'Gånghester',
    '508 00': 'Borås',
    '508 01': 'Borås',
    '508 02': 'Borås',
    '508 03': 'Borås',
    '508 04': 'Borås',
    '508 05': 'Borås',
    '508 06': 'Borås',
    '508 07': 'Borås',
    '508 08': 'Borås',
    '508 09': 'Borås',
    '508 10': 'Borås',
    '508 11': 'Borås',
    '508 12': 'Borås',
    '508 13': 'Borås',
    '508 14': 'Borås',
    '508 15': 'Borås',
    '508 16': 'Borås',
    '508 17': 'Borås',
    '508 18': 'Borås',
    '508 19': 'Borås',
    '508 20': 'Borås',
    '508 21': 'Borås',
    '508 22': 'Borås',
    '508 23': 'Borås',
    '508 24': 'Borås',
    '508 25': 'Borås',
    '508 26': 'Borås',
    '508 27': 'Borås',
    '508 28': 'Borås',
    '508 29': 'Borås',
    '508 30': 'Borås',
    '508 31': 'Borås',
    '508 32': 'Borås',
    '508 33': 'Borås',
    '508 34': 'Borås',
    '508 35': 'Borås',
    '508 36': 'Borås',
    '508 37': 'Borås',
    '508 38': 'Borås',
    '508 39': 'Borås',
    '508 40': 'Borås',
    '508 41': 'Borås',
    '508 42': 'Borås',
    '508 43': 'Borås',
    '508 44': 'Borås',
    '508 45': 'Borås',
    '508 46': 'Borås',
    '508 47': 'Borås',
    '508 48': 'Borås',
    '508 49': 'Borås',
    '508 50': 'Borås',
    '508 51': 'Borås',
    '508 52': 'Borås',
    '508 53': 'Borås',
    '508 54': 'Borås',
    '508 55': 'Borås',
    '508 56': 'Borås',
    '508 57': 'Borås',
    '508 58': 'Borås',
    '508 59': 'Borås',
    '508 60': 'Borås',
    '508 61': 'Borås',
    '508 62': 'Borås',
    '508 63': 'Borås',
    '508 64': 'Borås',
    '508 65': 'Borås',
    '508 66': 'Borås',
    '508 67': 'Borås',
    '508 68': 'Borås',
    '508 69': 'Borås',
    '508 70': 'Borås',
    '508 71': 'Borås',
    '508 72': 'Borås',
    '508 73': 'Borås',
    '508 74': 'Borås',
    '508 75': 'Borås',
    '508 76': 'Borås',
    '508 77': 'Borås',
    '508 78': 'Borås',
    '508 79': 'Borås',
    '508 80': 'Borås',
    '508 81': 'Borås',
    '508 82': 'Borås',
    '508 83': 'Borås',
    '508 84': 'Borås',
    '508 85': 'Borås',
    '508 86': 'Borås',
    '508 87': 'Borås',
    '508 88': 'Borås',
    '508 89': 'Borås',
    '508 90': 'Borås',
    '508 91': 'Borås',
    '508 92': 'Borås',
    '508 93': 'Borås',
    '508 94': 'Borås',
    '508 95': 'Borås',
    '508 96': 'Borås',
    '508 97': 'Borås',
    '508 98': 'Borås',
    '508 99': 'Borås',
    '511 20': 'Kinna',
    '511 24': 'Kinna',
    '512 20': 'Svenljunga',
    '513 20': 'Fristad',
    '514 04': 'Limmared',
    '514 20': 'Tranemo',
    '515 20': 'Viskafors',
    '516 20': 'Dalsjöfors',
    '517 20': 'Bollebygd',
    '518 20': 'Sandared',
    '518 43': 'Sjömarken',
    '519 20': 'Horred',
    '521 20': 'Falköping',
    '522 20': 'Tidaholm',
    '523 20': 'Ulricehamn',
    '524 20': 'Herrljunga',
    '531 20': 'Lidköping',
    '532 20': 'Skara',
    '533 20': 'Götene',
    '534 20': 'Vara',
    '541 20': 'Skövde',
    '541 37': 'Skövde',
    '541 41': 'Skövde',
    '542 20': 'Mariestad',
    '543 20': 'Tibro',
    '544 20': 'Hjo',
    '545 20': 'Töreboda',
    '546 20': 'Karlsborg',
    '547 20': 'Gullspång',
    '548 20': 'Hova',
    '549 20': 'Tidan',
    '662 20': 'Åmål',
    '666 20': 'Bengtsfors',
    '668 20': 'Ed',
    '665 01': 'Kil',
    '665 21': 'Kil',
    '665 22': 'Kil',
    '665 23': 'Kil',
    '665 24': 'Kil',
    '665 25': 'Kil',
    '665 30': 'Kil',
    '665 31': 'Kil',
    '665 32': 'Kil',
    '665 33': 'Kil',
    '665 34': 'Kil',
    '665 35': 'Kil',
    '665 91': 'Kil',
    '665 92': 'Kil',
    '665 93': 'Kil',
    '670 41': 'Koppom',
    '670 43': 'Skillingsfors',
    '673 01': 'Charlottenberg',
    '673 21': 'Charlottenberg',
    '673 22': 'Charlottenberg',
    '673 29': 'Charlottenberg',
    '673 31': 'Charlottenberg',
    '673 32': 'Charlottenberg',
    '673 92': 'Charlottenberg',
    '673 93': 'Morokulien',
    '680 51': 'Stöllet',
    '680 52': 'Ambjörby',
    '680 60': 'Sysslebäck',
    '680 61': 'Bograngen',
    '680 63': 'Likenäs',
    '680 65': 'Höljes',
    '685 01': 'Torsby',
    '685 21': 'Torsby',
    '685 22': 'Torsby',
    '685 23': 'Torsby',
    '685 24': 'Torsby',
    '685 25': 'Torsby',
    '685 29': 'Torsby',
    '685 30': 'Torsby',
    '685 32': 'Torsby',
    '685 33': 'Torsby',
    '685 34': 'Torsby',
    '685 80': 'Torsby',
    '685 91': 'Torsby',
    '685 92': 'Torsby',
    '685 93': 'Torsby',
    '685 94': 'Torsby',
    '685 97': 'Östmark',
    '685 99': 'Torsby',
    '688 21': 'Storfors',
    '688 22': 'Storfors',
    '688 23': 'Storfors',
    '688 29': 'Storfors',
    '688 30': 'Storfors',
    '688 31': 'Storfors',
    '688 91': 'Storfors',
    '688 92': 'Storfors',
    '663 01': 'Skoghall',
    '663 11': 'Hammarö',
    '663 12': 'Hammarö',
    '663 21': 'Skoghall',
    '663 22': 'Skoghall',
    '663 29': 'Skoghall',
    '663 30': 'Skoghall',
    '663 31': 'Skoghall',
    '663 32': 'Skoghall',
    '663 33': 'Skoghall',
    '663 34': 'Skoghall',
    '663 40': 'Hammarö',
    '663 41': 'Hammarö',
    '663 42': 'Hammarö',
    '663 81': 'Hammarö',
    '663 91': 'Hammarö',
    '663 92': 'Hammarö',
    '684 01': 'Munkfors',
    '684 21': 'Munkfors',
    '684 22': 'Munkfors',
    '684 23': 'Munkfors',
    '684 28': 'Munkfors',
    '684 29': 'Munkfors',
    '684 30': 'Munkfors',
    '684 31': 'Munkfors',
    '684 32': 'Munkfors',
    '684 91': 'Munkfors',
    '684 92': 'Munkfors',
    '667 01': 'Forshaga',
    '667 21': 'Forshaga',
    '667 22': 'Forshaga',
    '667 29': 'Forshaga',
    '667 30': 'Forshaga',
    '667 31': 'Forshaga',
    '667 32': 'Forshaga',
    '667 33': 'Forshaga',
    '667 34': 'Forshaga',
    '667 91': 'Forshaga',
    '669 21': 'Deje',
    '669 22': 'Deje',
    '669 27': 'Deje',
    '669 30': 'Deje',
    '669 31': 'Deje',
    '669 32': 'Deje',
    '669 91': 'Deje',
    '684 93': 'Ransäter',
    '660 40': 'Segmon',
    '664 01': 'Grums',
    '664 21': 'Grums',
    '664 22': 'Grums',
    '664 23': 'Grums',
    '664 28': 'Grums',
    '664 30': 'Grums',
    '664 31': 'Grums',
    '664 32': 'Grums',
    '664 33': 'Grums',
    '664 34': 'Grums',
    '664 40': 'Slottsbron',
    '664 80': 'Grums',
    '664 91': 'Grums',
    '666 93': 'Gustavsfors',
    '670 10': 'Töcksfors',
    '672 01': 'Årjäng',
    '672 21': 'Årjäng',
    '672 22': 'Årjäng',
    '672 23': 'Årjäng',
    '672 24': 'Årjäng',
    '672 29': 'Årjäng',
    '672 30': 'Årjäng',
    '672 31': 'Årjäng',
    '672 32': 'Årjäng',
    '672 91': 'Årjäng',
    '672 92': 'Årjäng',
    '672 93': 'Årjäng',
    '672 94': 'Årjäng',
    '672 95': 'Årjäng',
    '686 01': 'Sunne',
    '686 21': 'Sunne',
    '686 22': 'Sunne',
    '686 23': 'Sunne',
    '686 24': 'Sunne',
    '686 25': 'Sunne',
    '686 26': 'Sunne',
    '686 28': 'Sunne',
    '686 29': 'Sunne',
    '686 30': 'Sunne',
    '686 31': 'Sunne',
    '686 33': 'Sunne',
    '686 35': 'Sunne',
    '686 80': 'Sunne',
    '686 91': 'Sunne',
    '686 92': 'Sunne',
    '686 93': 'Sunne',
    '686 94': 'Rottneros',
    '686 95': 'Västra Ämtervik',
    '686 96': 'Östra Ämtervik',
    '686 98': 'Gräsmark',
    '650 01': 'Karlstad',
    '650 02': 'Karlstad',
    '650 04': 'Karlstad',
    '650 05': 'Karlstad',
    '650 07': 'Karlstad',
    '650 08': 'Karlstad',
    '650 09': 'Karlstad',
    '650 12': 'Karlstad',
    '651 00': 'Karlstad',
    '651 01': 'Karlstad',
    '651 02': 'Karlstad',
    '651 03': 'Karlstad',
    '651 04': 'Karlstad',
    '651 05': 'Karlstad',
    '651 06': 'Karlstad',
    '651 07': 'Karlstad',
    '651 08': 'Karlstad',
    '651 09': 'Karlstad',
    '651 10': 'Karlstad',
    '651 11': 'Karlstad',
    '651 12': 'Karlstad',
    '651 13': 'Karlstad',
    '651 14': 'Karlstad',
    '651 15': 'Karlstad',
    '651 16': 'Karlstad',
    '651 19': 'Karlstad',
    '651 21': 'Karlstad',
    '651 22': 'Karlstad',
    '651 28': 'Karlstad',
    '651 80': 'Karlstad',
    '651 82': 'Karlstad',
    '651 83': 'Karlstad',
    '651 84': 'Karlstad',
    '651 85': 'Karlstad',
    '651 86': 'Karlstad',
    '651 87': 'Karlstad',
    '651 88': 'Karlstad',
    '651 89': 'Karlstad',
    '652 01': 'Karlstad',
    '652 17': 'Karlstad',
    '652 18': 'Karlstad',
    '652 19': 'Karlstad',
    '652 20': 'Karlstad',
    '652 21': 'Karlstad',
    '652 22': 'Karlstad',
    '652 23': 'Karlstad',
    '652 24': 'Karlstad',
    '652 25': 'Karlstad',
    '652 26': 'Karlstad',
    '652 27': 'Karlstad',
    '652 28': 'Karlstad',
    '652 29': 'Karlstad',
    '652 30': 'Karlstad',
    '653 01': 'Karlstad',
    '653 39': 'Karlstad',
    '653 40': 'Karlstad',
    '653 41': 'Karlstad',
    '653 42': 'Karlstad',
    '653 43': 'Karlstad',
    '653 44': 'Karlstad',
    '653 45': 'Karlstad',
    '653 46': 'Karlstad',
    '653 47': 'Karlstad',
    '653 48': 'Karlstad',
    '653 49': 'Karlstad',
    '653 50': 'Karlstad',
    '653 51': 'Karlstad',
    '654 01': 'Karlstad',
    '654 55': 'Karlstad',
    '654 58': 'Karlstad',
    '654 59': 'Karlstad',
    '654 60': 'Karlstad',
    '654 61': 'Karlstad',
    '654 62': 'Karlstad',
    '654 63': 'Karlstad',
    '654 64': 'Karlstad',
    '654 65': 'Karlstad',
    '654 66': 'Karlstad',
    '654 67': 'Karlstad',
    '654 68': 'Karlstad',
    '654 69': 'Karlstad',
    '655 91': 'Karlstad',
    '655 92': 'Karlstad',
    '655 93': 'Karlstad',
    '655 94': 'Karlstad',
    '656 01': 'Karlstad',
    '656 31': 'Karlstad',
    '656 32': 'Karlstad',
    '656 33': 'Karlstad',
    '656 34': 'Karlstad',
    '656 35': 'Karlstad',
    '656 36': 'Karlstad',
    '656 37': 'Karlstad',
    '656 38': 'Karlstad',
    '656 39': 'Karlstad',
    '656 71': 'Skattkärr',
    '656 72': 'Skattkärr',
    '660 50': 'Vålberg',
    '660 52': 'Edsvalla',
    '665 94': 'Fagerås',
    '669 92': 'Deje',
    '660 57': 'Väse',
    '680 71': 'Björneborg',
    '681 01': 'Kristinehamn',
    '681 04': 'Kristinehamn',
    '681 10': 'Kristinehamn',
    '681 21': 'Kristinehamn',
    '681 22': 'Kristinehamn',
    '681 23': 'Kristinehamn',
    '681 24': 'Kristinehamn',
    '681 25': 'Kristinehamn',
    '681 26': 'Kristinehamn',
    '681 27': 'Kristinehamn',
    '681 28': 'Kristinehamn',
    '681 29': 'Kristinehamn',
    '681 30': 'Kristinehamn',
    '681 31': 'Kristinehamn',
    '681 32': 'Kristinehamn',
    '681 33': 'Kristinehamn',
    '681 34': 'Kristinehamn',
    '681 35': 'Kristinehamn',
    '681 36': 'Kristinehamn',
    '681 37': 'Kristinehamn',
    '681 38': 'Kristinehamn',
    '681 40': 'Kristinehamn',
    '681 41': 'Kristinehamn',
    '681 42': 'Kristinehamn',
    '681 43': 'Kristinehamn',
    '681 50': 'Kristinehamn',
    '681 51': 'Kristinehamn',
    '681 52': 'Kristinehamn',
    '681 53': 'Kristinehamn',
    '681 54': 'Kristinehamn',
    '681 60': 'Kristinehamn',
    '681 80': 'Kristinehamn',
    '681 82': 'Kristinehamn',
    '681 83': 'Kristinehamn',
    '681 84': 'Kristinehamn',
    '681 91': 'Kristinehamn',
    '681 92': 'Kristinehamn',
    '681 93': 'Kristinehamn',
    '681 94': 'Kristinehamn',
    '681 95': 'Kristinehamn',
    '681 96': 'Kristinehamn',
    '660 60': 'Molkom',
    '680 90': 'Nykroppa',
    '680 96': 'Lesjöfors',
    '682 01': 'Filipstad',
    '682 21': 'Filipstad',
    '682 22': 'Filipstad',
    '682 23': 'Filipstad',
    '682 24': 'Filipstad',
    '682 27': 'Filipstad',
    '682 30': 'Filipstad',
    '682 31': 'Filipstad',
    '682 32': 'Filipstad',
    '682 33': 'Filipstad',
    '682 34': 'Filipstad',
    '682 40': 'Filipstad',
    '682 82': 'Filipstad',
    '682 91': 'Filipstad',
    '682 92': 'Filipstad',
    '682 93': 'Nordmarkshyttan',
    '680 50': 'Ekshärad',
    '683 01': 'Hagfors',
    '683 21': 'Hagfors',
    '683 22': 'Hagfors',
    '683 23': 'Hagfors',
    '683 24': 'Hagfors',
    '683 29': 'Hagfors',
    '683 30': 'Hagfors',
    '683 31': 'Hagfors',
    '683 32': 'Hagfors',
    '683 33': 'Hagfors',
    '683 34': 'Hagfors',
    '683 40': 'Uddeholm',
    '683 80': 'Hagfors',
    '683 85': 'Hagfors',
    '683 91': 'Hagfors',
    '683 92': 'Hagfors',
    '683 93': 'Råda',
    '683 94': 'Lakene',
    '683 95': 'Sunnemo',
    '684 95': 'Höje',
    '686 97': 'Lysvik',
    '670 20': 'Glava',
    '670 35': 'Gunnarskog',
    '670 40': 'Åmotfors',
    '671 01': 'Arvika',
    '671 21': 'Arvika',
    '671 22': 'Arvika',
    '671 23': 'Arvika',
    '671 24': 'Arvika',
    '671 25': 'Arvika',
    '671 26': 'Arvika',
    '671 27': 'Arvika',
    '671 29': 'Arvika',
    '671 30': 'Arvika',
    '671 31': 'Arvika',
    '671 32': 'Arvika',
    '671 33': 'Arvika',
    '671 40': 'Arvika',
    '671 41': 'Arvika',
    '671 42': 'Arvika',
    '671 50': 'Arvika',
    '671 51': 'Arvika',
    '671 52': 'Arvika',
    '671 60': 'Arvika',
    '671 70': 'Edane',
    '671 80': 'Arvika',
    '671 81': 'Arvika',
    '671 82': 'Arvika',
    '671 91': 'Arvika',
    '671 92': 'Arvika',
    '671 93': 'Arvika',
    '671 94': 'Brunskog',
    '671 95': 'Klässbol',
    '671 96': 'Mangskog',
    '673 91': 'Charlottenberg',
    '661 00': 'Säffle',
    '661 21': 'Säffle',
    '661 22': 'Säffle',
    '661 23': 'Säffle',
    '661 24': 'Säffle',
    '661 25': 'Säffle',
    '661 26': 'Säffle',
    '661 29': 'Säffle',
    '661 30': 'Säffle',
    '661 31': 'Säffle',
    '661 32': 'Säffle',
    '661 33': 'Säffle',
    '661 40': 'Säffle',
    '661 41': 'Säffle',
    '661 42': 'Säffle',
    '661 43': 'Säffle',
    '661 80': 'Säffle',
    '661 81': 'Säffle',
    '661 91': 'Säffle',
    '661 92': 'Säffle',
    '661 93': 'Säffle',
    '661 94': 'Säffle',
    '661 95': 'Värmlands Nysäter',
    '661 96': 'Långserud',
    '662 03': 'Svanskog',
    '662 50': 'Svanskog',
    '662 96': 'Svanskog',
    '664 95': 'Borgvik',
    '658 00': 'Karlstad',
    '658 10': 'Karlstad',
    '658 11': 'Karlstad',
    '658 12': 'Karlstad',
    '658 13': 'Karlstad',
    '658 14': 'Karlstad',
    '658 15': 'Karlstad',
    '658 16': 'Karlstad',
    '658 17': 'Karlstad',
    '658 18': 'Karlstad',
    '658 19': 'Karlstad',
    '658 20': 'Karlstad',
    '659 97': 'Karlstad',
    '659 98': 'Karlstad',
    '659 99': 'Karlstad',
    '661 20': 'Säffle',
    '663 20': 'Skoghall',
    '664 20': 'Grums',
    '665 20': 'Kil',
    '667 20': 'Forshaga',
    '669 20': 'Deje',
    '671 20': 'Arvika',
    '672 20': 'Årjäng',
    '673 20': 'Charlottenberg',
    '681 20': 'Kristinehamn',
    '681 85': 'Kristinehamn',
    '682 20': 'Filipstad',
    '683 20': 'Hagfors',
    '684 20': 'Munkfors',
    '685 20': 'Torsby',
    '686 20': 'Sunne',
    '686 34': 'Sunne',
    '716 01': 'Fjugesta',
    '716 21': 'Fjugesta',
    '716 30': 'Fjugesta',
    '716 31': 'Fjugesta',
    '716 81': 'Fjugesta',
    '716 93': 'Fjugesta',
    '716 94': 'Mullhyttan',
    '548 74': 'Gårdsjö',
    '695 01': 'Laxå',
    '695 21': 'Laxå',
    '695 22': 'Laxå',
    '695 29': 'Laxå',
    '695 30': 'Laxå',
    '695 31': 'Laxå',
    '695 32': 'Laxå',
    '695 50': 'Finnerödja',
    '695 60': 'Hasselfors',
    '695 72': 'Röfors',
    '695 80': 'Laxå',
    '695 81': 'Laxå',
    '695 82': 'Laxå',
    '695 84': 'Hasselfors',
    '695 85': 'Hasselfors',
    '695 91': 'Laxå',
    '695 92': 'Laxå',
    '695 93': 'Finnerödja',
    '695 94': 'Finnerödja',
    '695 95': 'Hasselfors',
    '695 96': 'Tived',
    '695 97': 'Tived',
    '610 10': 'Ljusfallshammar',
    '694 01': 'Hallsberg',
    '694 21': 'Hallsberg',
    '694 22': 'Hallsberg',
    '694 23': 'Hallsberg',
    '694 24': 'Hallsberg',
    '694 30': 'Hallsberg',
    '694 31': 'Hallsberg',
    '694 32': 'Hallsberg',
    '694 33': 'Hallsberg',
    '694 34': 'Hallsberg',
    '694 35': 'Hallsberg',
    '694 36': 'Hallsberg',
    '694 37': 'Hallsberg',
    '694 50': 'Vretstorp',
    '694 72': 'Östansjö',
    '694 80': 'Hallsberg',
    '694 81': 'Hallsberg',
    '694 82': 'Hallsberg',
    '694 83': 'Hallsberg',
    '694 91': 'Hallsberg',
    '694 92': 'Hallsberg',
    '694 95': 'Vretstorp',
    '697 21': 'Pålsboda',
    '697 22': 'Pålsboda',
    '697 30': 'Pålsboda',
    '697 31': 'Pålsboda',
    '697 32': 'Pålsboda',
    '697 74': 'Sköllersta',
    '697 80': 'Sköllersta',
    '697 92': 'Pålsboda',
    '697 93': 'Hjortkvarn',
    '693 01': 'Degerfors',
    '693 03': 'Svartå',
    '693 21': 'Degerfors',
    '693 22': 'Degerfors',
    '693 29': 'Degerfors',
    '693 30': 'Degerfors',
    '693 31': 'Degerfors',
    '693 32': 'Degerfors',
    '693 33': 'Degerfors',
    '693 34': 'Degerfors',
    '693 35': 'Degerfors',
    '693 70': 'Åtorp',
    '693 73': 'Svartå',
    '693 80': 'Degerfors',
    '693 81': 'Degerfors',
    '693 91': 'Degerfors',
    '693 92': 'Degerfors',
    '693 93': 'Svartå',
    '693 94': 'Åtorp',
    '693 95': 'Åtorp',
    '712 01': 'Hällefors',
    '712 02': 'Grythyttan',
    '712 21': 'Hällefors',
    '712 22': 'Hällefors',
    '712 30': 'Hällefors',
    '712 31': 'Hällefors',
    '712 32': 'Hällefors',
    '712 33': 'Hällefors',
    '712 34': 'Hällefors',
    '712 60': 'Grythyttan',
    '712 80': 'Hällefors',
    '712 81': 'Grythyttan',
    '712 82': 'Grythyttan',
    '712 83': 'Hällefors',
    '712 91': 'Hällefors',
    '712 93': 'Hällefors',
    '712 94': 'Grythyttan',
    '712 95': 'Grythyttan',
    '714 01': 'Kopparberg',
    '714 21': 'Kopparberg',
    '714 22': 'Kopparberg',
    '714 30': 'Kopparberg',
    '714 31': 'Kopparberg',
    '714 32': 'Kopparberg',
    '714 33': 'Kopparberg',
    '714 34': 'Kopparberg',
    '714 35': 'Kopparberg',
    '714 36': 'Kopparberg',
    '714 37': 'Kopparberg',
    '714 72': 'Ställdalen',
    '714 80': 'Kopparberg',
    '714 81': 'Ställdalen',
    '714 82': 'Kopparberg',
    '714 92': 'Kopparberg',
    '772 94': 'Grängesberg',
    '640 10': 'Högsjö',
    '700 02': 'Örebro',
    '700 05': 'Örebro',
    '700 06': 'Örebro',
    '700 08': 'Örebro',
    '700 09': 'Örebro',
    '700 10': 'Örebro',
    '700 12': 'Örebro',
    '700 13': 'Örebro',
    '700 14': 'Örebro',
    '700 15': 'Örebro',
    '701 00': 'Örebro',
    '701 01': 'Örebro',
    '701 10': 'Örebro',
    '701 11': 'Örebro',
    '701 12': 'Örebro',
    '701 13': 'Örebro',
    '701 14': 'Örebro',
    '701 15': 'Örebro',
    '701 16': 'Örebro',
    '701 17': 'Örebro',
    '701 18': 'Örebro',
    '701 19': 'Örebro',
    '701 21': 'Örebro',
    '701 22': 'Örebro',
    '701 23': 'Örebro',
    '701 30': 'Örebro',
    '701 31': 'Örebro',
    '701 32': 'Örebro',
    '701 33': 'Örebro',
    '701 34': 'Örebro',
    '701 35': 'Örebro',
    '701 40': 'Örebro',
    '701 41': 'Örebro',
    '701 42': 'Örebro',
    '701 43': 'Örebro',
    '701 44': 'Örebro',
    '701 45': 'Örebro',
    '701 46': 'Örebro',
    '701 47': 'Örebro',
    '701 48': 'Örebro',
    '701 49': 'Örebro',
    '701 50': 'Örebro',
    '701 51': 'Örebro',
    '701 80': 'Örebro',
    '701 81': 'Örebro',
    '701 82': 'Örebro',
    '701 83': 'Örebro',
    '701 84': 'Örebro',
    '701 85': 'Örebro',
    '701 86': 'Örebro',
    '701 87': 'Örebro',
    '701 88': 'Örebro',
    '701 89': 'Örebro',
    '701 90': 'Örebro',
    '701 91': 'Örebro',
    '701 92': 'Örebro',
    '701 93': 'Örebro',
    '702 01': 'Örebro',
    '702 02': 'Örebro',
    '702 03': 'Örebro',
    '702 04': 'Örebro',
    '702 05': 'Örebro',
    '702 08': 'Örebro',
    '702 10': 'Örebro',
    '702 11': 'Örebro',
    '702 12': 'Örebro',
    '702 13': 'Örebro',
    '702 14': 'Örebro',
    '702 15': 'Örebro',
    '702 16': 'Örebro',
    '702 17': 'Örebro',
    '702 18': 'Örebro',
    '702 19': 'Örebro',
    '702 20': 'Örebro',
    '702 21': 'Örebro',
    '702 22': 'Örebro',
    '702 23': 'Örebro',
    '702 24': 'Örebro',
    '702 25': 'Örebro',
    '702 26': 'Örebro',
    '702 27': 'Örebro',
    '702 28': 'Örebro',
    '702 29': 'Örebro',
    '702 30': 'Örebro',
    '702 31': 'Örebro',
    '702 32': 'Örebro',
    '702 33': 'Örebro',
    '702 34': 'Örebro',
    '702 35': 'Örebro',
    '703 01': 'Örebro',
    '703 40': 'Örebro',
    '703 41': 'Örebro',
    '703 42': 'Örebro',
    '703 43': 'Örebro',
    '703 44': 'Örebro',
    '703 45': 'Örebro',
    '703 46': 'Örebro',
    '703 47': 'Örebro',
    '703 48': 'Örebro',
    '703 49': 'Örebro',
    '703 50': 'Örebro',
    '703 51': 'Örebro',
    '703 52': 'Örebro',
    '703 53': 'Örebro',
    '703 54': 'Örebro',
    '703 55': 'Örebro',
    '703 56': 'Örebro',
    '703 57': 'Örebro',
    '703 58': 'Örebro',
    '703 59': 'Örebro',
    '703 60': 'Örebro',
    '703 61': 'Örebro',
    '703 62': 'Örebro',
    '703 63': 'Örebro',
    '703 64': 'Örebro',
    '703 65': 'Örebro',
    '703 66': 'Örebro',
    '703 67': 'Örebro',
    '703 68': 'Örebro',
    '703 69': 'Örebro',
    '703 70': 'Örebro',
    '703 71': 'Örebro',
    '703 72': 'Örebro',
    '703 73': 'Örebro',
    '703 74': 'Örebro',
    '703 75': 'Örebro',
    '703 76': 'Örebro',
    '703 78': 'Örebro',
    '703 85': 'Glanshammar',
    '705 91': 'Örebro',
    '705 92': 'Örebro',
    '705 93': 'Örebro',
    '705 94': 'Örebro',
    '705 95': 'Örebro',
    '705 96': 'Glanshammar',
    '705 97': 'Glanshammar',
    '705 98': 'Lillkyrka',
    '715 01': 'Odensbacken',
    '715 21': 'Odensbacken',
    '715 31': 'Odensbacken',
    '715 32': 'Odensbacken',
    '715 72': 'Stora Mellösa',
    '715 91': 'Odensbacken',
    '715 92': 'Stora Mellösa',
    '715 93': 'Odensbacken',
    '715 94': 'Odensbacken',
    '715 95': 'Kilsmo',
    '716 92': 'Fjugesta',
    '718 40': 'Dyltabruk',
    '718 95': 'Ervalla',
    '718 96': 'Dyltabruk',
    '718 97': 'Dyltabruk',
    '719 21': 'Vintrosa',
    '719 22': 'Vintrosa',
    '719 30': 'Vintrosa',
    '719 31': 'Vintrosa',
    '719 32': 'Vintrosa',
    '719 40': 'Garphyttan',
    '719 41': 'Garphyttan',
    '719 80': 'Garphyttan',
    '719 91': 'Vintrosa',
    '719 92': 'Vintrosa',
    '719 93': 'Vintrosa',
    '719 94': 'Vintrosa',
    '719 95': 'Vintrosa',
    '692 01': 'Kumla',
    '692 21': 'Kumla',
    '692 22': 'Kumla',
    '692 23': 'Kumla',
    '692 24': 'Kumla',
    '692 25': 'Kumla',
    '692 29': 'Kumla',
    '692 30': 'Kumla',
    '692 31': 'Kumla',
    '692 32': 'Kumla',
    '692 33': 'Kumla',
    '692 34': 'Kumla',
    '692 35': 'Kumla',
    '692 36': 'Kumla',
    '692 37': 'Kumla',
    '692 38': 'Kumla',
    '692 72': 'Kumla',
    '692 73': 'Kumla',
    '692 80': 'Kumla',
    '692 81': 'Kumla',
    '692 82': 'Kumla',
    '692 83': 'Kumla',
    '692 84': 'Kumla',
    '692 85': 'Kumla',
    '692 91': 'Kumla',
    '692 92': 'Kumla',
    '692 93': 'Kumla',
    '694 93': 'Östansjö',
    '697 91': 'Pålsboda',
    '697 94': 'Sköllersta',
    '716 91': 'Fjugesta',
    '690 45': 'Åsbro',
    '694 94': 'Vretstorp',
    '696 01': 'Askersund',
    '696 21': 'Askersund',
    '696 22': 'Askersund',
    '696 23': 'Askersund',
    '696 30': 'Askersund',
    '696 31': 'Askersund',
    '696 32': 'Askersund',
    '696 33': 'Askersund',
    '696 34': 'Askersund',
    '696 73': 'Aspabruk',
    '696 74': 'Hammar',
    '696 75': 'Åmmeberg',
    '696 76': 'Zinkgruvan',
    '696 80': 'Aspabruk',
    '696 81': 'Zinkgruvan',
    '696 91': 'Askersund',
    '696 92': 'Askersund',
    '696 93': 'Aspabruk',
    '696 94': 'Hammar',
    '696 95': 'Åmmeberg',
    '696 96': 'Zinkgruvan',
    '691 01': 'Karlskoga',
    '691 02': 'Karlskoga',
    '691 05': 'Karlskoga',
    '691 10': 'Karlskoga',
    '691 21': 'Karlskoga',
    '691 22': 'Karlskoga',
    '691 23': 'Karlskoga',
    '691 24': 'Karlskoga',
    '691 25': 'Karlskoga',
    '691 26': 'Karlskoga',
    '691 27': 'Karlskoga',
    '691 31': 'Karlskoga',
    '691 32': 'Karlskoga',
    '691 33': 'Karlskoga',
    '691 34': 'Karlskoga',
    '691 35': 'Karlskoga',
    '691 36': 'Karlskoga',
    '691 37': 'Karlskoga',
    '691 38': 'Karlskoga',
    '691 41': 'Karlskoga',
    '691 42': 'Karlskoga',
    '691 43': 'Karlskoga',
    '691 44': 'Karlskoga',
    '691 45': 'Karlskoga',
    '691 46': 'Karlskoga',
    '691 47': 'Karlskoga',
    '691 48': 'Karlskoga',
    '691 51': 'Karlskoga',
    '691 52': 'Karlskoga',
    '691 53': 'Karlskoga',
    '691 54': 'Karlskoga',
    '691 75': 'Karlskoga',
    '691 76': 'Karlskoga',
    '691 77': 'Karlskoga',
    '691 78': 'Karlskoga',
    '691 79': 'Karlskoga',
    '691 80': 'Karlskoga',
    '691 81': 'Karlskoga',
    '691 82': 'Karlskoga',
    '691 83': 'Karlskoga',
    '691 85': 'Karlskoga',
    '691 86': 'Karlskoga',
    '691 88': 'Karlskoga',
    '691 91': 'Karlskoga',
    '691 93': 'Karlskoga',
    '691 94': 'Karlskoga',
    '691 92': 'Granbergsdal',
    '713 01': 'Nora',
    '713 21': 'Nora',
    '713 22': 'Nora',
    '713 23': 'Nora',
    '713 30': 'Nora',
    '713 31': 'Nora',
    '713 32': 'Nora',
    '713 33': 'Nora',
    '713 34': 'Nora',
    '713 72': 'Gyttorp',
    '713 80': 'Nora',
    '713 81': 'Nora',
    '713 82': 'Nora',
    '713 91': 'Nora',
    '713 92': 'Gyttorp',
    '713 93': 'Nora',
    '713 94': 'Nora',
    '711 01': 'Lindesberg',
    '711 04': 'Storå',
    '711 21': 'Lindesberg',
    '711 22': 'Lindesberg',
    '711 23': 'Lindesberg',
    '711 24': 'Lindesberg',
    '711 30': 'Lindesberg',
    '711 31': 'Lindesberg',
    '711 32': 'Lindesberg',
    '711 33': 'Lindesberg',
    '711 34': 'Lindesberg',
    '711 35': 'Lindesberg',
    '711 36': 'Lindesberg',
    '711 72': 'Vedevåg',
    '711 76': 'Storå',
    '711 77': 'Stråssa',
    '711 78': 'Guldsmedshyttan',
    '711 80': 'Lindesberg',
    '711 81': 'Lindesberg',
    '711 82': 'Lindesberg',
    '711 91': 'Lindesberg',
    '711 92': 'Vedevåg',
    '711 93': 'Lindesberg',
    '711 94': 'Lindesberg',
    '711 95': 'Gusselby',
    '711 96': 'Storå',
    '711 97': 'Storå',
    '711 98': 'Ramsberg',
    '714 91': 'Kopparberg',
    '714 93': 'Kopparberg',
    '714 94': 'Kopparberg',
    '718 01': 'Frövi',
    '718 21': 'Frövi',
    '718 22': 'Frövi',
    '718 30': 'Frövi',
    '718 31': 'Frövi',
    '718 32': 'Frövi',
    '718 33': 'Frövi',
    '718 80': 'Frövi',
    '718 81': 'Frövi',
    '718 91': 'Frövi',
    '718 92': 'Frövi',
    '718 93': 'Frövi',
    '718 94': 'Ervalla',
    '691 20': 'Karlskoga',
    '691 50': 'Karlskoga',
    '692 20': 'Kumla',
    '692 71': 'Kumla',
    '693 20': 'Degerfors',
    '694 20': 'Hallsberg',
    '695 20': 'Laxå',
    '696 20': 'Askersund',
    '697 20': 'Pålsboda',
    '701 94': 'Örebro',
    '701 95': 'Örebro',
    '702 81': 'Örebro',
    '702 82': 'Örebro',
    '702 86': 'Örebro',
    '703 02': 'Örebro',
    '703 03': 'Örebro',
    '703 81': 'Örebro',
    '705 10': 'Örebro',
    '708 00': 'Örebro',
    '708 01': 'Örebro',
    '708 02': 'Örebro',
    '708 03': 'Örebro',
    '708 04': 'Örebro',
    '708 05': 'Örebro',
    '708 06': 'Örebro',
    '708 07': 'Örebro',
    '708 08': 'Örebro',
    '708 09': 'Örebro',
    '708 10': 'Örebro',
    '709 99': 'Örebro',
    '711 20': 'Lindesberg',
    '712 20': 'Hällefors',
    '713 20': 'Nora',
    '714 20': 'Kopparberg',
    '715 20': 'Odensbacken',
    '716 20': 'Fjugesta',
    '718 20': 'Frövi',
    '730 91': 'Riddarhyttan',
    '739 01': 'Skinnskatteberg',
    '739 21': 'Skinnskatteberg',
    '739 22': 'Skinnskatteberg',
    '739 23': 'Skinnskatteberg',
    '739 30': 'Skinnskatteberg',
    '739 31': 'Skinnskatteberg',
    '739 70': 'Skinnskatteberg',
    '739 91': 'Skinnskatteberg',
    '739 92': 'Skinnskatteberg',
    '730 60': 'Ramnäs',
    '730 61': 'Virsbo',
    '735 01': 'Surahammar',
    '735 21': 'Surahammar',
    '735 22': 'Surahammar',
    '735 23': 'Surahammar',
    '735 31': 'Surahammar',
    '735 32': 'Surahammar',
    '735 33': 'Surahammar',
    '735 34': 'Surahammar',
    '735 35': 'Surahammar',
    '735 36': 'Surahammar',
    '735 37': 'Surahammar',
    '735 38': 'Surahammar',
    '735 91': 'Surahammar',
    '740 21': 'Järlåsa',
    '740 45': 'Tärnsjö',
    '740 46': 'Östervåla',
    '740 47': 'Harbo',
    '744 01': 'Heby',
    '744 02': 'Morgongåva',
    '744 21': 'Heby',
    '744 31': 'Heby',
    '744 32': 'Heby',
    '744 50': 'Morgongåva',
    '744 51': 'Morgongåva',
    '744 88': 'Heby',
    '744 91': 'Heby',
    '744 92': 'Huddungeby',
    '744 93': 'Runhällen',
    '744 94': 'Morgongåva',
    '744 95': 'Vittinge',
    '731 60': 'Valskog',
    '736 01': 'Kungsör',
    '736 21': 'Kungsör',
    '736 22': 'Kungsör',
    '736 23': 'Kungsör',
    '736 25': 'Kungsör',
    '736 30': 'Kungsör',
    '736 31': 'Kungsör',
    '736 32': 'Kungsör',
    '736 33': 'Kungsör',
    '736 34': 'Kungsör',
    '736 35': 'Kungsör',
    '736 36': 'Kungsör',
    '736 91': 'Kungsör',
    '736 92': 'Kungsör',
    '736 93': 'Kungsör',
    '730 40': 'Kolbäck',
    '734 01': 'Hallstahammar',
    '734 21': 'Hallstahammar',
    '734 22': 'Hallstahammar',
    '734 23': 'Hallstahammar',
    '734 24': 'Hallstahammar',
    '734 25': 'Hallstahammar',
    '734 26': 'Hallstahammar',
    '734 27': 'Hallstahammar',
    '734 30': 'Hallstahammar',
    '734 31': 'Hallstahammar',
    '734 32': 'Hallstahammar',
    '734 33': 'Hallstahammar',
    '734 34': 'Hallstahammar',
    '734 35': 'Hallstahammar',
    '734 36': 'Hallstahammar',
    '734 37': 'Hallstahammar',
    '734 38': 'Hallstahammar',
    '734 39': 'Hallstahammar',
    '734 40': 'Hallstahammar',
    '734 91': 'Hallstahammar',
    '734 92': 'Hallstahammar',
    '738 01': 'Norberg',
    '738 02': 'Norberg',
    '738 21': 'Norberg',
    '738 22': 'Norberg',
    '738 23': 'Norberg',
    '738 30': 'Norberg',
    '738 31': 'Norberg',
    '738 32': 'Norberg',
    '738 33': 'Norberg',
    '738 34': 'Norberg',
    '738 35': 'Norberg',
    '738 50': 'Norberg',
    '738 91': 'Norberg',
    '738 92': 'Norberg',
    '640 45': 'Kvicksund',
    '720 01': 'Västerås',
    '720 02': 'Västerås',
    '720 03': 'Västerås',
    '720 07': 'Västerås',
    '720 10': 'Västerås',
    '720 11': 'Västerås',
    '720 12': 'Västerås',
    '720 13': 'Västerås',
    '720 15': 'Västerås',
    '720 16': 'Västerås',
    '720 17': 'Västerås',
    '720 18': 'Västerås',
    '720 19': 'Västerås',
    '720 20': 'Västerås',
    '721 00': 'Västerås',
    '721 01': 'Västerås',
    '721 03': 'Västerås',
    '721 04': 'Västerås',
    '721 05': 'Västerås',
    '721 06': 'Västerås',
    '721 07': 'Västerås',
    '721 08': 'Västerås',
    '721 09': 'Västerås',
    '721 10': 'Västerås',
    '721 19': 'Västerås',
    '721 20': 'Västerås',
    '721 22': 'Västerås',
    '721 23': 'Västerås',
    '721 26': 'Västerås',
    '721 27': 'Västerås',
    '721 28': 'Västerås',
    '721 29': 'Västerås',
    '721 30': 'Västerås',
    '721 31': 'Västerås',
    '721 32': 'Västerås',
    '721 33': 'Västerås',
    '721 34': 'Västerås',
    '721 35': 'Västerås',
    '721 36': 'Västerås',
    '721 37': 'Västerås',
    '721 38': 'Västerås',
    '721 50': 'Västerås',
    '721 51': 'Västerås',
    '721 53': 'Västerås',
    '721 57': 'Västerås',
    '721 59': 'Västerås',
    '721 61': 'Västerås',
    '721 62': 'Västerås',
    '721 63': 'Västerås',
    '721 64': 'Västerås',
    '721 65': 'Västerås',
    '721 66': 'Västerås',
    '721 67': 'Västerås',
    '721 68': 'Västerås',
    '721 69': 'Västerås',
    '721 70': 'Västerås',
    '721 73': 'Västerås',
    '721 74': 'Västerås',
    '721 75': 'Västerås',
    '721 76': 'Västerås',
    '721 78': 'Västerås',
    '721 82': 'Västerås',
    '721 83': 'Västerås',
    '721 84': 'Västerås',
    '721 85': 'Västerås',
    '721 86': 'Västerås',
    '721 87': 'Västerås',
    '721 89': 'Västerås',
    '722 01': 'Västerås',
    '722 10': 'Västerås',
    '722 11': 'Västerås',
    '722 12': 'Västerås',
    '722 13': 'Västerås',
    '722 14': 'Västerås',
    '722 15': 'Västerås',
    '722 16': 'Västerås',
    '722 17': 'Västerås',
    '722 18': 'Västerås',
    '722 19': 'Västerås',
    '722 20': 'Västerås',
    '722 21': 'Västerås',
    '722 22': 'Västerås',
    '722 23': 'Västerås',
    '722 24': 'Västerås',
    '722 25': 'Västerås',
    '722 26': 'Västerås',
    '722 27': 'Västerås',
    '722 28': 'Västerås',
    '722 31': 'Västerås',
    '722 33': 'Västerås',
    '722 40': 'Västerås',
    '722 41': 'Västerås',
    '722 42': 'Västerås',
    '722 43': 'Västerås',
    '722 44': 'Västerås',
    '722 45': 'Västerås',
    '722 46': 'Västerås',
    '723 01': 'Västerås',
    '723 34': 'Västerås',
    '723 35': 'Västerås',
    '723 36': 'Västerås',
    '723 37': 'Västerås',
    '723 38': 'Västerås',
    '723 39': 'Västerås',
    '723 40': 'Västerås',
    '723 41': 'Västerås',
    '723 42': 'Västerås',
    '723 43': 'Västerås',
    '723 44': 'Västerås',
    '723 45': 'Västerås',
    '723 46': 'Västerås',
    '723 47': 'Västerås',
    '723 48': 'Västerås',
    '723 49': 'Västerås',
    '723 50': 'Västerås',
    '723 51': 'Västerås',
    '723 52': 'Västerås',
    '723 53': 'Västerås',
    '723 55': 'Västerås',
    '724 01': 'Västerås',
    '724 02': 'Västerås',
    '724 60': 'Västerås',
    '724 61': 'Västerås',
    '724 62': 'Västerås',
    '724 63': 'Västerås',
    '724 64': 'Västerås',
    '724 65': 'Västerås',
    '724 66': 'Västerås',
    '724 67': 'Västerås',
    '724 68': 'Västerås',
    '724 69': 'Västerås',
    '724 70': 'Västerås',
    '724 71': 'Västerås',
    '724 72': 'Västerås',
    '724 73': 'Västerås',
    '724 74': 'Västerås',
    '724 75': 'Västerås',
    '724 76': 'Västerås',
    '724 77': 'Västerås',
    '724 78': 'Västerås',
    '724 79': 'Västerås',
    '724 80': 'Västerås',
    '724 81': 'Västerås',
    '724 82': 'Västerås',
    '725 91': 'Västerås',
    '725 92': 'Västerås',
    '725 93': 'Västerås',
    '725 94': 'Västerås',
    '725 95': 'Västerås',
    '725 96': 'Västerås',
    '725 97': 'Västerås',
    '725 98': 'Västerås',
    '730 50': 'Skultuna',
    '730 70': 'Västerfärnebo',
    '730 71': 'Salbohed',
    '733 01': 'Sala',
    '733 21': 'Sala',
    '733 22': 'Sala',
    '733 23': 'Sala',
    '733 24': 'Sala',
    '733 25': 'Sala',
    '733 26': 'Sala',
    '733 27': 'Sala',
    '733 30': 'Sala',
    '733 31': 'Sala',
    '733 32': 'Sala',
    '733 33': 'Sala',
    '733 34': 'Sala',
    '733 35': 'Sala',
    '733 36': 'Sala',
    '733 37': 'Sala',
    '733 38': 'Sala',
    '733 39': 'Sala',
    '733 40': 'Sala',
    '733 73': 'Ransta',
    '733 75': 'Möklinta',
    '733 91': 'Sala',
    '733 92': 'Sala',
    '733 93': 'Sala',
    '733 94': 'Sala',
    '733 95': 'Sala',
    '733 96': 'Sala',
    '733 97': 'Sala',
    '733 98': 'Ransta',
    '733 99': 'Möklinta',
    '740 83': 'Fjärdhundra',
    '737 01': 'Fagersta',
    '737 02': 'Fagersta',
    '737 21': 'Fagersta',
    '737 22': 'Fagersta',
    '737 23': 'Fagersta',
    '737 24': 'Fagersta',
    '737 25': 'Fagersta',
    '737 30': 'Fagersta',
    '737 31': 'Fagersta',
    '737 32': 'Fagersta',
    '737 33': 'Fagersta',
    '737 34': 'Fagersta',
    '737 40': 'Fagersta',
    '737 41': 'Fagersta',
    '737 42': 'Fagersta',
    '737 43': 'Fagersta',
    '737 44': 'Fagersta',
    '737 45': 'Fagersta',
    '737 46': 'Fagersta',
    '737 47': 'Fagersta',
    '737 48': 'Fagersta',
    '737 49': 'Fagersta',
    '737 80': 'Fagersta',
    '737 82': 'Fagersta',
    '737 90': 'Ängelsberg',
    '737 91': 'Fagersta',
    '737 92': 'Fagersta',
    '730 30': 'Kolsva',
    '731 01': 'Köping',
    '731 02': 'Köping',
    '731 03': 'Köping',
    '731 21': 'Köping',
    '731 22': 'Köping',
    '731 23': 'Köping',
    '731 24': 'Köping',
    '731 25': 'Köping',
    '731 26': 'Köping',
    '731 27': 'Köping',
    '731 29': 'Köping',
    '731 30': 'Köping',
    '731 31': 'Köping',
    '731 32': 'Köping',
    '731 33': 'Köping',
    '731 34': 'Köping',
    '731 35': 'Köping',
    '731 36': 'Köping',
    '731 37': 'Köping',
    '731 40': 'Köping',
    '731 41': 'Köping',
    '731 42': 'Köping',
    '731 43': 'Köping',
    '731 50': 'Köping',
    '731 51': 'Köping',
    '731 52': 'Köping',
    '731 53': 'Köping',
    '731 54': 'Köping',
    '731 70': 'Köping',
    '731 80': 'Köping',
    '731 85': 'Köping',
    '731 91': 'Köping',
    '731 92': 'Köping',
    '731 93': 'Köping',
    '731 94': 'Köping',
    '731 95': 'Köping',
    '731 96': 'Köping',
    '731 97': 'Köping',
    '731 98': 'Köping',
    '710 41': 'Fellingsbro',
    '732 01': 'Arboga',
    '732 21': 'Arboga',
    '732 22': 'Arboga',
    '732 23': 'Arboga',
    '732 24': 'Arboga',
    '732 25': 'Arboga',
    '732 26': 'Arboga',
    '732 27': 'Arboga',
    '732 30': 'Arboga',
    '732 31': 'Arboga',
    '732 32': 'Arboga',
    '732 33': 'Arboga',
    '732 34': 'Arboga',
    '732 45': 'Arboga',
    '732 46': 'Arboga',
    '732 47': 'Arboga',
    '732 48': 'Arboga',
    '732 49': 'Arboga',
    '732 81': 'Arboga',
    '732 91': 'Arboga',
    '732 92': 'Arboga',
    '732 93': 'Arboga',
    '732 94': 'Arboga',
    '732 95': 'Arboga',
    '732 96': 'Arboga',
    '732 97': 'Arboga',
    '732 98': 'Arboga',
    '721 52': 'Västerås',
    '721 77': 'Västerås',
    '721 80': 'Västerås',
    '722 09': 'Västerås',
    '728 00': 'Västerås',
    '728 02': 'Västerås',
    '728 04': 'Västerås',
    '728 05': 'Västerås',
    '731 20': 'Köping',
    '731 81': 'Köping',
    '732 20': 'Arboga',
    '733 20': 'Sala',
    '734 20': 'Hallstahammar',
    '734 28': 'Kolbäck',
    '734 50': 'Kolbäck',
    '734 51': 'Kolbäck',
    '735 20': 'Surahammar',
    '736 20': 'Kungsör',
    '736 85': 'Kungsör',
    '737 20': 'Fagersta',
    '737 26': 'Fagersta',
    '737 81': 'Fagersta',
    '738 20': 'Norberg',
    '739 20': 'Skinnskatteberg',
    '744 19': 'Morgongåva',
    '744 20': 'Heby',
    '749 70': 'Fjärdhundra',
    '780 50': 'Vansbro',
    '780 51': 'Dala-Järna',
    '780 53': 'Nås',
    '780 54': 'Äppelbo',
    '780 61': 'Öje',
    '780 64': 'Lima',
    '780 67': 'Sälen',
    '780 68': 'Transtrand',
    '782 01': 'Malung',
    '782 21': 'Malung',
    '782 22': 'Malung',
    '782 23': 'Malung',
    '782 24': 'Malung',
    '782 27': 'Malung',
    '782 30': 'Malung',
    '782 31': 'Malung',
    '782 33': 'Malung',
    '782 34': 'Malung',
    '782 35': 'Malung',
    '782 75': 'Malungsfors',
    '782 90': 'Malung',
    '782 91': 'Malung',
    '780 40': 'Mockfjärd',
    '780 41': 'Gagnef',
    '780 44': 'Dala-Floda',
    '780 45': 'Björbo',
    '790 31': 'Djura',
    '793 01': 'Leksand',
    '793 04': 'Insjön',
    '793 11': 'Insjön',
    '793 12': 'Insjön',
    '793 13': 'Insjön',
    '793 21': 'Leksand',
    '793 22': 'Leksand',
    '793 23': 'Leksand',
    '793 24': 'Leksand',
    '793 25': 'Leksand',
    '793 26': 'Leksand',
    '793 27': 'Leksand',
    '793 30': 'Leksand',
    '793 31': 'Leksand',
    '793 32': 'Leksand',
    '793 33': 'Leksand',
    '793 35': 'Leksand',
    '793 36': 'Leksand',
    '793 40': 'Insjön',
    '793 50': 'Leksand',
    '793 60': 'Siljansnäs',
    '793 70': 'Tällberg',
    '793 85': 'Insjön',
    '793 86': 'Insjön',
    '793 90': 'Leksand',
    '793 91': 'Leksand',
    '793 92': 'Leksand',
    '793 94': 'Tällberg',
    '793 97': 'Siljansnäs',
    '790 70': 'Furudal',
    '795 01': 'Rättvik',
    '795 03': 'Rättvik',
    '795 21': 'Rättvik',
    '795 22': 'Rättvik',
    '795 23': 'Rättvik',
    '795 30': 'Rättvik',
    '795 31': 'Rättvik',
    '795 32': 'Rättvik',
    '795 33': 'Rättvik',
    '795 35': 'Rättvik',
    '795 36': 'Rättvik',
    '795 70': 'Vikarbyn',
    '795 80': 'Rättvik',
    '795 90': 'Rättvik',
    '795 91': 'Rättvik',
    '795 92': 'Rättvik',
    '795 95': 'Vikarbyn',
    '795 96': 'Boda Kyrkby',
    '794 01': 'Orsa',
    '794 21': 'Orsa',
    '794 22': 'Orsa',
    '794 30': 'Orsa',
    '794 31': 'Orsa',
    '794 32': 'Orsa',
    '794 33': 'Orsa',
    '794 34': 'Orsa',
    '794 90': 'Orsa',
    '794 91': 'Orsa',
    '794 92': 'Orsa',
    '794 93': 'Orsa',
    '794 98': 'Orsa',
    '780 69': 'Sörsjön',
    '790 90': 'Särna',
    '790 91': 'Idre',
    '796 01': 'Älvdalen',
    '796 02': 'Älvdalen',
    '796 21': 'Älvdalen',
    '796 22': 'Älvdalen',
    '796 25': 'Älvdalen',
    '796 26': 'Älvdalen',
    '796 30': 'Älvdalen',
    '796 31': 'Älvdalen',
    '796 90': 'Älvdalen',
    '796 91': 'Älvdalen',
    '796 99': 'Älvdalen',
    '772 92': 'Grängesberg',
    '777 01': 'Smedjebacken',
    '777 03': 'Söderbärke',
    '777 21': 'Smedjebacken',
    '777 22': 'Smedjebacken',
    '777 23': 'Smedjebacken',
    '777 25': 'Smedjebacken',
    '777 30': 'Smedjebacken',
    '777 31': 'Smedjebacken',
    '777 32': 'Smedjebacken',
    '777 33': 'Smedjebacken',
    '777 34': 'Smedjebacken',
    '777 50': 'Smedjebacken',
    '777 51': 'Smedjebacken',
    '777 60': 'Söderbärke',
    '777 80': 'Smedjebacken',
    '777 81': 'Smedjebacken',
    '777 82': 'Smedjebacken',
    '777 90': 'Smedjebacken',
    '777 91': 'Smedjebacken',
    '777 93': 'Söderbärke',
    '777 94': 'Söderbärke',
    '792 01': 'Mora',
    '792 02': 'Mora',
    '792 05': 'Sollerön',
    '792 08': 'Mora',
    '792 21': 'Mora',
    '792 22': 'Mora',
    '792 23': 'Mora',
    '792 24': 'Mora',
    '792 25': 'Mora',
    '792 26': 'Mora',
    '792 27': 'Mora',
    '792 30': 'Mora',
    '792 31': 'Mora',
    '792 32': 'Mora',
    '792 33': 'Mora',
    '792 34': 'Mora',
    '792 36': 'Mora',
    '792 37': 'Mora',
    '792 50': 'Mora',
    '792 51': 'Mora',
    '792 52': 'Mora',
    '792 53': 'Mora',
    '792 75': 'Färnäs',
    '792 77': 'Nusnäs',
    '792 80': 'Mora',
    '792 85': 'Mora',
    '792 90': 'Sollerön',
    '792 91': 'Mora',
    '792 92': 'Mora',
    '792 93': 'Venjan',
    '792 94': 'Mora',
    '792 95': 'Mora',
    '792 96': 'Våmhus',
    '792 97': 'Mora',
    '792 98': 'Garsås',
    '790 15': 'Sundborn',
    '790 20': 'Grycksbo',
    '790 21': 'Bjursås',
    '790 22': 'Sågmyra',
    '790 23': 'Svärdsjö',
    '790 25': 'Linghed',
    '791 01': 'Falun',
    '791 05': 'Falun',
    '791 06': 'Falun',
    '791 07': 'Falun',
    '791 09': 'Falun',
    '791 10': 'Falun',
    '791 11': 'Falun',
    '791 12': 'Falun',
    '791 13': 'Falun',
    '791 14': 'Falun',
    '791 16': 'Falun',
    '791 17': 'Falun',
    '791 18': 'Falun',
    '791 19': 'Falun',
    '791 21': 'Falun',
    '791 22': 'Falun',
    '791 23': 'Falun',
    '791 24': 'Falun',
    '791 25': 'Falun',
    '791 26': 'Falun',
    '791 27': 'Falun',
    '791 28': 'Falun',
    '791 29': 'Falun',
    '791 30': 'Falun',
    '791 31': 'Falun',
    '791 32': 'Falun',
    '791 33': 'Falun',
    '791 34': 'Falun',
    '791 35': 'Falun',
    '791 36': 'Falun',
    '791 37': 'Falun',
    '791 38': 'Falun',
    '791 40': 'Falun',
    '791 41': 'Falun',
    '791 42': 'Falun',
    '791 43': 'Falun',
    '791 44': 'Falun',
    '791 45': 'Falun',
    '791 46': 'Falun',
    '791 47': 'Falun',
    '791 50': 'Falun',
    '791 51': 'Falun',
    '791 52': 'Falun',
    '791 53': 'Falun',
    '791 55': 'Falun',
    '791 56': 'Falun',
    '791 60': 'Falun',
    '791 61': 'Falun',
    '791 62': 'Falun',
    '791 70': 'Falun',
    '791 71': 'Falun',
    '791 72': 'Falun',
    '791 73': 'Falun',
    '791 74': 'Falun',
    '791 75': 'Falun',
    '791 76': 'Falun',
    '791 77': 'Falun',
    '791 80': 'Falun',
    '791 82': 'Falun',
    '791 83': 'Falun',
    '791 84': 'Falun',
    '791 85': 'Falun',
    '791 86': 'Falun',
    '791 88': 'Falun',
    '791 91': 'Falun',
    '791 92': 'Falun',
    '791 93': 'Falun',
    '791 94': 'Falun',
    '791 95': 'Falun',
    '791 96': 'Falun',
    '781 00': 'Borlänge',
    '781 01': 'Borlänge',
    '781 02': 'Borlänge',
    '781 05': 'Borlänge',
    '781 06': 'Borlänge',
    '781 07': 'Borlänge',
    '781 10': 'Borlänge',
    '781 21': 'Borlänge',
    '781 22': 'Borlänge',
    '781 23': 'Borlänge',
    '781 24': 'Borlänge',
    '781 26': 'Borlänge',
    '781 27': 'Borlänge',
    '781 28': 'Borlänge',
    '781 29': 'Borlänge',
    '781 70': 'Borlänge',
    '781 71': 'Borlänge',
    '781 72': 'Borlänge',
    '781 73': 'Borlänge',
    '781 81': 'Borlänge',
    '781 82': 'Borlänge',
    '781 83': 'Borlänge',
    '781 84': 'Borlänge',
    '781 85': 'Borlänge',
    '781 87': 'Borlänge',
    '781 88': 'Borlänge',
    '781 93': 'Borlänge',
    '781 94': 'Borlänge',
    '781 95': 'Borlänge',
    '781 96': 'Borlänge',
    '784 30': 'Borlänge',
    '784 31': 'Borlänge',
    '784 32': 'Borlänge',
    '784 33': 'Borlänge',
    '784 34': 'Borlänge',
    '784 35': 'Borlänge',
    '784 36': 'Borlänge',
    '784 37': 'Borlänge',
    '784 40': 'Borlänge',
    '784 41': 'Borlänge',
    '784 42': 'Borlänge',
    '784 43': 'Borlänge',
    '784 44': 'Borlänge',
    '784 45': 'Borlänge',
    '784 50': 'Borlänge',
    '784 51': 'Borlänge',
    '784 52': 'Borlänge',
    '784 53': 'Borlänge',
    '784 54': 'Borlänge',
    '784 55': 'Borlänge',
    '784 56': 'Borlänge',
    '784 60': 'Borlänge',
    '784 61': 'Borlänge',
    '784 62': 'Borlänge',
    '784 63': 'Borlänge',
    '784 65': 'Borlänge',
    '784 66': 'Borlänge',
    '784 67': 'Borlänge',
    '784 68': 'Borlänge',
    '784 72': 'Borlänge',
    '784 73': 'Borlänge',
    '784 74': 'Borlänge',
    '784 75': 'Borlänge',
    '784 76': 'Borlänge',
    '784 77': 'Borlänge',
    '793 41': 'Insjön',
    '783 01': 'Säter',
    '783 21': 'Säter',
    '783 22': 'Säter',
    '783 23': 'Säter',
    '783 27': 'Säter',
    '783 30': 'Säter',
    '783 31': 'Säter',
    '783 32': 'Säter',
    '783 34': 'Säter',
    '783 35': 'Säter',
    '783 50': 'Gustafs',
    '783 90': 'Säter',
    '783 92': 'Stora Skedvi',
    '783 95': 'Gustafs',
    '770 70': 'Långshyttan',
    '770 71': 'Stjärnsund',
    '776 01': 'Hedemora',
    '776 21': 'Hedemora',
    '776 22': 'Hedemora',
    '776 23': 'Hedemora',
    '776 28': 'Hedemora',
    '776 30': 'Hedemora',
    '776 31': 'Hedemora',
    '776 32': 'Hedemora',
    '776 33': 'Hedemora',
    '776 34': 'Hedemora',
    '776 35': 'Hedemora',
    '776 36': 'Hedemora',
    '776 70': 'Vikmanshyttan',
    '776 90': 'Hedemora',
    '776 91': 'Hedemora',
    '776 92': 'Hedemora',
    '776 93': 'Hedemora',
    '776 95': 'Vikmanshyttan',
    '776 96': 'Dala-Husby',
    '776 97': 'Dala-Husby',
    '776 98': 'Garpenberg',
    '783 93': 'Stora Skedvi',
    '774 01': 'Avesta',
    '774 04': 'Horndal',
    '774 21': 'Avesta',
    '774 22': 'Avesta',
    '774 23': 'Avesta',
    '774 24': 'Avesta',
    '774 27': 'Avesta',
    '774 29': 'Avesta',
    '774 30': 'Avesta',
    '774 31': 'Avesta',
    '774 32': 'Avesta',
    '774 33': 'Avesta',
    '774 34': 'Avesta',
    '774 35': 'Avesta',
    '774 40': 'Avesta',
    '774 41': 'Avesta',
    '774 60': 'Avesta',
    '774 61': 'Avesta',
    '774 62': 'Avesta',
    '774 67': 'Horndal',
    '774 80': 'Avesta',
    '774 81': 'Avesta',
    '774 82': 'Avesta',
    '774 89': 'Fors',
    '774 91': 'Avesta',
    '774 92': 'Avesta',
    '774 93': 'Avesta',
    '774 94': 'Avesta',
    '774 97': 'Fors',
    '774 98': 'Horndal',
    '774 99': 'By Kyrkby',
    '775 25': 'Krylbo',
    '775 26': 'Krylbo',
    '775 28': 'Krylbo',
    '775 50': 'Krylbo',
    '775 51': 'Krylbo',
    '775 52': 'Krylbo',
    '775 53': 'Krylbo',
    '775 54': 'Krylbo',
    '775 70': 'Krylbo',
    '775 71': 'Krylbo',
    '775 95': 'Krylbo',
    '775 96': 'Krylbo',
    '770 10': 'Fredriksberg',
    '770 12': 'Sunnansjö',
    '770 13': 'Grangärde',
    '770 14': 'Nyhammar',
    '771 01': 'Ludvika',
    '771 21': 'Ludvika',
    '771 22': 'Ludvika',
    '771 23': 'Ludvika',
    '771 24': 'Ludvika',
    '771 25': 'Ludvika',
    '771 26': 'Ludvika',
    '771 28': 'Ludvika',
    '771 30': 'Ludvika',
    '771 31': 'Ludvika',
    '771 32': 'Ludvika',
    '771 33': 'Ludvika',
    '771 34': 'Ludvika',
    '771 35': 'Ludvika',
    '771 36': 'Ludvika',
    '771 40': 'Ludvika',
    '771 41': 'Ludvika',
    '771 42': 'Ludvika',
    '771 43': 'Ludvika',
    '771 50': 'Ludvika',
    '771 51': 'Ludvika',
    '771 52': 'Ludvika',
    '771 53': 'Ludvika',
    '771 54': 'Ludvika',
    '771 55': 'Ludvika',
    '771 60': 'Ludvika',
    '771 65': 'Ludvika',
    '771 80': 'Ludvika',
    '771 81': 'Ludvika',
    '771 82': 'Ludvika',
    '771 83': 'Ludvika',
    '771 84': 'Ludvika',
    '771 90': 'Ludvika',
    '771 92': 'Ludvika',
    '771 94': 'Ludvika',
    '772 01': 'Grängesberg',
    '772 21': 'Grängesberg',
    '772 22': 'Grängesberg',
    '772 30': 'Grängesberg',
    '772 31': 'Grängesberg',
    '772 32': 'Grängesberg',
    '772 40': 'Grängesberg',
    '772 50': 'Grängesberg',
    '772 70': 'Saxdalen',
    '772 80': 'Grängesberg',
    '772 90': 'Grängesberg',
    '781 99': 'Idkerberget',
    '771 03': 'Ludvika',
    '771 20': 'Ludvika',
    '774 02': 'Avesta',
    '774 20': 'Avesta',
    '776 02': 'Vikmanshyttan',
    '776 20': 'Hedemora',
    '777 20': 'Smedjebacken',
    '781 15': 'Borlänge',
    '781 20': 'Borlänge',
    '782 03': 'Malung',
    '782 20': 'Malung',
    '783 20': 'Säter',
    '791 20': 'Falun',
    '792 07': 'Mora',
    '792 20': 'Mora',
    '793 20': 'Leksand',
    '794 20': 'Orsa',
    '795 20': 'Rättvik',
    '796 20': 'Älvdalen',
    '816 01': 'Ockelbo',
    '816 21': 'Ockelbo',
    '816 22': 'Ockelbo',
    '816 23': 'Ockelbo',
    '816 25': 'Ockelbo',
    '816 26': 'Ockelbo',
    '816 30': 'Ockelbo',
    '816 31': 'Ockelbo',
    '816 80': 'Ockelbo',
    '816 90': 'Ockelbo',
    '816 91': 'Jädraås',
    '816 92': 'Lingbo',
    '816 93': 'Ockelbo',
    '816 94': 'Ockelbo',
    '813 01': 'Hofors',
    '813 02': 'Torsåker',
    '813 21': 'Hofors',
    '813 22': 'Hofors',
    '813 23': 'Hofors',
    '813 27': 'Hofors',
    '813 30': 'Hofors',
    '813 31': 'Hofors',
    '813 32': 'Hofors',
    '813 33': 'Hofors',
    '813 34': 'Hofors',
    '813 35': 'Hofors',
    '813 36': 'Hofors',
    '813 40': 'Torsåker',
    '813 81': 'Hofors',
    '813 82': 'Hofors',
    '813 91': 'Hofors',
    '813 94': 'Torsåker',
    '813 95': 'Torsåker',
    '813 96': 'Torsåker',
    '790 26': 'Enviken',
    '822 01': 'Alfta',
    '822 21': 'Alfta',
    '822 22': 'Alfta',
    '822 25': 'Alfta',
    '822 30': 'Alfta',
    '822 31': 'Alfta',
    '822 80': 'Alfta',
    '822 91': 'Alfta',
    '822 98': 'Alfta',
    '822 99': 'Alfta',
    '828 01': 'Edsbyn',
    '828 21': 'Edsbyn',
    '828 22': 'Edsbyn',
    '828 23': 'Edsbyn',
    '828 25': 'Edsbyn',
    '828 30': 'Edsbyn',
    '828 31': 'Edsbyn',
    '828 32': 'Edsbyn',
    '828 33': 'Edsbyn',
    '828 34': 'Edsbyn',
    '828 80': 'Edsbyn',
    '828 81': 'Edsbyn',
    '828 91': 'Edsbyn',
    '828 92': 'Edsbyn',
    '828 93': 'Voxnabruk',
    '828 94': 'Edsbyn',
    '828 95': 'Viksjöfors',
    '828 99': 'Edsbyn',
    '820 70': 'Bergsjö',
    '820 71': 'Ilsbo',
    '820 72': 'Strömsbruk',
    '820 74': 'Stocka',
    '820 75': 'Harmånger',
    '820 76': 'Jättendal',
    '820 77': 'Gnarp',
    '820 41': 'Färila',
    '820 42': 'Korskrogen',
    '820 43': 'Kårböle',
    '820 50': 'Los',
    '820 51': 'Hamra',
    '827 00': 'Ljusdal',
    '827 02': 'Tallåsen',
    '827 21': 'Ljusdal',
    '827 22': 'Ljusdal',
    '827 23': 'Ljusdal',
    '827 24': 'Ljusdal',
    '827 25': 'Ljusdal',
    '827 30': 'Ljusdal',
    '827 31': 'Ljusdal',
    '827 32': 'Ljusdal',
    '827 33': 'Ljusdal',
    '827 34': 'Ljusdal',
    '827 35': 'Ljusdal',
    '827 40': 'Tallåsen',
    '827 80': 'Ljusdal',
    '827 81': 'Ljusdal',
    '827 82': 'Ljusdal',
    '827 91': 'Ljusdal',
    '827 92': 'Ljusdal',
    '827 93': 'Ljusdal',
    '827 94': 'Ljusdal',
    '827 95': 'Tallåsen',
    '800 02': 'Gävle',
    '800 04': 'Gävle',
    '800 06': 'Gävle',
    '800 08': 'Gävle',
    '800 10': 'Gävle',
    '800 11': 'Gävle',
    '800 14': 'Gävle',
    '800 15': 'Gävle',
    '801 00': 'Gävle',
    '801 01': 'Gävle',
    '801 02': 'Gävle',
    '801 03': 'Gävle',
    '801 04': 'Gävle',
    '801 05': 'Gävle',
    '801 06': 'Gävle',
    '801 07': 'Gävle',
    '801 08': 'Gävle',
    '801 26': 'Gävle',
    '801 27': 'Gävle',
    '801 28': 'Gävle',
    '801 29': 'Gävle',
    '801 30': 'Gävle',
    '801 31': 'Gävle',
    '801 32': 'Gävle',
    '801 33': 'Gävle',
    '801 34': 'Gävle',
    '801 35': 'Gävle',
    '801 36': 'Gävle',
    '801 37': 'Gävle',
    '801 38': 'Gävle',
    '801 39': 'Gävle',
    '801 70': 'Gävle',
    '801 72': 'Gävle',
    '801 73': 'Gävle',
    '801 74': 'Gävle',
    '801 76': 'Gävle',
    '801 81': 'Gävle',
    '801 82': 'Gävle',
    '801 83': 'Gävle',
    '801 84': 'Gävle',
    '801 85': 'Gävle',
    '801 86': 'Gävle',
    '801 87': 'Gävle',
    '801 88': 'Gävle',
    '802 00': 'Gävle',
    '802 50': 'Gävle',
    '802 51': 'Gävle',
    '802 52': 'Gävle',
    '802 53': 'Gävle',
    '802 54': 'Gävle',
    '802 55': 'Gävle',
    '802 56': 'Gävle',
    '802 57': 'Gävle',
    '802 60': 'Gävle',
    '802 61': 'Gävle',
    '802 62': 'Gävle',
    '802 63': 'Gävle',
    '802 64': 'Gävle',
    '802 65': 'Gävle',
    '802 66': 'Gävle',
    '802 67': 'Gävle',
    '802 69': 'Gävle',
    '802 70': 'Gävle',
    '802 71': 'Gävle',
    '802 72': 'Gävle',
    '802 74': 'Gävle',
    '802 75': 'Gävle',
    '802 76': 'Gävle',
    '802 77': 'Gävle',
    '802 80': 'Gävle',
    '802 81': 'Gävle',
    '802 82': 'Gävle',
    '802 83': 'Gävle',
    '802 84': 'Gävle',
    '802 85': 'Gävle',
    '802 86': 'Gävle',
    '802 88': 'Gävle',
    '802 91': 'Gävle',
    '802 92': 'Gävle',
    '802 93': 'Gävle',
    '803 01': 'Gävle',
    '803 02': 'Gävle',
    '803 09': 'Gävle',
    '803 10': 'Gävle',
    '803 11': 'Gävle',
    '803 20': 'Gävle',
    '803 21': 'Gävle',
    '803 22': 'Gävle',
    '803 23': 'Gävle',
    '803 24': 'Gävle',
    '803 25': 'Gävle',
    '804 00': 'Gävle',
    '804 23': 'Gävle',
    '804 24': 'Gävle',
    '804 25': 'Gävle',
    '804 26': 'Gävle',
    '804 27': 'Gävle',
    '804 28': 'Gävle',
    '804 29': 'Gävle',
    '804 30': 'Gävle',
    '804 31': 'Gävle',
    '804 32': 'Gävle',
    '804 33': 'Gävle',
    '804 34': 'Gävle',
    '805 91': 'Gävle',
    '805 92': 'Gävle',
    '805 95': 'Gävle',
    '805 96': 'Gävle',
    '805 97': 'Gävle',
    '805 98': 'Gävle',
    '806 00': 'Gävle',
    '806 26': 'Gävle',
    '806 27': 'Gävle',
    '806 28': 'Gävle',
    '806 31': 'Gävle',
    '806 32': 'Gävle',
    '806 33': 'Gävle',
    '806 34': 'Gävle',
    '806 35': 'Gävle',
    '806 36': 'Gävle',
    '806 37': 'Gävle',
    '806 38': 'Gävle',
    '806 41': 'Gävle',
    '806 42': 'Gävle',
    '806 43': 'Gävle',
    '806 45': 'Gävle',
    '806 46': 'Gävle',
    '806 48': 'Gävle',
    '806 49': 'Gävle',
    '810 40': 'Hedesunda',
    '814 91': 'Furuvik',
    '817 01': 'Norrsundet',
    '817 02': 'Bergby',
    '817 21': 'Norrsundet',
    '817 30': 'Norrsundet',
    '817 40': 'Bergby',
    '817 91': 'Norrsundet',
    '817 92': 'Bergby',
    '817 93': 'Hamrångefjärden',
    '818 01': 'Valbo',
    '818 02': 'Valbo',
    '818 03': 'Forsbacka',
    '818 21': 'Valbo',
    '818 30': 'Valbo',
    '818 31': 'Valbo',
    '818 32': 'Valbo',
    '818 33': 'Valbo',
    '818 34': 'Valbo',
    '818 41': 'Forsbacka',
    '818 42': 'Forsbacka',
    '818 91': 'Valbo',
    '818 92': 'Valbo',
    '774 68': 'Horndal',
    '810 20': 'Österfärnebo',
    '810 21': 'Gysinge',
    '810 22': 'Årsunda',
    '811 00': 'Sandviken',
    '811 01': 'Sandviken',
    '811 02': 'Järbo',
    '811 03': 'Sandviken',
    '811 21': 'Sandviken',
    '811 22': 'Sandviken',
    '811 23': 'Sandviken',
    '811 24': 'Sandviken',
    '811 25': 'Sandviken',
    '811 26': 'Sandviken',
    '811 30': 'Sandviken',
    '811 31': 'Sandviken',
    '811 32': 'Sandviken',
    '811 33': 'Sandviken',
    '811 34': 'Sandviken',
    '811 35': 'Sandviken',
    '811 36': 'Sandviken',
    '811 37': 'Sandviken',
    '811 38': 'Sandviken',
    '811 39': 'Sandviken',
    '811 40': 'Sandviken',
    '811 41': 'Sandviken',
    '811 50': 'Sandviken',
    '811 51': 'Sandviken',
    '811 52': 'Sandviken',
    '811 53': 'Sandviken',
    '811 54': 'Sandviken',
    '811 60': 'Sandviken',
    '811 61': 'Sandviken',
    '811 62': 'Sandviken',
    '811 70': 'Järbo',
    '811 71': 'Järbo',
    '811 80': 'Sandviken',
    '811 81': 'Sandviken',
    '811 88': 'Sandviken',
    '811 89': 'Sandviken',
    '811 91': 'Sandviken',
    '811 92': 'Sandviken',
    '811 93': 'Sandviken',
    '811 94': 'Sandviken',
    '811 95': 'Järbo',
    '812 01': 'Storvik',
    '812 03': 'Kungsgården',
    '812 21': 'Storvik',
    '812 22': 'Storvik',
    '812 25': 'Storvik',
    '812 30': 'Storvik',
    '812 31': 'Storvik',
    '812 40': 'Kungsgården',
    '812 41': 'Gästrike-Hammarby',
    '812 51': 'Åshammar',
    '812 90': 'Storvik',
    '812 92': 'Gästrike-Hammarby',
    '812 93': 'Kungsgården',
    '812 94': 'Åshammar',
    '818 43': 'Forsbacka',
    '817 94': 'Axmar',
    '820 20': 'Ljusne',
    '820 21': 'Vallvik',
    '820 22': 'Sandarne',
    '820 23': 'Bergvik',
    '820 26': 'Marmaverken',
    '826 01': 'Söderhamn',
    '826 04': 'Norrala',
    '826 08': 'Trönödal',
    '826 21': 'Söderhamn',
    '826 22': 'Söderhamn',
    '826 23': 'Söderhamn',
    '826 24': 'Söderhamn',
    '826 25': 'Söderhamn',
    '826 26': 'Söderhamn',
    '826 27': 'Söderhamn',
    '826 30': 'Söderhamn',
    '826 31': 'Söderhamn',
    '826 32': 'Söderhamn',
    '826 34': 'Söderhamn',
    '826 35': 'Söderhamn',
    '826 36': 'Söderhamn',
    '826 37': 'Söderhamn',
    '826 39': 'Söderhamn',
    '826 40': 'Söderhamn',
    '826 50': 'Söderhamn',
    '826 60': 'Söderhamn',
    '826 61': 'Söderala',
    '826 62': 'Norrala',
    '826 70': 'Söderhamn',
    '826 80': 'Söderhamn',
    '826 81': 'Söderhamn',
    '826 83': 'Söderhamn',
    '826 84': 'Söderhamn',
    '826 86': 'Söderhamn',
    '826 91': 'Söderhamn',
    '826 92': 'Söderala',
    '826 93': 'Söderhamn',
    '826 94': 'Norrala',
    '826 95': 'Trönödal',
    '816 95': 'Åmotsbruk',
    '820 10': 'Arbrå',
    '820 11': 'Vallsta',
    '820 29': 'Stråtjära',
    '820 40': 'Järvsö',
    '821 00': 'Bollnäs',
    '821 10': 'Bollnäs',
    '821 11': 'Bollnäs',
    '821 12': 'Bollnäs',
    '821 13': 'Bollnäs',
    '821 21': 'Bollnäs',
    '821 22': 'Bollnäs',
    '821 23': 'Bollnäs',
    '821 24': 'Bollnäs',
    '821 25': 'Bollnäs',
    '821 26': 'Bollnäs',
    '821 30': 'Bollnäs',
    '821 31': 'Bollnäs',
    '821 32': 'Bollnäs',
    '821 33': 'Bollnäs',
    '821 34': 'Bollnäs',
    '821 35': 'Bollnäs',
    '821 36': 'Bollnäs',
    '821 40': 'Bollnäs',
    '821 41': 'Bollnäs',
    '821 42': 'Bollnäs',
    '821 43': 'Bollnäs',
    '821 50': 'Bollnäs',
    '821 51': 'Bollnäs',
    '821 58': 'Rengsjö',
    '821 80': 'Bollnäs',
    '821 82': 'Bollnäs',
    '821 91': 'Bollnäs',
    '821 92': 'Bollnäs',
    '821 93': 'Bollnäs',
    '821 94': 'Bollnäs',
    '821 95': 'Bollnäs',
    '821 98': 'Rengsjö',
    '822 92': 'Alfta',
    '823 01': 'Kilafors',
    '823 21': 'Kilafors',
    '823 22': 'Kilafors',
    '823 30': 'Kilafors',
    '823 80': 'Kilafors',
    '823 91': 'Kilafors',
    '823 92': 'Holmsveden',
    '823 93': 'Segersta',
    '820 60': 'Delsbo',
    '820 62': 'Bjuråker',
    '820 64': 'Näsviken',
    '820 65': 'Forsa',
    '820 78': 'Hassela',
    '824 00': 'Hudiksvall',
    '824 01': 'Hudiksvall',
    '824 02': 'Hudiksvall',
    '824 10': 'Hudiksvall',
    '824 11': 'Hudiksvall',
    '824 12': 'Hudiksvall',
    '824 13': 'Hudiksvall',
    '824 14': 'Hudiksvall',
    '824 15': 'Hudiksvall',
    '824 21': 'Hudiksvall',
    '824 22': 'Hudiksvall',
    '824 23': 'Hudiksvall',
    '824 24': 'Hudiksvall',
    '824 25': 'Hudiksvall',
    '824 26': 'Hudiksvall',
    '824 27': 'Hudiksvall',
    '824 28': 'Hudiksvall',
    '824 30': 'Hudiksvall',
    '824 33': 'Hudiksvall',
    '824 34': 'Hudiksvall',
    '824 40': 'Hudiksvall',
    '824 41': 'Hudiksvall',
    '824 42': 'Hudiksvall',
    '824 43': 'Hudiksvall',
    '824 50': 'Hudiksvall',
    '824 51': 'Hudiksvall',
    '824 52': 'Hudiksvall',
    '824 80': 'Hudiksvall',
    '824 81': 'Hudiksvall',
    '824 82': 'Hudiksvall',
    '824 83': 'Hudiksvall',
    '824 91': 'Hudiksvall',
    '824 92': 'Hudiksvall',
    '824 93': 'Hudiksvall',
    '824 94': 'Hudiksvall',
    '825 00': 'Iggesund',
    '825 21': 'Iggesund',
    '825 30': 'Iggesund',
    '825 31': 'Iggesund',
    '825 32': 'Iggesund',
    '825 80': 'Iggesund',
    '825 91': 'Iggesund',
    '825 92': 'Njutånger',
    '825 93': 'Njutånger',
    '825 95': 'Enånger',
    '825 96': 'Enånger',
    '802 08': 'Gävle',
    '806 30': 'Gävle',
    '808 00': 'Gävle',
    '808 01': 'Gävle',
    '808 02': 'Gävle',
    '808 04': 'Gävle',
    '808 10': 'Gävle',
    '808 11': 'Gävle',
    '808 14': 'Gävle',
    '808 15': 'Gävle',
    '811 19': 'Järbo',
    '811 20': 'Sandviken',
    '812 04': 'Åshammar',
    '812 19': 'Åshammar',
    '812 20': 'Storvik',
    '813 19': 'Torsåker',
    '813 20': 'Hofors',
    '816 20': 'Ockelbo',
    '817 20': 'Norrsundet',
    '818 19': 'Forsbacka',
    '818 20': 'Valbo',
    '821 19': 'Rengsjö',
    '821 20': 'Bollnäs',
    '821 81': 'Bollnäs',
    '822 20': 'Alfta',
    '822 81': 'Alfta',
    '823 03': 'Segersta',
    '823 20': 'Kilafors',
    '824 20': 'Hudiksvall',
    '825 19': 'Enånger',
    '825 20': 'Iggesund',
    '826 20': 'Söderhamn',
    '826 82': 'Söderhamn',
    '826 85': 'Söderhamn',
    '827 20': 'Ljusdal',
    '828 20': 'Edsbyn',
    '840 10': 'Ljungaverk',
    '840 12': 'Fränsta',
    '841 00': 'Ånge',
    '841 01': 'Ånge',
    '841 03': 'Östavall',
    '841 21': 'Ånge',
    '841 22': 'Ånge',
    '841 23': 'Ånge',
    '841 31': 'Ånge',
    '841 32': 'Ånge',
    '841 33': 'Ånge',
    '841 34': 'Ånge',
    '841 44': 'Alby',
    '841 80': 'Ånge',
    '841 81': 'Ånge',
    '841 91': 'Ånge',
    '841 92': 'Ånge',
    '841 93': 'Östavall',
    '841 94': 'Alby',
    '841 96': 'Erikslund',
    '841 97': 'Erikslund',
    '860 30': 'Sörberge',
    '860 32': 'Fagervik',
    '861 01': 'Timrå',
    '861 21': 'Timrå',
    '861 22': 'Timrå',
    '861 23': 'Timrå',
    '861 24': 'Timrå',
    '861 26': 'Timrå',
    '861 31': 'Timrå',
    '861 32': 'Timrå',
    '861 33': 'Timrå',
    '861 34': 'Timrå',
    '861 35': 'Timrå',
    '861 36': 'Timrå',
    '861 81': 'Timrå',
    '861 82': 'Timrå',
    '861 83': 'Timrå',
    '861 84': 'Timrå',
    '861 91': 'Timrå',
    '860 35': 'Söråker',
    '870 10': 'Älandsbro',
    '870 15': 'Utansjö',
    '871 01': 'Härnösand',
    '871 02': 'Härnösand',
    '871 03': 'Härnösand',
    '871 21': 'Härnösand',
    '871 22': 'Härnösand',
    '871 23': 'Härnösand',
    '871 24': 'Härnösand',
    '871 25': 'Härnösand',
    '871 26': 'Härnösand',
    '871 27': 'Härnösand',
    '871 29': 'Härnösand',
    '871 30': 'Härnösand',
    '871 31': 'Härnösand',
    '871 32': 'Härnösand',
    '871 33': 'Härnösand',
    '871 40': 'Härnösand',
    '871 41': 'Härnösand',
    '871 42': 'Härnösand',
    '871 45': 'Härnösand',
    '871 50': 'Härnösand',
    '871 51': 'Härnösand',
    '871 52': 'Härnösand',
    '871 53': 'Härnösand',
    '871 54': 'Härnösand',
    '871 60': 'Härnösand',
    '871 61': 'Härnösand',
    '871 62': 'Härnösand',
    '871 65': 'Härnösand',
    '871 80': 'Härnösand',
    '871 81': 'Härnösand',
    '871 82': 'Härnösand',
    '871 84': 'Härnösand',
    '871 85': 'Härnösand',
    '871 86': 'Härnösand',
    '871 87': 'Härnösand',
    '871 88': 'Härnösand',
    '871 91': 'Härnösand',
    '871 92': 'Härnösand',
    '871 93': 'Härnösand',
    '871 94': 'Viksjö',
    '850 03': 'Sundsvall',
    '850 06': 'Sundsvall',
    '850 07': 'Sundsvall',
    '850 08': 'Sundsvall',
    '850 09': 'Sundsvall',
    '850 10': 'Sundsvall',
    '850 12': 'Sundsvall',
    '850 13': 'Sundsvall',
    '850 15': 'Sundsvall',
    '851 00': 'Sundsvall',
    '851 02': 'Sundsvall',
    '851 03': 'Sundsvall',
    '851 04': 'Sundsvall',
    '851 05': 'Sundsvall',
    '851 06': 'Sundsvall',
    '851 07': 'Sundsvall',
    '851 10': 'Sundsvall',
    '851 21': 'Sundsvall',
    '851 22': 'Sundsvall',
    '851 23': 'Sundsvall',
    '851 24': 'Sundsvall',
    '851 25': 'Sundsvall',
    '851 70': 'Sundsvall',
    '851 71': 'Sundsvall',
    '851 72': 'Sundsvall',
    '851 73': 'Sundsvall',
    '851 74': 'Sundsvall',
    '851 75': 'Sundsvall',
    '851 76': 'Sundsvall',
    '851 78': 'Sundsvall',
    '851 79': 'Sundsvall',
    '851 80': 'Sundsvall',
    '851 81': 'Sundsvall',
    '851 82': 'Sundsvall',
    '851 83': 'Sundsvall',
    '851 84': 'Sundsvall',
    '851 85': 'Sundsvall',
    '851 86': 'Sundsvall',
    '851 87': 'Sundsvall',
    '851 88': 'Sundsvall',
    '851 89': 'Sundsvall',
    '851 90': 'Sundsvall',
    '851 92': 'Sundsvall',
    '851 93': 'Sundsvall',
    '851 94': 'Sundsvall',
    '851 96': 'Sundsvall',
    '851 98': 'Sundsvall',
    '852 01': 'Sundsvall',
    '852 29': 'Sundsvall',
    '852 30': 'Sundsvall',
    '852 31': 'Sundsvall',
    '852 32': 'Sundsvall',
    '852 34': 'Sundsvall',
    '852 35': 'Sundsvall',
    '852 36': 'Sundsvall',
    '852 37': 'Sundsvall',
    '852 38': 'Sundsvall',
    '852 39': 'Sundsvall',
    '852 40': 'Sundsvall',
    '853 01': 'Sundsvall',
    '853 50': 'Sundsvall',
    '853 51': 'Sundsvall',
    '853 52': 'Sundsvall',
    '853 53': 'Sundsvall',
    '853 56': 'Sundsvall',
    '853 57': 'Sundsvall',
    '854 01': 'Sundsvall',
    '854 60': 'Sundsvall',
    '854 61': 'Sundsvall',
    '854 62': 'Sundsvall',
    '854 63': 'Sundsvall',
    '854 66': 'Sundsvall',
    '854 67': 'Sundsvall',
    '854 68': 'Sundsvall',
    '855 90': 'Sundsvall',
    '856 01': 'Sundsvall',
    '856 30': 'Sundsvall',
    '856 31': 'Sundsvall',
    '856 32': 'Sundsvall',
    '856 33': 'Sundsvall',
    '856 34': 'Sundsvall',
    '856 40': 'Sundsvall',
    '856 41': 'Sundsvall',
    '856 42': 'Sundsvall',
    '856 43': 'Sundsvall',
    '856 44': 'Sundsvall',
    '856 45': 'Sundsvall',
    '856 50': 'Sundsvall',
    '856 51': 'Sundsvall',
    '856 52': 'Sundsvall',
    '856 53': 'Sundsvall',
    '857 30': 'Sundsvall',
    '857 31': 'Sundsvall',
    '857 32': 'Sundsvall',
    '857 33': 'Sundsvall',
    '857 34': 'Sundsvall',
    '857 35': 'Sundsvall',
    '857 40': 'Sundsvall',
    '857 41': 'Sundsvall',
    '857 42': 'Sundsvall',
    '857 50': 'Sundsvall',
    '857 51': 'Sundsvall',
    '857 52': 'Sundsvall',
    '857 53': 'Sundsvall',
    '859 85': 'Sundsvall',
    '859 99': 'Sundsvall',
    '860 13': 'Stöde',
    '860 25': 'Kovland',
    '860 33': 'Bergeforsen',
    '860 40': 'Indal',
    '862 01': 'Kvissleby',
    '862 02': 'Njurunda',
    '862 21': 'Kvissleby',
    '862 22': 'Kvissleby',
    '862 23': 'Kvissleby',
    '862 24': 'Kvissleby',
    '862 31': 'Kvissleby',
    '862 32': 'Kvissleby',
    '862 33': 'Kvissleby',
    '862 34': 'Kvissleby',
    '862 40': 'Njurunda',
    '862 41': 'Njurunda',
    '862 91': 'Kvissleby',
    '862 92': 'Kvissleby',
    '862 94': 'Njurunda',
    '862 95': 'Njurunda',
    '862 96': 'Njurunda',
    '863 21': 'Sundsbruk',
    '863 22': 'Sundsbruk',
    '863 23': 'Sundsbruk',
    '863 31': 'Sundsbruk',
    '863 32': 'Sundsbruk',
    '863 33': 'Sundsbruk',
    '863 34': 'Sundsbruk',
    '863 35': 'Sundsbruk',
    '863 36': 'Sundsbruk',
    '864 01': 'Matfors',
    '864 21': 'Matfors',
    '864 22': 'Matfors',
    '864 23': 'Matfors',
    '864 31': 'Matfors',
    '864 32': 'Matfors',
    '864 33': 'Matfors',
    '864 91': 'Matfors',
    '864 92': 'Matfors',
    '865 01': 'Alnö',
    '865 21': 'Alnö',
    '865 22': 'Alnö',
    '865 31': 'Alnö',
    '865 32': 'Alnö',
    '865 33': 'Alnö',
    '865 51': 'Ankarsvik',
    '865 91': 'Alnö',
    '865 92': 'Alnö',
    '870 16': 'Ramvik',
    '870 30': 'Nordingrå',
    '870 31': 'Mjällom',
    '870 32': 'Ullånger',
    '870 33': 'Docksta',
    '870 52': 'Nyland',
    '872 00': 'Kramfors',
    '872 21': 'Kramfors',
    '872 22': 'Kramfors',
    '872 23': 'Kramfors',
    '872 24': 'Kramfors',
    '872 25': 'Kramfors',
    '872 26': 'Kramfors',
    '872 30': 'Kramfors',
    '872 31': 'Kramfors',
    '872 32': 'Kramfors',
    '872 33': 'Kramfors',
    '872 34': 'Kramfors',
    '872 35': 'Kramfors',
    '872 36': 'Kramfors',
    '872 43': 'Kramfors',
    '872 63': 'Lunde',
    '872 64': 'Sandöverken',
    '872 75': 'Lugnvik',
    '872 80': 'Kramfors',
    '872 91': 'Kramfors',
    '872 92': 'Kramfors',
    '872 93': 'Lunde',
    '872 94': 'Sandöverken',
    '872 95': 'Lugnvik',
    '872 96': 'Bjärtrå',
    '872 97': 'Skog',
    '872 98': 'Noraström',
    '873 21': 'Bollstabruk',
    '873 22': 'Bollstabruk',
    '873 30': 'Bollstabruk',
    '873 40': 'Bollstabruk',
    '873 50': 'Väja',
    '873 80': 'Bollstabruk',
    '873 81': 'Väja',
    '873 91': 'Bollstabruk',
    '873 92': 'Bollstabruk',
    '880 30': 'Näsåker',
    '880 41': 'Edsele',
    '881 01': 'Sollefteå',
    '881 04': 'Sollefteå',
    '881 21': 'Sollefteå',
    '881 22': 'Sollefteå',
    '881 23': 'Sollefteå',
    '881 24': 'Sollefteå',
    '881 25': 'Sollefteå',
    '881 26': 'Sollefteå',
    '881 27': 'Sollefteå',
    '881 30': 'Sollefteå',
    '881 31': 'Sollefteå',
    '881 32': 'Sollefteå',
    '881 33': 'Sollefteå',
    '881 34': 'Sollefteå',
    '881 35': 'Sollefteå',
    '881 40': 'Sollefteå',
    '881 41': 'Sollefteå',
    '881 50': 'Sollefteå',
    '881 51': 'Sollefteå',
    '881 52': 'Sollefteå',
    '881 60': 'Sollefteå',
    '881 80': 'Sollefteå',
    '881 81': 'Sollefteå',
    '881 85': 'Sollefteå',
    '881 86': 'Sollefteå',
    '881 91': 'Sollefteå',
    '881 92': 'Sollefteå',
    '881 93': 'Sollefteå',
    '881 94': 'Sollefteå',
    '881 95': 'Undrom',
    '882 21': 'Långsele',
    '882 22': 'Långsele',
    '882 30': 'Långsele',
    '882 40': 'Långsele',
    '882 50': 'Långsele',
    '882 80': 'Långsele',
    '882 81': 'Långsele',
    '882 91': 'Långsele',
    '882 93': 'Helgum',
    '882 95': 'Graninge',
    '880 37': 'Junsele',
    '890 35': 'Husum',
    '890 37': 'Gideå',
    '890 42': 'Mellansel',
    '890 50': 'Björna',
    '890 51': 'Långviksmon',
    '891 01': 'Örnsköldsvik',
    '891 04': 'Örnsköldsvik',
    '891 17': 'Örnsköldsvik',
    '891 18': 'Örnsköldsvik',
    '891 20': 'Örnsköldsvik',
    '891 21': 'Örnsköldsvik',
    '891 22': 'Örnsköldsvik',
    '891 23': 'Örnsköldsvik',
    '891 24': 'Örnsköldsvik',
    '891 25': 'Örnsköldsvik',
    '891 26': 'Örnsköldsvik',
    '891 27': 'Örnsköldsvik',
    '891 28': 'Örnsköldsvik',
    '891 29': 'Örnsköldsvik',
    '891 30': 'Örnsköldsvik',
    '891 31': 'Örnsköldsvik',
    '891 32': 'Örnsköldsvik',
    '891 33': 'Örnsköldsvik',
    '891 34': 'Örnsköldsvik',
    '891 35': 'Örnsköldsvik',
    '891 36': 'Örnsköldsvik',
    '891 37': 'Örnsköldsvik',
    '891 38': 'Örnsköldsvik',
    '891 40': 'Örnsköldsvik',
    '891 41': 'Örnsköldsvik',
    '891 42': 'Örnsköldsvik',
    '891 43': 'Örnsköldsvik',
    '891 50': 'Örnsköldsvik',
    '891 51': 'Örnsköldsvik',
    '891 55': 'Arnäsvall',
    '891 60': 'Örnsköldsvik',
    '891 61': 'Örnsköldsvik',
    '891 77': 'Järved',
    '891 78': 'Bonässund',
    '891 80': 'Örnsköldsvik',
    '891 82': 'Örnsköldsvik',
    '891 85': 'Örnsköldsvik',
    '891 86': 'Örnsköldsvik',
    '891 88': 'Örnsköldsvik',
    '891 89': 'Örnsköldsvik',
    '891 90': 'Örnsköldsvik',
    '891 92': 'Örnsköldsvik',
    '891 95': 'Arnäsvall',
    '891 96': 'Arnäsvall',
    '892 00': 'Domsjö',
    '892 21': 'Domsjö',
    '892 22': 'Domsjö',
    '892 23': 'Domsjö',
    '892 24': 'Domsjö',
    '892 30': 'Domsjö',
    '892 31': 'Domsjö',
    '892 32': 'Domsjö',
    '892 33': 'Domsjö',
    '892 34': 'Domsjö',
    '892 40': 'Domsjö',
    '892 41': 'Domsjö',
    '892 42': 'Domsjö',
    '892 43': 'Domsjö',
    '892 50': 'Domsjö',
    '892 51': 'Domsjö',
    '892 92': 'Domsjö',
    '893 00': 'Bjästa',
    '893 01': 'Bjästa',
    '893 02': 'Köpmanholmen',
    '893 21': 'Bjästa',
    '893 22': 'Bjästa',
    '893 23': 'Bjästa',
    '893 24': 'Bjästa',
    '893 30': 'Bjästa',
    '893 31': 'Bjästa',
    '893 40': 'Köpmanholmen',
    '893 80': 'Bjästa',
    '893 91': 'Bjästa',
    '893 92': 'Bjästa',
    '893 95': 'Sidensjö',
    '893 96': 'Sidensjö',
    '893 99': 'Ulvöhamn',
    '894 21': 'Själevad',
    '894 22': 'Själevad',
    '894 23': 'Själevad',
    '894 30': 'Själevad',
    '894 31': 'Själevad',
    '894 35': 'Själevad',
    '894 40': 'Överhörnäs',
    '894 41': 'Överhörnäs',
    '894 91': 'Själevad',
    '894 93': 'Överhörnäs',
    '894 95': 'Moliden',
    '895 01': 'Bredbyn',
    '895 21': 'Bredbyn',
    '895 22': 'Bredbyn',
    '895 23': 'Bredbyn',
    '895 30': 'Bredbyn',
    '895 91': 'Bredbyn',
    '895 92': 'Bredbyn',
    '895 93': 'Bredbyn',
    '895 97': 'Skorped',
    '895 98': 'Solberg',
    '841 20': 'Ånge',
    '850 02': 'Sundsvall',
    '850 11': 'Sundsvall',
    '851 08': 'Sundsvall',
    '851 11': 'Sundsvall',
    '858 00': 'Sundsvall',
    '858 10': 'Sundsvall',
    '858 20': 'Sundsvall',
    '858 30': 'Sundsvall',
    '858 40': 'Sundsvall',
    '859 00': 'Sundsvall',
    '859 01': 'Sundsvall',
    '859 02': 'Sundsvall',
    '859 03': 'Sundsvall',
    '859 04': 'Sundsvall',
    '859 05': 'Sundsvall',
    '859 06': 'Sundsvall',
    '859 07': 'Sundsvall',
    '859 08': 'Sundsvall',
    '859 09': 'Sundsvall',
    '859 10': 'Sundsvall',
    '859 11': 'Sundsvall',
    '859 12': 'Sundsvall',
    '859 13': 'Sundsvall',
    '859 14': 'Sundsvall',
    '859 15': 'Sundsvall',
    '859 16': 'Sundsvall',
    '859 17': 'Sundsvall',
    '859 18': 'Sundsvall',
    '859 19': 'Sundsvall',
    '859 20': 'Sundsvall',
    '859 21': 'Sundsvall',
    '859 22': 'Sundsvall',
    '859 23': 'Sundsvall',
    '859 24': 'Sundsvall',
    '859 25': 'Sundsvall',
    '859 26': 'Sundsvall',
    '859 27': 'Sundsvall',
    '859 28': 'Sundsvall',
    '859 29': 'Sundsvall',
    '859 30': 'Sundsvall',
    '859 31': 'Sundsvall',
    '859 32': 'Sundsvall',
    '859 33': 'Sundsvall',
    '859 34': 'Sundsvall',
    '859 35': 'Sundsvall',
    '859 36': 'Sundsvall',
    '859 37': 'Sundsvall',
    '859 38': 'Sundsvall',
    '859 39': 'Sundsvall',
    '859 40': 'Sundsvall',
    '859 41': 'Sundsvall',
    '859 42': 'Sundsvall',
    '859 43': 'Sundsvall',
    '859 44': 'Sundsvall',
    '859 45': 'Sundsvall',
    '859 46': 'Sundsvall',
    '859 47': 'Sundsvall',
    '859 48': 'Sundsvall',
    '859 49': 'Sundsvall',
    '859 50': 'Sundsvall',
    '859 51': 'Sundsvall',
    '859 52': 'Sundsvall',
    '859 53': 'Sundsvall',
    '859 54': 'Sundsvall',
    '859 55': 'Sundsvall',
    '859 56': 'Sundsvall',
    '859 57': 'Sundsvall',
    '859 58': 'Sundsvall',
    '859 59': 'Sundsvall',
    '859 60': 'Sundsvall',
    '859 61': 'Sundsvall',
    '859 62': 'Sundsvall',
    '859 63': 'Sundsvall',
    '859 64': 'Sundsvall',
    '859 65': 'Sundsvall',
    '859 66': 'Sundsvall',
    '859 67': 'Sundsvall',
    '859 68': 'Sundsvall',
    '859 69': 'Sundsvall',
    '859 70': 'Sundsvall',
    '859 71': 'Sundsvall',
    '859 72': 'Sundsvall',
    '859 73': 'Sundsvall',
    '859 74': 'Sundsvall',
    '859 75': 'Sundsvall',
    '859 76': 'Sundsvall',
    '859 77': 'Sundsvall',
    '859 78': 'Sundsvall',
    '859 79': 'Sundsvall',
    '859 80': 'Sundsvall',
    '859 81': 'Sundsvall',
    '859 82': 'Sundsvall',
    '859 83': 'Sundsvall',
    '859 84': 'Sundsvall',
    '859 86': 'Sundsvall',
    '859 87': 'Sundsvall',
    '859 88': 'Sundsvall',
    '859 89': 'Sundsvall',
    '859 90': 'Sundsvall',
    '859 91': 'Sundsvall',
    '859 92': 'Sundsvall',
    '859 93': 'Sundsvall',
    '859 94': 'Sundsvall',
    '859 95': 'Sundsvall',
    '859 96': 'Sundsvall',
    '859 97': 'Sundsvall',
    '859 98': 'Sundsvall',
    '861 20': 'Timrå',
    '862 20': 'Kvissleby',
    '863 20': 'Sundsbruk',
    '864 20': 'Matfors',
    '865 20': 'Alnö',
    '871 20': 'Härnösand',
    '872 20': 'Kramfors',
    '873 20': 'Bollstabruk',
    '881 20': 'Sollefteå',
    '881 84': 'Sollefteå',
    '882 01': 'Långsele',
    '882 20': 'Långsele',
    '891 15': 'Örnsköldsvik',
    '891 16': 'Örnsköldsvik',
    '891 39': 'Örnsköldsvik',
    '892 20': 'Domsjö',
    '893 20': 'Bjästa',
    '894 20': 'Själevad',
    '895 20': 'Bredbyn',
    '840 70': 'Hammarstrand',
    '840 73': 'Bispgården',
    '860 41': 'Liden',
    '840 13': 'Torpshammar',
    '840 50': 'Gällö',
    '840 58': 'Pilgrimstad',
    '840 60': 'Bräcke',
    '840 64': 'Kälarne',
    '840 67': 'Sörbygden',
    '830 43': 'Ås',
    '830 44': 'Nälden',
    '830 67': 'Valsjöbyn',
    '835 01': 'Krokom',
    '835 21': 'Krokom',
    '835 22': 'Krokom',
    '835 31': 'Krokom',
    '835 32': 'Krokom',
    '835 33': 'Krokom',
    '835 41': 'Dvärsätt',
    '835 80': 'Krokom',
    '835 91': 'Krokom',
    '835 92': 'Krokom',
    '835 94': 'Aspås',
    '830 70': 'Hammerdal',
    '830 80': 'Hoting',
    '830 86': 'Lidsjöberg',
    '830 90': 'Gäddede',
    '830 93': 'Stora Blåsjön',
    '833 01': 'Strömsund',
    '833 21': 'Strömsund',
    '833 22': 'Strömsund',
    '833 23': 'Strömsund',
    '833 24': 'Strömsund',
    '833 26': 'Strömsund',
    '833 31': 'Strömsund',
    '833 32': 'Strömsund',
    '833 33': 'Strömsund',
    '833 34': 'Strömsund',
    '833 35': 'Strömsund',
    '833 80': 'Strömsund',
    '833 81': 'Strömsund',
    '833 82': 'Strömsund',
    '833 92': 'Strömsund',
    '833 93': 'Strömsund',
    '833 94': 'Strömsund',
    '833 95': 'Strömsund',
    '833 96': 'Strömsund',
    '880 40': 'Ramsele',
    '880 50': 'Backe',
    '880 51': 'Rossön',
    '830 01': 'Hallen',
    '830 02': 'Mattmar',
    '830 04': 'Mörsil',
    '830 05': 'Järpen',
    '830 10': 'Undersåker',
    '830 12': 'Vålådalen',
    '830 13': 'Åre',
    '830 15': 'Duved',
    '830 19': 'Storlien',
    '830 47': 'Trångsviken',
    '830 51': 'Offerdal',
    '830 24': 'Oviken',
    '840 24': 'Överturingen',
    '840 30': 'Rätan',
    '840 31': 'Åsarna',
    '840 32': 'Klövsjö',
    '840 34': 'Storsjö Kapell',
    '840 35': 'Ljungdalen',
    '820 46': 'Ramsjö',
    '840 80': 'Lillhärdal',
    '840 85': 'Lofsdalen',
    '840 90': 'Ytterhogdal',
    '840 91': 'Vemhån',
    '840 92': 'Vemdalen',
    '840 93': 'Hede',
    '840 94': 'Tännäs',
    '840 95': 'Funäsdalen',
    '840 96': 'Ljusnedal',
    '840 97': 'Bruksvallarna',
    '840 98': 'Tänndalen',
    '842 01': 'Sveg',
    '842 21': 'Sveg',
    '842 22': 'Sveg',
    '842 31': 'Sveg',
    '842 32': 'Sveg',
    '842 33': 'Sveg',
    '842 80': 'Sveg',
    '842 91': 'Sveg',
    '842 92': 'Sveg',
    '842 93': 'Sveg',
    '842 94': 'Sveg',
    '842 99': 'Sveg',
    '830 21': 'Tandsbyn',
    '830 22': 'Fåker',
    '830 23': 'Hackås',
    '830 30': 'Lit',
    '830 60': 'Föllinge',
    '830 76': 'Stugun',
    '831 00': 'Östersund',
    '831 01': 'Östersund',
    '831 02': 'Östersund',
    '831 03': 'Östersund',
    '831 05': 'Östersund',
    '831 06': 'Östersund',
    '831 07': 'Östersund',
    '831 08': 'Östersund',
    '831 09': 'Östersund',
    '831 17': 'Östersund',
    '831 21': 'Östersund',
    '831 22': 'Östersund',
    '831 23': 'Östersund',
    '831 24': 'Östersund',
    '831 25': 'Östersund',
    '831 26': 'Östersund',
    '831 27': 'Östersund',
    '831 28': 'Östersund',
    '831 30': 'Östersund',
    '831 31': 'Östersund',
    '831 33': 'Östersund',
    '831 34': 'Östersund',
    '831 35': 'Östersund',
    '831 36': 'Östersund',
    '831 37': 'Östersund',
    '831 38': 'Östersund',
    '831 40': 'Östersund',
    '831 41': 'Östersund',
    '831 42': 'Östersund',
    '831 43': 'Östersund',
    '831 45': 'Östersund',
    '831 46': 'Östersund',
    '831 47': 'Östersund',
    '831 48': 'Östersund',
    '831 51': 'Östersund',
    '831 52': 'Östersund',
    '831 55': 'Östersund',
    '831 61': 'Östersund',
    '831 62': 'Östersund',
    '831 71': 'Östersund',
    '831 72': 'Östersund',
    '831 73': 'Östersund',
    '831 74': 'Östersund',
    '831 75': 'Östersund',
    '831 76': 'Östersund',
    '831 77': 'Östersund',
    '831 80': 'Östersund',
    '831 81': 'Östersund',
    '831 82': 'Östersund',
    '831 83': 'Östersund',
    '831 84': 'Östersund',
    '831 85': 'Östersund',
    '831 86': 'Östersund',
    '831 87': 'Östersund',
    '831 88': 'Östersund',
    '831 89': 'Östersund',
    '831 91': 'Östersund',
    '831 92': 'Östersund',
    '831 95': 'Östersund',
    '832 01': 'Frösön',
    '832 21': 'Frösön',
    '832 23': 'Frösön',
    '832 41': 'Frösön',
    '832 42': 'Frösön',
    '832 43': 'Frösön',
    '832 44': 'Frösön',
    '832 45': 'Frösön',
    '832 46': 'Frösön',
    '832 47': 'Frösön',
    '832 51': 'Frösön',
    '832 53': 'Frösön',
    '832 54': 'Frösön',
    '832 55': 'Frösön',
    '832 93': 'Frösön',
    '832 94': 'Orrviken',
    '832 96': 'Frösön',
    '834 01': 'Brunflo',
    '834 21': 'Brunflo',
    '834 31': 'Brunflo',
    '834 32': 'Brunflo',
    '834 33': 'Brunflo',
    '834 34': 'Brunflo',
    '834 97': 'Brunflo',
    '834 98': 'Brunflo',
    '839 88': 'Östersund',
    '840 40': 'Svenstavik',
    '831 19': 'Östersund',
    '831 20': 'Östersund',
    '831 29': 'Östersund',
    '831 32': 'Östersund',
    '832 00': 'Frösön',
    '832 20': 'Frösön',
    '833 17': 'Strömsund',
    '833 18': 'Strömsund',
    '833 19': 'Strömsund',
    '833 20': 'Strömsund',
    '833 27': 'Strömsund',
    '833 83': 'Strömsund',
    '833 87': 'Strömsund',
    '834 20': 'Brunflo',
    '835 20': 'Krokom',
    '838 80': 'Hackås',
    '839 01': 'Östersund',
    '839 02': 'Östersund',
    '839 03': 'Östersund',
    '839 04': 'Östersund',
    '839 05': 'Östersund',
    '839 06': 'Östersund',
    '839 07': 'Östersund',
    '839 08': 'Östersund',
    '839 09': 'Östersund',
    '839 10': 'Östersund',
    '839 11': 'Östersund',
    '839 12': 'Östersund',
    '839 13': 'Östersund',
    '839 14': 'Östersund',
    '839 15': 'Östersund',
    '839 16': 'Östersund',
    '839 17': 'Östersund',
    '839 18': 'Östersund',
    '839 19': 'Östersund',
    '839 20': 'Östersund',
    '839 21': 'Östersund',
    '839 22': 'Östersund',
    '839 23': 'Östersund',
    '839 24': 'Östersund',
    '839 25': 'Östersund',
    '839 26': 'Östersund',
    '839 27': 'Östersund',
    '839 28': 'Östersund',
    '839 29': 'Östersund',
    '839 30': 'Östersund',
    '839 31': 'Östersund',
    '839 32': 'Östersund',
    '839 33': 'Östersund',
    '839 34': 'Östersund',
    '839 35': 'Östersund',
    '839 36': 'Östersund',
    '839 37': 'Östersund',
    '839 38': 'Östersund',
    '839 39': 'Östersund',
    '839 40': 'Östersund',
    '839 41': 'Östersund',
    '839 42': 'Östersund',
    '839 43': 'Östersund',
    '839 44': 'Östersund',
    '839 45': 'Östersund',
    '839 46': 'Östersund',
    '839 47': 'Östersund',
    '839 48': 'Östersund',
    '839 49': 'Östersund',
    '839 50': 'Östersund',
    '839 85': 'Östersund',
    '839 86': 'Östersund',
    '839 87': 'Östersund',
    '842 20': 'Sveg',
    '121 07': 'Stockholm-globen',
    '121 25': 'Stockholm-globen',
    '132 20': 'Saltsjö-boo',
    '136 37': 'Handen',
    '136 38': 'Handen',
    '148 40': 'Segersäng',
    '190 40': 'Stockholm-arlanda',
    '190 45': 'Stockholm-Arlanda',
    '190 46': 'Stockholm-Arlanda',
    '190 47': 'Stockholm-Arlanda',
    '190 50': 'Stockholm-Arlanda',
    '190 60': 'Stockholm-Arlanda',
    '195 05': 'Arlandastad',
    '195 60': 'Arlandastad',
    '195 61': 'Arlandastad',
    '195 87': 'Stockholm',
    '218 45': 'Vintrie',
    '230 32': 'Malmö-Sturup',
    '230 40': 'Bara',
    '230 41': 'Klågerup',
    '230 53': 'Alnarp',
    '261 72': 'Häljarp',
    '261 73': 'Häljarp',
    '266 20': 'Munka-ljungby',
    '271 71': 'Nybrostrand',
    '427 20': 'Billdal',
    '427 24': 'Billdal',
    '622 30': 'Gotlands Tofta',
    '622 32': 'Gotlands Tofta',
    '622 65': 'Gotlands Tofta',
    '622 66': 'Gotlands Tofta',
    '622 70': 'Gotlands Tofta',
    '624 66': 'Fårö',
    '624 67': 'Fårö',
    '734 94': 'Strömsholm',
    '749 50': 'Ekolsund',
    '772 20': 'Grängesberg',
  },
}

export const getCityFromZip = (zipCode: number | string, country: string = 'dk') => {
  const countryMap = zipCodeMap[country]
  let cityName: string | false = false

  if (countryMap) {
    cityName = countryMap[`${zipCode}`]
  }

  return cityName
}
