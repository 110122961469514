import ActionTypeKeys from './ActionTypeKeys'
import { IUserAuthData } from '../reducers/user/initialState'

export interface ISaveAuthDataAction {
  type: ActionTypeKeys.SAVE_AUTH_DATA
  payload: IUserAuthData
}

export const saveAuthData = (payload: IUserAuthData): ISaveAuthDataAction => ({
  type: ActionTypeKeys.SAVE_AUTH_DATA,
  payload,
})

export interface ISaveUserTokenAction {
  type: ActionTypeKeys.SAVE_USER_TOKEN
  payload: string
}

export const saveUserToken = (payload: string): ISaveUserTokenAction => ({
  type: ActionTypeKeys.SAVE_USER_TOKEN,
  payload,
})

export type IClearUserTokenAction = () => ISaveUserTokenAction
export const clearUserToken: IClearUserTokenAction = () => saveUserToken('')
