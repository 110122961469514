let uniqueIdCounter = 1

const getUniqueId = (prefix: string = '') => {
  let prefixString = prefix
  const date = new Date()
  const partA = date.getMilliseconds() * date.getMilliseconds()
  // const partB = `${date.getMilliseconds() * date.getSeconds()}-`;

  uniqueIdCounter += 1

  if (prefixString) {
    prefixString += '-'
  }

  // return `${prefixString}${partA}-${partB}${uniqueIdCounter}`;
  return `${prefixString}${partA}-${uniqueIdCounter}`
}

export default getUniqueId
