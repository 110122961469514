import React from 'react'
import { ILoginRequest } from '@omnicar/sam-types'
import EmailField from '../Field/EmailField/EmailField'
import PasswordField from '../Field/PasswordField/PasswordField'
import { Formik, Form, FormikProps } from 'formik'
import { login } from '../../api/api'
import { Dispatch, connect } from 'react-redux'
import ActionTypes from '../../actions/ActionTypes'
import { saveAuthData, ISaveAuthDataAction } from '../../actions/userActions'
import './Login.css'
import { homePagePath } from '../../routes/paths'
import history from '../../utils/history'
import { IUserAuthData } from '../../reducers/user/initialState'
import { saveRefreshToken } from 'utils/auth'

interface IProps {
  saveAuthData: (data: IUserAuthData) => ISaveAuthDataAction
}

const initialValues: ILoginRequest = { username: '', password: '' }

class LogIn extends React.Component<IProps> {
  state = {
    loading: false,
  }

  render() {
    const { loading } = this.state

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ handleChange, values }: FormikProps<ILoginRequest>) => (
          <Form className="LoginForm__inner">
            <EmailField
              className="login__email"
              name="username"
              disabled={loading}
              onChange={handleChange}
              title="Email"
              value={values.username}
            />
            <PasswordField
              className="login__password"
              name="password"
              disabled={loading}
              onChange={handleChange}
              title="Password"
              value={values.password}
            />
            <button className="PPProviderDetails__submitbutton" type="submit" disabled={loading}>
              Log in
            </button>
          </Form>
        )}
      </Formik>
    )
  }

  private handleSubmit = (values: ILoginRequest) => {
    this.setState({ loading: true }, async () => {
      const response = await login(values)
      this.setState({ loading: false })

      if (response.data && response.data.token && response.data.refreshToken) {
        const { token, isDev, refreshToken } = response.data
        this.props.saveAuthData({ token, isDev })
        saveRefreshToken(refreshToken)
        history.replace(homePagePath)
      }
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  saveAuthData: (data: IUserAuthData) => dispatch(saveAuthData(data)),
})

export default connect(null, mapDispatchToProps)(LogIn)
