enum ActionTypeKeys {
  // ResetAction - will reset store to the initalState
  STORE_RESET = 'STORE_RESET',

  // Application Actions
  APPLICATION_UPDATE = 'APPLICATION_UPDATE',
  APPLICATION_RESET = 'APPLICATION_RESET',
  APPLICATION_TRANSACTION_UPDATE = 'APPLICATION_TRANSACTION_UPDATE',
  APPLICATION_TRANSACTION_RESET = 'APPLICATION_TRANSACTION_RESET',
  APPLICATION_NETWORK_ERROR_UPDATE = 'APPLICATION_NETWORK_ERROR_UPDATE',
  APPLICATION_NETWORK_ERROR_RESET = 'APPLICATION_NETWORK_ERROR_RESET',

  // Contract Option Actions
  CONTRACT_OPTION_DETAILS_RESET = 'CONTRACT_OPTION_DETAILS_RESET',
  CONTRACT_OPTION_DETAILS_UPDATE = 'CONTRACT_OPTION_DETAILS_UPDATE',
  CONTRACT_OPTION_ID_UPDATE = 'CONTRACT_OPTION_ID_UPDATE',
  CONTRACT_OPTION_LIST_RESET = 'CONTRACT_OPTION_LIST_RESET',
  CONTRACT_OPTION_LIST_UPDATE = 'CONTRACT_OPTION_LIST_UPDATE',

  // Contract Provider Actions
  CONTRACT_PROVIDER_DETAILS_RESET = 'CONTRACT_PROVIDER_DETAILS_RESET',
  CONTRACT_PROVIDER_DETAILS_UPDATE = 'CONTRACT_PROVIDER_DETAILS_UPDATE',
  CONTRACT_PROVIDER_ID_UPDATE = 'CONTRACT_PROVIDER_ID_UPDATE',
  CONTRACT_PROVIDER_LIST_RESET = 'CONTRACT_PROVIDER_LIST_RESET',
  CONTRACT_PROVIDER_LIST_UPDATE = 'CONTRACT_PROVIDER_LIST_UPDATE',

  // Contract Template Actions
  CONTRACT_TEMPLATE_DETAILS_RESET = 'CONTRACT_TEMPLATE_DETAILS_RESET',
  CONTRACT_TEMPLATE_DETAILS_UPDATE = 'CONTRACT_TEMPLATE_DETAILS_UPDATE',
  CONTRACT_TEMPLATE_ID_UPDATE = 'CONTRACT_TEMPLATE_ID_UPDATE',
  CONTRACT_TEMPLATE_LIST_RESET = 'CONTRACT_TEMPLATE_LIST_RESET',
  CONTRACT_TEMPLATE_LIST_UPDATE = 'CONTRACT_TEMPLATE_LIST_UPDATE',

  // Country Actions
  COUNTRY_LIST_UPDATE = 'COUNTRY_LIST_UPDATE',

  // Stripe Actions
  STRIPE_DETAILS_RESET = 'STRIPE_DETAILS_RESET',
  STRIPE_DETAILS_UPDATE = 'STRIPE_DETAILS_UPDATE',

  // Provider Page Actions
  PROVIDERPAGE_RESET = 'PROVIDERPAGE_RESET',
  PROVIDERPAGE_ROUTE_UPDATE = 'PROVIDERPAGE_ROUTE_UPDATE',
  PROVIDERPAGE_UPDATE = 'PROVIDERPAGE_UPDATE',

  // User actions
  USER_LIST_UPDATE = 'USER_LIST_UPDATE',

  // User auth actions
  SAVE_AUTH_DATA = 'SAVE_AUTH_DATA',
  SAVE_USER_TOKEN = 'SAVE_USER_TOKEN',

  // Outage handling
  SET_OUTAGE = 'SET_OUTAGE',
}

export default ActionTypeKeys
