import React, { ChangeEvent } from 'react'
import Select, { ValueType } from 'react-select'
import { BaseOption as Option } from 'types'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './SelectField.css'
import { Field, FieldProps } from 'formik'

// @NOTE This component only takes string or numbers for now
// we also want it to be able to handle

export type TSelecetValue = number | string | null | number[] | string[]
type TValue = TSelecetValue | undefined

interface IProps extends IWithStyledFieldProps {
  className: string
  formik?: boolean
  defaultValue: TSelecetValue
  disabled?: boolean
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement> | any) => void
  onValueChange?: (e: ChangeEvent<HTMLInputElement> | any) => void
  onFieldChange?: (value: TSelecetValue) => void
  options: Option[]
  placeholder?: string
  value?: TValue
  selectProps?: { clearable: boolean }
  isMulti?: boolean
}

interface IState {
  options: Option[]
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class SelectField extends React.Component<IProps, IState> {
  state = {
    options: [],
  }

  static defaultProps = {
    selectProps: { clearable: false },
  }

  // tslint:disable jsx-no-lambda
  public render() {
    const {
      options,
      disabled,
      defaultValue,
      name,
      onBlur,
      selectProps,
      onChange,
      value,
      placeholder,
      formik,
      isMulti,
      onFieldChange,
    } = this.props

    // @TODO refactor this - the formik prop is just a hack
    return (
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          return (
            <Select
              className="Select"
              classNamePrefix="Select"
              {...selectProps}
              options={options}
              isMulti={isMulti}
              placeholder={placeholder}
              value={this.formatValue(field.value != null ? field.value : value)}
              isDisabled={disabled}
              inputProps={{ onBlur }}
              onChange={(selectedOption: ValueType<Option, any>) => {
                const option = selectedOption as Option
                if (formik === false) {
                  onChange && onChange(option)
                } else {
                  if (selectedOption) {
                    form.setFieldValue(field.name, option.value)
                    onFieldChange && onFieldChange(option.value)
                  } else {
                    form.setFieldValue(field.name, defaultValue)
                    onFieldChange && onFieldChange(defaultValue)
                  }
                }
              }}
            />
          )
        }}
      </Field>
    )
  }

  private formatValue = (value: TValue): Option | Option[] | undefined => {
    const { options } = this.props
    if (typeof value === 'object' && value) {
      return options.filter((e) => value.some((v: string | number) => v === e.value))
    } else {
      return options.find((e) => e.value === value)
    }
  }
}

export default withStyledField(SelectField, 'SelectField')
