import { PriceSpecification } from '@omnicar/sam-types'
import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredString, requiredNumber } from './validations'

// Default Values
export const priceSpecificationValueDefaults: PriceSpecification = {
  currency: 'DKK',
  price: 0,
  priceWith3Decimals: 0,
  priceInclVat: 0,
  vatShare: 0,
}

// API Model
const model: IApiModel = {
  name: 'priceSpecificationModel',
  fields: [
    {
      name: 'price',
      uiType: 'number',
      title: 'Price',
      validation: [requiredNumber],
    },
    {
      name: 'priceWith3Decimals',
      uiType: 'number',
      title: 'Price',
      validation: [requiredNumber],
    },
    {
      name: 'priceInclVat',
      uiType: 'number',
      title: 'Incl. VAT',
      validation: [requiredNumber],
    },
    {
      name: 'vatShare',
      uiType: 'number',
      title: 'VAT Share',
      validation: [requiredString],
    },
    {
      name: 'currency',
      uiType: 'string',
      title: 'Currency',
      validation: [requiredString],
    },
  ],
  view: {},
}

export const priceSpecificationModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<PriceSpecification>(view, viewContext, model, priceSpecificationValueDefaults)
