import configureStore from '../store/configureStore'
import browserHistory from './history'
import { persistStore } from 'redux-persist'

const store = configureStore(browserHistory)
export const storePersistor = persistStore(store)

export default store

// Cypress hooks
declare global {
  /* tslint:disable */
  interface Window {
    Cypress: any
    store: typeof store
  }
  /* tslint:enable */
}

if (window && window.Cypress) {
  window.store = store
}
