import ActionTypeKeys from '../../actions/ActionTypeKeys'
import ActionTypes from '../../actions/ActionTypes'

import initialState from './initialState'

export default (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.SAVE_AUTH_DATA:
      return { ...state, authData: { ...action.payload } }
    case ActionTypeKeys.SAVE_USER_TOKEN:
      return { ...state, authData: { ...state.authData, token: action.payload } }
  }

  return state
}
