import Cookies from 'js-cookie'
import { refreshTokens } from 'api/api'
import { setTokenRefreshStarted } from './localStorage'
import history from './history'
import { loginPagePath } from 'routes/paths'
import { saveAuthData } from 'actions/userActions'
import { storeReset } from 'actions/storeActions'
import store from './store'

const REFRESH_TOKEN_KEY = 'mbnhDSdlk32lkopSBhsjRmD' // for cookies
const REFRESH_TOKEN_DURATION = 5 // 5 days (for cookies)
const REFRESH_TOKEN_INTERVAL = 55 * 60 * 1000 // 55 minutes

export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN_KEY)

export const handleRefreshToken = async () => {
  const currentRefreshToken = getRefreshToken()
  if (!currentRefreshToken) {
    clearTokens()
    return
  }
  const response = await refreshTokens(currentRefreshToken)
  const { token, refreshToken, isDev } = response.data || {}

  if (response.statusCode === 200 && token && refreshToken) {
    // save tokens and user info
    store.dispatch(saveAuthData({ token, isDev }))
    saveRefreshToken(refreshToken)
    setTokenRefreshStarted(0)
  } else {
    clearTokens()
  }
}
/**
 * Saves refresh-token to cookies and creates a timer to refresh it
 * @param token
 */
export const saveRefreshToken = (token: string) => {
  Cookies.set(REFRESH_TOKEN_KEY, token, {
    expires: REFRESH_TOKEN_DURATION,
    secure: true,
  })
}

export const setRefreshTokenInterval = () => setInterval(handleRefreshToken, REFRESH_TOKEN_INTERVAL)

/**
 * Clears refresh-token and access-token from cookies/localStorage/redux.
 * Then clears the timer
 */
export const clearTokens = () => {
  // clear tokens and store
  Cookies.remove(REFRESH_TOKEN_KEY)
  store.dispatch(storeReset())
  // clear refreshing date
  setTokenRefreshStarted(0)
  // redirect to login
  history.replace(loginPagePath)
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
