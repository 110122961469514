import { TIsoCountry } from '@omnicar/sam-types'
import {
  ICheckIfProviderConnectedRequestFromClient,
  ICheckIfProviderConnectedResponseToClient,
} from '@omnicar/sam-types/types/v4PricingTool/dealerAndProvider'
import React from 'react'
import ActionButton from '../ActionButton/ActionButton'
import './index.css'
import { v4PTCheckIfProviderConnected } from '../../api/api'
import ProviderConnectAddToV4Dialog from './ProviderConnectAddToV4Dialog'

interface IProps {
  className?: string
  isoCountry: TIsoCountry
  label?: string
  edit: boolean
  providerId: number | null
  providerName: string
  v4ProviderId: number | null
}

interface IState {
  v4DealerIdFromV4: number | null
  v4DealerNameFromV4: string
  isV4InfoUndefined: boolean
  isDialogOpen: boolean
}

const initialState: IState = {
  v4DealerIdFromV4: null,
  v4DealerNameFromV4: '',
  isV4InfoUndefined: true,
  isDialogOpen: false,
}

export const dealerIdAndNameToJSX = (
  v4DealerIdFromV4: number | null,
  v4DealerNameFromV4: string,
  isV4InfoUndefined: boolean,
): JSX.Element => {
  if (isV4InfoUndefined) {
    return <i>-- Loading... --</i>
  }

  return (
    <>
      {v4DealerIdFromV4 ? (
        <span className={'ConnectProvider__text_v4_dealer_is_connected'}>
          {v4DealerIdFromV4} - {v4DealerNameFromV4}
        </span>
      ) : (
        <span className={'ConnectProvider__text_no_v4_dealer_connected'}>-- No V4 Dealer connected --</span>
      )}
    </>
  )
}

class ProviderConnectAddToV4ButtonField extends React.Component<IProps, IState> {
  state = initialState

  componentDidMount = async () => {
    await this.fetchAndSetConnectedDealer()
  }

  render() {
    const { isoCountry, label, edit, providerId, providerName } = this.props
    const { isDialogOpen, v4DealerNameFromV4, v4DealerIdFromV4, isV4InfoUndefined } = this.state

    const isConnected = !!v4DealerIdFromV4
    const isEditing = edit

    return (
      <>
        <label className={'Field__title SelectField__title'}>{label || 'Connected Dealer ID'}</label>
        {dealerIdAndNameToJSX(v4DealerIdFromV4, v4DealerNameFromV4, isV4InfoUndefined)}
        {isConnected || !isEditing ? (
          <>
            {isEditing && (
              <ActionButton
                className="ConnectProvider__connect_provider_v4_edit_button"
                onClick={this.openDialogConnectProviderAddToV4}
                text="Edit connected V4 Dealer ..."
              />
            )}
          </>
        ) : (
          <ActionButton
            className="ConnectProvider__connect_provider_add_to_v4_button"
            onClick={this.openDialogConnectProviderAddToV4}
            text="Connect Provider / Add to V4 ..."
          />
        )}
        <ProviderConnectAddToV4Dialog
          isoCountry={isoCountry}
          samProviderId={providerId || 0}
          samProviderName={providerName || ''}
          isOpen={isDialogOpen}
          onClose={this.closeDialogConnectProviderAddToV4}
        />
      </>
    )
  }

  fetchAndSetConnectedDealer = async () => {
    const { providerId, isoCountry } = this.props
    this.setState({ isV4InfoUndefined: true, v4DealerNameFromV4: '', v4DealerIdFromV4: null })

    const request: ICheckIfProviderConnectedRequestFromClient = { isoCountry, providerId: providerId! }
    const response = await v4PTCheckIfProviderConnected(request)

    let v4DealerName = 'n/a'
    let v4DealerId: number | null = null

    if (response?.data) {
      const reply: ICheckIfProviderConnectedResponseToClient = response.data

      v4DealerId = reply?.v4DealerId
      v4DealerName = reply?.v4DealerName || ''
    }

    this.setState({ isV4InfoUndefined: false, v4DealerNameFromV4: v4DealerName, v4DealerIdFromV4: v4DealerId })
  }

  /**
   * Open Dialog-"ConnectProviderAddToV4".
   */
  openDialogConnectProviderAddToV4 = () => {
    this.setState({ isDialogOpen: true })
  }

  /**
   * Open Dialog-"ConnectProviderAddToV4".
   */
  closeDialogConnectProviderAddToV4 = async () => {
    this.setState({ isDialogOpen: false })
    await this.fetchAndSetConnectedDealer()
  }
}

export default ProviderConnectAddToV4ButtonField
