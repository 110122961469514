import IApiModel, { IApiField, createModel } from './apiModel'
import { IStripeVerification } from '@omnicar/sam-types'

// Default Values
export const stripeVerificationDefaults: IStripeVerification = {
  disabled_reason: null,
  due_by: null,
  fields_needed: [],
}

// API Model
const model: IApiModel = {
  name: 'stripeDateOfBirthModel',
  fields: [
    {
      name: 'disabled_reason',
      uiType: 'string',
      title: 'Disabled Reason',
    },
    {
      name: 'due_by',
      uiType: 'string',
      title: 'Due By',
    },
    {
      name: 'fields_needed',
      uiType: 'string',
      title: 'Fields Missing',
    },
  ],
  view: {},
}

export const stripeVerificationModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeVerification>(view, viewContext, model, stripeVerificationDefaults)
