import React, { ChangeEvent } from 'react'
import { IApiErrors } from '../../../apiModels/apiModel'
import { IContactPersonResponse } from '@omnicar/sam-types'
import FormSection from '../../FormSection/FormSection'
import Address from '../Address/Address'
import StringField from '../../Field/StringField/StringField'
import EmailField from '../../Field/EmailField/EmailField'
import { BaseOption } from 'types'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean | string
  value: IContactPersonResponse
  countryOptions: BaseOption[]
  countryReadonly?: boolean
}

const ContactPerson: React.SFC<IProps> = ({
  edit,
  errors = {},
  name,
  title,
  value,
  onBlur,
  onChange,
  countryOptions,
  countryReadonly,
}) => (
  <div className="ContactPerson">
    <FormSection
      name="general"
      classNamePrefix="ContactPerson"
      title={title ? (title === true ? 'Contact Person' : title) : ''}
    >
      <StringField
        className="ContactPerson__name"
        disabled={!edit}
        errors={errors.name}
        locked={!edit}
        name={name ? `${name}.name` : 'name'}
        onBlur={onBlur}
        onChange={onChange}
        size={50}
        title="Name"
        value={value.name}
      />
      <EmailField
        className="ContactPerson__email"
        disabled={!edit}
        errors={errors.email}
        locked={!edit}
        name={name ? `${name}.email` : 'email'}
        onBlur={onBlur}
        onChange={onChange}
        size={35}
        title="Email"
        value={value.email}
      />
      <StringField
        className="ContactPerson__phone"
        disabled={!edit}
        errors={errors.phone}
        locked={!edit}
        name={name ? `${name}.phone` : 'phone'}
        onBlur={onBlur}
        onChange={onChange}
        size={20}
        title="Phone"
        value={value.phone}
      />
      <Address
        edit={edit}
        countryOptions={countryOptions}
        countryReadonly={countryReadonly}
        errors={errors.address}
        name={name ? `${name}.address` : 'address'}
        onBlur={onBlur}
        onChange={onChange}
        title={true}
        value={value.address}
      />
    </FormSection>
  </div>
)

export default ContactPerson
