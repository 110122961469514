import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core'
import { PaymentGateway, TIsoCountry } from '@omnicar/sam-types'
import React from 'react'
import './index.css'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Check as CheckIcon, CloseTwoTone as CloseIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { debugPrint } from '../../utils/index'

const IS_DEBUG_PRINT = false // For debugging puposes, 'false' is default.

const gatewayItems: IGatewayItem[] = [
  { gateway: 'Stripe', descr: 'CustomerSubscription' },
  { gateway: 'V4', descr: 'DealerPaid' },
  { gateway: 'B2B', descr: 'Business-to-Business' },
]

interface IOwnProps {
  isOpen: boolean
  paymentGateways: PaymentGateway[]
  isoCountry: TIsoCountry
  providerId: number
  providerName: string
  onClose: () => void
  callbackSetFieldValue: any
}

type TProps = IOwnProps

interface IState {
  B2B: boolean
  Stripe: boolean
  V4: boolean
}

const initialState: IState = {
  B2B: false,
  Stripe: false,
  V4: false,
}

interface IGatewayItem {
  gateway: PaymentGateway
  descr: string
}

class PaymentGatewaysDialog extends React.Component<TProps, IState> {
  state = initialState

  componentDidMount = async () => {
    this.setToInitialStates()
  }

  setToInitialStates = () => {
    const isChecked: IState = { ...initialState }

    gatewayItems.forEach((item: IGatewayItem) => {
      const gateway = item.gateway

      if (this.props.paymentGateways.includes(gateway)) {
        isChecked[gateway] = true
      }
    })

    this.setState(isChecked)
  }

  render = () => {
    const { isOpen, isoCountry } = this.props
    const { handleOKButton, handleCancelButton } = this

    return (
      <Dialog
        className={classNames('ConnectProvider__dialog_container')}
        fullWidth={false}
        open={isOpen}
        onClose={handleCancelButton}
      >
        <DialogTitle>Edit Payment Gateways ({isoCountry})</DialogTitle>
        <DialogContent className={'ConnectProvider__dialog_content_container'}>
          {this.renderSAMProviderInfo()}
          {this.renderCheckboxes()}
        </DialogContent>
        <DialogActions>
          <Button
            className={classNames('ConnectProvider__button_in_dialog')}
            variant="contained"
            onClick={handleOKButton}
          >
            <CheckIcon className={'ConnectProvider__icon'} />
            OK
          </Button>
          <Button
            className={classNames('ConnectProvider__button_in_dialog')}
            variant="contained"
            onClick={handleCancelButton}
          >
            <CloseIcon className={'ConnectProvider__icon'} />
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderSAMProviderInfo = (): JSX.Element => {
    const { providerId, providerName } = this.props

    return (
      <div>
        <Paper className="ConnectProvider__dialog_part_paper" elevation={1}>
          <label className={'Field__title SelectField__title'}>JustGO Provider ID - Name:</label>
          <b>
            {providerId} - {providerName}
          </b>
        </Paper>
      </div>
    )
  }

  renderCheckboxes = (): JSX.Element => {
    const jsxCheckboxes: JSX.Element[] = []
    debugPrint(IS_DEBUG_PRINT, `\nPaymentGateways-DIALOG: renderCheckboxes(): (#${this.props.providerId})`)

    gatewayItems.forEach((item: IGatewayItem) => {
      const isChecked: boolean = this.state[item.gateway]
      jsxCheckboxes.push(this.myCheckbox(item.gateway, `${item.gateway} - (${item.descr})`, isChecked))

      debugPrint(IS_DEBUG_PRINT, `gatewayItem name: "${item.gateway}", state: ${isChecked}`)
    })

    return (
      <div className="ConnectProvider__payment_gateways">
        <h3>Payment Gateways</h3>
        <div className="ConnectProvider__dialog_checkboxes">{jsxCheckboxes}</div>
      </div>
    )
  }

  myCheckbox = (id: PaymentGateway, label: string, isChecked: boolean): JSX.Element => {
    return (
      <div key={`key-${label}`}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked: boolean = event.target.checked

                switch (id) {
                  case 'Stripe':
                    this.setState({ Stripe: isChecked })
                    break
                  case 'V4':
                    this.setState({ V4: isChecked })
                    break
                  case 'B2B':
                    this.setState({ B2B: isChecked })
                    break
                  default:
                    console.error('No case for PaymentGateway: ' + id)
                }
              }}
              name={label}
              color="primary"
            />
          }
          label={label}
        />
      </div>
    )
  }

  handleOKButton = () => {
    this.props.onClose()

    const selectedGateways: PaymentGateway[] = this.statesToPaymentGateways()
    this.props.callbackSetFieldValue('paymentGateways', selectedGateways)
  }

  handleCancelButton = () => {
    this.props.onClose()
    this.setToInitialStates()
  }

  /**
   * @returns All checked gateways as an array, if no checkboxes have
   * been checked then 'NONE' is returned.
   */
  statesToPaymentGateways = (): PaymentGateway[] => {
    const arrayGateways: PaymentGateway[] = []

    gatewayItems.forEach((item: IGatewayItem) => {
      const gateway = item.gateway
      if (this.state[gateway]) {
        arrayGateways.push(gateway)
      }
    })

    if (arrayGateways && arrayGateways.length <= 0) arrayGateways.push('NONE')

    return arrayGateways
  }
}

export default PaymentGatewaysDialog
