import { IUserResponse } from '@omnicar/sam-types'
import IApiModel, { createModel, IApiField } from './apiModel'
import { contactPersonModel, contactPersonValueDefaults } from './contactPerson'
import { emailPattern, requiredPrimaryKey, requiredString } from './validations'

// API Interfaces -> Just direct exports to keep import proxied here
// as we also have default values, filters and models

export interface IUserRequestGet {
  contractProviderId?: number
  search?: string
}

// Default Values
export const userValueDefaults: IUserResponse = {
  userId: -1,
  email: '',
  userRole: 'customer',
  disabledAt: null,
  contactPerson: contactPersonValueDefaults,
}

// API Model
const model: IApiModel = {
  name: 'userModel',
  fields: [
    {
      name: 'userId',
      uiType: 'string',
      title: 'User ID',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'userRole',
      uiType: 'string',
      title: 'Role',
      validation: [requiredString],
    },
    {
      name: 'email',
      uiType: 'string',
      title: 'Email',
      validation: [requiredString, emailPattern],
    },
    {
      name: 'disabledAt',
      uiType: 'string',
      title: 'Disabled At',
    },
    {
      name: 'contactPerson',
      uiType: 'string',
      title: 'Contact Person',
      relation: 'ContactPerson',
    },
  ],
  view: {
    providerPage: [
      {
        name: 'userId',
      },
      {
        name: 'userRole',
      },
      {
        name: 'email',
      },
      {
        name: 'disabledAt',
      },
    ],
  },
}

const contactPerson = contactPersonModel()

export const userModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IUserResponse>(view, viewContext, model, userValueDefaults, {
    ContactPerson: { name: 'ContactPerson', model: contactPerson },
  })
