import React from 'react'
import { IReleaseNoteResponse } from '@omnicar/sam-types'
import { ReleaseTag } from '@omnicar/sam-types'

interface IProps {
  notes: IReleaseNoteResponse[]
}

const findEnDescription = (obj: IReleaseNoteResponse): string | undefined =>
  obj.descriptions.find((e) => e.locale === 'en-GB')?.description

const ReleaseNote: React.SFC<IProps> = ({ notes }) => {
  return (
    <ul className="ReleaseNotes__release-list">
      {notes.map((note, i) => {
        const tag = ReleaseTag[note.releaseTag]
        const description = findEnDescription(note)
        return (
          <li key={i}>
            <span className={`ReleaseNotes__release-tag__${tag}`}>{tag}</span>
            <h5>{description} </h5>
          </li>
        )
      })}
    </ul>
  )
}

export default ReleaseNote
