import React, { MouseEvent, KeyboardEvent } from 'react'
import './ActionButton.css'

interface IProps {
  className?: string
  disabled?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => void
  text?: string
  //type?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class ActionButton extends React.Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    className: 'ActionButton',
    type: 'button',
  }

  public render() {
    const { className, disabled, text, type } = this.props
    const { handleMouseClick, handleKeyboardUp } = this

    return (
      <button
        className={className}
        disabled={disabled}
        onClick={handleMouseClick}
        onKeyUp={handleKeyboardUp}
        type={type}
      >
        {text && text}
      </button>
    )
  }

  private handleMouseClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()

    const { onClick } = this.props

    onClick && onClick(event)
  }

  private handleKeyboardUp = (event: KeyboardEvent<HTMLButtonElement>) => {
    // keyCode 13 is the enter key
    if (event.keyCode === 13) {
      const { onClick } = this.props

      onClick && onClick(event)
    }
  }
}

export default ActionButton
