import { IContractProviderListItemResponse, ICountryResponse, ProviderState } from '@omnicar/sam-types'
import debounce from 'lodash.debounce'
import React, { ChangeEvent, KeyboardEvent, MouseEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import { IProviderPageState } from 'reducers/providerPage/providerPageInitialState'
import ActionTypes from '../../../actions/ActionTypes'
import {
  countryListGet,
  IProviderPageRouteUpdate,
  IProviderPageUpdate,
  providerListGet,
  providerPageRouteUpdate,
  providerPageUpdate,
} from '../../../actions/providerPageActions'
import { contractProviderModel, IContractProviderRequestGet } from '../../../apiModels/contractProvider'
import SortableTable from '../../../components/SortableTable/SortableTable'
import { IRootState } from '../../../reducers/initialState'
import './PPProviderList.css'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import IApiModel from 'apiModels/apiModel'
import Select, { ValueType } from 'react-select'
import { resolveCountryOptionsMap } from 'utils/country'
import CreateDefaultProviderDialog from '../../../components/Dialog/CreateDefaultProviderDialog'
import { getLocalStoreItem, setLocalStoreItem } from '../../../utils/localStorage'
import PPProviderFilters, { ProviderFilter } from './PPProviderFilters/PPProviderFilters'

const allCountriesOption = { value: '', label: 'All' } as CountryOption

interface CountryOption {
  value: string
  label: string
}

const KeyCode = {
  Enter: 13,
  Cancel: 27,
}

const styles = () =>
  createStyles({
    checkboxesContainer: {
      display: 'flex',
    },
    checkboxItem: {
      display: 'flex',
      color: '#222',
    },
    checkboxlabel: {
      minWidth: '210px',
      width: 'auto',
    },
    checkbox: {
      paddingTop: '0',
      paddingBottom: '0',
      textAlign: 'right',
      marginRight: '15px',
    },
    inactiveProviderRow: {
      backgroundColor: '#ff8e64',
      '&:hover': {
        backgroundColor: '#ff6e38',
      },
    },
    activeFilterClickedColor: {
      borderColor: '#0dac64',
      backgroundColor: '#0dac64 !important',
    },
    acitveFilterColor: {
      backgroundColor: 'gray',
      color: 'white',
    },
    inactiveFilterClickedColor: {
      backgroundColor: '#f95113 !important',
    },
    inactiveFilterColor: {
      backgroundColor: 'gray',
      color: 'white',
    },
  })

interface IReducerProps {
  providerList: IContractProviderListItemResponse[]
  providerId: number
  countryList: ICountryResponse[]
}

interface IActionProps {
  providerListGet: (request?: IContractProviderRequestGet) => Promise<void>
  providerPageUpdate: (props: IProviderPageState) => IProviderPageUpdate
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
  countryListGet: () => Promise<void>
}

interface IState {
  createDefaultProviderPopupOpen: boolean
  activeCountryOption: CountryOption
  searchQuery: string
  activeFilter?: ProviderFilter
}
const stateDefault: IState = {
  createDefaultProviderPopupOpen: false,
  activeCountryOption: allCountriesOption,
  searchQuery: '',
  activeFilter: undefined,
}

type TProps = IReducerProps & IActionProps & WithStyles<typeof styles>

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPProviderList extends React.Component<TProps, IState> {
  isShowCheckboxInProviderListArray: { name: string; title: string }[] = []

  constructor(props: TProps) {
    super(props)
    this.state = { ...stateDefault }

    this.searchProviderList = debounce(this.searchProviderList, 300)
  }

  public componentDidMount() {
    const activeFilter = this.getActiveProvidersFilter()
    this.props.countryListGet()
    const activeCountryValue = getLocalStoreItem('countryOption') || allCountriesOption.value
    this.searchProviderList('', activeCountryValue, activeFilter.key as ProviderState)
    const preSelectedOption = this.resolveSelectedCountryOption(activeCountryValue)

    this.setState({ activeCountryOption: preSelectedOption, activeFilter: activeFilter })
  }

  public render() {
    const { providerList, countryList, classes } = this.props
    const { activeFilter } = this.state

    const model: IApiModel = contractProviderModel('providerPage')
    const isTest = process.env.REACT_APP_ENVIRONMENT !== 'production'

    return (
      <div className="PPProviderList">
        <header className="PPProviderList__header">
          <div className="PPProviderList__features">
            <button
              className="PPProviderList__createbutton"
              onClick={this.handleCreateMouse}
              onKeyUp={this.handleCreateKeyboard}
            >
              Create New Provider
            </button>
          </div>
          {isTest && (
            <div className="PPProviderList__features">
              <button
                className="PPProviderList__createbutton"
                onClick={this.handleCreateDefaultMouse}
                onKeyUp={this.handleCreateDefaultKeyboard}
              >
                Create Default Test Provider
              </button>
              {this.state.createDefaultProviderPopupOpen && (
                <CreateDefaultProviderDialog
                  open={this.state.createDefaultProviderPopupOpen === true}
                  onClose={this.handleDefaultProverPopupMouseClose}
                  countryList={countryList}
                />
              )}
            </div>
          )}
        </header>
        {/* Filter buttons */}
        <div className="PPProviderList__displayOptionsRow">
          <div className="PPProviderList__options">
            <div style={{ color: '#777', paddingLeft: '20px' }}>View: </div>
            <Select
              className="Select"
              classNamePrefix="Select"
              onChange={(option: ValueType<CountryOption, any>) =>
                this.handleCountryChange((option as CountryOption).value)
              }
              value={this.state.activeCountryOption}
              options={[allCountriesOption, ...resolveCountryOptionsMap(countryList)]}
            />
            <input
              className="PPProviderList__searchfield"
              size={40}
              placeholder="Search Providers"
              onChange={this.handleSearchQuery}
            />
          </div>

          <PPProviderFilters
            filters={this.getProviderFilters()}
            onChange={this.handleFiltersChanged}
            activeFilter={activeFilter}
          />
        </div>
        <main className="PPProviderList__content">
          <SortableTable
            primaryKey="contractProviderId"
            data={providerList}
            model={model}
            customStyling={[{ key: 'state', value: 'Inactive', class: classes.inactiveProviderRow }]}
            onRowClick={this.handleRowClick}
          />
        </main>
      </div>
    )
  }

  private handleFiltersChanged = async (activeFilter: ProviderFilter | undefined) => {
    const { searchQuery, activeCountryOption } = this.state
    this.setState({ activeFilter })
    this.searchProviderList(searchQuery, activeCountryOption.value, activeFilter && (activeFilter.key as ProviderState))
  }

  private getActiveProvidersFilter = (): ProviderFilter => {
    return {
      title: 'Active Providers',
      colorClass: this.props.classes.acitveFilterColor,
      activeColorClass: this.props.classes.activeFilterClickedColor,
      key: 'Active',
    }
  }

  private getProviderFilters = (): ProviderFilter[] => {
    return [
      this.getActiveProvidersFilter(),
      {
        title: 'Inactive Providers',
        colorClass: this.props.classes.inactiveFilterColor,
        activeColorClass: this.props.classes.inactiveFilterClickedColor,
        key: 'Inactive',
      },
    ]
  }

  private resolveSelectedCountryOption = (option: string) =>
    option ? { value: option, label: option } : allCountriesOption

  private persistCountryOption = (option: string) => setLocalStoreItem('countryOption', option)

  private searchProviderList = (search: string, country: string, state?: ProviderState) => {
    this.persistCountryOption(country)
    this.props.providerListGet({ search, country, state })
  }

  private handleCountryChange = (activeCountryValue: string | undefined) => {
    if (!activeCountryValue && activeCountryValue !== '') {
      return
    }
    const { searchQuery, activeFilter } = this.state
    const selectedOption = this.resolveSelectedCountryOption(activeCountryValue)
    this.setState({ activeCountryOption: selectedOption })
    this.searchProviderList(searchQuery, activeCountryValue, activeFilter && (activeFilter.key as ProviderState))
  }

  private handleSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const { activeCountryOption, activeFilter } = this.state
    const searchQuery = (event.target && event.target.value) || ''
    this.setState({ searchQuery })
    this.searchProviderList(searchQuery, activeCountryOption.value, activeFilter && (activeFilter.key as ProviderState))
  }

  private handleCreateMouse = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()

    this.props.providerPageRouteUpdate('provider-create')
  }

  private handleCreateDefaultMouse = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    this.setState({ createDefaultProviderPopupOpen: true })
  }

  private handleCreateDefaultKeyboard = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.keyCode === KeyCode.Enter) {
      this.setState({ createDefaultProviderPopupOpen: true })
    }
  }

  private handleDefaultProverPopupMouseClose = () => {
    this.setState({ createDefaultProviderPopupOpen: false })
  }

  private handleCreateKeyboard = (event: KeyboardEvent<HTMLButtonElement>) => {
    // keyCode 13 is the enter key
    if (event.keyCode === KeyCode.Enter) {
      this.props.providerPageRouteUpdate('provider-create')
    }
  }

  private handleRowClick = (record: any) => {
    if (record && record.contractProviderId) {
      const { contractProviderId } = record

      this.props.providerPageUpdate({
        providerId: contractProviderId,
        route: 'provider-details',
        stripeDetails: null,
      })
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerId: state.providerPage.providerId,
  providerList: state.providerPage.providerList,
  countryList: state.providerPage.countryList,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  providerListGet: (request: IContractProviderRequestGet) => dispatch(providerListGet(request)),
  providerPageUpdate: (props: IProviderPageState) => dispatch(providerPageUpdate(props)),
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
  countryListGet: () => dispatch(countryListGet()),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PPProviderList))
