import React from 'react'
import { connect } from 'react-redux'
import { IRootState } from 'reducers/initialState'
import Equalizer from '../Animation/Equalizer/Equalizer'
import './TransactionOverlay.css'

interface IProps {
  transaction: string
}

const TransactionOverlay: React.SFC<IProps> = ({ transaction }) => (
  <div className={`TransactionOverlay ${transaction !== '' ? 'TransactionOverlay--show' : ''}`}>
    <div className="TransactionOverlay__background" />
    <div className="TransactionOverlay__animation">
      <Equalizer title={transaction} />
    </div>
  </div>
)

const mapStateToProps = (state: IRootState) => ({
  transaction: state.application.transaction,
})

export default connect(mapStateToProps, {})(TransactionOverlay)
