import React from 'react'
import './Equalizer.css'

interface IProps {
  title?: string
}

const Equalizer: React.SFC<IProps> = ({ title }) => (
  <div className="Equalizer">
    <div className="Equalizer__animation">
      <div className="Equalizer__animation-1" />
      <div className="Equalizer__animation-2" />
      <div className="Equalizer__animation-3" />
      <div className="Equalizer__animation-4" />
      <div className="Equalizer__animation-5" />
    </div>
    <div className="Equalizer__text">{title && title}</div>
  </div>
)

export default Equalizer
