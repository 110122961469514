import { IApplicationState } from 'reducers/application/applicationInitialState'
import ActionTypeKeys from './ActionTypeKeys'

/*************************************************************
 * PAGE ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

export interface IApplicationProps extends IApplicationState {}

export interface IApplicationUpdate {
  readonly type: ActionTypeKeys.APPLICATION_UPDATE
  readonly props: IApplicationProps
}
export const applicationUpdate = (props: IApplicationProps) => {
  return {
    type: ActionTypeKeys.APPLICATION_UPDATE,
    props,
  }
}

export interface IApplicationReset {
  readonly type: ActionTypeKeys.APPLICATION_RESET
}
export const applicationReset = (): IApplicationReset => {
  return {
    type: ActionTypeKeys.APPLICATION_RESET,
  }
}

export interface IApplicationTransactionUpdate {
  readonly type: ActionTypeKeys.APPLICATION_TRANSACTION_UPDATE
  readonly transaction: string
}
export const applicationTransactionUpdate = (transaction: string): IApplicationTransactionUpdate => {
  return {
    type: ActionTypeKeys.APPLICATION_TRANSACTION_UPDATE,
    transaction,
  }
}

export interface IApplicationTransactionReset {
  readonly type: ActionTypeKeys.APPLICATION_TRANSACTION_RESET
}
export const applicationTransactionReset = (): IApplicationTransactionReset => {
  return {
    type: ActionTypeKeys.APPLICATION_TRANSACTION_RESET,
  }
}

export interface IApplicationNetworkErrorUpdate {
  readonly type: ActionTypeKeys.APPLICATION_NETWORK_ERROR_UPDATE
  readonly networkError: boolean
}
export const applicationNetworkErrorUpdate = (networkError: boolean): IApplicationNetworkErrorUpdate => {
  return {
    type: ActionTypeKeys.APPLICATION_NETWORK_ERROR_UPDATE,
    networkError,
  }
}

export interface IApplicationNetworkErrorReset {
  readonly type: ActionTypeKeys.APPLICATION_NETWORK_ERROR_RESET
}
export const applicationNetworkErrorReset = (): IApplicationNetworkErrorReset => {
  return {
    type: ActionTypeKeys.APPLICATION_NETWORK_ERROR_RESET,
  }
}
