import { applicationInitialState, IApplicationState } from './application/applicationInitialState'
import { providerPageInitialState, IProviderPageState } from './providerPage/providerPageInitialState'
import { reactRouterInitialState } from './reactRouter/reactRouterInitialState'
import { RouterState } from 'connected-react-router'
import userInitialState, { IUserState } from './user/initialState'
import { IOutageResponse } from '@omnicar/sam-types'

export interface IRootState {
  readonly application: IApplicationState
  readonly providerPage: IProviderPageState
  readonly router: RouterState
  readonly user: IUserState
  readonly outage: IOutageResponse | null
}

export const initialState: IRootState = {
  application: applicationInitialState,
  providerPage: providerPageInitialState,
  router: reactRouterInitialState,
  user: userInitialState,
  outage: null,
}
