import React, { ChangeEvent } from 'react'
import { IApiErrors } from '../../../apiModels/apiModel'
import FormSection from '../../FormSection/FormSection'
import SelectField from '../../Field/SelectField/SelectField'
import { BaseOption as Option } from 'types'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean | string
  value: number | null
  optionsList: Option[]
  create: boolean | undefined
}

const defaultOption: Option = {
  value: null,
  label: 'NONE',
}

const ParentProvider: React.SFC<IProps> = ({
  edit,
  errors = {},
  name,
  title,
  value,
  onBlur,
  onChange,
  optionsList,
  create,
}) => (
  <div className="ParentProvider">
    <FormSection name="general" classNamePrefix="ParentProvider" title={title}>
      <SelectField
        defaultValue={null}
        className="ParentProvider"
        disabled={!edit || !create}
        errors={errors.parentProvider}
        locked={!edit}
        name={name || 'parentProviderId'}
        onBlur={onBlur}
        onChange={onChange}
        title="Main Provider"
        placeholder="Select main provider"
        options={[defaultOption, ...optionsList]}
        value={value}
      />
    </FormSection>
  </div>
)

export default ParentProvider
