import React from 'react'
import { getTranslationValues, patchTranslationValue, createTranslationValue } from 'api/api'
import { IsoLocale, ITranslationValueResponse, ITranslationResponse } from '@omnicar/sam-types'
import TranslationValue from 'pages/TranslationPage/Form/TranslationValue'
import { Typography } from '@material-ui/core'
import { translationValueDefaults } from 'apiModels/translationValue'
import { isEqual } from 'lodash'
import { showSuccess } from 'utils/toastify'

// const locales: IsoLocale[] = ['da-DK', 'sv-SE', 'fi-FI', 'nb-NO', 'en-GB'] // 'en-GB' is deprecated.
const locales: IsoLocale[] = ['da-DK', 'sv-SE', 'fi-FI', 'nb-NO', 'en']

interface IProps {
  translation: ITranslationResponse | undefined
}

interface IState {
  values: ITranslationValueResponse[]
  originalValues: ITranslationValueResponse[]
}

class TranslationValuesList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      values: [],
      originalValues: [],
    }
  }

  public componentDidMount() {
    this.fetchTranslationValues()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.translation?.id !== this.props.translation?.id) {
      this.fetchTranslationValues()
    }
  }

  render() {
    const { translation } = this.props

    return (
      <div className="TranslationValues__inner">
        {!translation && (
          <Typography variant="h6" className="TranslationValues__title">
            You have to create TranslationKey before values!
          </Typography>
        )}
        <button
          className="TranslationValue__submitbutton"
          disabled={this.disableSubmit()}
          onClick={this.handleSubmit}
          type="submit"
        >
          Update translations
        </button>

        {locales.map((l) => (
          <TranslationValue
            key={l}
            value={this.getTranslationValue(l)}
            locale={l}
            onChange={this.handleValuesUpdate}
            disabled={!translation}
          />
        ))}
      </div>
    )
  }

  private getTranslationValue = (locale: IsoLocale): ITranslationValueResponse =>
    this.state.values.find((v) => v.locale === locale) || { ...translationValueDefaults, locale }

  private disableSubmit = () => {
    const { originalValues, values } = this.state
    const { translation } = this.props

    return (
      !translation ||
      isEqual(
        values.filter((v) => v.value),
        originalValues,
      )
    )
  }

  handleSubmit = async () => {
    const { values } = this.state
    const { translation } = this.props

    if (!translation || this.disableSubmit()) {
      return
    }

    const validValues = values.filter((v) => v.value && this.checkIsValueModified(v))

    const valuesFromResponse = await Promise.all(
      validValues.map(async (value) => {
        const { statusCode, data } =
          value.id > 0
            ? await patchTranslationValue(value.id, value)
            : await createTranslationValue(translation.id, value)
        return (statusCode === 200 && data) || undefined
      }),
    )

    if (!valuesFromResponse.some((v) => !v)) {
      showSuccess('Values update succeeded')
    }

    await this.fetchTranslationValues()
  }

  private checkIsValueModified = (value: ITranslationValueResponse): boolean => {
    if (value.id < 0) {
      return true
    }

    const originValue = this.state.originalValues.find((v) => v.id === value.id)

    return !isEqual(originValue, value)
  }

  handleValuesUpdate = (value: ITranslationValueResponse) =>
    this.setState(({ values }) => {
      const currentValue =
        value.id > 0 ? values.find((v) => v.id === value.id) : values.find((v) => v.locale === value.locale)

      return currentValue
        ? { values: values.map((v) => (v.locale === value.locale ? value : v)) }
        : { values: [...values, value] }
    })

  private async fetchTranslationValues() {
    const { translation } = this.props

    if (!translation) {
      this.setState({ values: [], originalValues: [] })
      return
    }

    const { statusCode, data } = await getTranslationValues(translation.id)
    const values = (statusCode === 200 && data) || []

    this.setState({ values, originalValues: values })
  }
}

export default TranslationValuesList
