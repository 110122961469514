import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'

interface IOwnProps {
  open: boolean
  onCancel: () => void
  onOk: () => Promise<void>
  titleText: string
  mainText: string
  cancelText: string
  okText: string
}

type TProps = IOwnProps

const AlertDialog: React.SFC<TProps> = ({ open, onCancel, onOk, titleText, mainText, cancelText, okText }) => {
  return (
    <Dialog style={{ visibility: 'visible' }} open={open} onClose={onCancel}>
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent>{mainText}</DialogContent>
      <DialogActions>
        <Button onClick={onOk} color="primary" autoFocus={true}>
          {okText}
        </Button>
        <Button onClick={onCancel} color="primary">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
