import React from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import { RadioGroup, Radio } from 'react-radio-group'
import './RadioGroupField.css'
import { Field, FieldProps } from 'formik'
import { BaseOption as Option } from 'types'

interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: any) => void
  options: Option[]
  placeholder?: string
  value?: number | string
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class RadioGroupField extends React.Component<IProps> {
  // tslint:disable jsx-no-lambda
  public render() {
    const { options } = this.props
    const { disabled, locked, name, onChange, value, placeholder } = this.props

    let radioItems: any = []

    options.forEach((option: any, index: number) => {
      radioItems.push(
        <div key={index}>
          <Radio className="RadioGroupField__radio" value={option.value} disabled={disabled || locked} />
          &nbsp;{option.label}
        </div>,
      )
    })

    return (
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          return (
            <RadioGroup
              placeholder={placeholder}
              selectedValue={field.value ? field.value : value}
              disabled={disabled || locked}
              onChange={(selectedOption: Option) => {
                form.setFieldValue(field.name, selectedOption)

                // If an onChange(..) function was provided, then call it.
                if (onChange) {
                  onChange(selectedOption)
                }
              }}
            >
              {radioItems}
            </RadioGroup>
          )
        }}
      </Field>
    )
  }
}

export default withStyledField(RadioGroupField, 'RadioGroupField')
