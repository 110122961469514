import React, { ChangeEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './TextareaField.css'

interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  maxLength?: number
  name: string
  onBlur?: (e: any) => void
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  cols?: number
  value?: string
}

const TextareaField: React.SFC<IProps> = ({
  className,
  disabled,
  hidden,
  maxLength,
  name,
  onBlur,
  onChange,
  cols,
  value,
}) => (
  <textarea
    className={className}
    disabled={disabled}
    hidden={hidden}
    id={name}
    maxLength={maxLength}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    cols={cols}
  />
)

TextareaField.defaultProps = {
  className: '',
  disabled: false,
  hidden: false,
  value: '',
}

export default withStyledField(TextareaField, 'TextareaField')
