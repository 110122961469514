import React from 'react'
import { deleteAccessKey, getAccessKeys } from 'api/api'
import { IAccessKey } from '@omnicar/sam-types'
import AccessKeysList from 'pages/AccessKeyPage/List'
import AccessKeyForm from 'pages/AccessKeyPage/Form'
import './AccessKey.css'
import { showSuccess, showWarning } from 'utils/toastify'

interface IState {
  accessKeys: IAccessKey[]
  showCreationForm: boolean
  currentKey: IAccessKey | undefined
}

class AccessKeyPage extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      accessKeys: [],
      showCreationForm: false,
      currentKey: undefined,
    }
  }

  public componentDidMount() {
    this.fetchAccessKeys()
  }

  render() {
    const { showCreationForm, accessKeys, currentKey } = this.state

    return (
      <div className="AccessKeys__inner">
        <header className="AccessKeys__header">
          <div className="AccessKeys__header-info">
            <h1 className="AccessKeys__title">{showCreationForm ? 'Access key form' : 'Access keys list'}</h1>
          </div>
        </header>
        <div className="AccessKeys__navigation">
          <div className="AccessKeys__management-container">
            {showCreationForm ? (
              <>
                <button className="AccessKeys__returnbutton" onClick={this.handleFormReset}>
                  Back to list
                </button>
                <button className="AccessKeys__createbutton" onClick={this.handleCreate}>
                  Create new
                </button>
              </>
            ) : (
              <button className="AccessKeys__createbutton" onClick={this.handleCreate}>
                Create access key
              </button>
            )}
          </div>
        </div>
        {showCreationForm ? (
          <AccessKeyForm currentAccessKey={currentKey} updateList={this.handleUpdate} onReset={this.handleFormReset} />
        ) : (
          <AccessKeysList accessKeys={accessKeys} onEdit={this.handleEdit} onDelete={this.handleDelete} />
        )}
      </div>
    )
  }

  private handleDelete = async (key: IAccessKey) => {
    const { statusCode } = await deleteAccessKey(key.accessKeyId)

    if (statusCode === 200) {
      this.setState(({ accessKeys }) => ({
        accessKeys: accessKeys.filter((k) => k.accessKeyId !== key.accessKeyId),
      }))
      showSuccess(`Access key "${key.apiKey}" DELETED!`)
    } else {
      showWarning('Cannot delete access key')
    }
  }

  handleUpdate = (key: IAccessKey, keepCurrentKey?: boolean) =>
    this.setState(({ accessKeys, currentKey }) => {
      const newKeys = currentKey
        ? accessKeys.map((k) => (k.accessKeyId === key.accessKeyId ? key : k))
        : [key, ...accessKeys]
      const newState = keepCurrentKey ? { currentKey: key } : { currentKey: undefined, showCreationForm: false }

      return {
        accessKeys: newKeys,
        ...newState,
      }
    })

  handleEdit = (currentKey: IAccessKey) =>
    this.setState({
      currentKey,
      showCreationForm: true,
    })

  handleFormReset = async () =>
    this.setState({
      showCreationForm: false,
      currentKey: undefined,
    })

  handleCreate = () => this.setState({ showCreationForm: true, currentKey: undefined })

  fetchAccessKeys = async () => {
    const { statusCode, data } = await getAccessKeys()
    const accessKeys = (statusCode === 200 && data) || []
    this.setState({ accessKeys })
  }
}

export default AccessKeyPage
