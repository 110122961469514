import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredString } from './validations'
import { IStripeAddress } from '@omnicar/sam-types'

// Default Values
export const stripeAddressDefaults: IStripeAddress = {
  city: '',
  country: 'DK',
  line1: '',
  line2: null,
  postalCode: '',
  state: null,
}

// API Model
const model: IApiModel = {
  name: 'stripeAddressModel',
  fields: [
    {
      name: 'city',
      uiType: 'string',
      title: 'City',
      validation: [requiredString],
    },
    {
      name: 'country',
      uiType: 'string',
      title: 'Country',
      validation: [requiredString],
    },
    {
      name: 'line1',
      uiType: 'string',
      title: 'Address 1',
    },
    {
      name: 'line2',
      uiType: 'string',
      title: 'Address 2',
    },
    {
      name: 'postalCode',
      uiType: 'string',
      title: 'Postal Code',
      validation: [requiredString],
    },
    {
      name: 'state',
      uiType: 'string',
      title: 'State',
    },
  ],
  view: {},
}

export const stripeAddressModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeAddress>(view, viewContext, model, stripeAddressDefaults)
